import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InfoIcon from "../../../assets/infoIcon";
import { LightTooltip, useStylesCreation } from "./style";

export const CardDetails = (props) => {
  const classes = useStylesCreation(props);
  const { t } = props;
  // let count = props?.data?.count?.toString().replace(/₹/g, "");
  // count = "₹" + Math.round(Number(count)).toLocaleString();
  return (
    <div className={classes.cardDetail}>
      <Grid container spacing={2} alignItems="center">

        {props?.data?.is_count ? (
          <Grid item xs={9}>
            <Typography
              className={classes.detailsCard2}
              sx={{
                width: props?.width ?? "auto",
              }}
            >
              {props?.data?.area}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={9}>
            {props?.data?.sub?.length > 0 && (
              <Typography className={classes.countCard} noWrap>
                {props?.data?.count}
              </Typography>
            )}
            {props?.data?.label?.length > 0 && (
              <LightTooltip placement="top" title={props?.data?.count}>
                <Typography className={classes.countCard} noWrap>{props?.data?.count}</Typography>
              </LightTooltip>
            )}
          </Grid>
        )}
        <Grid item xs={3} justifyContent={"end"} alignItems="end" display={"flex"}>
          {props?.data?.is_count > 0 ? (
            <Typography className={classes.areaMeter}>
              {props?.data?.data}
            </Typography>
          ) : (
            <>
              {props?.data?.image_type ? (
                <img src={props?.data?.image} alt="images" />
              ) : props?.data?.icon_type ? (
                <>{props?.data?.icon}</>
              ) : (
                <Typography>{props?.data?.image}</Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Box className={classes.detailsCard1}>
        {props?.data?.sub?.length > 0 && (
          <Box>
            <Typography
              className={classes.detailsCard2}
              style={{ ...props?.sub_style, maxWidth: "unset" }}
            >
              {props?.data?.sub ?? props?.data?.sub}
            </Typography>
          </Box>
        )}
        {props?.data?.label?.length > 0 && (
          <Box>
            <Typography
              className={classes.detailsCard2}
              style={{ ...props?.label_style }}
            >
              {props?.data?.label ?? props?.data?.label}
            </Typography>
          </Box>
        )}
        {props?.data?.info?.length > 0 && (
          <LightTooltip title={props?.data?.info} placement="top">
            <Box className={classes.info_icon}>
              <InfoIcon />
            </Box>
          </LightTooltip>
        )}
      </Box>
      {props?.data?.isView && (
        <Box className={classes.viewBox} onClick={props?.onClick}>
          <Typography className={classes.viewTxt}>{t("View")}</Typography>
        </Box>
      )}
    </div>
  );
};
