import React from "react"
import { Steps } from "../../../screens/residentOnboarding/steps"
import ContactIcon from "../../../screens/residentOnboarding/utils/contactIcon"
import { boardingPropertyType } from "../../../screens/residentOnboarding/utils/residentOnboard"
import { enumSelect, enum_types, wrapLabelsInT } from "../../../utils"
import { returnremoveExisitContact } from "../utils"
// import { useStyles } from "../styles";
// import { Typography } from "@mui/material"

export const Contact = ({ dataNew, updateStateNew, company_id, setDataNew, is_read_only, is_quotation = false, is_convert = false, t = () => false, moduleId = "" }) => {
    const is_workspace = ['49', '35'].includes(moduleId)
    // const classes = useStyles()

    const [enumValue, setEnumValue] = React.useState({
        revenue_type: []
    })

    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.revenue_type, enum_types.purpose_global_type])
        if (is_workspace) {
            setEnumValue({
                revenue_type: result?.revenue_type?.filter((x) => x?.value === "Lease"),
            })
        }
        else {
            setEnumValue({
                revenue_type: result?.revenue_type?.filter((x) => x?.value !== "Stay"),

            })
        }
    }

    const handleContact = (val) => {
        let data = returnremoveExisitContact(val);

        setDataNew({
            ...dataNew,
            ...data,
            is_exist: val
        })

    }
    React.useEffect(() => {
        getEnum()
        if(is_workspace){
            updateStateNew("purpose",{title:"Commercial",label:"Commercial",value:"Commercial"},"revenue_type",{label:"Lease",value:"Lease"})
        }
        //eslint-disable-next-line
    }, [])

    const contactOprions = [
        {
            label: t("Existing Contact"),
            icon: <ContactIcon />,
            value: true
        },
        {
            label: t("Create New Prospect"),
            icon: <ContactIcon />,
            value: false
        },
    ]

    return (
        <>
            {/* {!is_quotation &&
                <Typography className={classes.labelBold}>Prospective Details</Typography>
            } */}


            <Steps
                t={t}
                component={[
                    {
                        type: "propertyType",
                        data: is_workspace ? boardingPropertyType?.filter((x) => { return x?.value !== "Residential" }) : boardingPropertyType,
                        selected: dataNew?.purpose,
                        handleClick: (value) => updateStateNew("purpose", value),
                        header: t("Purpose"),
                        error: dataNew?.error?.purpose?.length > 0,
                        errorMsg: dataNew?.error?.purpose,
                        isActive: !is_quotation,
                        isRequired: true,
                        isReadOnly: is_read_only,

                    },
                    {
                        type: "toggleButton",
                        header: t("Revenue Type"),
                        value: dataNew?.revenue_type?.value,
                        handleChange: (value) => updateStateNew("revenue_type", { value: value, label: value }),
                        option: wrapLabelsInT(enumValue?.revenue_type, t),
                        isActive: !is_quotation,
                        isrequired: true,
                        isError: dataNew?.error?.revenue_type?.length > 0,
                        errorMessage: dataNew?.error?.revenue_type,
                        isReadOnly: is_read_only,
                        sm: 12,
                        md: 12,
                        lg: 12
                    },
                    {
                        type: "contactTypeSelect",
                        onChange: (value) => handleContact(value),
                        value: dataNew?.is_exist,
                        isActive: !is_convert,
                        options: contactOprions
                    }
                ]}
            />
        </>
    )
}