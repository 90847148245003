import gql from "graphql-tag";

export let SELECT_CONTACT = (offset, limit, searchText, obj, id = "") => {
  return gql`
  query SELECT_CONTACT{
    count: contact(
      where:{
          is_active:true
          client:"${obj?.client}",
          ${!!obj?.company_id ? `company_id:"${obj?.company_id}"` : ''}
  
          or: {
              first_name: {iregex:"${searchText}"}
              last_name: {iregex:"${searchText}"}
              email_id: {iregex:"${searchText}"}
              mobile_no: {iregex:"${searchText}"}
            }
      }
    ) {
      count:count_id
    }
    contact(
        where:{
          is_active:true
          client:"${obj?.client}",
          ${!!obj?.company_id ? `company_id:"${obj?.company_id}"` : ''}
          or: {
            first_name: {iregex:"${searchText}"}
            last_name: {iregex:"${searchText}"}
            email_id: {iregex:"${searchText}"}
            mobile_no: {iregex:"${searchText}"}
          }
        }
        offset:${offset}
        limit:${limit}
      ){
          id
          mobile_code:mobile_no_country_code
          value1:email_id
          email_id
          label:first_name
          value:id,
          email:email_id,
          mobileNo:mobile_no,
          mobile_no_country_code,
          url:image_url
          image_url
      first_name
      last_name
      mobile_no
      mobile_no_country_code
      alternative_mobile
      alternative_mobile_country_code
      fax_no
      facebook
      twitter
      linkedin
      phone
      phone_code
      email_id
      email:email_id
      image_url
      street_1
      street_2
      street_3
      city
      district
      state
      country
      pincode:zipcode
      latitude
      longitude
      phone_code
      address{
        door_no
       street_1
        street_2
      street_3
        country
        state
        district
      }
        }
  }
  `
}

export let SELECT_ACCOUNT = (offset, limit, searchText, obj, id = "") => gql`
query SELECT_CONTACT{
  count: contact_account(
    where:{
        is_active:true
        client:"${obj?.client}",
        name: {iregex:"${searchText}"}
        relationship:Customer
        ${!!obj?.company_id ? `company_id:"${obj?.company_id}"` : ''}

    

    }
  ) {
    count:count_id
  }
  contact_account(
      where:{
        is_active:true
        client:"${obj?.client}",
        name: {iregex:"${searchText}"}
        relationship:Customer
        ${!!obj?.company_id ? `company_id:"${obj?.company_id}"` : ''}

      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
        value:id,
        mobile_no_country_code:account_no,
        url:logo
        value1:account_no
        id
        account_no
        name
      }
}
`
  ;

export const GET_QUATATION_DETAILS = gql`{
    quotation(where: { id: $id }){
      description
      lead_id
      total_amount
      total_discount
      total_refundable
      total_tax
      lease_start_date
      lease_end_date
      account_no
      revenue_type
      lease_period
      lease_payment_period
      billing_start_date
      quotation_no
      billing_cycle_date
      billing_day
      payment_mode
      bill_generation
      created_at
      quotation_no
      is_active
      grace_period
      is_resend
      company_id
      quote_status
      quotation_assets(where:{is_active:true}){
        url
      }
      lead{
        id
        prospective{
          image_url
          id
          first_name
          last_name
          mobile_no_country_code
          mobile_no
          email_id
        }
      }
    }


    quotation_units(where: { quote_id: $id,is_active:true }){
     id
     unit_id
    }
  }

`;


export const GET_LEAD_DETAILS = gql`{
  lead(where: { id: $id }) {
    id
    lead_type
    value
    lease_start_date
    lease_end_date
    lease_period
    company_id
    value_type
    revenue_type
    unit_usage
    grace_period_type
    grace_period_value
    auto_renewal
    auto_renewal_escalation
    increment_escalation
    urgent
    bill_generation
    billing_cycle_date
    payment_period
    payment_mode
    billing_start_date
    billing_day
    subject
    contact_account
    occupants_type
    contract {
      value:id
      id
      start_date
      end_date
      contract_no
      label:contract_no
      period
  period_value
  contract_scope_of_works(where:{is_active:true}){
    property_id
    unitByID{
      id
      property_id
    }
  }
      accountByID {
        label: name
        value: id
        mobile_no_country_code: account_no
        url: logo
        value1: account_no
        id
        account_no
      }
    }
    lead_units(where: { is_active: true }) {
      id
      unit {
        id
        owner_id
        unit_no
        unit_name: name
        logo
        unit_category {
          value:id
          label:name
        }
        property {
          property_id: id
          property_name: name
          label: name
          property_no
          value: id
          url: logo
          property_no
          label1: total_area
          uom_master {
            name
          }
        }
        contact_account {
          label: name
          value: id
          url: logo
          label1: account_no
        }
      }
    }
  }
}


`;


export let SELECT_BROKER_ACCOUNT = (offset, limit, searchText, obj, id = "") => gql`
query SELECT_CONTACT{
  count: contact_account(
    where:{
        is_active:true
        client:"${obj?.client}",
        name: {iregex:"${searchText}"}
        relationship:"Sales Broker" ,
        company_id:"${obj?.company_id}"
    

    }
  ) {
    count:count_id
  }
  contact_account(
      where:{
        is_active:true
        client:"${obj?.client}",
        name: {iregex:"${searchText}"}
        relationship:"Sales Broker" ,
        company_id:"${obj?.company_id}"
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
        value:id,
        mobile_no_country_code:account_no,
        url:logo
        value1:account_no
        id
        account_no
        logo
        vendor_id
      }
}
`
  ;

  export let SELECT_CONTRACT = (offset, limit, searchText, obj, id = "") => gql`
query SELECT_CONTACT{
  count: contract(
    where:{
        is_active:true
        client:"${obj?.client}",
        contract_no: {iregex:"${searchText}"}
        account_id:"${obj?.account_id}"
        contract_type:3
        status:2
    }
  ) {
    count:count_id
  }
  contract(
    order_by:{ created_at:desc}
      where:{
        is_active:true
        client:"${obj?.client}",
        contract_no: {iregex:"${searchText}"}
        account_id:"${obj?.account_id}"
        contract_type:3
        status:2
      }
      offset:${offset}
      limit:${limit}
    ){
        value:id
        id
        start_date
        end_date
        contract_no
        label:contract_no
        period
    period_value
    contract_scope_of_works(where:{is_active:true}){
      property_id
      unitByID{
        id
        property_id
      }
    }
      }
}
`
  ;

  export let SELECT_ACCOUNT_ALL = (offset, limit, searchText, obj, id = "") => gql`
query SELECT_CONTACT{
  count: contact_account(
    where:{
        is_active:true
        client:"${obj?.client}",
        name: {iregex:"${searchText}"}
        ${!!obj?.company_id ? `company_id:"${obj?.company_id}"` : ''}

    

    }
  ) {
    count:count_id
  }
  contact_account(
    order_by:{ created_at:desc}
      where:{
        is_active:true
        client:"${obj?.client}",
        name: {iregex:"${searchText}"}
        ${!!obj?.company_id ? `company_id:"${obj?.company_id}"` : ''}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
        value:id,
        mobile_no_country_code:account_no,
        url:logo
        value1:account_no
        id
        account_no
        mobile_code:mobile_no_country_code
        mobile_no
        email_id
      }
}
`
  ;





