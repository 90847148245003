import { Box, Grid, Stack, Divider, Button, Dialog, DialogContent, DialogContentText, Typography, Drawer } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next';
import FilterIcon from '../../assets/filterIcon';
import { AlertDialog, FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, enum_types, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import { FreeTextInvoiceTableHeading, FreeTextInvoiceTablePath } from '../../utils/freeTextInvoice';
import styled from '@mui/material/styles/styled';
import { FreeTextInvoiceStyle } from './style'
import StepsContent from '../createInvoiceFreeText/stepsContent';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import { StatusOptionListLang } from '../../utils/accessGates/accessGatesListUtils';
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { useApolloClient } from '@apollo/client';
import { UPDATE_STATUS } from '../../graphql/freeTextInvoice';
import { CreateEditInvoiceDrawer } from './createEditInvoiceDrawer';
import { FreeTextInvoiceContext } from './freeTextInvoiceContext';
import { CreateEditInvoice } from './createEditInvoice';

const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 152px)`,
    overflow: "hidden",
    margin: "16px"
}))

const FreeTextInvoice = ({ t = () => false }) => {
    const defaultFilterState = { status: [true] };

    const {
        freeTextData,
        setFreeTextData,
        stateData,
        getFreeTextInvoice,
        setStateData,
        updateState,
        handleOnClose,
        getCustomerList,
        setInitialType
    } = React.useContext(FreeTextInvoiceContext)

    const classes = FreeTextInvoiceStyle()
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const debounce = UseDebounce()
    const history = useHistory()
    const client = useApolloClient();

    const type = window.location.pathname === Routes.freeTextInvoice ? null : window.location.pathname === Routes.creditNote ? "Credit" : "Debit"
    const Heading = FreeTextInvoiceTableHeading(t)

    const [companyList, setCompanyList] = React.useState([]);
    const [permission, setPermission] = React.useState({})
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)

    const [publish, setPublish] = React.useState(false)
    const [tableData, setTableData] = React.useState({})

    const [filterData, setFilterData] = React.useState(defaultFilterState)
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [confirmation, setComfirmation] = React.useState(false)
    const [selectedRow, setSelectedRow] = React.useState({})

    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);

    React.useEffect(() => {
        const _ = getRoutePermissionNew(auth)
        if (_) {
            setPermission(_)
            if (_?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getCustomerList(0, 10, "", [], [], company?.selected?.value)
                }
            }
        }
        // eslint-disable-next-line        
    }, [auth])


    //   company switcher
    const handleCompanyChange = (e) => {
        setSelectedCompany(e);
        getCustomerList(0, 10, "", [], [], e?.value)

    };

    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getCustomerList(offset, limit, "", [], [], null)
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getCustomerList(0, value, "", [], [], null)
    };


    //apply filter
    const onApplyFilter = (value) => {
        const constructedInvoiceType = value?.invoiceType?.map((data) => {
            return data?.value
        })
        setFilterData(value)
        getCustomerList(0, 10, "", constructedInvoiceType, value?.status, null, value?.accountNo?.label)
    }
    //Table handle row


    const handleTableIcon = (type, data) => {
        if (type === "view") {
            setInitialType(type)
            getFreeTextInvoice(data, type)
            return setTableData(data)


        }
        else if (type === "edit") {
            setInitialType(type)
            getFreeTextInvoice(data, type)
        }
        else if (type === "double_click") {
            setInitialType("view")
            getFreeTextInvoice(data, "view")
            setTableData(data)
        } else if (type === "publish_invoice") {
            setComfirmation(true)
            setSelectedRow(data)
        }
    }

    const publishInvoice = (id = selectedRow?.id) => {
        setIsDisableBtn(true)
        const payload = {
            id: id
        }
        NetworkCall(
            `${config.api_url}/free_text_invoice/published_to`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                updateProspect(id)
                setComfirmation(false)
            })
            .catch((error) => {
                setIsDisableBtn(false)
                console.log(error)
            });

    }

    const updateProspect = (id) => {
        setIsDisableBtn(true)
        const payload = { status: "Published" }
        client
            .mutate({
                mutation: UPDATE_STATUS,
                variables: {
                    id: id,
                    payload: payload,
                },
            })
            .then((rs) => {
                setPublish(!publish)
                setSelectedRow({})
                setIsDisableBtn(false)
                getCustomerList(0, 10, "", [], [], selectedCompany?.value)
            })
            .catch((er) => {
                setIsDisableBtn(false)
                console.log(er);
            });
    };



    const editDetailFromView = () => {
        history.push({
            pathname: Routes.createInvoiceFreeText,
            state: { type: "edit", invoiceDataList: freeTextData?.invoiceDataList },
        })
    }

    const closePublish = () => {
        setPublish(!publish)
        setFreeTextData({ ...freeTextData, view: false })

    }

    const handleSearch = (data) => {
        setFreeTextData({ ...freeTextData, searchText: data })
        debounce(() => getCustomerList(0, 10, data, [], [], null), 800)
    }

    const loadAccountNo = async (search, array, handleLoading) => {
        const payload = {
            company_id: selectedCompany?.value,
            type: [],
            offset: 0,
            limit: 100,
            search: search ?? ""
        };
        let result = NetworkCall(
            `${config.api_url}/account/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            handleLoading();
            let optionss = res?.data?.data?.list?.map((val) => {
                return {
                    value: val?.id,
                    label: val?.account_no
                }
            })
            let array = optionss
            return {
                options: [...array],
                hasMore: false
            }
        }).catch((err) => {
            handleLoading();
            console.log(err)
            return {
                options: [],
                hasMore: false
            }
        })
        return result
    }
    const render = () => {
        return (
            <Box>
                <Subheader
                    title={type === "Credit" ? t("Credit Invoice") : t("Customer Invoice")}
                    hideBackButton={(stateData?.step === 3) ? false : true}
                    select={true}
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e);
                    }}
                    goBack={() => {
                        setStateData({ ...stateData, step: 2, createEditList: false })
                        updateState("createPopup", true)
                    }}
                    isReadOnly={stateData?.step === 3}
                />

                {
                    !stateData?.createEditList ?
                        <CustomPaper>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={4}>
                                    {/*search field*/}
                                    <SearchFilter value={freeTextData?.searchText}
                                        placeholder={t("SearchAccount")}
                                        handleChange={(value) => handleSearch(value)}
                                        height={"40px"}
                                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    {/*Add Btn*/}
                                    <Box display={"flex"} justifyContent={"end"}>
                                        <Stack direction="row"
                                            divider={<Divider orientation="vertical" flexItem variant="middle" sx={{ height: 40, marginInline: "16px", marginTop: "0px" }} />}>
                                            <Box onClick={() => setFilterDrawer(!filterDrawer)}>
                                                <FilterIcon />
                                            </Box>
                                            {permission?.create &&
                                                <Button variant="contained" className={classes.createBtn}
                                                    onClick={() => {
                                                        setInitialType("create")
                                                        setFreeTextData({ ...freeTextData, createPopup: !freeTextData?.createPopup })
                                                    }}>{t("Create")}
                                                </Button>
                                            }
                                        </Stack>
                                    </Box >
                                </Grid >
                            </Grid >

                            <TableWithPagination
                                heading={Heading}
                                rows={freeTextData?.tableRow}
                                path={FreeTextInvoiceTablePath}
                                showpagination={true}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleTableIcon}
                                onClick={() => false}
                                tableType="no-side"
                                dataType={[
                                    { type: ["more_free_text_invoice"], name: "icon" },
                                    { type: ["text"], name: "invoice_no" },
                                    { type: ["text"], name: "date" },
                                    { type: ["text"], name: "type" },
                                    { type: ["text"], name: "account_no" },
                                    { type: ["text"], name: "account_name" },
                                    { type: ["text"], name: "invoice_type" },
                                    { type: ["text"], name: "total_amount_due" },
                                    { type: ["description"], name: "description" },
                                    { type: ["text"], name: "created_by" },
                                    { type: ["kyc_status"], name: "status" }
                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={freeTextData?.count}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 300px)'}
                                view={permission?.read}
                                edit={permission?.update}
                                delete={false}
                                publish_invoice={true}
                                enable_double_click={true}
                            />
                        </CustomPaper > :
                        <CreateEditInvoice />
                }

                {/* <AlertDialog open={freeTextData?.createPopup}
                    onClose={() => setFreeTextData({ ...freeTextData, createPopup: !freeTextData?.createPopup })}
                    header={t("CreateFreeText")}
                    component={<CreatePopup data={createFreeText} />}
                    isNormal
                /> */}

                <AlertDialog open={freeTextData?.view}
                    onClose={() => setFreeTextData({ ...freeTextData, view: !freeTextData?.view })}
                    header={t("View")}
                    component={
                        <Box p={2}>
                            <StepsContent type="view" stateData={freeTextData?.invoiceDataList}
                                editDetailFromView={editDetailFromView} publishInvoice={publishInvoice}
                                viewData={tableData} selectedCompany={selectedCompany} isDisableBtn={isDisableBtn} />
                        </Box>}
                    lg
                />

                <React.Fragment key={'right'}>
                    <Drawer
                        anchor={'right'}
                        open={freeTextData?.createPopup}
                        onClose={() => handleOnClose()}
                    >
                        <CreateEditInvoiceDrawer />
                        {/* <Create t={t} onClose={toggleDrawer} state={state} reload={() => listFunction(0, 10, 1, null)} company_id={state?.SelectedCompany?.value} quote_id={state?.selected_id} lead_id={state?.lead_id} btnName="" />
                         */}
                        {/* <CreateNewContractEdit title={stateData?.type === "view" ? t("View Contract") : stateData?.type === "edit" ? t("Edit Contract") : t("Create Contract")} onClose={handleOnClose} /> */}
                    </Drawer>
                </React.Fragment>

                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(!filterDrawer)}
                    onApply={(value) => onApplyFilter(value)}
                    defaultState={defaultFilterState}
                    components={[
                        type === null && {
                            component: "select",
                            value: filterData?.invoiceType,
                            options: [],
                            isMulti: true,
                            label: t("InvoiceType"),
                            placeholder: t("Select Invoice Type"),
                            state_name: "invoiceType",
                            placeholder: t("Select Invoice Type"),
                            loadOptions: (search, array, handleLoading) => loadOptionsApis(
                                "/enum",
                                { "enumName": [enum_types.free_text_invoice_type] },
                                search,
                                array,
                                handleLoading,
                                "free_text_invoice_type",
                                // {},
                                // enummanualResponse
                            ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        },
                        {
                            component: "select",
                            value: filterData?.accountNo,
                            options: [],
                            isMulti: false,
                            label: t("Account Number"),
                            state_name: "accountNo",
                            placeholder: t("Select Account Number"),
                            loadOptions: (search, array, handleLoading) => loadAccountNo(
                                search, array, handleLoading
                            ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: StatusOptionListLang(t),
                            isMulti: true,
                            state_name: "status",
                            label: t("Status"),
                        },
                    ]} />

                <Dialog
                    open={confirmation}
                    onClose={() => setComfirmation(!confirmation)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.publishDialog}
                >
                    <DialogContent sx={{ padding: 0 }}>
                        <DialogContentText sx={{ background: "transparent" }}>
                            <div style={{
                                paddingRight: "60px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                backgroundImage: `url(${"images/success.svg"})`,
                                backgroundRepeat: "no-repeat",
                                height: 250,
                                width: 600
                            }}>
                                {/* <Stack spacing={2} alignItems={"center"}>

                                <Typography className={classes.publishPopupTitle}>{t("InvoicePublishedSuccessfully")}</Typography>
                                <Box className={classes.publishPopupBtn}
                                    onClick={closePublish}>
                                    <Typography className={classes.publishbtnText}>{t("Okay")}</Typography>
                                </Box>
                            </Stack> */}

                                <div className={classes.proceedDialog}>
                                    <Stack spacing={3}>
                                        <Typography className={classes.convert}>
                                            {tableData?.type === "Credit" ? t("Are you sure you want to publish credit Note") : t("Are you sure you want to publish invoice")}
                                        </Typography>
                                        <Box display="flex" alignItems="center" className={classes.marginTop}>
                                            <Button className={classes.yes} onClick={() => publishInvoice(selectedRow?.id)} disabled={isDisableBtn}>
                                                {t("Yes")}
                                            </Button>
                                            <Button className={classes.No} onClick={() => setComfirmation(!confirmation)}>
                                                {t("No")}
                                            </Button>
                                        </Box>
                                    </Stack>
                                </div>

                            </div >

                        </DialogContentText >
                    </DialogContent >
                </Dialog >

                <Dialog
                    open={publish}
                    onClose={closePublish}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.publishDialog}
                >
                    <DialogContent sx={{ padding: 0 }}>
                        <DialogContentText sx={{ background: "transparent" }}>
                            <div style={{
                                paddingInlineEnd: auth?.auth?.auth?.language === 'ar' ? "290px" : "60px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                backgroundImage: `url(${"images/success.svg"})`,
                                backgroundRepeat: "no-repeat",
                                height: 250,
                                width: 600
                            }}>
                                <Stack spacing={2} alignItems={"center"}>
                                    <Typography className={classes.publishPopupTitle}>{tableData?.type === "Credit" ? t("Credit Note published successfully") : t("InvoicePublishedSuccessfully")}</Typography>


                                    <Box className={classes.publishPopupBtn}
                                        onClick={closePublish}>
                                        <Typography className={classes.publishbtnText}>{t("Okay")}</Typography>
                                    </Box>
                                </Stack >

                            </div >

                        </DialogContentText >
                    </DialogContent >
                </Dialog >
            </Box >
        )
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}

export default withNamespaces("freeTextInvoice")(FreeTextInvoice); 