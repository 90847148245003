import React, { useState } from "react";
import { Subheader, FormGenerator } from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import { initial, CustomTypography } from "./common";
import { useStyles } from "./style";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NetWorkCallMethods, AlertProps, accessCheckRender, getRoutePermissionNew, getCompanyOption, getPropertyOptions } from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import {
  GET_GENERAL_CATEGORY_MASTERS_NEW as GET_GENERAL_CATEGORY_MASTERS,
  GET_GENERAL_SUB_CATEGORY_NEW as GET_GENERAL_SUB_CATEGORY,
  SELECTUNITS_NEW as SELECTUNITS,
  GET_GENERAL_REQUEST,
} from "../../graphql/queries";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Routes } from "../../router/routes"

import { withNamespaces } from "react-i18next";
import { CustomLoadOptionsApis } from "../requestView/steps/utils";
import { loadOptionsApis } from "../maintenancerequest/utils";

const Request = ({
  t = () => false

}) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({ ...initial });
  const { data } = useLocation();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})
  const [properties, setProperties] = React.useState([])
  const [isDisableBtn, setIsDisableBtn] = React.useState(false)
  const [defaultValue, setDefaultValue] = React.useState({
    project: "",
    contract: "",
    contact: ""
  })

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        let propertiesArray = getPropertyOptions(company)
        setProperties(propertiesArray ?? [])
        if (data?.main?.isEdit) getDetails();
      }
    }
    // eslint-disable-next-line
  }, [auth, data?.main?.id]);

  // validation
  const validateContact = () => {
    let isValid = true;
    let error = state.error;
    if (state?.Category?.length === 0) {
      isValid = false;
      error.Category = t("Category is Required");
    }
    if (state?.Unit?.length === 0) {
      isValid = false;
      error.Unit = t("Unit is Required");
    }
    if (state?.subCategory?.length === 0) {
      isValid = false;
      error.subCategory = t("Sub-Category is Required");
    }
    if (state?.Title?.length === 0) {
      isValid = false;
      error.Title = t("Problem Title is Required");
    }
    if (state?.Property?.length === 0) {
      isValid = false;
      error.Property = t("Property is Required");
    }
    if (
      state?.Category?.length === 0 ||
      state?.subCategory?.length === 0 ||
      state?.Unit?.length === 0 ||
      state?.Title?.length === 0 ||
      state?.Property?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }

    setState({ ...state, error });

    return isValid;
  };
  //   updateState
  const updateState = (key, value) => {
    let error = state?.error;
    error[key] = "";
    if (key === "Category") {
      setState({ ...state, subCategory: "", [key]: value, error });
    } else if (key === "Property") {
      setState({ ...state, Unit: "", [key]: value, error });
    }
    else if (key === "project") {
      setDefaultValue({ ...defaultValue, project: value })
    }
    else if (key === "contract") {
      setDefaultValue({ ...defaultValue, contract: value })
    }
    else {
      setState({ ...state, [key]: value, error });
    }
  };
  // request creation
  const saveMaintenanceRequest = () => {
    if (validateContact()) {
      setIsDisableBtn(true)
      const payload = {
        type: 1,
        subject: state?.Title ?? null,
        category: state?.Category?.value,
        sub_category: state?.subCategory?.value,
        units: [state?.Unit?.value],
        description: state?.Description,
        userProfileId: localStorage.getItem("profileID"),
        propertyId: state?.Property?.value,
        companyId: data?.main?.company?.value ?? null,
        project_id:defaultValue?.project?.value??null,
        contract_id:defaultValue?.contract?.value??null,
        is_active: state?.status
      };
      if (data?.main?.isEdit) {
        payload["id"] = data?.main?.id;
      }
      NetworkCall(
        `${config.api_url}/request/${data?.main?.isEdit === true ? "update" : "create"
        }`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          setIsDisableBtn(false)
          history.push(Routes?.generalRequest);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${data?.main?.isEdit === true ? t("General Request Updated successfully.") : t("General Request Created successfully.")
              } `,
          });
        })
        .catch((error) => {
          setIsDisableBtn(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Maintenance Request ${data?.main?.isEdit === true ? "Updated" : "Created"
              } successfully.`,
          });
        });
    }
  };
  const getDefaultProject = (property_id = "", unit_id = "") => {
    const payload = {
      "property_id": property_id,
      "unit_id": unit_id,
      "agreement_unit_id": ""
    }
    NetworkCall(
      `${config?.api_url}/request/get_agreement_project`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (response?.data?.data?.title?.length > 0 || response?.data?.data?.contract_id?.length > 0) {
          const result = {
            label: `${response?.data?.data?.title} - ${response?.data?.data?.project_no}`,
            value: response?.data?.data?.project_id
          }
          const contract = {
            label: response?.data?.data?.contract_no,
            value: response?.data?.data?.contract_id
          }
          setDefaultValue({ ...defaultValue, project: result, contract: contract })
        }
        // if (data?.main?.isEdit) getDetails(response?.data?.data);
        // const result = response?.data?.data?.map((x, i) => {
        //   return {
        //     ...x,
        //     time: TimeIn24HrFormat[i]
        //   }
        // })
        // setSlotsData(result)
      })
      .catch((error) => {
        setDefaultValue({ project: null, contract: null })
        console.log(error)
      });
  };
  const manualResponse = (array) => {
    const details = {
      data: array?.data?.map((i) => {
        return {
          ...i,
          label: `${i?.title} - ${i?.project_no}`,
          value: i?.id
        }
      }),
      count: array?.count
    }
    return details
  }
  const handlePropertyUnit = (k, v) => {
      getDefaultProject(state?.Property?.value, [v?.value])
      updateState(k, v)
  }
  // get details
  const getDetails = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading"),
    });
    const payload = {
      query: GET_GENERAL_REQUEST,
      variables: {
        generalRequestNo: data?.main?.id,
        maintenanceRequestNo: data?.main?.id,
        referenceId: data?.main?.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response?.data?.data;
        setState({
          Category: {
            value: result?.table?.[0]?.catagory?.id ?? "",
            label: result?.table?.[0]?.catagory?.type ?? "",
          },
          subCategory: {
            value: result?.table?.[0]?.subcatagory?.id ?? "",
            label: result?.table?.[0]?.subcatagory?.type ?? "",
          },
          Unit: {
            value: result?.table?.[0]?.unit?.[0].unit?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].unit?.name ?? "",
          },
          Title: result?.table?.[0]?.subject ?? "",
          Description: result?.table?.[0]?.description ?? "",
          status: result?.table?.[0]?.is_active,
          Property: {
            value: result?.table?.[0]?.unit?.[0].property?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].property?.name ?? "",
          },
          error: {
            Category: "",
            subCategory: "",
            Unit: "",
            Title: "",
            Description: "",
            status: "",
            Property: "",
            Agreement: "",
            member: "",
          },
        });
        setDefaultValue({
          project: result?.table?.[0]?.projectByID !== null ? {
            label: `${result?.table?.[0]?.projectByID?.title} - ${result?.table?.[0]?.projectByID?.project_no}`,
            value: result?.table?.[0]?.projectByID?.id
          } : null,
          contract: result?.table?.[0]?.contractByID !== null ? {
            label: result?.table?.[0]?.contractByID?.contract_no,
            value: result?.table?.[0]?.contractByID?.id
          } : null,
        })
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong please try again"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const render = () => {
    return <>
      <Subheader
        title={`${data?.main?.isEdit === true ? t("Edit General Request") : t("Create General Request")}`}
        goBack={() => {
          history.goBack(-1);
        }}
      />
      <Box className={classes.root1}>
        <Grid container spacing={3}>
          {/*  SERVICE DETAILS*/}
          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>{t("SERVICE DETAILS")}</CustomTypography>
              <FormGenerator t={t}
                components={[
                  {
                    isActive: true,
                    component: "select",

                    label: t("Property"),
                    value: state?.Property,
                    placeholder: t("Property"),
                    onChange: (value) => updateState("Property", value),
                    error: state?.error?.Property,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    options: properties
                  },
                  {
                    isActive: true,
                    component: "select",

                    label: t("Unit"),
                    value: state?.Unit,
                    placeholder: t("Unit"),
                    onChange: (value) => handlePropertyUnit("Unit", value),
                    error: state?.error?.Unit,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        SELECTUNITS,
                        "unit",
                        { isActive: true },
                        { label: "label", value: "value" },
                        false,
                        false,
                        state?.Property?.value
                      ),
                    key: JSON.stringify(state?.Property),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Contract"),
                    value: defaultValue?.contract,
                    placeholder: t("Contract"),
                    onChange: (value) => updateState("contract", value),
                    error: state?.error?.contract,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    isClearable:true,
                    loadOptions: (search, array, handleLoading) => loadOptionsApis(
                      "contract/get_all_contract",
                      {
                        company_id: data?.main?.company?.value,
                        contract_type: [3],
                        // account_id:data?.sla_account_id?.value,
                        // start_date:details?.agreement_type === "Sale Agreement" ? timeZoneConverter(details?.lease_start_date , "YYYY-MM-DD") : "",
                        // end_date:details?.agreement_type === "Sale Agreement" ?  timeZoneConverter(details?.lease_end_date , "YYYY-MM-DD") : ""
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      // manualResponse,
                      // { all: true }
                    ),
                    key: JSON.stringify(state?.unit),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Project"),
                    value: defaultValue?.project,
                    placeholder: t("Project"),
                    isClearable:true,
                    onChange: (value) => updateState("project", value),
                    error: state?.error?.project,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) => CustomLoadOptionsApis(
                      "project/drop_down",
                      {
                        company_id: data?.main?.company?.value
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      manualResponse,
                      // { all: true }
                    ),
                    key: JSON.stringify(state?.unit),
                  },
                
                  // {
                  //   isActive: true,
                  //   component: "select",
                  //   label: "Agreement",
                  //   value: state?.Agreement,
                  //   placeholder: "Agreement",
                  //   onChange: (value) => updateState("Agreement", value),
                  //   error: state?.error?.Agreement,
                  //   isRequired: true,
                  //   size: {
                  //     xs: 12,
                  //     sm: 12,
                  //     md: 6,
                  //     lg: 3,
                  //   },
                  //   isPaginate: true,
                  //   loadOptions: (search, array, handleLoading) =>
                  //     loadOptions(
                  //       search,
                  //       array,
                  //       handleLoading,
                  //       SELECTUNITS,
                  //       "unit",
                  //       { isActive: true },
                  //       { label: "label", value: "value" },
                  //       false,
                  //       false,
                  //       state?.Property?.value
                  //     ),
                  //   key: JSON.stringify(state?.Property),
                  // },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Category"),
                    value: state?.Category,
                    placeholder: t("Category"),
                    onChange: (value) => updateState("Category", value),
                    error: state?.error?.Category,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        GET_GENERAL_CATEGORY_MASTERS,
                        "general_category_master",
                        { isActive: true },
                        { label: "label", value: "value" },
                        false,
                        false
                      ),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Sub-Category"),
                    value: state?.subCategory,
                    placeholder: t("Sub-Category"),
                    onChange: (value) => updateState("subCategory", value),
                    error: state?.error?.subCategory,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        GET_GENERAL_SUB_CATEGORY,
                        "general_sub_category_master",
                        { isActive: true },
                        { label: "label", value: "value" },
                        false,
                        false,
                        state?.Category?.value ?? ""
                      ),
                    key: JSON.stringify(state?.Category),
                  },
                  {
                    isActive: true,
                    component: "text",
                    label: t("Problem Title"),
                    value: state?.Title,
                    placeholder: t("Problem Title"),
                    onChange: (e) => updateState("Title", e?.target?.value),
                    error: state?.error?.Title,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                  },
                  {
                    isActive: true,
                    component: "text",
                    label: t("Problem Description"),
                    value: state?.Description,
                    placeholder: t("Problem Description"),
                    onChange: (e) =>
                      updateState("Description", e?.target?.value),
                    error: state?.error?.Description,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 6,
                    },
                    isPaginate: true,
                  },
                  {
                    size: {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 3,
                    },
                    isActive: true,
                    component: "toggle",
                    label: t("Status"),
                    value: state?.status,
                    placeholder: t("Status"),
                    onChange: (value) => updateState("status", value),
                    error: state?.error?.status,
                    isRequired: true,
                    options: [
                      { label: t("Active"), value: true },
                      { label: t("Inactive"), value: false },
                    ],
                  },
                ]}
              />
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <AddMemberCard
              data={{
                name: "Assign To",
                btnText: "Add Member",
              }}
              selectedMember={state?.member ?? ""}
              updateState={updateState}
            />
          </Grid> */}
        </Grid>
      </Box>
      {/* buttom btn */}
      <Box className={classes.bottombtn}>
        <Box>
          <Button
            className={classes.Cancel}
            onClick={() => {
              history.goBack(-1);
            }}
          >
            {t("Cancel")}
          </Button >
        </Box >
        {((data?.main?.isEdit && permission?.update) || (!Boolean(data?.main?.isEdit) && permission?.create)) &&
          <Box>
            <Button className={classes.next} onClick={saveMaintenanceRequest} disabled={isDisableBtn}>
              {data?.main?.isEdit ? t("Update") : t("Submit")}
            </Button >
          </Box >}
      </Box >
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};

export default withNamespaces("maintanceRequest")(Request)
