import { Box, Grid, Stack, Typography, Avatar } from "@mui/material";
import React from "react";
// import { Budget, SQFT } from "../../../assets";
import { LoadingSection } from "../../../components";
import { useStyles } from "./styles";
import { LocalStorageKeys, NetWorkCallMethods, getTotalArray, stringAvatar } from '../../../utils'
import { AuthContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import PieCharts from "../../../components/piechart"
import moment from "moment"
import { format } from "date-fns";

export const Details = ({ t, oppertunity_id = "", setDetails = () => false, details = {}, permission = {}, setShortListedCount = () => false, loading }) => {
    const classes = useStyles()
    const [state, setState] = React.useState(true)
    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

    const auth = React.useContext(AuthContext)
    const addLost = () => {
        const payload = {
            lead_id: oppertunity_id,

        };
        NetworkCall(
            `${config.api_url}/opportunity/get_ouote_rentbreakup`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setState(response?.data?.data?.map((x) => {
                    return {
                        ...x,
                        count: !!x?.count ? parseInt(x?.count) : 0,
                        fill: `#${Math.random().toString(16).substring(2, 8)}`,

                    }
                }))
                // alert.setSnack({
                //   ...alert,
                //   open: true,
                //   severity: AlertProps.severity.success,
                //   msg: `${key === "transfer" ? t("Lead Transfer") : t("Lead closed")
                //     } ${t("updated Successfully")}`,
                // });
            })
            .catch((error) => {
                // alert.setSnack({
                //   ...alert,
                //   open: true,
                //   severity: AlertProps.severity.error,
                //   msg: t("Something went wrong please try again"),
                //   vertical: AlertProps.vertical.top,
                //   horizontal: AlertProps.horizontal.center,
                // });
            });
    };
    //initial state
    React.useState(() => {
        if (permission?.read) {
            addLost()
        }
    }, [permission])

    return (

        <>
            {
                loading ? <LoadingSection top={"5vh"} message={t("Loading Details...")} /> :

                    <Box className={classes.tabRoot}>
                        <Grid container spacing={2}>
                            <Grid item sm={state?.length > 0 ? 12 : 12} md={state?.length > 0 ? 8 : 12} lg={state?.length > 0 ? 9 : 12}>


                                {/*profile section */}
                                <Grid container spacing={1}>
                                    {/*profile image */}
                                    <Grid item xs={12} sm={12} md={9} lg={1.5}>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <Box className={classes.imageRoot} sx={{ position: "relative" }}>
                                                <Avatar src={details?.contact?.get_assets_url_id ?? null} alt="" className={classes.image} {...stringAvatar(details?.contact?.first_name, 14, "4px")}
                                                />
                                                <Box display="flex" justifyContent="center" marginTop="-30px" sx={{ position: "absolute", zIndex: 1, left: "25px", bottom: "0px" }}>
                                                    <Typography className={classes.priority}>{details?.urgent}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {/*profile details */}
                                    <Grid item lg={10.5} >
                                        <Box className={classes.content}>
                                            <Typography className={classes.title}>{t("OPPORTUNITY DETAILS")}</Typography>
                                            <Grid container spacing={1}>
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{t("Contact Name")}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.contact?.first_name}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{t("Mobile")}</Typography>
                                                    <Typography className={auth?.auth?.auth?.language === 'ar' ? classes.subHeaderNo : classes.subHeader}>{details?.contact?.mobile_no_country_code} {details?.contact?.mobile_no}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{t("Email Id")}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.contact?.email}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{"Broker Account"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.borker?.label ?? "-"}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{"Broker Agent"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.broker_agent_account?.label ?? "-"}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{"Opportunity Owner"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.agent?.[0]?.first_name ?? "-"}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} marginTop="4px">
                                                {/* <Grid item xs={2}>
                                <Typography className={classes.header}>Website</Typography>
                                <Typography className={classes.subHeader}>Tomcrujackson.com</Typography>
                            </Grid> */}
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{"Customer Account"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.account?.label ?? "-"}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{"Company Name"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.company_name ?? "-"}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{"City"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.city ?? "-"}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={3} xs={6}>
                                                    <Typography className={classes.header}>{"Country"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.country_master?.label ?? "-"}</Typography>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>

                                {/*opperunity details */}
                                <Grid container spacing={2} marginTop="20px">
                                    <Grid item xs={12} sm={8}>
                                        <Box className={classes.borderRoot}>
                                            <Grid container>
                                                <Grid item xs={6} sm={4}>
                                                    <Typography className={classes.header}>{"Internal Source"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.internal_lead_source?.name ?? "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={4}>
                                                    <Typography className={classes.header}>{"Budget Amount"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.budget_amount ?? "0"} {selectedCompany?.currency_symbol}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={4}>
                                                    <Typography className={classes.header}>{"External Source"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.lead_source ?? "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={4}>
                                                    <Typography className={classes.header}>{"Expected Close Period"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.expected_close_date ? format(new Date(details?.expected_close_date), "dd MMM yy") : "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={4}>
                                                    <Typography className={classes.header}>{"Monthly Salary"}</Typography>
                                                    <Typography className={classes.subHeader}>{details?.monthly_salary ?? "0"} {selectedCompany?.currency_symbol}</Typography>
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box className={classes.borderRoot}>
                                            <Typography className={classes.title}>{t("DESCRIPTION")} :</Typography>
                                            <Typography className={classes.notes}>
                                                <span dangerouslySetInnerHTML={{ __html: details?.subject }} />
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>

                                {/*lease details */}
                                {
                                    details?.revenue_type !== "Sale" &&
                                    <Box className={classes.borderRoot1} marginTop="20px">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header1}>{t("Lease Duration")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.value} {details?.lease_period}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header1}>{t("Lease Start And End Date")}</Typography>
                                                <Typography className={classes.subHeader}>&#x202a;{moment(details?.lease_start_date).format("DD MMM YYYY")} to {moment(details?.lease_end_date).format("DD MMM YYYY")}&#x202c;</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header1}>{t("Preferrd Payment Options")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.payment_mode}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Billing Cycle Method")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.bill_generation}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Billing Cycle Date")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.billing_start_date}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Grace Period")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.grace_period_value} {details?.grace_period_type}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Apply Grace Period")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.apply_grace_period}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Revenue Based Lease")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.revenue_based_lease ? "True" : "False"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Turn Over Type")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.turnover_type ?? "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Renewal Based")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.auto_renewal_escalation}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Auto-renewal")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.auto_renewal ? "True" : "False"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Increment/Escasulation")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.increment_escalation ?? 0}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Wallet Carry over")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.is_wallet_carry ? "Yes" : "No"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Company Name")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.company_name?.length ? details?.company_name : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("City")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.city?.length ? details?.city : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Country")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.country_master?.label ?? "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Probability")}</Typography>
                                                <Typography className={classes.subHeader}>{!!details?.probability ? `${details?.probability}%` : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Confirm Interest")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.conform_interest ? "Yes" : "No"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                                {/*sale details*/}
                                {
                                    details?.revenue_type === "Sale" &&
                                    <Box className={classes.borderRoot1} marginTop="20px">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header1}>{t("Earliest Occupation Date")}</Typography>
                                                <Typography className={classes.subHeader}>{moment(details?.occpation_date).format("DD MMM YYYY")}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header1}>{t("Contract Start Date")}</Typography>

                                                <Typography className={classes.subHeader}>{moment(details?.contract_start_date).format("DD MMM YYYY")}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header1}>{t("Payment Period")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.payment_period}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header1}>{t("Payment Options")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.payment_mode}</Typography>
                                            </Grid>

                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header1}>{t("Delivery Mailstone Template")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.milestone_templateByID?.name}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Wallet Carry over")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.is_wallet_carry ? "Yes" : "No"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Company Name")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.company_name?.length ? details?.company_name : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("City")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.city?.length ? details?.city : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Country")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.country_master?.label ?? "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Budget Amount")}</Typography>
                                                <Typography className={classes.subHeader}>{!!details?.budget_amount ? `${details?.budget_amount}${selectedCompany?.currency_symbol}` : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Probability")}</Typography>
                                                <Typography className={classes.subHeader}>{!!details?.probability ? `${details?.probability}%` : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography className={classes.header}>{t("Confirm Interest")}</Typography>
                                                <Typography className={classes.subHeader}>{details?.conform_interest ? "Yes" : "No" ?? "-"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                            </Grid>
                            {
                                state?.length > 0 &&
                                <Grid item sm={12} md={4} lg={3} xs={12}>
                                    <Box className={classes.borderRootChart}>
                                        <Stack spacing={2}>
                                            <Typography className={classes.graphTitle}>{t("Opportunity Revenue")}</Typography>

                                            <Box width={'100%'}>
                                                <PieCharts data={state ?? []} isTotal total={`${state?.[0]?.symbol ?? ""} ${getTotalArray(state, 'count')}`} centerTitle={<Box >
                                                    <Typography className={classes.total}>{t("Total")}</Typography>
                                                    <Typography className={classes.total}>{t("Amount")}</Typography>
                                                </Box>} radius={'50%'} />

                                            </Box>
                                            <Box className={classes.legends} sx={{ height: "calc(100vh - 414px)", overflow: "auto" }}>
                                                <Grid container spacing={2}>
                                                    {
                                                        state?.map((x, i) => {
                                                            return (
                                                                <Grid item xs={12}>
                                                                    <Stack direction="row" alignItems={"baseline"} spacing={1}>
                                                                        <Box style={{ backgroundColor: x?.fill }} className={classes.dot} />
                                                                        <Typography ml={1}>
                                                                            <Typography className={classes.count}>{state?.[0]?.symbol ?? ""}{x?.count}</Typography>
                                                                            <Typography noWrap className={classes.name}>{x?.name}</Typography>
                                                                        </Typography>
                                                                    </Stack>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Box>
                                        </Stack>


                                    </Box>

                                </Grid>
                            }

                        </Grid>

                        {/*  */}
                    </Box >


            }

        </>
    )
}