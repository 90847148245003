import { useApolloClient } from "@apollo/client";
import { Box, Drawer } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FilterGenerator, LoadingSection, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE_INVENTARY } from "../../graphql/inspectionMaster";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods, enumSelect, enum_types, getCompanyOption } from "../../utils";
import { StatusOptionList, initialState } from "../../utils/insepectionMaster";

import { Form, SearchFilters, Table } from "./components";
import { useStyles } from "./style";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { BackendRoutes } from "../../router/routes";

const InspectionMaster = ({ t }) => {
    const classes = useStyles();
    const client = useApolloClient();
    const debounce = UseDebounce();
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext);
    const [data, setData] = React.useState({ ...initialState });
    const [searchText, setSearchText] = React.useState("");
    const [compenyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [drawer, setDrawer] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    const [tabel_loading, set_table_loading] = React.useState(true)
    const [itemList, setItemList] = React.useState({
        list: [],
        count: 0
    });
    const [open, setOpen] = React.useState(false);
    const [filterData, setFilterData] = React.useState({
        is_active: [true],
        item_types: [],
        category_ids: [],
        manufacturer_ids: [],
    });
    const [options, setOptions] = React.useState({ item_types: [],uom:[] })

    //get list
    const getInventoryItemList = (company_id, searchText, offset, limits, filterDatas) => {
        set_table_loading(true);

        const payload = {
            company_id: company_id,
            search: searchText,
            offset: offset,
            limit: limits,
            active: filterDatas?.is_active ?? [true],
            type: filterDatas?.item_types?.map((x) => { return x?.value }),
            category_ids: filterDatas?.category_ids?.map((x) => { return x?.value }),
            manufacturer_ids: filterDatas?.manufacturer_ids?.map((x) => { return x?.value }),
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.inspection_items_getAll}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            setItemList({
                list: response?.data?.data?.map((val) => {
                    return {
                        ...val,
                        tax_group: val?.vat?.group_name ?? "-",
                        status: val?.is_active ? "Active" : "In-Active"
                    }
                }),
                count: response.data.count,
            });

            setLoading(false);
            set_table_loading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            set_table_loading(false);
        });
    }

    //initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getInventoryItemList(company?.selected?.value, "", 0, 10, filterData)

            getEnum()
        }
        // eslint-disable-next-line
    }, [auth])

    //reload
    const reload = () => {
        getInventoryItemList(selectedCompany?.value, "", 0, 10, filterData)

    }
    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_item_type,enum_types?.utility_period_type])
        const temp_item_types = result?.inspection_item_type?.filter((val) => { return (val?.value !== "Inspection" && val?.value !== "Product" )});
        setOptions({ item_types: temp_item_types,uom:result?.utility_period_type });
    }
    //on isActive
    const onIsActive = (id, status) => {

        const payload = {
            is_active: status
        }
        client.mutate({
            mutation: UPDATE_INVENTARY,
            variables: {
                id: id,
                updatePayload: payload
            }
        }).then(rs => {
            reload();
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Updated"),
            });
        }).catch(er => {
            console.log(er)
        })


    }

    //handle icon
    const handleClickHandler = (type, val) => {

        if (type === "edit") {
            setOpen(true);
            setData({ ...data, ...val, isEdit: true })
        }
        else if (type === "view") {
            setOpen(true);
            setData({ ...data, ...val, isView: true, isEdit: false })
        }
        else if (type === "active") {
            onIsActive(val?.id, !val?.is_active);
        }

    }

    //handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getInventoryItemList(value?.value, searchText, 0, 10, {})
    }

    //apply filter
    const onApplyFilter = (data) => {
        getInventoryItemList(selectedCompany?.value, searchText, 0, 10, data)
        setFilterData(data)
    }

    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getInventoryItemList(selectedCompany?.value, searchText, offset, limit, filterData)
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getInventoryItemList(selectedCompany?.value, searchText, 0, value, filterData)
    }

    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getInventoryItemList(selectedCompany?.value, e, 0, limit, filterData)
    }

    //onClose form
    const closeForm = () => {
        setOpen(false)
        setData({
            ...initialState, error: {
                name: "",
                item_type: "",
                cost_price: "",
                sale_price: "",
                item: "",
                item_category: "",
                item_subcategory: "",
                manufacturer_name: "",
                status: "",
                parentItem: "",
                uom:""
            }
        })
    }

    const constructOptionResponse = (response_array = [], type) => {
        let constructed_response_array = [];

        switch (type) {
            case "manufacturer":
                constructed_response_array = response_array?.map((i) => {
                    return {
                        ...i,
                        label: i?.name,
                        value: i?.id
                    }
                })
                break;

            case "category":
                constructed_response_array = response_array?.map((i) => {
                    return {
                        ...i,
                        label: i?.name,
                        value: i?.id
                    }
                })
                break;

            default:
                break;
        }

        return constructed_response_array;
    };

    return (
        <Box>
            {/*sub header */}
            <Subheader
                hideBackButton={true}
                title={t("Item Master")}

                select
                options={compenyList}
                value={selectedCompany}
                onchange={(e) => {
                    handleCompanyChange(e)
                }}
            />
            {
                loading ?
                    <LoadingSection top="20vh" message={t("loading")} /> :

                    <Box container className={classes.root}>
                        {/*search btn component */}
                        <SearchFilters
                            onClick={() => setOpen(true)}
                            searchText={searchText}
                            handleSearch={handleSearch}
                            openfilter={() => setDrawer(true)}
                            filterData={filterData}
                            customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}

                        />
                        {/*table component */}
                        <Table
                            handleIcon={handleClickHandler}
                            handleChangeLimit={handleChangeLimit}
                            handlePagination={handlePagination}
                            page={page}
                            list={itemList}
                            limit={limit}
                            handleSearch={handleSearch}
                            searchText={searchText}
                            view={true}
                            edit={true}
                            delete={true}
                            is_loading={tabel_loading} />
                    </Box>
            }
            {/*form component */}
            <Drawer
                anchor={"right"}
                open={open}
                onClose={() => closeForm()}
            >
                <Form
                    company={selectedCompany}
                    company_id={selectedCompany?.value}
                    data={data}
                    itemTypeOptions={options?.item_types}
                    uomOptions={options?.uom}
                    setData={setData}
                    reload={reload}
                    t={t}
                    onClose={closeForm}

                />
            </Drawer>
            {/* <AlertDialog
                open={open}
                onClose={closeForm}
                header={data?.isEdit ? `${t("edit")}` : `${t("create")}`}
                component={<Form
                    company={selectedCompany}
                    company_id={selectedCompany?.value}
                    data={data}
                    itemTypeOptions={itemTypeOptions}
                    setData={setData}
                    reload={reload}
                    t={t}
                    onClose={closeForm}

                />}
                isNormal
            /> */}

            {/*filter component */}
            {drawer && (
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.is_active,
                            state_name: "is_active",
                            label: t("Active"),
                            options: StatusOptionList(t),
                            isMulti: true
                        },
                        {
                            component: "select",
                            value: filterData?.item_types,
                            options: options?.item_types,
                            isMulti: true,
                            state_name: "item_types",
                            label: t("Item Type"),
                        },
                        {
                            component: "select",
                            value: filterData?.manufacturer_ids,
                            options: [],
                            isMulti: true,
                            label: t("Manufacturer"),
                            state_name: "manufacturer_ids",
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                    BackendRoutes?.manufacturer_master_get?.slice(1),
                                    {}, search, array, handleLoading,
                                    "data", {}, constructOptionResponse,
                                    { type: "manufacturer" }
                                ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        },
                        {
                            component: "select",
                            value: filterData?.category_ids,
                            options: [],
                            isMulti: true,
                            label: t("Category"),
                            state_name: "category_ids",
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                    BackendRoutes?.inspection_item_category_get?.slice(1),
                                    {}, search, array, handleLoading,
                                    "data", {}, constructOptionResponse,
                                    { type: "category" }
                                ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            )}

        </Box>

    )

}
export default withNamespaces("inspectionItemmaster")(InspectionMaster); 