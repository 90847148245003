/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Avatar, Box, Button, Divider, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React, { useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import "../../../App.css";
import { DialogBox, Editor, MapWithFields, MobileNumberInputComponent, NewDatePicker, SearchFilter, SelectBox, TextBox, UseDebounce } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import {
      GET_PROPERTY_FORM_MASTER as queryOne,
      GET_PROPERTY_GROUP_PURPOSE,
      PROPERTY_HIERARCHY_MASTER,
      UOM_MASTER
} from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, concat_string, LocalStorageKeys, NetWorkCallMethods, SemiBold, stringAvatar, timeZoneConverter, useWindowDimensions, YesNoOption } from "../../../utils";
import { useStylesCreation } from "./style";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { DeleteIcon } from "../../../assets";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ShareHolderContactLoader } from "./shareholderContactLoader";
import NoDataFound from "../../../assets/noData";
import { ShareHolderContactCard } from "./shareholderContactCard";
import { ContractLoader } from "./contractLoader";
import { ContractCard } from "./contractCard";
import { ProjectLoader } from "./projectLoader";
import { ProjectCard } from "./projectCard";

const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize:"0.75rem",
      fontFamily: `${SemiBold}`,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}))

const propertyStates = [
      {
            label: "Active",
            value: true,
      },
      {
            label: "In-Active",
            value: false,
      },
];

const shareHolderTableHeader = [
      { header: "Contact", width: "300px" },
      { header: "Share", width: "100px" },
      { header: "Name", width: "200px" },
      { header: "Mobile Number", width: "175px" },
      { header: "Email Address", width: "200px" },
      { header: "Delete" },
]

const propertyContractTableHeader = [
      { header: "Contract ID", width: "300px" },
      { header: "Contract Date", width: "200px" },
      { header: "Period", width: "200px" },
      { header: "Account ID", width: "200px" },
      { header: "Vendor ID", width: "200px" },
      { header: "Vendor Name", width: "200px" },
      { header: "Delete" },
]

const propertyProjectTableHeader = [
      { header: "Project ID", width: "300px" },
      { header: "Name", width: "200px" },
      { header: "Period", width: "200px" },
      { header: "Project Type", width: "200px" },
      { header: "Delete" },
]

export const PropertyDetails = (props) => {
      const localStorageCompany = localStorage?.getItem?.(LocalStorageKeys?.selectedCompany);
      const selectedCompany = localStorageCompany ? JSON?.parse?.(localStorageCompany) : "";
      const { t, propertyID } = (props);
      const { mapLoad } = props;
      const size = useWindowDimensions()
      const alert = React.useContext(AlertContext);
      const classes = useStylesCreation();
      const [loading, setLoading] = React.useState(false);
      const [fullScreenMap, setFullScreenMap] = React.useState(false)
      const userProfileID = localStorage.getItem("profileID")
      const [isShareHolderDialogOpen, setIsShareHolderDialogOpen] = useState(false);
      const [isShareHolderContactListLoading, setIsShareHolderContactListLoading] = useState(true);
      const [searchShareHolderContactText, setSearchShareHolderContactText] = useState("");
      const [shareHolderContactOffset, setShareHolderContactOffset] = useState(0);
      const [selectedShareHolderContact, setSelectedShareHolderContact] = useState({data: "", index: ""});
      const [shareHolderContacts, setShareHolderContacts] = useState({ rows: [], total_rows_count: 0 });
      const [isContractDialogOpen, setIsContractDialogOpen] = useState(false);
      const [isContractListLoading, setIsContractListLoading] = useState(true);
      const [searchContractText, setSearchContractText] = useState("");
      const [contractOffset, setContractOffset] = useState(0);
      const [selectedContract, setSelectedContract] = useState({data: "", index: ""});
      const [propertyContracts, setPropertyContracts] = useState({ rows: [], total_rows_count: 0 });
      const [isProjectDialogOpen, setIsProjectDialogOpen] = useState(false);
      const [isProjectListLoading, setIsProjectListLoading] = useState(true);
      const [searchProjectText, setSearchProjectText] = useState("");
      const [projectOffset, setProjectOffset] = useState(0);
      const [selectedProject, setSelectedProject] = useState({data: "", index: ""});
      const [propertyProjects, setPropertyProjects] = useState({ rows: [], total_rows_count: 0 });
      const debounce = UseDebounce();
      const shareHolderContactloader = {
            initial: {
                  true: () => setIsShareHolderContactListLoading(true),
                  false: () => setIsShareHolderContactListLoading(false),
            },
      }
      const contractloader = {
            initial: {
                  true: () => setIsContractListLoading(true),
                  false: () => setIsContractListLoading(false),
            },
      }
      const projectloader = {
            initial: {
                  true: () => setIsProjectListLoading(true),
                  false: () => setIsProjectListLoading(false),
            },
      }
      const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            era: 'short',
            calendar: 'islamic-umalqura' // Specify the Hijri calendar
      };

      const hijriFormatter = new Intl.DateTimeFormat('ar-SA-u-ca-islamic-umalqura', options);

      const handleOpenShareHolderDialog = (c, i) => {
            setSelectedShareHolderContact({ data: c, index: i });
            setIsShareHolderDialogOpen(true);
            getShareHolderContacts({ selectedContact: { data: c, index: i } });
      }

      const handleOpenContractDialog = (c, i) => {
            setSelectedContract({ data: c, index: i });
            setIsContractDialogOpen(true);
            getContracts({ selected_contract: { data: c, index: i } });
      }

      const handleOpenProjectDialog = (p, i) => {
            setSelectedProject({ data: p, index: i });
            setIsProjectDialogOpen(true);
            getProjects({ selected_project: { data: p, index: i } });
      }

      const handleCloseShareHolderDialog = () => {
            setIsShareHolderDialogOpen(false);
            setSelectedShareHolderContact({data: "", index: ""});
            setSearchShareHolderContactText("");
            setShareHolderContactOffset(0);
      }

      const handleCloseContractDialog = () => {
            setIsContractDialogOpen(false);
            setSelectedContract({data: "", index: ""});
            setSearchContractText("");
            setContractOffset(0);
      }

      const handleCloseProjectDialog = () => {
            setIsProjectDialogOpen(false);
            setSelectedProject({data: "", index: ""});
            setSearchProjectText("");
            setProjectOffset(0);
      }

      const handleSearchShareHolderContact = (searchText) => {
            setIsShareHolderContactListLoading(true);
            setSearchShareHolderContactText(searchText);
            debounce(() => {
                  const offset = 0;
                  setShareHolderContactOffset(offset);
                  getShareHolderContacts({ offset, search: searchText, load_type: "initial" });
            }, 800)
      }

      const handleSearchContract = (searchText) => {
            setIsContractListLoading(true);
            setSearchContractText(searchText);
            debounce(() => {
                  const offset = 0;
                  setContractOffset(offset);
                  getContracts({ offset, search: searchText, load_type: "initial" });
            }, 800)
      }

      const handleSearchProject = (searchText) => {
            setIsProjectListLoading(true);
            setSearchProjectText(searchText);
            debounce(() => {
                  const offset = 0;
                  setProjectOffset(offset);
                  getProjects({ offset, search: searchText, load_type: "initial" });
            }, 800)
      }

      const getShareHolderContacts = ({
            offset = shareHolderContactOffset,
            limit = 10,
            search = searchShareHolderContactText,
            selectedContact = selectedShareHolderContact,
            load_type = "initial",
      }) => {
            shareHolderContactloader?.[load_type]?.true();

            let payload = {
                  offset, limit, search,
                  filter: {
                        exclude_contact_ids: props?.data?.shareHolders
                              ?.filter?.(_ => !_?.isDeleted)
                              ?.map?.(_ => _?.contact?.id ?? false)
                              ?.filter?.(Boolean)
                              ?.filter?.(__ => __ !== selectedContact?.data?.id)
                  }
            };

            NetworkCall(
                  `${config?.api_url}/contact/list`,
                  NetWorkCallMethods?.post, payload, null, true, false
            ).then((response) => {
                  let temp_row = [];

                  if (load_type === "loadMore") {
                        temp_row = response?.data?.data
                              ? [
                                    ...shareHolderContacts?.rows,
                                    ...response?.data?.data?.map?.(_ => {
                                          return {
                                                ..._,
                                                value: _?.id,
                                                isSelected: selectedContact?.data?.id === _?.id
                                          }
                                    }),
                              ]
                              : shareHolderContacts?.rows;
                  } else {
                        temp_row = response?.data?.data?.map?.(_ => {
                              return {
                                    ..._,
                                    value: _?.id,
                                    isSelected: selectedContact?.data?.id === _?.id
                              }
                        }) ?? [];
                  }

                  setShareHolderContacts({
                        rows: temp_row,
                        total_rows_count: response?.data?.count ?? 0,
                  })

                  shareHolderContactloader?.[load_type]?.false();
            }).catch((e) => {
                  alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps?.severity?.error
                  })

                  shareHolderContactloader?.[load_type]?.false();
            });
      }

      const getContracts = ({
            offset = contractOffset,
            limit = 10,
            search = searchContractText,
            selected_contract = selectedContract,
            load_type = "initial",
      }) => {
            contractloader?.[load_type]?.true();

            let payload = {
                  offset, limit, search, contract_type: [9],
                  company_id: selectedCompany?.value,
                  filter: {
                        exclude_contract_ids: props?.data?.propertyContracts
                              ?.filter?.(_ => !_?.isDeleted)
                              ?.map?.(_ => _?.contract?.id ?? false)
                              ?.filter?.(Boolean)
                              ?.filter?.(__ => __ !== selected_contract?.data?.id)
                  }
            };

            NetworkCall(
                  `${config.api_url}/contract/get_all_contract`,
                  NetWorkCallMethods?.post, payload, null, true, false
            ).then((response) => {
                  let temp_row = [];

                  if (load_type === "loadMore") {
                        temp_row = response?.data?.data
                              ? [
                                    ...propertyContracts?.rows,
                                    ...response?.data?.data?.map?.(_ => {
                                          return {
                                                ..._,
                                                value: _?.id,
                                                isSelected: selected_contract?.data?.id === _?.id
                                          }
                                    }),
                              ]
                              : propertyContracts?.rows;
                  } else {
                        temp_row = response?.data?.data?.map?.(_ => {
                              return {
                                    ..._,
                                    value: _?.id,
                                    isSelected: selected_contract?.data?.id === _?.id
                              }
                        }) ?? [];
                  }

                  setPropertyContracts({
                        rows: temp_row,
                        total_rows_count: response?.data?.count ?? 0,
                  })

                  contractloader?.[load_type]?.false();
            }).catch((e) => {
                  alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps?.severity?.error
                  })

                  contractloader?.[load_type]?.false();
            });
      }

      const getProjects = ({
            offset = projectOffset,
            limit = 10,
            search = searchProjectText,
            selected_project = selectedProject,
            load_type = "initial",
      }) => {
            projectloader?.[load_type]?.true();

            let payload = {
                  offset, limit, search,
                  company_id: selectedCompany?.value,
                  filter: {
                        exclude_project_ids: props?.data?.propertyProjects
                              ?.filter?.(_ => !_?.isDeleted)
                              ?.map?.(_ => _?.project?.id ?? false)
                              ?.filter?.(Boolean)
                              ?.filter?.(__ => __ !== selected_project?.data?.id)
                  }
            };

            NetworkCall(
                  `${config?.api_url}/project/list`,
                  NetWorkCallMethods?.post, payload, null, true, false
            ).then((response) => {
                  let temp_row = [];

                  if (load_type === "loadMore") {
                        temp_row = response?.data?.data?.data
                              ? [
                                    ...propertyProjects?.rows,
                                    ...response?.data?.data?.data?.map?.(_ => {
                                          return {
                                                ..._,
                                                value: _?.id,
                                                isSelected: selected_project?.data?.id === _?.id
                                          }
                                    }),
                              ]
                              : propertyProjects?.rows;
                  } else {
                        temp_row = response?.data?.data?.data?.map?.(_ => {
                              return {
                                    ..._,
                                    value: _?.id,
                                    isSelected: selected_project?.data?.id === _?.id
                              }
                        }) ?? [];
                  }

                  setPropertyProjects({
                        rows: temp_row,
                        total_rows_count: response?.data?.data?.count ?? 0,
                  })

                  projectloader?.[load_type]?.false();
            }).catch((e) => {
                  alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps?.severity?.error
                  })

                  projectloader?.[load_type]?.false();
            });
      }

      const fetchMoreShareHolderContacts = () => {
            if (shareHolderContacts?.rows?.length < shareHolderContacts?.total_rows_count) {
                  const offset = shareHolderContactOffset + 10;
                  setShareHolderContactOffset(offset);
                  getShareHolderContacts({ offset, load_type: "loadMore" });
            }
      }

      const fetchMoreContracts = () => {
            if (propertyContracts?.rows?.length < propertyContracts?.total_rows_count) {
                  const offset = contractOffset + 10;
                  setContractOffset(offset);
                  getContracts({ offset, load_type: "loadMore" });
            }
      }

      const fetchMoreProjects = () => {
            if (propertyProjects?.rows?.length < propertyProjects?.total_rows_count) {
                  const offset = projectOffset + 10;
                  setProjectOffset(offset);
                  getProjects({ offset, load_type: "loadMore" });
            }
      }

      const handleOnClickShareHolderContact = (val) => {
            props?.updateShareHolders("edit", "contact", val, selectedShareHolderContact?.index);
            handleCloseShareHolderDialog();
      };

      const handleOnClickContract = (val) => {
            props?.updatePropertyContracts("edit", "contract", val, selectedContract?.index);
            handleCloseContractDialog();
      };

      const handleOnClickProject = (val) => {
            props?.updatePropertyProjects("edit", "project", val, selectedProject?.index);
            handleCloseProjectDialog();
      };

      const loadOptions = async (search = "", array, type) => {

            setLoading(type);
            let result, query, offset = 0;
            let limit = 10

            if (search && !Boolean(array?.length)) {
                  offset = 0;
            }
            else {
                  offset = array?.length;
            }

            switch (type) {
                  case 'property_type':
                        // if (!props?.data?.purpose?.value?.length > 0) {
                        //       setLoading(null);
                        //       return { options: [] }
                        // }
                        query = GET_PROPERTY_GROUP_PURPOSE(offset, 10, search, null, props?.data?.Company?.value).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.property_group_master],
                              hasMore: (array?.length + result?.property_group_master?.length) < result?.count[0]?.count
                        }
                  case 'country_master':
                        query = queryOne(offset, 10, search, userProfileID, type).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.country_master],
                              hasMore: (array?.length + result?.country_master?.length) < result?.count[0]?.count
                        }
                  case 'client_user_roles':
                        query = queryOne(offset, 10, search, userProfileID, type).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.client_user_roles?.company],
                              hasMore: (array?.length + result?.client_user_roles?.length) < result?.count[0]?.count
                        }
                  case 'uom_master':
                        query = UOM_MASTER(search, offset, limit).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.uom_master],
                              hasMore: (array?.length + result?.uom_master?.length) < result?.count[0]?.count
                        }
                  case 'property_hierarchy_master':
                        query = PROPERTY_HIERARCHY_MASTER(search, offset, limit).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.property_hierarchy_master],
                              hasMore: (array?.length + result?.property_hierarchy_master?.length) < result?.count[0]?.count
                        }
                  default:
                        return { options: [] }
            }
      }
      const networkCallBack = async (query, variable = {}) => {
            let payload = {
                  query,
                  variable: variable
            };

            const options = await NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        let main = response.data.data;
                        setLoading(null);
                        return main
                  })
                  .catch(() => {
                        setLoading(null);
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("Some Thing Went Wrong"),
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                        return null
                  });

            return options
      }

      React.useEffect(() => {
            document.addEventListener("fullscreenchange", existFullscreen)
            return () => {
                  document.removeEventListener("fullscreenchange", existFullscreen)

            }

      }, [])
      //exist full screen
      const existFullscreen = () => {
            if (document.fullscreenElement === null) {
                  setFullScreenMap(false)
            }

      }
      //open full screen
      const fullScreenControl = () => {
            setFullScreenMap(true)
            document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

      }

      const manualVendorResponse = (array) => array?.map(_ => {
            return {
                  ..._,
                  label: (<Stack direction={"row"} columnGap={2} alignItems={"center"}>
                        <Avatar className={classes.optionAvatar}
                              src={_?.image_url} {...stringAvatar(_?.name)} />
                        <Typography className={classes.optionHeader}>
                              {concat_string(
                                    {
                                          vendor_no: _?.vendor_no,
                                          vendor_name: _?.name,
                                    },
                                    ["vendor_no", "vendor_name"],
                                    " - "
                              )}
                        </Typography>
                  </Stack>),
                  value: _?.id,
                  address: concat_string(
                    {
                      door_no: _?.door_no,
                      street_1: _?.street_1,
                      street_2: _?.street_2,
                      landmark: _?.landmark,
                      area: _?.area,
                      city: _?.city,
                      state: _?.state,
                      country: _?.country,
                      pincode: _?.pincode,
                    },
                    [
                      "door_no", "street_1", "street_2",
                      "landmark", "area", "city",
                      "state", "country", "pincode",
                    ], ", ", "-"
                  ),
                  latitude: _.latitude,
                  longitude: _.longitude,
            }
      });

      return (
            <div>
                  <Grid container spacing={3}>

                        <Grid item xs={12} md={2} lg={2}>
                              <Box className={classes.imagebox}
                                    height={{
                                          md: size?.width >= 1257
                                                ? (props?.data?.error?.property?.length > 0 ? "312px" : "288px")
                                                : (props?.data?.error?.property?.length > 0 ? "390px" : "366px")
                                    }}>
                                    <Typography className={classes.title}>
                                          {t("Property Image")}
                                    </Typography>
                                    <br />
                                    <Avatar src={props?.data?.img?.length > 0 ? props?.data?.img : "/images/citynew.svg"} style={{ margin: '0 auto' }} className={props?.data?.img?.length > 0 ? classes.avatar : classes.avatar1} />
                                    <br />
                                    <label>   <Box className={classes.button} variant='contained' style={{ marginTop: '10px' }}>{t("Upload image")}</Box>
                                          <input type='file' style={{ display: 'none' }} accept="image/*"
                                                onClick={(event) => {
                                                      event.target.value = null
                                                }}
                                                onChange={(e) => props?.updateimg(e?.target?.files?.[0])} /> </label>
                                    {props?.data?.img?.length > 0 ? <Typography className={classes.removeimg} onClick={props?.removeimg}>{t("Remove Image")}</Typography> : props?.data?.error?.img?.length > 0 ? (
                                          <span style={{ fontSize:"0.75rem", color: "red" }}>
                                                {"Image is Required"}
                                          </span>
                                    ) : <span style={{ fontSize:"0.75rem" }}>
                                          <Box height={"18px"} />
                                    </span>}

                              </Box>
                        </Grid>
                        <Grid item xs={12} md={10} lg={10}>
                              <Box className={classes.imagebox1}
                                    height={{
                                          md: size?.width >= 1257
                                                ? (props?.data?.error?.property?.length > 0 ? "312px" : "288px")
                                                : (props?.data?.error?.property?.length > 0 ? "390px" : "366px")
                                    }}>
                                    <Typography className={classes.title}>
                                          {t("Property Details")}
                                    </Typography>
                                    <Grid container rowSpacing={1} columnSpacing={3}>
                                          <Grid item xs={12} md={4} lg={size?.width >= 1257 ? 3 : 4}>
                                                <TextBox
                                                      isrequired
                                                      isReadonly
                                                      label={t("Company")}
                                                      value={props?.data?.Company?.label ?? ""} />
                                          </Grid>
                                          <Grid item xs={12} md={4} lg={4}>
                                                <TextBox
                                                      isrequired
                                                      label={t("Property Name")}
                                                      placeholder={t("Enter Property Name")}
                                                      value={props?.data?.property ?? ""}
                                                      onChange={(e) => {
                                                            props?.handlePropertyName(e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.property?.length > 0}
                                                      errorMessage={props?.data?.error?.property} />
                                          </Grid>
                                          <Grid item xs={12} md={4} lg={size?.width >= 1257 ? 2.5 : 4}>
                                                <SelectBox
                                                      isRequired
                                                      label={t("Status")}
                                                      placeholder={t("Select Status")}
                                                      options={propertyStates ?? []}
                                                      value={props?.data?.status ?? ""}
                                                      onChange={(value) => {
                                                            props?.updateState("status", value);
                                                      }}
                                                      isError={props?.data?.error?.status?.length > 0}
                                                      errorMessage={props?.data?.error?.status} />
                                          </Grid>
                                          <Grid item xs={12} md={4} lg={size?.width >= 1257 ? 2.5 : 4}>
                                                <Grid item xs={12}>
                                                      <Typography variant="body1" className={classes.label}>
                                                            {t("Community")}<sup style={{ color: "red" }}>*</sup>
                                                      </Typography>
                                                </Grid>
                                                <Grid container rowGap={"2px"} columnGap={"2px"}>
                                                      {YesNoOption.map((value) => {
                                                            return <Button className={
                                                                  value?.value === props?.data?.is_community ?
                                                                        classes.buttonSelected :
                                                                        classes.buttonUnSelected}
                                                                  onClick={() => { props?.updateState("is_community", value?.value) }}>
                                                                  <Typography className={
                                                                        value?.value === props?.data?.is_community ?
                                                                              classes.buttonTextSelected :
                                                                              classes.buttonTextUnSelected}>
                                                                        {t(value?.label)}</Typography>
                                                            </Button>
                                                      })}
                                                </Grid>
                                          </Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: '8px' }}>
                                          <Grid item xs={12}>
                                                <Editor
                                                      value={props?.data?.des ?? ""}
                                                      label={t("Property Description")}
                                                      handleChange={(e) => {
                                                            props?.updateState("des", e);
                                                      }}
                                                      id={props?.id}
                                                      height={"80px"} />
                                          </Grid>
                                    </Grid>
                              </Box>
                        </Grid>
                  </Grid>


                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          isReadOnly={props?.data?.is_external && propertyID && propertyID?.length > 0}
                                          label={t("Hierarchy")}
                                          placeholder={t("Select Hierarchy")}
                                          value={props?.data?.propertyHierarchy}
                                          onChange={(value) => {
                                                props?.updateState("propertyHierarchy", value);
                                          }}
                                          loading={loading === "property_hierarchy_master"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'property_hierarchy_master')}
                                          isError={props?.data?.error?.propertyHierarchy?.length > 0}
                                          errorMessage={props?.data?.error?.propertyHierarchy} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2} >
                                    <SelectBox
                                          isRequired
                                          isReadOnly={props?.data?.is_external && propertyID && propertyID?.length > 0}
                                          label={t("Purpose")}
                                          placeholder={t("Select Purpose")}
                                          options={props?.enumValue.property_purpose}
                                          value={props?.data?.purpose ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("purpose", value);
                                          }}
                                          isError={props?.data?.error?.purpose?.length > 0}
                                          errorMessage={props?.data?.error?.purpose} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2} >
                                    <SelectBox
                                          isRequired
                                          label={t("Type")}
                                          placeholder={t("Select Type")}
                                          value={props?.data?.type}
                                          onChange={(value) => {
                                                props?.updateState("type", value);
                                          }}
                                          // key={JSON.stringify(props?.data?.purpose?.value)}
                                          loading={loading === "property_type"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'property_type')}
                                          isError={props?.data?.error?.type?.length > 0}
                                          errorMessage={props?.data?.error?.type} />
                              </Grid>

                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          isReadOnly={props?.data?.is_external && propertyID && propertyID?.length > 0}
                                          label={t("Revenue Type")}
                                          placeholder={t("Select Revenue Type")}
                                          options={props?.enumValue.revenue_type}
                                          value={props?.data?.revenue ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("revenue", value);
                                          }}
                                          isError={props?.data?.error?.revenue?.length > 0}
                                          errorMessage={props?.data?.error?.revenue} />
                              </Grid>
                              {props?.data?.revenue?.payment_period &&
                                    <Grid item xs={12} md={3} lg={2} >
                                          <SelectBox
                                                isRequired
                                                label={t("Payment Period")}
                                                placeholder={t("Select Payment Period")}
                                                options={props?.data?.revenue?.payment_period ?? []}
                                                value={props?.data?.period ?? ""}
                                                onChange={(value) => {
                                                      props?.updateState("period", value);
                                                }}
                                                isError={props?.data?.error?.period?.length > 0}
                                                errorMessage={props?.data?.error?.period} />
                                    </Grid>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label={t("Unit of Measure")}
                                          placeholder={t("Select Unit of Measure")}
                                          value={props?.data?.unit}
                                          onChange={(value) => {
                                                props?.updateState("unit", value);
                                          }}
                                          loading={loading === "uom_master"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'uom_master')}
                                          isError={props?.data?.error?.unit?.length > 0}
                                          errorMessage={props?.data?.error?.unit} />
                              </Grid>
                              {props?.data?.unit?.value && <>
                                    <Grid item xs={12} md={3} lg={2} >
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label={t("Total Property Area")}
                                                placeholder={t("Enter Total area")}
                                                value={props?.data?.total ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("total", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end"><Typography className={classes.fontSize}>
                                                      {props?.data?.unit?.label}</Typography></InputAdornment>}
                                                isError={props?.data?.error?.total?.length > 0}
                                                errorMessage={props?.data?.error?.total} />
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                // isrequired
                                                label={t("Set Back Area")}
                                                placeholder={t("Enter Set Back Area")}
                                                value={props?.data?.setBackArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("setBackArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                      {props?.data?.unit?.label}</Typography></InputAdornment>}
                                          // isError={props?.data?.error?.setBackArea?.length > 0}
                                          // errorMessage={props?.data?.error?.setBackArea}
                                          />
                                    </Grid>
                              </>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label={t("Year Built")}
                                          placeholder={t("Year Built")}
                                          value={props?.data?.built ?? ""}
                                          isrequired
                                          onChange={(value) => props?.updateState("built", value)}
                                          isError={props?.data?.error?.built?.length > 0}
                                          errorMessage={props?.data?.error?.built}
                                          maxDate={new Date()}
                                          newDate
                                          noTime />
                              </Grid>
                              {props?.data?.revenue?.value === "Sale" && <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label={t("Handover Date")}
                                          placeholder={t("Handover Date")}
                                          value={props?.data?.handover ?? ""}
                                          isRequired
                                          onChange={(value) => props?.updateState("handover", value)}
                                          isError={props?.data?.error?.handover?.length > 0}
                                          errorMessage={props?.data?.error?.handover}
                                          newDate
                                          noTime />
                              </Grid>}

                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          type='number'
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          label={t("No. of Swimming Pool")}
                                          placeholder={t("Enter Count")}
                                          value={props?.data?.swimmingPool ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("swimmingPool", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2} >
                                    <TextBox
                                          type='number'
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          label={t("No. of Elevators")}
                                          placeholder={t("Enter Count")}
                                          value={props?.data?.elevator ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("elevator", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          label={t("Pet Policy")}
                                          placeholder={t("Select Pet Policy")}
                                          options={props?.enumValue?.pet_policy_type}
                                          value={props?.data?.pet ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("pet", value);
                                          }} />
                              </Grid>
                              {/* AS PER CR (TESTING BUGS) COMMENDED */}
                              {/* <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Typography variant="body1" className={classes.label}>
                                                Discussion Forum
                                          </Typography>
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {EnableDisableOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.discussionForum ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("discussionForum", value?.value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.discussionForum ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Typography variant="body1" className={classes.label}>
                                                Amenities Booking
                                          </Typography>
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {EnableDisableOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.amenitiesBooking ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("amenitiesBooking", value?.value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.amenitiesBooking ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                              </Grid> */}
                              {/* <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Typography variant="body1" className={classes.label}>
                                                Asset Functional Location
                                          </Typography>
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.assetFunc ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("assetFunc", value?.value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.assetFunc ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                              </Grid> */}
                              {/* <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Typography variant="body1" className={classes.label}>
                                                Asset Property
                                          </Typography>
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.assetProperty ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("assetProperty", value?.value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.assetProperty ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                              </Grid> */}
                              {/* <Grid item xs={12} md={3} lg={2}>
                                    <Typography variant="body1" className={classes.label}>
                                          Public Listing<sup style={{ color: "red" }}>*</sup>
                                    </Typography>
                                    <Grid container spacing={"2px"}>
                                          <Grid item xs={4}>
                                                <Typography
                                                      onClick={() => {
                                                            props?.updateState("publiclist", "None");
                                                      }}
                                                      className={
                                                            props?.data?.publiclist === "None"
                                                                  ? classes.Requirements
                                                                  : classes.Requirementsqfts
                                                      }
                                                >
                                                      None
                                                </Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                                <Typography
                                                      onClick={() => {
                                                            props?.updateState("publiclist", "Private");
                                                      }}
                                                      className={
                                                            props?.data?.publiclist === "Private"
                                                                  ? classes.Requirements
                                                                  : classes.Requirementsqfts
                                                      }
                                                >
                                                      Private
                                                </Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                                <Typography
                                                      onClick={() => {
                                                            props?.updateState("publiclist", "Public");
                                                      }}
                                                      className={
                                                            props?.data?.publiclist === "Public"
                                                                  ? classes.Requirements
                                                                  : classes.Requirementsqfts
                                                      }
                                                >
                                                      Public
                                                </Typography>
                                          </Grid>
                                    </Grid>
                                    {props?.data?.error?.publiclist?.length > 0 && (
                                          <span style={{ fontSize:"0.75rem", color: "red" }}>
                                                Public Listing is required
                                          </span>
                                    )}
                              </Grid> */}
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          label={t("Title Deed")}
                                          placeholder={t("Enter Title Deed")}
                                          value={props?.data?.titleDeed ?? ""}
                                          onChange={(e) => props?.updateState("titleDeed", e.target.value)}
                                          isError={props?.data?.error?.titleDeed?.length > 0}
                                          errorMessage={props?.data?.error?.titleDeed} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label={t("Title Deed Date")}
                                          placeholder={t("Select Date")}
                                          value={props?.data?.deedDate ?? ""}
                                          onChange={(value) => props?.updateState("deedDate", value)}
                                          isError={props?.data?.error?.deedDate?.length > 0}
                                          errorMessage={props?.data?.error?.deedDate}
                                          newDate
                                          noTime />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          isReadonly
                                          label={t("Hijri Date")}
                                          placeholder={"-"}
                                          value={props?.data?.hijriDate ? hijriFormatter.format(new Date(props?.data?.hijriDate)) : "-"} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          type='number'
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          label={t("Documented Land Area")}
                                          placeholder={t("Enter Area")}
                                          value={props?.data?.landArea ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("landArea", e.target.value);
                                          }}
                                          endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                {props?.data?.unit?.label}</Typography></InputAdornment>}
                                          isError={props?.data?.error?.landArea?.length > 0}
                                          errorMessage={props?.data?.error?.landArea}
                                    />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          type='number'
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          label={t("Market Price")}
                                          placeholder={t("Enter Price")}
                                          value={props?.data?.marketPrice ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("marketPrice", e.target.value);
                                          }}
                                          startAdornment={<InputAdornment position="start" ><Typography className={classes.fontSize}>
                                                {selectedCompany?.code}</Typography></InputAdornment>}
                                          isError={props?.data?.error?.marketPrice?.length > 0}
                                          errorMessage={props?.data?.error?.marketPrice}
                                    />
                              </Grid>
                        </Grid>
                  </Box>


                  <div className='map-wrapper'>
                        <Box className={classes.imagebox1} style={{ marginTop: '24px', position: 'relative' }}>
                              <CustomTypography>{t("Address")}</CustomTypography>
                              {
                                    props?.load &&
                                    <MapWithFields
                                          fullScreenMap={fullScreenMap}
                                          fullScreenControl={fullScreenControl}
                                          mapOptions={{
                                                isInput: true,
                                                center: {
                                                      lat: props?.data?.latitude,
                                                      lng: props?.data?.longitude
                                                },
                                                lat: props?.data?.latitude,
                                                lng: props?.data?.longitude,
                                                mapLoad
                                          }}
                                          mapHeight={"370px"}
                                          breakpoints={{
                                                map: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 5,
                                                      lg: 6,
                                                      xl: 6
                                                },
                                                components: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 7,
                                                      lg: 6,
                                                      xl: 6
                                                }
                                          }}
                                          mapResult={props?.mapResult}
                                          autoCompletePlacement={{
                                                top: 14,
                                          }}
                                          fields={[
                                                {
                                                      label: t("Door Number"),
                                                      component: "TextField",
                                                      value: props?.data?.doorNo,
                                                      state_name: 'doorNo',
                                                      isrequired: false,
                                                      placeholder: t("Enter Door Number"),
                                                      error: props?.data?.error?.doorNo,
                                                      errorMessage: props?.data?.error?.doorNo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Address Line 1"),
                                                      component: "TextField",
                                                      value: props?.data?.addressLineOne,
                                                      state_name: 'addressLineOne',
                                                      isrequired: false,
                                                      placeholder: t("Enter Address Line 1"),
                                                      errorMessage: props?.data?.error?.addressLineOne,
                                                      error: props?.data?.error?.addressLineOne?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: t("Address Line 2"),
                                                      component: "TextField",
                                                      value: props?.data?.addressLineTwo,
                                                      state_name: 'addressLineTwo',
                                                      isrequired: false,
                                                      placeholder: t("Enter Address Line 2"),
                                                      errorMessage: props?.data?.error?.addressLineTwo,
                                                      error: props?.data?.error?.addressLineTwo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: t("Landmark"),
                                                      component: "TextField",
                                                      value: props?.data?.landmark,
                                                      state_name: 'landmark',
                                                      isrequired: false,
                                                      placeholder: t("Enter Landmark"),
                                                      errorMessage: props?.data?.error?.landmark,
                                                      error: props?.data?.error?.landmark?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Area"),
                                                      component: "TextField",
                                                      value: props?.data?.area,
                                                      state_name: 'area',
                                                      isrequired: true,
                                                      placeholder: t("Enter Area"),
                                                      errorMessage: props?.data?.error?.area,
                                                      error: props?.data?.error?.area?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("City"),
                                                      component: "TextField",
                                                      value: props?.data?.city,
                                                      state_name: 'city',
                                                      isrequired: true,
                                                      placeholder: t("Enter City"),
                                                      errorMessage: props?.data?.error?.city,
                                                      error: props?.data?.error?.city?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("State"),
                                                      component: "TextField",
                                                      state_name: 'state',
                                                      value: props?.data?.state,
                                                      isrequired: true,
                                                      placeholder: t("Enter State"),
                                                      errorMessage: props?.data?.error?.state,
                                                      error: props?.data?.error?.state?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Country"),
                                                      component: "TextField",
                                                      value: props?.data?.country,
                                                      state_name: 'country',
                                                      isrequired: true,
                                                      placeholder: t("Enter Country"),
                                                      errorMessage: props?.data?.error?.country,
                                                      error: props?.data?.error?.country?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Pincode"),
                                                      component: "TextField",
                                                      state_name: 'zipcode',
                                                      value: props?.data?.zipcode,
                                                      isrequired: false,
                                                      placeholder: t("Enter Pincode"),
                                                      errorMessage: props?.data?.error?.zipcode,
                                                      error: props?.data?.error?.zipcode?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Latitude"),
                                                      component: "TextField",
                                                      state_name: 'latitude',
                                                      value: props?.data?.latitude,
                                                      isrequired: false,
                                                      placeholder: t("Enter Latitude"),
                                                      errorMessage: props?.data?.error?.latitude,
                                                      error: props?.data?.error?.latitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Longitude"),
                                                      component: "TextField",
                                                      state_name: 'longitude',
                                                      value: props?.data?.longitude,
                                                      isrequired: false,
                                                      placeholder: t("Enter Longitude"),
                                                      errorMessage: props?.data?.error?.longitude,
                                                      error: props?.data?.error?.longitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Smart Geo Address"),
                                                      component: "TextField",
                                                      state_name: 'smartGeoAddress',
                                                      value: props?.data?.smartGeoAddress,
                                                      isrequired: false,
                                                      placeholder: t("Enter Smart Geo Address"),
                                                      errorMessage: props?.data?.error?.smartGeoAddress,
                                                      error: props?.data?.error?.smartGeoAddress?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                          ]}
                                          onChangeFields={(key, value) => props?.updateState(key, value)}
                                    />
                              }

                        </Box>
                  </div>

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={t("Business Phone")}
                                          placeholder={t("Business Phone")}
                                          value={props?.data?.bussinessmobile}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("bussinessmobile", value);
                                          }}
                                          isError={props?.data?.error?.bussinessmobile?.length > 0}
                                          errorMessage={props?.data?.error?.bussinessmobile}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                                    {/* <Typography variant={"caption"} color={"error"}>
                                          {props?.exists?.Bussiness}
                                    </Typography> */}
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={t("Mobile Phone")}
                                          placeholder={t("Mobile Phone")}
                                          value={props?.data?.mobile}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("mobile", value);
                                          }}
                                          isError={props?.data?.error?.mobile?.length > 0}
                                          errorMessage={props?.data?.error?.mobile}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                                    {/* <Typography variant={"caption"} color={"error"}>
                                          {props?.exists?.mobile}
                                    </Typography> */}
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          label={t("Website")}
                                          placeholder={t("Enter Website")}
                                          value={props?.data?.website ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("website", e.target.value);
                                          }}
                                          isError={props?.data?.error?.website?.length > 0}
                                          errorMessage={props?.data?.error?.website} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          label={t("Email Address")}
                                          placeholder={t("Enter Email Address")}
                                          value={props?.data?.email ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("email", e.target.value);
                                          }}
                                          isError={props?.data?.error?.email?.length > 0}
                                          errorMessage={props?.data?.error?.email} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          isrequired
                                          label={t("Alerts Email")}
                                          placeholder={t("Enter Email Address")}
                                          value={props?.data?.communicationEmail ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("communicationEmail", e.target.value);
                                          }}
                                          isError={props?.data?.error?.communicationEmail?.length > 0}
                                          errorMessage={props?.data?.error?.communicationEmail} />
                              </Grid>
                        </Grid>
                  </Box>

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                              <Typography className={classes.title}>
                                    {t("Developer Information")}
                              </Typography>
                              {props?.data?.vendor?.value &&
                                    <Typography className={classes.title}
                                          sx={{
                                                color: "#ff4b4b !important",
                                                cursor: "pointer",
                                          }}
                                          onClick={() => props?.updateState("vendor", "")}>
                                          {t("Clear")}
                                    </Typography>}
                        </Stack>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3}>
                                    <SelectBox
                                          noSearch
                                          label={t("Vendor Profile")}
                                          placeholder={t("Search Vendor Profile")}
                                          value={props?.data?.vendor ?? ""}
                                          customOptionComponent={(option) => {
                                                return (
                                                      <Stack direction={"row"} spacing={2}
                                                            p={1} alignItems={"center"}
                                                            style={{
                                                                  border: (option?.isSelected)
                                                                        ? "1px solid #5078E1"
                                                                        : "1px solid #E4E8EE",
                                                                  backgroundColor: (option?.isSelected)
                                                                        ? "#F1F7FF" : "#fff"
                                                            }}>
                                                            <Avatar className={classes.optionAvatar}
                                                                  src={option?.data?.image_url}
                                                                  {...stringAvatar(option?.data?.name)}></Avatar>
                                                            <Stack>
                                                                  <Typography className={classes.optionHeader}>
                                                                        {concat_string(
                                                                              {
                                                                                    vendor_no: option?.data?.vendor_no,
                                                                                    vendor_name: option?.data?.name,
                                                                              },
                                                                              ["vendor_no", "vendor_name"],
                                                                              " - "
                                                                        )}
                                                                  </Typography>
                                                                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                                        <Typography className={classes.optionSubTitle}>
                                                                              {concat_string(
                                                                                    {
                                                                                          mobile: concat_string(
                                                                                                {
                                                                                                      mobile_code: option?.data?.mobile_no_country_code,
                                                                                                      mobile_no: option?.data?.mobile_no,
                                                                                                },
                                                                                                ["mobile_code", "mobile_no"], " - "
                                                                                          ),
                                                                                          email_id: option?.data?.email_id,
                                                                                    },
                                                                                    ["mobile", "email_id"], ", "
                                                                              )}
                                                                        </Typography>
                                                                  </Stack>
                                                            </Stack>
                                                      </Stack>
                                                )
                                          }}
                                          loadOptions={(search, array, handleLoading) =>
                                                loadOptionsApis(
                                                      "vendor/list",
                                                      { company_id: selectedCompany?.value },
                                                      search,
                                                      array,
                                                      handleLoading,
                                                      "data",
                                                      {},
                                                      manualVendorResponse
                                                )
                                          }
                                          isPaginate={true}
                                          menuOptionHeight={"250px"}
                                          onChange={(val) => props?.updateState("vendor", val)}
                                          isError={props?.data?.error?.vendor?.length > 0}
                                          errorMessage={props?.data?.error?.vendor} />
                              </Grid>
                              {props?.data?.vendor?.value &&
                                    <>
                                          {props?.data?.vendor?.developerName &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Developer Name")}
                                                            placeholder={""}
                                                            value={props?.data?.vendor?.developerName ?? ""} />
                                                </Grid>}
                                          {props?.data?.vendor?.mobile &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Mobile Number")}
                                                            placeholder={""}
                                                            value={props?.data?.vendor?.mobile ?? ""} />
                                                </Grid>}
                                          {props?.data?.vendor?.email &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Email Address")}
                                                            placeholder={""}
                                                            value={props?.data?.vendor?.email ?? ""} />
                                                </Grid>}
                                          {props?.data?.vendor?.website &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Website")}
                                                            placeholder={""}
                                                            value={props?.data?.vendor?.website ?? ""} />
                                                </Grid>}
                                    </>}
                        </Grid>
                  </Box>

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                              <Typography className={classes.title}>
                                    {t("Registration Office Information")}
                              </Typography>
                              {props?.data?.registrationOffice?.value &&
                                    <Typography className={classes.title}
                                          sx={{
                                                color: "#ff4b4b !important",
                                                cursor: "pointer",
                                          }}
                                          onClick={() => props?.updateState("registrationOffice", "")}>
                                          {t("Clear")}
                                    </Typography>}
                        </Stack>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3}>
                                    <SelectBox
                                          noSearch
                                          label={t("Registration Office")}
                                          placeholder={t("Search Registration Office")}
                                          value={props?.data?.registrationOffice ?? ""}
                                          customOptionComponent={(option) => {
                                                return (
                                                      <Stack direction={"row"} spacing={2}
                                                            p={1} alignItems={"center"}
                                                            style={{
                                                                  border: (option?.isSelected)
                                                                        ? "1px solid #5078E1"
                                                                        : "1px solid #E4E8EE",
                                                                  backgroundColor: (option?.isSelected)
                                                                        ? "#F1F7FF" : "#fff"
                                                            }}>
                                                            <Avatar className={classes.optionAvatar}
                                                                  src={option?.data?.image_url}
                                                                  {...stringAvatar(option?.data?.name)}></Avatar>
                                                            <Stack>
                                                                  <Typography className={classes.optionHeader}>
                                                                        {concat_string(
                                                                              {
                                                                                    vendor_no: option?.data?.vendor_no,
                                                                                    vendor_name: option?.data?.name,
                                                                              },
                                                                              ["vendor_no", "vendor_name"],
                                                                              " - "
                                                                        )}
                                                                  </Typography>
                                                                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                                        <Typography className={classes.optionSubTitle}>
                                                                              {concat_string(
                                                                                    {
                                                                                          mobile: concat_string(
                                                                                                {
                                                                                                      mobile_code: option?.data?.mobile_no_country_code,
                                                                                                      mobile_no: option?.data?.mobile_no,
                                                                                                },
                                                                                                ["mobile_code", "mobile_no"], " - "
                                                                                          ),
                                                                                          email_id: option?.data?.email_id,
                                                                                    },
                                                                                    ["mobile", "email_id"], ", "
                                                                              )}
                                                                        </Typography>
                                                                  </Stack>
                                                            </Stack>
                                                      </Stack>
                                                )
                                          }}
                                          loadOptions={(search, array, handleLoading) =>
                                                loadOptionsApis(
                                                      "vendor/list",
                                                      { company_id: selectedCompany?.value },
                                                      search,
                                                      array,
                                                      handleLoading,
                                                      "data",
                                                      {},
                                                      manualVendorResponse
                                                )
                                          }
                                          isPaginate={true}
                                          menuOptionHeight={"250px"}
                                          onChange={(val) => props?.updateState("registrationOffice", val)}
                                          isError={props?.data?.error?.registrationOffice?.length > 0}
                                          errorMessage={props?.data?.error?.registrationOffice} />
                              </Grid>
                              {props?.data?.registrationOffice?.value &&
                                    <>
                                          {props?.data?.registrationOffice?.officeName &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Office Name")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.officeName ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.mobile &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Mobile Number")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.mobile ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.email &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Email Address")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.email ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.website &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Website")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.website ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.address &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Address")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.address ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.latitude &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Latitude")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.latitude ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.longitude &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Longitude")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.longitude ?? ""} />
                                                </Grid>}
                                    </>}
                        </Grid>
                  </Box>

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                              <Typography className={classes.title}>
                                    {t("Share Holders")}
                              </Typography>
                              {props?.data?.shareHolders?.filter?.(_ => !_?.isDeleted)?.length === 0 &&
                                    <Button variant="text"
                                          onClick={() => props?.updateShareHolders("add")}
                                          sx={{
                                                marginBottom: "12px",
                                                "&:hover": { backgroundColor: "white" }
                                          }}>
                                          <Typography className={classes.addShareHolder}>
                                                {t("+ Add")}
                                          </Typography>
                                    </Button>}
                        </Stack>
                        {props?.data?.shareHolders?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Grid container rowSpacing={"4px"} columnSpacing={"4px"} sx={{ overflow: "overlay" }}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                          {shareHolderTableHeader?.map(_ => {
                                                return (<Box className={classes.headCell} sx={{ width: _?.width ?? "auto" }}>
                                                      <Typography className={classes.headText}> {_?.header} </Typography>
                                                </Box>)
                                          })}
                                    </Stack>
                                    {props?.data?.shareHolders?.map((_, i) => {
                                          return (_?.isDeleted
                                                ? <></>
                                                : <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[0]?.width }}>
                                                            <Box className={classes.contactDropdown}
                                                                  onClick={() => handleOpenShareHolderDialog(_?.contact, i)}>
                                                                  {_?.contact?.value ?
                                                                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                                                                              <Avatar className={classes.optionAvatar}
                                                                                    src={_?.contact?.image_url} {...stringAvatar(_?.contact?.first_name)} />
                                                                              <Typography className={classes.contactDropdownValue} noWrap width={"196px"}>
                                                                                    {concat_string(
                                                                                          {
                                                                                                user_no: _?.contact?.user_no,
                                                                                                first_name: _?.contact?.first_name,
                                                                                          },
                                                                                          ["user_no", "first_name"],
                                                                                          " - "
                                                                                    )}
                                                                              </Typography>
                                                                        </Stack>
                                                                        : <Typography className={classes.contactDropdownPlaceholder} noWrap width={"244px"}>
                                                                              {t("Search Contact")}
                                                                        </Typography>}
                                                                        <KeyboardArrowDownIcon color="#333333" />
                                                            </Box>
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[1]?.width }}>
                                                            <TextBox
                                                                  isReadonly={!_?.contact?.id}
                                                                  label={""}
                                                                  placeholder={t("0.0")}
                                                                  value={_?.value ?? ""}
                                                                  onChange={(e) => props?.updateShareHolders("edit", "value", e.target.value, i)}
                                                                  endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>%</Typography></InputAdornment>} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[2]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contact?.first_name ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[3]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={concat_string(
                                                                        {
                                                                              mobile_code: _?.contact?.mobile_no_country_code,
                                                                              mobile_no: _?.contact?.mobile_no,
                                                                        },
                                                                        ["mobile_code", "mobile_no"], " - "
                                                                  )} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[4]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contact?.email_id ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell}>
                                                            <IconButton onClick={() => props?.updateShareHolders("edit", "isDeleted", true, i)}>
                                                                  <DeleteIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Stack>)
                                    })}
                              </Grid>}
                        {props?.data?.shareHolders?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Button variant="text"
                                    onClick={() => props?.updateShareHolders("add")}
                                    sx={{
                                          marginTop: "16px", height: "40px",
                                          "&:hover": { backgroundColor: "white" }
                                    }}>
                                    <Typography className={classes.addShareHolder}>
                                          {t("+ Add Another Share Holder")}
                                    </Typography>
                              </Button>}
                  </Box>
                  <DialogBox
                        maxWidth={'sm'}
                        open={isShareHolderDialogOpen}
                        onClose={handleCloseShareHolderDialog}
                        handleClose={handleCloseShareHolderDialog}
                        header={t("Share Holder Contacts")}
                        component={
                              <Stack direction={"column"} rowGap={"16px"} padding={"16px 24px"} sx={{ backgroundColor: "#ffffff" }}>
                                    <SearchFilter value={searchShareHolderContactText} placeholder={t("Search Contacts")}
                                          handleChange={(value) => handleSearchShareHolderContact(value)} />
                                    {isShareHolderContactListLoading
                                          ? (<Box sx={{ height: size?.height - 400 }}>
                                                <Stack divider={<Divider />}>
                                                      {[1, 2, 3, 4]?.map?.(_ => <ShareHolderContactLoader classes={classes} />)}
                                                </Stack>
                                          </Box>)
                                          : (shareHolderContacts?.rows?.length > 0
                                                ? (<InfiniteScroll
                                                      dataLength={shareHolderContacts?.rows?.length}
                                                      next={fetchMoreShareHolderContacts}
                                                      height={size?.height - 400}
                                                      hasMore={shareHolderContacts?.rows?.length < shareHolderContacts?.total_rows_count}
                                                      loader={<ShareHolderContactLoader classes={classes} />}
                                                      endMessage={
                                                            <Box width={"100%"} m={"16px 0px"}
                                                                  display={"flex"} justifyContent={"center"}>
                                                                  <Typography className={classes.shareHolderContactEnd}>
                                                                        {t("End Of Contacts")}
                                                                  </Typography>
                                                            </Box>
                                                      }>
                                                      <Stack divider={<Divider />}>
                                                            {shareHolderContacts?.rows?.map?.(_ => {
                                                                  return <ShareHolderContactCard
                                                                        classes={classes}
                                                                        data={_}
                                                                        onClick={handleOnClickShareHolderContact}/>
                                                            })}
                                                      </Stack>
                                                </InfiniteScroll>)
                                                : (<Box className={classes.shareHolderContactNoDataBox}
                                                      sx={{ height: size?.height - 400 }}>
                                                      <NoDataFound />
                                                </Box>))}
                              </Stack>
                        } />

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                              <Typography className={classes.title}>
                                    {t("Contracts")}
                              </Typography>
                              {props?.data?.propertyContracts?.filter?.(_ => !_?.isDeleted)?.length === 0 &&
                                    <Button variant="text"
                                          onClick={() => props?.updatePropertyContracts("add")}
                                          sx={{
                                                marginBottom: "12px",
                                                "&:hover": { backgroundColor: "white" }
                                          }}>
                                          <Typography className={classes.addShareHolder}>
                                                {t("+ Add")}
                                          </Typography>
                                    </Button>}
                        </Stack>
                        {props?.data?.propertyContracts?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Grid container rowSpacing={"4px"} columnSpacing={"4px"} sx={{ overflow: "overlay" }}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                          {propertyContractTableHeader?.map(_ => {
                                                return (<Box className={classes.headCell} sx={{ width: _?.width ?? "auto" }}>
                                                      <Typography className={classes.headText}> {_?.header} </Typography>
                                                </Box>)
                                          })}
                                    </Stack>
                                    {props?.data?.propertyContracts?.map((_, i) => {
                                          return (_?.isDeleted
                                                ? <></>
                                                : <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyContractTableHeader?.[0]?.width }}>
                                                            <Box className={classes.contactDropdown}
                                                                  onClick={() => handleOpenContractDialog(_?.contract, i)}>
                                                                  {_?.contract?.value ?
                                                                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                                                                              <Avatar className={classes.optionAvatar}
                                                                                    {...stringAvatar(_?.contract?.contract_no)} />
                                                                              <Typography className={classes.contactDropdownValue} noWrap width={"196px"}>
                                                                                    {_?.contract?.contract_no}
                                                                              </Typography>
                                                                        </Stack>
                                                                        : <Typography className={classes.contactDropdownPlaceholder} noWrap width={"244px"}>
                                                                              {t("Search Contract")}
                                                                        </Typography>}
                                                                  <KeyboardArrowDownIcon color="#333333" />
                                                            </Box>
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyContractTableHeader?.[1]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={concat_string(
                                                                        {
                                                                              start_date: _?.contract?.start_date ? timeZoneConverter(_?.contract?.start_date) : "",
                                                                              end_date: _?.contract?.end_date ? timeZoneConverter(_?.contract?.end_date) : "",
                                                                        },
                                                                        ["start_date", "end_date"], " - ", "-")
                                                                  } />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyContractTableHeader?.[2]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={concat_string(
                                                                        {
                                                                              period_value: _?.contract?.period_value?.toString(),
                                                                              period: _?.contract?.period,
                                                                        },
                                                                        ["period_value", "period"], " ", "-")
                                                                  } />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyContractTableHeader?.[3]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contract?.account?.account_no ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyContractTableHeader?.[4]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contract?.account?.vendor?.vendor_code ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyContractTableHeader?.[5]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contract?.account?.vendor?.name ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell}>
                                                            <IconButton onClick={() => props?.updatePropertyContracts("edit", "isDeleted", true, i)}>
                                                                  <DeleteIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Stack>)
                                    })}
                              </Grid>}
                        {props?.data?.propertyContracts?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Button variant="text"
                                    onClick={() => props?.updatePropertyContracts("add")}
                                    sx={{
                                          marginTop: "16px", height: "40px",
                                          "&:hover": { backgroundColor: "white" }
                                    }}>
                                    <Typography className={classes.addShareHolder}>
                                          {t("+ Add Another Contract")}
                                    </Typography>
                              </Button>}
                  </Box>
                  <DialogBox
                        maxWidth={'sm'}
                        open={isContractDialogOpen}
                        onClose={handleCloseContractDialog}
                        handleClose={handleCloseContractDialog}
                        header={t("Contracts")}
                        component={
                              <Stack direction={"column"} rowGap={"16px"} padding={"16px 24px"} sx={{ backgroundColor: "#ffffff" }}>
                                    <SearchFilter value={searchContractText} placeholder={t("Search Contracts")}
                                          handleChange={(value) => handleSearchContract(value)} />
                                    {isContractListLoading
                                          ? (<Box sx={{ height: size?.height - 400 }}>
                                                <Stack divider={<Divider />}>
                                                      {[1, 2, 3, 4]?.map?.(_ => <ContractLoader classes={classes} />)}
                                                </Stack>
                                          </Box>)
                                          : (propertyContracts?.rows?.length > 0
                                                ? (<InfiniteScroll
                                                      dataLength={propertyContracts?.rows?.length}
                                                      next={fetchMoreContracts}
                                                      height={size?.height - 400}
                                                      hasMore={propertyContracts?.rows?.length < propertyContracts?.total_rows_count}
                                                      loader={<ContractLoader classes={classes} />}
                                                      endMessage={
                                                            <Box width={"100%"} m={"16px 0px"}
                                                                  display={"flex"} justifyContent={"center"}>
                                                                  <Typography className={classes.shareHolderContactEnd}>
                                                                        {t("End Of Contracts")}
                                                                  </Typography>
                                                            </Box>
                                                      }>
                                                      <Stack divider={<Divider />}>
                                                            {propertyContracts?.rows?.map?.(_ => {
                                                                  return <ContractCard
                                                                        classes={classes}
                                                                        data={_}
                                                                        onClick={handleOnClickContract} />
                                                            })}
                                                      </Stack>
                                                </InfiniteScroll>)
                                                : (<Box className={classes.shareHolderContactNoDataBox}
                                                      sx={{ height: size?.height - 400 }}>
                                                      <NoDataFound />
                                                </Box>))}
                              </Stack>
                        } />

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                              <Typography className={classes.title}>
                                    {t("Projects")}
                              </Typography>
                              {props?.data?.propertyProjects?.filter?.(_ => !_?.isDeleted)?.length === 0 &&
                                    <Button variant="text"
                                          onClick={() => props?.updatePropertyProjects("add")}
                                          sx={{
                                                marginBottom: "12px",
                                                "&:hover": { backgroundColor: "white" }
                                          }}>
                                          <Typography className={classes.addShareHolder}>
                                                {t("+ Add")}
                                          </Typography>
                                    </Button>}
                        </Stack>
                        {props?.data?.propertyProjects?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Grid container rowSpacing={"4px"} columnSpacing={"4px"} sx={{ overflow: "overlay" }}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                          {propertyProjectTableHeader?.map(_ => {
                                                return (<Box className={classes.headCell} sx={{ width: _?.width ?? "auto" }}>
                                                      <Typography className={classes.headText}> {_?.header} </Typography>
                                                </Box>)
                                          })}
                                    </Stack>
                                    {props?.data?.propertyProjects?.map((_, i) => {
                                          return (_?.isDeleted
                                                ? <></>
                                                : <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyProjectTableHeader?.[0]?.width }}>
                                                            <Box className={classes.contactDropdown}
                                                                  onClick={() => handleOpenProjectDialog(_?.project, i)}>
                                                                  {_?.project?.value ?
                                                                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                                                                              <Avatar className={classes.optionAvatar}
                                                                                    {...stringAvatar(_?.project?.project_no)} />
                                                                              <Typography className={classes.contactDropdownValue} noWrap width={"196px"}>
                                                                                    {_?.project?.project_no}
                                                                              </Typography>
                                                                        </Stack>
                                                                        : <Typography className={classes.contactDropdownPlaceholder} noWrap width={"244px"}>
                                                                              {t("Search Project")}
                                                                        </Typography>}
                                                                  <KeyboardArrowDownIcon color="#333333" />
                                                            </Box>
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyProjectTableHeader?.[1]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.project?.title} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyProjectTableHeader?.[2]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={concat_string(
                                                                        {
                                                                              planned_start_date: _?.project?.planned_start_date ? timeZoneConverter(_?.project?.planned_start_date) : "",
                                                                              planned_end_date: _?.project?.planned_end_date ? timeZoneConverter(_?.project?.planned_end_date) : "",
                                                                        },
                                                                        ["planned_start_date", "planned_end_date"], " - ", "-")                                                                  
                                                                  } />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: propertyProjectTableHeader?.[3]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.project?.type ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell}>
                                                            <IconButton onClick={() => props?.updatePropertyProjects("edit", "isDeleted", true, i)}>
                                                                  <DeleteIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Stack>)
                                    })}
                              </Grid>}
                        {props?.data?.propertyProjects?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Button variant="text"
                                    onClick={() => props?.updatePropertyProjects("add")}
                                    sx={{
                                          marginTop: "16px", height: "40px",
                                          "&:hover": { backgroundColor: "white" }
                                    }}>
                                    <Typography className={classes.addShareHolder}>
                                          {t("+ Add Another Project")}
                                    </Typography>
                              </Button>}
                  </Box>
                  <DialogBox
                        maxWidth={'sm'}
                        open={isProjectDialogOpen}
                        onClose={handleCloseProjectDialog}
                        handleClose={handleCloseProjectDialog}
                        header={t("Projects")}
                        component={
                              <Stack direction={"column"} rowGap={"16px"} padding={"16px 24px"} sx={{ backgroundColor: "#ffffff" }}>
                                    <SearchFilter value={searchProjectText} placeholder={t("Search Projects")}
                                          handleChange={(value) => handleSearchProject(value)} />
                                    {isProjectListLoading
                                          ? (<Box sx={{ height: size?.height - 400 }}>
                                                <Stack divider={<Divider />}>
                                                      {[1, 2, 3, 4]?.map?.(_ => <ProjectLoader classes={classes} />)}
                                                </Stack>
                                          </Box>)
                                          : (propertyProjects?.rows?.length > 0
                                                ? (<InfiniteScroll
                                                      dataLength={propertyProjects?.rows?.length}
                                                      next={fetchMoreProjects}
                                                      height={size?.height - 400}
                                                      hasMore={propertyProjects?.rows?.length < propertyProjects?.total_rows_count}
                                                      loader={<ProjectLoader classes={classes} />}
                                                      endMessage={
                                                            <Box width={"100%"} m={"16px 0px"}
                                                                  display={"flex"} justifyContent={"center"}>
                                                                  <Typography className={classes.shareHolderContactEnd}>
                                                                        {t("End Of Projects")}
                                                                  </Typography>
                                                            </Box>
                                                      }>
                                                      <Stack divider={<Divider />}>
                                                            {propertyProjects?.rows?.map?.(_ => {
                                                                  return <ProjectCard
                                                                        classes={classes}
                                                                        data={_}
                                                                        onClick={handleOnClickProject} />
                                                            })}
                                                      </Stack>
                                                </InfiniteScroll>)
                                                : (<Box className={classes.shareHolderContactNoDataBox}
                                                      sx={{ height: size?.height - 400 }}>
                                                      <NoDataFound />
                                                </Box>))}
                              </Stack>
                        } />
            </div >
      )
}