import moment from 'moment';
import { config } from "../../config";
import { GET_UNIT_CATEGORY_LEASE } from '../../graphql/propertySearch';
import { GET_PROPERTY_GROUP } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { LocalStorageKeys, NetWorkCallMethods, convertTimeUtcToZoneCalander } from "../../utils";
import { Purpose, UnitCategory } from "./components";
export const unitCategoryEnum = {
    plot: "Plot",
    land: "Land",
    parking_unit: "Parking Unit"
}

export const residentialValidation = (state) => {

    if (state?.unitCategory?.label === "Residential Unit") {
        if (
            !(!!state?.location?.address) ||
            !(!!state?.location?.latitude) ||
            !(!!state?.location?.longitude) ||
            // !(!!state?.propertyPurpose?.label) ||
            !(!!state?.propertyType?.label) ||
            // (state?.propertyPurpose?.value === "Lease" ? (
            //     !(!!state?.duration?.date) ||
            //     (Number(state?.duration?.input) <= 0) ||
            //     (!state?.duration?.period)
            // ) : false) ||
            // (state?.propertyPurpose?.value === "Sale" ? (
            //     !(!!state?.handOverDate?.date)
            // ) : false) ||
            !(!!state?.unitCategory?.label) ||
            !(!!state?.bedBath?.bed) ||
            !(!!state?.bedBath?.bath)
        ) {
            return true
        }
    } else {
        if (
            !(!!state?.location?.address) ||
            !(!!state?.location?.latitude) ||
            !(!!state?.location?.longitude) ||
            // !(!!state?.propertyPurpose?.label) ||
            !(!!state?.propertyType?.label) ||
            // (state?.propertyPurpose?.value === "Lease" ? (
            //     !(!!state?.duration?.date) ||
            //     (Number(state?.duration?.input) <= 0) ||
            //     (!state?.duration?.period)
            // ) : false) ||
            // (state?.propertyPurpose?.value === "Sale" ? (
            //     !(!!state?.handOverDate?.date)
            // ) : false) ||
            !(!!state?.unitCategory?.label) ||
            !(!!state?.requiredArea?.maxArea) ||
            !(!!state?.requiredArea?.minArea)
        ) {
            return true
        }
    }

}


export const commercialValidation = (state) => {
    if (
        !(!!state?.location?.address) ||
        !(!!state?.location?.latitude) ||
        !(!!state?.location?.longitude) ||
        // !(!!state?.propertyPurpose?.label) ||
        !(!!state?.propertyType?.label) ||
        // (state?.propertyPurpose?.value === "Lease" ? (
        //     !(!!state?.duration?.date) ||
        //     (Number(state?.duration?.input) <= 0) ||
        //     (!state?.duration?.period)
        // ) : false) ||
        // (state?.propertyPurpose?.value === "Sale" ? (
        //     !(!!state?.handOverDate?.date)
        // ) : false) ||
        !(!!state?.unitCategory?.label) ||
        !(!!state?.requiredArea?.maxArea) ||
        !(!!state?.requiredArea?.minArea) 
    ) {
        return true
    }
}

export const leaseValidation = (state) => {
    if (
        !(!!state?.location?.address) ||
        !(!!state?.location?.latitude) ||
        !(!!state?.location?.longitude) ||
        !(!!state?.propertyType?.label) ||
        !(!!state?.unitCategory?.label) ||
        !(!!state?.requiredArea?.maxArea) ||
        !(!!state?.requiredArea?.minArea) ||
        !(!!state?.requiredArea?.measurement) ||
        !(!!state?.checkInOut?.startDate) ||
        !(!!state?.checkInOut?.endDate)
    ) {
        return true
    }
}


export const storageValidation = (state) => {
    if (
        !(!!state?.location?.address) ||
        !(!!state?.location?.latitude) ||
        !(!!state?.location?.longitude) ||
        !(!!state?.propertyType?.label) ||
        !(!!state?.unitCategory?.label) ||
        !(!!state?.requiredArea?.maxArea) ||
        !(!!state?.requiredArea?.minArea) ||
        !(!!state?.requiredArea?.measurement) ||
        !(!!state?.checkInOut?.startDate) ||
        !(!!state?.checkInOut?.endDate)
    ) {
        return true
    }
}

export const measurementUnits = [
    {
        label: "Acres",
        value: "Acres",
    },
    {
        label: "sq.meter",
        value: "sq.meter",
    },
    {
        label: "sq.feet",
        value: "sq.feet",
    },
    {
        label: "Hectare",
        value: "Hectare",
    },
    {
        label: "Cents",
        value: "Cents",
    },
    {
        label: "Sq.Yards",
        value: "Sq.Yards",
    },
];

export const filterType = (type) => {
    if (type === "residential") {
        return {
            // area: "area range",
            facilites: "facilities",
            // amenities: "amenities",
            grace: "grace",
            pet: "pet",
            furnish: "furnish",
            unittype: "unittype",
            // budget: "budget"
        }
    }
    else if (type === "commercial" || type === "storage") {
        return {
            // amenities: "amenities",
            facilites: "facilities",
            grace: "grace",
            unittype: true,
            // budget: "budget",
        }
    }
    else if (type === "lease") {
        return {
            facilites: "facilities",
            furnish: "furnish",
            // amenities: "amenities",
            // budget: "budget"
        }
    }
}

export const typeCaps = (type) => {
    switch (type) {
        case 'residential':
            return 'Residential'
        case 'commercial':
            return 'Commercial'
        case 'storage':
            return 'Storage'
        case 'lease':
            return 'Lease'
        default:
            return ''
    }
}

export const allowfilterState = [
    'aminities', 'facilites', 'facilitesbool', 'furnish', 'pet', 'unittype'
]

export const returnMomentFormat = (val) => convertTimeUtcToZoneCalander(val);

export const returnDiff = (startDate, endDate) => moment(endDate).diff(moment(startDate), 'days');

export const getDateValue = (value, sendEndDate, gracePeriod = 0, subtract) => {
    const { period = null, input = null, date = null } = value ?? {};
    if (period && input && date) {
        let val = getDate(period, date, Number(input));
        if (gracePeriod && gracePeriod > -1) {
            val = getDate('Day', val, Number(gracePeriod));
        }
        if (sendEndDate) {
            if (subtract) {
                return moment(val).subtract(1, "days")?._d
            } else {
                return val
            }

        }
        return `${returnMomentFormat(date)} - ${returnMomentFormat(moment(val).subtract(1, "days"))}`
    }
    return ''
}

const returnRequiredArea = (data) => {
    const { maxArea = "", minArea = "", mesurmentLabel = "" } = data ?? {}
    return `${minArea} - ${maxArea}  ${mesurmentLabel}`
}

const returnCheckedInOut = (data) => {
    const { startDate = "", endDate = "" } = data ?? {}
    return `${startDate ? returnMomentFormat(startDate) : ''} - ${endDate ? returnMomentFormat(endDate) : ''}`
}

export const returnFilterValue = (activeTab) => {
    return activeTab === 0 || activeTab === 1 ? 'Residential' : activeTab === 2 ? 'Storage' : null
}


export const BED_BATH = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5+", value: 5 },
]

export const propertyCategerieseList = [
    {
        label: "Residential Unit",
        value: "Residential Unit",
    },
    {
        label: "Co-Bedroom",
        value: "Co-Bedroom"
    },
    {
        label: "Co-Working",
        value: "Co-Working"
    },
    {
        label: "Co-Bed",
        value: "Co-Bed"
    },

];

export const PROPERTYPURPOSE = [
    { label: "Lease", value: "Lease", },
    { label: "Buy", value: "Sale", }
]

export const UNITCATEGORYLIST = (type) => {
    switch (type) {
        case 'residential':
            return [
                {
                    label: "All",
                    value: null,
                },
                {
                    label: "Residential Unit",
                    value: "Residential Unit",
                },
                {
                    label: "Community Unit",
                    value: "Community Unit",
                },
                {
                    label: "Parking Unit",
                    value: "Parking Unit",
                },
                {
                    label: "Land",
                    value: "Land",
                },
                {
                    label: "Plot",
                    value: "Plot",
                }
            ]
        case 'commercial':
            return [
                {
                    label: "All",
                    value: null,
                },

                {
                    label: "Commercial Unit",
                    value: "Commercial Unit",
                },
                {
                    label: "Parking Unit",
                    value: "Parking Unit",
                },
                {
                    label: "Land",
                    value: "Land",
                },
                {
                    label: "Plot",
                    value: "Plot",
                },
                {
                    label: "Advertising Space",
                    value: "Advertising Space"
                }
            ]
        case 'storage':
            return [
                {
                    label: "All",
                    value: null,
                },

                {
                    label: "Storage Unit",
                    value: "Storage Unit",
                },

            ]
        default:
            return []
    }
}

export const loadOptions = async (search = "", array, type, params, handleLoading, company) => {
    handleLoading(type);
    let result, query, offset = 0;
    if (search && !Boolean(array?.length)) {
        offset = 0;
    } else if (params?.all) {
        offset = (offset === 0 && array?.length > 0) ? (array?.length - 1) : array?.length;
    }
    else {
        offset = array?.length;
    }


    switch (type) {
        case 'property_group_master':
            query = GET_PROPERTY_GROUP(offset, 10, search, params?.revenue, company).loc.source.body;
            result = await networkCallBack(query, handleLoading);
            return {
                options: params?.all ?
                    (offset === 0 ? [{ value: null, label: "All" }, ...result?.property_group_master] :
                        [...result?.property_group_master]) : [...result?.property_group_master],
                hasMore: (array?.length + result?.property_group_master?.length) < result?.count[0]?.count
            }
        case 'unit_category_master_residential':
            query = GET_UNIT_CATEGORY_LEASE(offset, 10, search).loc.source.body;
            result = await networkCallBack(query, handleLoading);
            return {
                options: params?.all ?
                    (offset === 0 ? [{ value: null, label: "All" }, ...result?.unit_category_master] :
                        [...result?.unit_category_master]) : [...result?.unit_category_master],
                hasMore: (array?.length + result?.unit_category_master?.length) < result?.count[0]?.count
            }
        case 'unit_category_master_commercial':
            query = GET_UNIT_CATEGORY_LEASE(offset, 10, search,{ids:params?.ids}).loc.source.body;
            result = await networkCallBack(query, handleLoading);
            return {
                options: params?.all ?
                    (offset === 0 ? [{ value: null, label: "All" }, ...result?.unit_category_master] :
                        [...result?.unit_category_master]) : [...result?.unit_category_master],
                hasMore: (array?.length + result?.unit_category_master?.length) < result?.count[0]?.count
            }
        case 'unit_category_master_lease':
            query = GET_UNIT_CATEGORY_LEASE(offset, 10, search).loc.source.body;
            result = await networkCallBack(query, handleLoading);
            return {
                options: params?.all ?
                    (offset === 0 ? [{ value: null, label: "All" }, ...result?.unit_category_master] :
                        [...result?.unit_category_master]) : [...result?.unit_category_master],
                hasMore: (array?.length + result?.unit_category_master?.length) < result?.count[0]?.count
            }

        default:
            return { options: [] }
    }
}

export const networkCallBack = async (query, handleLoading) => {

    let payload = {
        query
    };

    const options = await NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((response) => {
            let main = response.data.data;
            handleLoading(null);
            return main
        })
        .catch((error) => {
            handleLoading(null);

            return null
        });

    return options
}
export const propertyResultComponentGenerator = (type, value, filterData, company, t = () => false,is_workspace) => {


    if (type === "residential") {
        return [
            {
                component: 'location',
                state_name: 'location',
                value: value?.location,
                placeholder: t('Select City'),
                label: t("Location"),
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2.5
                }
            },
            // {
            //     component: 'select',
            //     state_name: 'propertyPurpose',
            //     value: value?.propertyPurpose,
            //     placeholder: 'Select Purpose',
            //     options: PROPERTYPURPOSE,
            //     label: "Property Purpose",
            //     icon: <Purpose width={15} height={15} />,
            //     breakpoints: {
            //         xs: 12,
            //         sm: 4,
            //         md: 1.5
            //     }
            // },
            {
                component: 'select',
                state_name: 'propertyType',
                value: value?.propertyType,
                isPaginate: true,
                placeholder: t('Select Type'),
                type: 'property_group_master',
                company: company?.value,
                label: t("Property Type"),
                revenue: "Residential",
                icon: <Purpose width={15} height={15} />,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2.5
                },
                all: true
            },
            {
                component: 'date',
                // state_name: 'duration',
                // value:  value?.duration,
                // placeholder: 'Choose Date Range',
                // label: `${`Duration`}`,
                // data: getDateValue(value?.duration, false, filterData?.facilitesbool),
                // title: 'Lease Duration',
                // showEndDate: value?.propertyPurpose?.value === "Lease",
                disabled: true,
                
                state_name: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? 'duration' : 'handOverDate',
                value: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? value?.duration : value?.handOverDate,
                placeholder: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? 'Choose Date Range' : 'Choose Handover Date',
                label: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? `${`Duration (${value?.propertyPurpose},${type})`}` : `${`Handover (${value?.propertyPurpose},${type})`}`,               
                data: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ?  getDateValue(value?.duration, false, filterData?.facilitesbool) : value?.handOverDate?.date ? returnMomentFormat(value?.handOverDate?.date) : '',
                title: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? 'Lease Duration' : 'Handover Date',
                showEndDate: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage"),
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 3
                }
            },
            {
                component: "select",
                isPaginate: true,
                state_name: 'unitCategory',
                value: value?.unitCategory,
                placeholder: t('Select Category'),
                //options: UNITCATEGORYLIST('residential'),
                loadOptions: (search, array) => loadOptions(search, array, 'unit_category_master_residential'),
                label: t("Unit Category"),
                type: "unit_category_master_residential",
                icon: <UnitCategory width={15} height={15} />,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2
                },
                all: true
            },
            {
                component: (value?.unitCategory?.label === "Residential Unit") ? null : 'area',
                label: t("Required Area"),
                state_name: 'requiredArea',
                data: returnRequiredArea(value?.requiredArea),
                value: value?.requiredArea,
                options: measurementUnits,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2
                }
            },
            {

                component: (value?.unitCategory?.label === "Residential Unit") ? 'bedBath' : null,
                bedOptions: BED_BATH,
                bathOptions: BED_BATH,
                data: returnRequiredArea(value?.requiredArea),
                label: t(" Bed Room and Bath"),
                options: measurementUnits,
                state_name: 'bedBath',
                value: value?.bedBath,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2
                }
            },
        ]
    }
    else if (type === "commercial" || type === "storage") {
        return [
            {
                component: 'location',
                state_name: 'location',
                value: value?.location,
                placeholder: t('Select City'),
                label: t("Location"),
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2.5
                }
            },
            // {
            //     component: 'select',
            //     state_name: 'propertyPurpose',
            //     value: value?.propertyPurpose,
            //     placeholder: 'Select Purpose',
            //     options: PROPERTYPURPOSE,
            //     label: "Property Purpose",
            //     icon: <Purpose width={15} height={15} />,
            //     breakpoints: {
            //         xs: 12,
            //         sm: 4,
            //         md: 1.5
            //     }
            // },
            {
                component: 'select',
                state_name: 'propertyType',
                value: value?.propertyType,
                isPaginate: true,
                placeholder: t('Select Type'),
                type: 'property_group_master',
                label: t("Property Type"),
                revenue: "Lease",
                company: company?.value,
                icon: <Purpose width={15} height={15} />,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2
                }
            },
            {
                component: 'date',
                state_name: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? 'duration' : 'handOverDate',
                value: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? value?.duration : value?.handOverDate,
                placeholder: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? 'Choose Date Range' : 'Choose Handover Date',
                label: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? `${`${t("Duration")} (${t(value?.propertyPurpose)},${type})`}` : `${`${t("Handover")} (${t(value?.propertyPurpose)})`}`,
                data: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? getDateValue(value?.duration, false, filterData?.facilitesbool) : value?.handOverDate?.date ? returnMomentFormat(value?.handOverDate?.date) : '',
                title: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage") ? 'Lease Duration' : 'Handover Date',
                showEndDate: (value?.propertyPurpose === "Lease" || value?.propertyPurpose === "Manage"),
                disabled: true,

                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 3
                }
            },
            {
                component: 'select',
                state_name: 'unitCategory',
                value: value?.unitCategory,
                placeholder: t('Select Category'),
                //  options: UNITCATEGORYLIST(type),
                label: t("Unit Category"),
                isPaginate: true,
                loadOptions: (search, array) => loadOptions(search, array, 'unit_category_master_commercial'),
                type: "unit_category_master_commercial",
                icon: <UnitCategory width={15} height={15} />,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2.5
                },
                all: true,
                ids:is_workspace?[7,8,9,3]:[],
            },
            {
                component: 'area',
                label: t("Required Area"),
                state_name: 'requiredArea',
                data: returnRequiredArea(value?.requiredArea),
                value: value?.requiredArea,
                options: measurementUnits,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2
                }
            },
        ]
    }
    else if (type === "lease") {
        return [
            {
                component: 'location',
                state_name: 'location',
                value: value?.location,
                placeholder: t('Select City'),
                label: t("Location"),
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2.4
                }
            },
            {
                component: 'select',
                state_name: 'propertyType',
                value: value?.propertyType,
                isPaginate: true,
                placeholder: t('Select Type'),
                type: 'property_group_master',
                debounceTimeout: 800,
                label: t("Property Type"),
                company: company?.value,
                icon: <Purpose width={15} height={15} />,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2.4
                }
            },
            {
                component: 'select',
                state_name: 'unitCategory',
                value: value?.unitCategory,
                placeholder: t('Select Category'),
                //options: propertyCategerieseList,
                label: t("Unit Category"),
                loadOptions: (search, array) => loadOptions(search, array, 'unit_category_master_lease'),
                isPaginate: true,
                icon: <UnitCategory width={15} height={15} />,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2.4
                }
            },
            {
                component: 'rangePicker',
                label: t("Check In & Out"),
                state_name: 'checkInOut',
                data: returnCheckedInOut(value?.checkInOut),
                value: value?.checkInOut,
                startDateTitle: t('Check In'),
                endDateTitle: t('Check Out'),
                placeholder: t('Enter Check In & Out'),
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2.4
                }
            },
            {
                component: 'area',
                label: t("Required Area"),
                state_name: 'requiredArea',
                data: returnRequiredArea(value?.requiredArea),
                value: value?.requiredArea,
                options: measurementUnits,
                breakpoints: {
                    xs: 12,
                    sm: 4,
                    md: 2.4
                }
            },
        ]
    }
}

export const getCoords = async () => {
    const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    const lat = pos.coords.latitude;
    const long = pos.coords.longitude;

    const result = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${config.googleMapApiKey}`)
        .then(data => data.json())
        .then(({ results }) => {
            if (Array.isArray(results)) {
                const [info] = results;
                let city = info?.address_components?.find(comp => comp?.types?.includes("locality"));
                let location = info?.address_components?.find(comp => comp?.types?.includes("administrative_area_level_1"));
                let _obj = {};
                _obj["address"] = `${city?.long_name}, ${location?.long_name}`;
                _obj["latitude"] = lat;
                _obj["longitude"] = long;
                _obj["city"] = city?.long_name;
                _obj["location"] = location?.long_name;
                return (_obj)

            }
        }).catch((err) => {
            console.log(err);
            return null
        });

    return result
};

export const getDate = (type, date, value) => {

    let copyDate = new Date(JSON.parse(JSON.stringify(date)))

    switch (type) {
        case "Day":
            return new Date(moment(copyDate).add(value, 'day'))
        case "Month":
            return new Date(moment(copyDate).add(value, 'month'))
        case "Year":
            return new Date(moment(copyDate).add(value, 'year'))
        case "Monthly":
            return new Date(moment(copyDate).add(value, 'month'))
        case "Yearly":
            return new Date(moment(copyDate).add(value, 'year'))
        default:
            return new Date()
    }
}

export const returnPropertyPayload = (data = {}, location = {}, filterData = {}, state = null) => {
    const module_id = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const is_workspace = ['49', '35'].includes(module_id)
    return {

        tenantId: `${config.tenantId}`,
        search_location: {
            lat: location?.latitude ?? null,
            long: location?.longitude ?? null,
            distance: data?.distance ?? 10
        },
        property_purpose: state?.purpose ?? null,
        revenue_type: data?.propertyPurpose ?? null,
        property_type: data?.propertyType?.value ?? null,
        unit_category: is_workspace ? data?.unitCategory?.value === null ? [7,8,9,3] : [data?.unitCategory?.value] : data?.unitCategory?.value ? [data?.unitCategory?.value] : null,
        unit_type: filterData?.unittype?.length > 0 ? filterData?.unittype?.map((val) => val?.value) : null,
        requiredBeds: (data?.unitCategory?.label === "Residential Unit") ? data?.bedBath?.bed : null,
        requiredBaths: (data?.unitCategory?.label === "Residential Unit") ? data?.bedBath?.bath : null,
        duration: (data?.propertyPurpose === "Sale") ?
            {
                fromDate: null,
                toDate: null
            } :
            {
                fromDate: (state?.type === "lease" || data?.data?.propertyPurpose?.value === "Lease") ?
                    data?.checkInOut?.startDate : state?.data?.lead ? state?.data?.lead?.lease_start_date : data?.duration?.date
                        ?? null,
                toDate: (state?.type === "lease" || data?.data?.propertyPurpose?.value === "Lease") ? data?.checkInOut?.endDate : state?.data?.lead ? state?.data?.lead?.lease_end_date : getDateValue(data?.duration, true, filterData?.facilitesbool),
            },
        areaRequirements: {
            minArea:
                state?.type === "residential" ?
                    (data?.unitCategory?.label === "Residential Unit") ?
                        filterData?.range1 ? parseInt(filterData?.range1) : null : data?.requiredArea?.minArea
                    : data?.requiredArea?.minArea ? parseInt(data?.requiredArea?.minArea) : null,



            maxArea: state?.type === "residential" ?
                (data?.unitCategory?.label === "Residential Unit") ?
                    filterData?.range2 ? parseInt(filterData?.range2) : null
                    : data?.requiredArea?.maxArea
                : data?.requiredArea?.maxArea ? parseInt(data?.requiredArea?.maxArea) : null,
            areaUnit: state?.type === "residential" ? (data?.unitCategory?.label === "Residential Unit") ?

                filterData?.range1 && filterData?.range2 ? filterData?.rangetype?.value : null
                : data?.requiredArea?.measurement
                : data?.requiredArea?.measurement ?? null
        },
        budget: {
            minCost: filterData?.budget1 ? parseInt(filterData?.budget1) : null,
            maxCost: filterData?.budget2 ? parseInt(filterData?.budget2) : null,
            currency: filterData?.budgettype?.value ? filterData?.budget1 && filterData?.budget2 ? filterData?.budgettype?.value : null : null
        },
        hand_over_date: (state?.type === "sale" || data?.propertyPurpose === "Sale") ? data?.handOverDate?.date ? moment(data?.handOverDate?.date).add(1, "day")?._i
            : null : undefined,
        furnishing: filterData?.furnish ? [filterData?.furnish] : null,
        isPetAllowed: filterData?.pet ? filterData?.pet === "Allowed" ? true : false : null,
        facilities: filterData?.facilites?.length > 0 ? filterData?.facilites?.map((val) => val?.value) : null,
        amenities: filterData?.aminities?.length > 0 ? filterData?.aminities?.map((val) => val?.value) : null,
    }
}
// enum api
export const enumSelect = async (enumType) => {
    const payload = {
        enumName: [enumType]
    }
    let emnum = await NetworkCall(
        `${config.api_url}/enum`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
    return emnum?.data?.data?.[enumType]
}

export const isHas=(obj)=>{
    const hasData = Object.values(obj).some(value => {
        return value !== null && (Array.isArray(value) ? value.length > 0 : true);
    });
    return hasData
}