/* eslint-disable react-hooks/rules-of-hooks */
import { useApolloClient } from "@apollo/client";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Avatar, Box, Button, Divider, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import "../../../App.css";
import { DialogBox, Editor, Label, MapWithFields, MobileNumberInputComponent, NewDatePicker, SearchFilter, SelectBox, TextBox, UseDebounce } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import {
      GET_PARENT_UNIT_BY_PROPERTY_ID,
      GET_PARENT_UNIT_BY_FLOOR_ID,
      GET_UNIT_FORM_MASTER_NEW as queryOne,
      GET_UNIT_TYPE,
      UOM_MASTER
} from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { activeOptionsList, AlertProps, concat_string, enumName, getCustomBlockName, getCustomFloorName, LocalStorageKeys, NetWorkCallMethods, stringAvatar, timeZoneConverter, useWindowDimensions } from "../../../utils";

import { GET_ALL_BLOCK_OR_FLOOR_BY_PROPERTY_ID } from "../../createFloorNew/utils";
import { useStylesCreation } from "./style";
import { Bold } from "../../../utils";
import { DeleteIcon } from "../../../assets";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ShareHolderContactLoader } from "../../propertcreation/createproperty/shareholderContactLoader";
import { ShareHolderContactCard } from "../../propertcreation/createproperty/shareholderContactCard";
import NoDataFound from "../../../assets/noData";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { ProjectLoader } from "./projectLoader";
import { ProjectCard } from "./projectCard";
import { ContractLoader } from "./contractLoader";
import { ContractCard } from "./contractCard";
const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize:"0.75rem",
      fontFamily: Bold,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}))
const CustomtoolTip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
            color: "white",
      },
      [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            padding: "12px",
            color: "#4E5A6B",
            border: "1px solid #E4E8EE"
      },
}));

const shareHolderTableHeader = [
      { header: "Contact", width: "300px" },
      { header: "Share", width: "100px" },
      { header: "Name", width: "200px" },
      { header: "Mobile Number", width: "175px" },
      { header: "Email Address", width: "200px" },
      { header: "Delete" },
]

const unitContractTableHeader = [
      { header: "Contract ID", width: "300px" },
      { header: "Contract Date", width: "200px" },
      { header: "Period", width: "200px" },
      { header: "Account ID", width: "200px" },
      { header: "Vendor ID", width: "200px" },
      { header: "Vendor Name", width: "200px" },
      { header: "Delete" },
]

const unitProjectTableHeader = [
      { header: "Project ID", width: "300px" },
      { header: "Name", width: "200px" },
      { header: "Period", width: "200px" },
      { header: "Project Type", width: "200px" },
      { header: "Delete" },
]

export const UnitDetails = (props) => {
      const localStorageCompany = localStorage?.getItem?.(LocalStorageKeys?.selectedCompany);
      const selectedCompany = localStorageCompany ? JSON?.parse?.(localStorageCompany) : "";
      const { t } = (props)
      const {
            mapLoad,
            showBlock,
            showFloor,
            propertyID,
            blockID,
            floorID,
            onChangeFloor,
            main
      } = props;
      const buildupAreades = t("The area of an apartment that can be covered by a carpet or the net usable area is known as the carpet area. It is the distance between the inner walls. The carpet area would include the areas of the bedroom, living room, kitchen, bathrooms, balconies & staircases within the house/flat. It does not include the external and internal walls, terraces, common areas, lifts, corridors, utility ducts, etc")
      const superbuilup = t("Super Built-up area is referred to as the ‘saleable area’ and homebuyers are usually charged based on this value. It is the sum of the exclusive built-up area, and common areas including the lift, corridors, staircase, clubhouse, and other amenities.")

      const [fullScreenMap, setFullScreenMap] = React.useState(false)
      const classes = useStylesCreation();
      const client = useApolloClient()
      const size = useWindowDimensions()
      const [loading, setLoading] = React.useState(false)
      const alert = React.useContext(AlertContext);
      const [isShareHolderDialogOpen, setIsShareHolderDialogOpen] = useState(false);
      const [isShareHolderContactListLoading, setIsShareHolderContactListLoading] = useState(true);
      const [searchShareHolderContactText, setSearchShareHolderContactText] = useState("");
      const [shareHolderContactOffset, setShareHolderContactOffset] = useState(0);
      const [selectedShareHolderContact, setSelectedShareHolderContact] = useState({data: "", index: ""});
      const [shareHolderContacts, setShareHolderContacts] = useState({ rows: [], total_rows_count: 0 });
      const [isContractDialogOpen, setIsContractDialogOpen] = useState(false);
      const [isContractListLoading, setIsContractListLoading] = useState(true);
      const [searchContractText, setSearchContractText] = useState("");
      const [contractOffset, setContractOffset] = useState(0);
      const [selectedContract, setSelectedContract] = useState({data: "", index: ""});
      const [unitContracts, setUnitContracts] = useState({ rows: [], total_rows_count: 0 });
      const [isProjectDialogOpen, setIsProjectDialogOpen] = useState(false);
      const [isProjectListLoading, setIsProjectListLoading] = useState(true);
      const [searchProjectText, setSearchProjectText] = useState("");
      const [projectOffset, setProjectOffset] = useState(0);
      const [selectedProject, setSelectedProject] = useState({data: "", index: ""});
      const [unitProjects, setUnitProjects] = useState({ rows: [], total_rows_count: 0 });
      const debounce = UseDebounce();
      const shareHolderContactloader = {
            initial: {
                  true: () => setIsShareHolderContactListLoading(true),
                  false: () => setIsShareHolderContactListLoading(false),
            },
      }
      const contractloader = {
            initial: {
                  true: () => setIsContractListLoading(true),
                  false: () => setIsContractListLoading(false),
            },
      }
      const projectloader = {
            initial: {
                  true: () => setIsProjectListLoading(true),
                  false: () => setIsProjectListLoading(false),
            },
      }
      const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            era: 'short',
            calendar: 'islamic-umalqura' // Specify the Hijri calendar
      };

      const hijriFormatter = new Intl.DateTimeFormat('ar-SA-u-ca-islamic-umalqura', options);

      const handleOpenShareHolderDialog = (c, i) => {
            setSelectedShareHolderContact({ data: c, index: i });
            setIsShareHolderDialogOpen(true);
            getShareHolderContacts({ selectedContact: { data: c, index: i } });
      }

      const handleOpenContractDialog = (c, i) => {
            setSelectedContract({ data: c, index: i });
            setIsContractDialogOpen(true);
            getContracts({ selected_contract: { data: c, index: i } });
      }

      const handleOpenProjectDialog = (p, i) => {
            setSelectedProject({ data: p, index: i });
            setIsProjectDialogOpen(true);
            getProjects({ selected_project: { data: p, index: i } });
      }

      const handleCloseShareHolderDialog = () => {
            setIsShareHolderDialogOpen(false);
            setSelectedShareHolderContact({data: "", index: ""});
            setSearchShareHolderContactText("");
            setShareHolderContactOffset(0);
      }

      const handleCloseContractDialog = () => {
            setIsContractDialogOpen(false);
            setSelectedContract({data: "", index: ""});
            setSearchContractText("");
            setContractOffset(0);
      }

      const handleCloseProjectDialog = () => {
            setIsProjectDialogOpen(false);
            setSelectedProject({data: "", index: ""});
            setSearchProjectText("");
            setProjectOffset(0);
      }

      const handleSearchShareHolderContact = (searchText) => {
            setIsShareHolderContactListLoading(true);
            setSearchShareHolderContactText(searchText);
            debounce(() => {
                  const offset = 0;
                  setShareHolderContactOffset(offset);
                  getShareHolderContacts({ offset, search: searchText, load_type: "initial" });
            }, 800)
      }

      const handleSearchContract = (searchText) => {
            setIsContractListLoading(true);
            setSearchContractText(searchText);
            debounce(() => {
                  const offset = 0;
                  setContractOffset(offset);
                  getContracts({ offset, search: searchText, load_type: "initial" });
            }, 800)
      }

      const handleSearchProject = (searchText) => {
            setIsProjectListLoading(true);
            setSearchProjectText(searchText);
            debounce(() => {
                  const offset = 0;
                  setProjectOffset(offset);
                  getProjects({ offset, search: searchText, load_type: "initial" });
            }, 800)
      }

      const getShareHolderContacts = ({
            offset = shareHolderContactOffset,
            limit = 10,
            search = searchShareHolderContactText,
            selectedContact = selectedShareHolderContact,
            load_type = "initial",
      }) => {
            shareHolderContactloader?.[load_type]?.true();

            let payload = {
                  offset, limit, search,
                  filter: {
                        exclude_contact_ids: props?.data?.shareHolders
                              ?.filter?.(_ => !_?.isDeleted)
                              ?.map?.(_ => _?.contact?.id ?? false)
                              ?.filter?.(Boolean)
                              ?.filter?.(__ => __ !== selectedContact?.data?.id)
                  }
            };

            NetworkCall(
                  `${config?.api_url}/contact/list`,
                  NetWorkCallMethods?.post, payload, null, true, false
            ).then((response) => {
                  let temp_row = [];

                  if (load_type === "loadMore") {
                        temp_row = response?.data?.data
                              ? [
                                    ...shareHolderContacts?.rows,
                                    ...response?.data?.data?.map?.(_ => {
                                          return {
                                                ..._,
                                                value: _?.id,
                                                isSelected: selectedContact?.data?.id === _?.id
                                          }
                                    }),
                              ]
                              : shareHolderContacts?.rows;
                  } else {
                        temp_row = response?.data?.data?.map?.(_ => {
                              return {
                                    ..._,
                                    value: _?.id,
                                    isSelected: selectedContact?.data?.id === _?.id
                              }
                        }) ?? [];
                  }

                  setShareHolderContacts({
                        rows: temp_row,
                        total_rows_count: response?.data?.count ?? 0,
                  })

                  shareHolderContactloader?.[load_type]?.false();
            }).catch((e) => {
                  alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps?.severity?.error
                  })

                  shareHolderContactloader?.[load_type]?.false();
            });
      }

      const getContracts = ({
            offset = contractOffset,
            limit = 10,
            search = searchContractText,
            selected_contract = selectedContract,
            load_type = "initial",
      }) => {
            contractloader?.[load_type]?.true();

            let payload = {
                  offset, limit, search, contract_type: [9], 
                  company_id: selectedCompany?.value,
                  filter: {
                        exclude_contract_ids: props?.data?.unitContracts
                              ?.filter?.(_ => !_?.isDeleted)
                              ?.map?.(_ => _?.contract?.id ?? false)
                              ?.filter?.(Boolean)
                              ?.filter?.(__ => __ !== selected_contract?.data?.id)
                  }
            };

            NetworkCall(
                  `${config.api_url}/contract/get_all_contract`,
                  NetWorkCallMethods?.post, payload, null, true, false
            ).then((response) => {
                  let temp_row = [];

                  if (load_type === "loadMore") {
                        temp_row = response?.data?.data
                              ? [
                                    ...unitContracts?.rows,
                                    ...response?.data?.data?.map?.(_ => {
                                          return {
                                                ..._,
                                                value: _?.id,
                                                isSelected: selected_contract?.data?.id === _?.id
                                          }
                                    }),
                              ]
                              : unitContracts?.rows;
                  } else {
                        temp_row = response?.data?.data?.map?.(_ => {
                              return {
                                    ..._,
                                    value: _?.id,
                                    isSelected: selected_contract?.data?.id === _?.id
                              }
                        }) ?? [];
                  }

                  setUnitContracts({
                        rows: temp_row,
                        total_rows_count: response?.data?.count ?? 0,
                  })

                  contractloader?.[load_type]?.false();
            }).catch((e) => {
                  alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps?.severity?.error
                  })

                  contractloader?.[load_type]?.false();
            });
      }

      const getProjects = ({
            offset = projectOffset,
            limit = 10,
            search = searchProjectText,
            selected_project = selectedProject,
            load_type = "initial",
      }) => {
            projectloader?.[load_type]?.true();

            let payload = {
                  offset, limit, search,
                  company_id: selectedCompany?.value,
                  filter: {
                        exclude_project_ids: props?.data?.unitProjects
                              ?.filter?.(_ => !_?.isDeleted)
                              ?.map?.(_ => _?.project?.id ?? false)
                              ?.filter?.(Boolean)
                              ?.filter?.(__ => __ !== selected_project?.data?.id)
                  }
            };

            NetworkCall(
                  `${config?.api_url}/project/list`,
                  NetWorkCallMethods?.post, payload, null, true, false
            ).then((response) => {
                  let temp_row = [];

                  if (load_type === "loadMore") {
                        temp_row = response?.data?.data?.data
                              ? [
                                    ...unitProjects?.rows,
                                    ...response?.data?.data?.data?.map?.(_ => {
                                          return {
                                                ..._,
                                                value: _?.id,
                                                isSelected: selected_project?.data?.id === _?.id
                                          }
                                    }),
                              ]
                              : unitProjects?.rows;
                  } else {
                        temp_row = response?.data?.data?.data?.map?.(_ => {
                              return {
                                    ..._,
                                    value: _?.id,
                                    isSelected: selected_project?.data?.id === _?.id
                              }
                        }) ?? [];
                  }

                  setUnitProjects({
                        rows: temp_row,
                        total_rows_count: response?.data?.data?.count ?? 0,
                  })

                  projectloader?.[load_type]?.false();
            }).catch((e) => {
                  alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps?.severity?.error
                  })

                  projectloader?.[load_type]?.false();
            });
      }

      const fetchMoreShareHolderContacts = () => {
            if (shareHolderContacts?.rows?.length < shareHolderContacts?.total_rows_count) {
                  const offset = shareHolderContactOffset + 10;
                  setShareHolderContactOffset(offset);
                  getShareHolderContacts({ offset, load_type: "loadMore" });
            }
      }

      const fetchMoreContracts = () => {
            if (unitContracts?.rows?.length < unitContracts?.total_rows_count) {
                  const offset = contractOffset + 10;
                  setContractOffset(offset);
                  getContracts({ offset, load_type: "loadMore" });
            }
      }

      const fetchMoreProjects = () => {
            if (unitProjects?.rows?.length < unitProjects?.total_rows_count) {
                  const offset = projectOffset + 10;
                  setProjectOffset(offset);
                  getProjects({ offset, load_type: "loadMore" });
            }
      }

      const handleOnClickShareHolderContact = (val) => {
            props?.updateShareHolders("edit", "contact", val, selectedShareHolderContact?.index);
            handleCloseShareHolderDialog();
      };

      const handleOnClickContract = (val) => {
            props?.updateUnitContracts("edit", "contract", val, selectedContract?.index);
            handleCloseContractDialog();
      };

      const handleOnClickProject = (val) => {
            props?.updateUnitProjects("edit", "project", val, selectedProject?.index);
            handleCloseProjectDialog();
      };

      // const [enumValue, setEnum] = React.useState({
      //       payment_period: [],
      //       furnish: [],
      //       orientation: [],
      //       pet_policy_type: [],
      //       purpose_type: [],
      //       area_metric_type: [],
      //       listing_type: [],
      //       unit_execution_status: [],
      // })

      const loadOptions = async (search = "", array, type) => {
            setLoading(type);
            let result, query, variable, offset, unitCategoryIDs = 0;
            let limit = 10
            let unit_category_id = props?.data?.unitCategory?.value


            if (search && !Boolean(array?.length)) {
                  offset = 0;
            }
            else {
                  offset = array?.length;
            }

            switch (unit_category_id) {
                  case 6: // Bed
                        unitCategoryIDs = [2, 4, 10, 11]; // Residential, Commercial, Room, Facility
                        break;

                  case 9: // Coworking
                        unitCategoryIDs = [2, 4, 11]; // Residential, Commercial, Facility
                        break;

                  case 10: // Room
                        unitCategoryIDs = [2, 4, 11]; // Residential, Commercial, Facility
                        break;

                  default:
                        break;

            }

            switch (type) {
                  case 'property_group_master':
                        query = queryOne(offset, 10, search, null, type).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.property_group_master],
                              hasMore: (array?.length + result?.property_group_master?.length) < result?.count[0]?.count
                        }
                  // case 'unit_type_master':
                  //       query = queryOne(offset, 10, search, null, type).loc.source.body;
                  //       result = await networkCallBack(query);
                  //       return {
                  //             options: [...result?.unit_type_master],
                  //             hasMore: (array?.length + result?.unit_type_master?.length) < result?.count[0]?.count
                  //       }
                  case 'unit_type_master':
                        if (!props?.data?.unitPurpose?.value?.length > 0) {
                              setLoading(null);
                              return { options: [] }
                        }
                        query = GET_UNIT_TYPE(offset, 10, search, props?.data?.unitPurpose?.value, props?.data?.company?.value).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.unit_type_master],
                              hasMore: (array?.length + result?.unit_type_master?.length) < result?.count[0]?.count
                        }
                  case 'parent_unit':
                        query = props?.data?.showAllUnits ?
                              GET_PARENT_UNIT_BY_PROPERTY_ID(
                                    propertyID, search, offset,
                                    limit, unitCategoryIDs,
                                    props?.data?.unitPurpose?.value).loc.source.body :
                              GET_PARENT_UNIT_BY_FLOOR_ID(
                                    props?.data?.floorID?.value,
                                    search, unitCategoryIDs,
                                    props?.data?.unitPurpose?.value,
                                    offset, limit).loc.source.body;

                        let options = []
                        if (props?.data?.showAllUnits) {
                              result = await networkCallBack(query)
                              options = result?.property[0].unitByID?.map((item) => {
                                    let _i = item;
                                    _i.label = item?.label + " - " + item?.unit_no;
                                    return _i;
                              });
                        } else {
                              result = await networkCallBack(query)
                              options = result?.floor[0].unit?.map((item) => {
                                    let _i = item;
                                    _i.label = item?.label + " - " + item?.unit_no;
                                    return _i;
                              });
                        }
                        return {
                              options: [...options],
                              hasMore: (array?.length + options?.length) < result?.count[0]?.count[0]?.count
                        }
                  case 'property':
                        query = GET_PARENT_UNIT_BY_PROPERTY_ID;
                        variable = { propertyID, search, offset, limit }
                        result = await networkCallBackG(query, variable);
                        // let options2 = result?.property[0].unitByID?.map((item) => {
                        //       let _i = item;
                        //       _i['value'] = item?.id;
                        //       _i['label'] = item?.name + " - " + item?.unit_no;
                        //       return _i;
                        // });
                        let options2 = result?.property[0].unitByID;
                        return {
                              options: [...options2],
                              hasMore: (array?.length + options2?.length) < result?.count[0]?.count[0]?.count
                        }
                  case 'block_details':
                        if (!propertyID) {
                              setLoading(null);
                              return { options: [] }
                        }
                        else {
                              query = GET_ALL_BLOCK_OR_FLOOR_BY_PROPERTY_ID(
                                    offset, 10, search, "property_id", "block", propertyID).loc.source.body;
                              result = await networkCallBack(query);
                              return {
                                    options: [...result?.block],
                                    hasMore: (array?.length + result?.block?.length) < result?.count[0]?.count
                              }
                        }
                  case 'floor_details':
                        if (props?.data?.blockID?.value || blockID || propertyID) {
                              let chooseBlockID = props?.data?.blockID?.value ? props?.data?.blockID?.value : blockID;
                              query = GET_ALL_BLOCK_OR_FLOOR_BY_PROPERTY_ID(
                                    offset, 10, search,
                                    chooseBlockID ? "block_id" : "property_id",
                                    "floor", chooseBlockID ?? propertyID).loc.source.body;
                              result = await networkCallBack(query);
                              return {
                                    options: [...result?.floor],
                                    hasMore: (array?.length + result?.floor?.length) < result?.count[0]?.count
                              }
                        }
                        else {
                              setLoading(null);
                              return { options: [] }
                        }
                  case 'uom_master':
                        query = UOM_MASTER(search, offset, limit).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.uom_master],
                              hasMore: (array?.length + result?.uom_master?.length) < result?.count[0]?.count
                        }
                  default:
                        return { options: [] }
            }
      }
      const networkCallBack = async (query, variable = {}) => {
            let payload = {
                  query,
                  variable: variable
            };

            const options = await NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        let main = response.data.data;
                        setLoading(null);
                        return main
                  })
                  .catch(() => {
                        setLoading(null);
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("Some Thing Went Wrong"),
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                        return null
                  });

            return options
      }



      // Function for network callback for Async Select Options using Graphql
      const networkCallBackG = async (query, variables = {}) => {
            const options = await client.query({
                  query, fetchPolicy: "network-only", variables
            }).then((r) => {
                  let main = r?.data
                  setLoading(null)
                  return main
            }).catch((e) => {
                  setLoading(null)
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                  })
                  return null
            })
            return options
      }
      // const getEnum = async () => {
      //       const result = await enumSelect([
      //             enum_types.unit_payment_period, enum_types.furnishing_type,
      //             enum_types.orientation_type, enum_types.pet_policy_type,
      //             enum_types.unit_category, enum_types.purpose_global_type,
      //             enum_types.revenue_type, enum_types.area_metric_type,
      //             enum_types.listing_type, enum_types.unit_execution_status
      //       ])

      //       const unit_category_master = await client.query({
      //             query: GET_UNIT_CATEGORY_MASTER,
      //             variables: {}
      //       }).then(res => res?.data?.unit_category_master).catch(err => console.log(err));

      //       let purpose_global_type = [], residential_unit_category = [];
      //       let commercial_unit_category = [], facilities_unit_category = [];

      //       // // Include Residential Unit, Room Space, Bed Space, Plot, Land, Parking Unit, Storage Space
      //       // let incRUC = [2, 10, 6, 5, 1, 8, 7]

      //       // // Include Commercial Unit, Work Space, Room Space, Bed Space, Plot, Land, Parking Unit, Marketing Space, Storage Space
      //       // let incCUC = [4, 9, 10, 6, 5, 1, 8, 3, 7, 11]

      //       // // Include Facility Space
      //       // let incFUC = [11]

      //       for (let i = 0; i < result.purpose_global_type.length; i++) {
      //             if (!([enumName.mixed].includes(result.purpose_global_type[i].value))) {
      //                   purpose_global_type = [...purpose_global_type, result.purpose_global_type[i]]
      //             }
      //       }

      //       for (let i = 0; i < unit_category_master.length; i++) {
      //             // if ((incRUC.includes(unit_category_master[i].value))) {
      //                   residential_unit_category = [
      //                         ...residential_unit_category,
      //                         {
      //                               ...unit_category_master[i],
      //                               ...unitCategoryMaster[unit_category_master[i].value]
      //                         }
      //                   ]
      //             // }
      //             // if ((incCUC.includes(unit_category_master[i].value))) {
      //                   commercial_unit_category = [
      //                         ...commercial_unit_category,
      //                         {
      //                               ...unit_category_master[i],
      //                               ...unitCategoryMaster[unit_category_master[i].value]
      //                         }
      //                   ]
      //             // }
      //             // if ((incFUC.includes(unit_category_master[i].value))) {
      //                   facilities_unit_category = [
      //                         ...facilities_unit_category,
      //                         {
      //                               ...unit_category_master[i],
      //                               ...unitCategoryMaster[unit_category_master[i].value]
      //                         }
      //                   ]
      //             // }
      //       }

      //       for (let i = 0; i < purpose_global_type.length; i++) {
      //             purpose_global_type[i].revenueTypeList = result.revenue_type
      //             purpose_global_type[i].value === enumName.residential &&
      //                   (purpose_global_type[i].unitCategoryList = residential_unit_category)
      //             purpose_global_type[i].value === enumName.commercial &&
      //                   (purpose_global_type[i].unitCategoryList = commercial_unit_category)
      //             purpose_global_type[i].value === enumName.facilities &&
      //                   (purpose_global_type[i].unitCategoryList = facilities_unit_category)
      //       }

      //       setEnum({
      //             payment_period: result?.payment_value_type,
      //             furnish: result?.furnishing_type,
      //             orientation: result?.orientation_type,
      //             pet_policy_type: result?.pet_policy_type,
      //             purpose_type: purpose_global_type,
      //             area_metric_type: result?.area_metric_type,
      //             listing_type: result?.listing_type,
      //             unit_execution_status: result?.unit_execution_status
      //       })
      // }

      // React.useEffect(() => {
      //       getEnum()
      //       // eslint-disable-next-line
      // }, [])

      React.useEffect(() => {
            document.addEventListener("fullscreenchange", existFullscreen)
            return () => {
                  document.removeEventListener("fullscreenchange", existFullscreen)

            }

      }, [])
      //exist full screen
      const existFullscreen = () => {
            if (document.fullscreenElement === null) {
                  setFullScreenMap(false)
            }

      }
      //open full screen
      const fullScreenControl = () => {
            setFullScreenMap(true)
            document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

      }

      const handleChangeSeat = (value) => {
            props?.updateState("seat", value)
            props?.setData({
                  ...props?.data,
                  seat: value,
                  measurementUnit: { label: "Seat", value: 9 },
                  totalArea: value
            })

      }

      const manualVendorResponse = (array) => array?.map(_ => {
            return {
                  ..._,
                  label: (<Stack direction={"row"} columnGap={2} alignItems={"center"}>
                        <Avatar className={classes.optionAvatar}
                              src={_?.image_url} {...stringAvatar(_?.name)} />
                        <Typography className={classes.optionHeader}>
                              {concat_string(
                                    {
                                          vendor_no: _?.vendor_no,
                                          vendor_name: _?.name,
                                    },
                                    ["vendor_no", "vendor_name"],
                                    " - "
                              )}
                        </Typography>
                  </Stack>),
                  value: _?.id,
                  address: concat_string(
                        {
                              door_no: _?.door_no,
                              street_1: _?.street_1,
                              street_2: _?.street_2,
                              landmark: _?.landmark,
                              area: _?.area,
                              city: _?.city,
                              state: _?.state,
                              country: _?.country,
                              pincode: _?.pincode,
                        },
                        [
                              "door_no", "street_1", "street_2",
                              "landmark", "area", "city",
                              "state", "country", "pincode",
                        ], ", ", "-"
                  ),
                  latitude: _.latitude,
                  longitude: _.longitude,
            }
      });

      return (
            <div>
                  <Grid container spacing={3}>
                        {
                              (showBlock || showFloor) &&
                              <Grid item xs={12}>
                                    <Box className={classes.imagebox1}>
                                          <Grid container spacing={3}>
                                                {
                                                      showBlock &&
                                                      <Grid item xs={3}>
                                                            <SelectBox
                                                                  isRequired
                                                                  isReadOnly={blockID || floorID || main?.isEdit}
                                                                  label={getCustomBlockName(props?.data?.property_hierarchy)}
                                                                  placeholder={`${t("Select")} ` + getCustomBlockName(props?.data?.property_hierarchy)}
                                                                  loading={loading === "block_details"}
                                                                  isPaginate
                                                                  debounceTimeout={800}
                                                                  loadOptions={(search, array) => loadOptions(search, array, 'block_details')}
                                                                  // options={companyIDs ?? []}
                                                                  value={props?.data?.blockID ?? ""}
                                                                  onChange={(value) => {
                                                                        props?.updateState("blockID", value);
                                                                  }}
                                                                  isError={props?.data?.error?.blockID?.length > 0}
                                                                  errorMessage={props?.data?.error?.blockID}
                                                            />
                                                      </Grid>
                                                }
                                                {
                                                      showFloor &&
                                                      <Grid item xs={3}>
                                                            <SelectBox
                                                                  key={showBlock ? props?.data?.blockID?.value : ""}
                                                                  isRequired
                                                                  isReadOnly={floorID || main?.isEdit}
                                                                  label={getCustomFloorName(props?.data?.property_hierarchy)}
                                                                  placeholder={`${t("Select")} ` + getCustomFloorName(props?.data?.property_hierarchy)}
                                                                  loading={loading === "floor_details"}
                                                                  isPaginate
                                                                  debounceTimeout={800}
                                                                  loadOptions={(search, array) => loadOptions(search, array, 'floor_details')}
                                                                  // options={companyIDs ?? []}
                                                                  value={props?.data?.floorID ?? ""}
                                                                  onChange={(value) => {
                                                                        onChangeFloor(value);
                                                                  }}
                                                                  isError={props?.data?.error?.floorID?.length > 0}
                                                                  errorMessage={props?.data?.error?.floorID}
                                                            />
                                                      </Grid>
                                                }
                                          </Grid>
                                    </Box>
                              </Grid>
                        }
                        <Grid item xs={12} md={2} lg={2}>
                              <Box className={classes.imagebox}>
                                    <Typography className={classes.title}>
                                          {t("UNIT IMAGE")}
                                    </Typography>
                                    <Avatar src={props?.data?.image?.length > 0 ? props?.data?.image : "/images/citynew.svg"} style={{ margin: '0 auto' }} className={props?.data?.image?.length > 0 ? classes.avatar : classes.avatar1} />
                                    <div>
                                          <label> <Box className={classes.button} variant='contained' style={{ marginTop: '50px' }}>    {t("Upload image")}</Box> <input type='file' style={{ display: 'none' }} accept="image/*" onChange={(e) => props?.updateimg(e?.target?.files?.[0])}
                                                onClick={(event) => {
                                                      event.target.value = null
                                                }} /> </label>
                                    </div>
                                    {props?.data?.image?.length > 0 ? <Typography className={classes.removeimg} onClick={props?.removeimg}>{t("Remove Image")}</Typography> : props?.data?.error?.image?.length > 0 ? (
                                          <span style={{ fontSize:"0.75rem", color: "red" }}>
                                                {t("Unit Image is required")}
                                          </span>
                                    ) : <span style={{ fontSize:"0.75rem" }}>
                                          <Box height={"18px"} />
                                    </span>}

                              </Box>
                        </Grid>
                        <Grid item xs={12} md={10} lg={10}>
                              <Box className={classes.imagebox1} height={"292px"}>
                                    <Typography className={classes.title}>
                                          {t("UNIT DETAILS")}
                                    </Typography>

                                    <Grid container rowSpacing={1} columnSpacing={3}>
                                          <Grid item xs={12} md={3} lg={3}>
                                                <TextBox
                                                      isrequired
                                                      isReadonly
                                                      label={t("Company")}
                                                      placeholder={t("Select Company")}
                                                      value={props?.data?.company?.label ?? ""}
                                                      isError={props?.data?.error?.company?.length > 0}
                                                      errorMessage={props?.data?.error?.company} />
                                          </Grid>
                                          <Grid item xs={12} md={3} lg={3}>
                                                <TextBox
                                                      isrequired
                                                      label={t("Name")}
                                                      placeholder={t("Enter Name")}
                                                      value={props?.data?.unitName ?? ""}
                                                      onChange={(e) => {
                                                            props?.updateState("unitName", e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.unitName?.length > 0}
                                                      errorMessage={props?.data?.error?.unitName} />
                                          </Grid>
                                          <Grid item xs={12} md={3} lg={3}>
                                                <SelectBox
                                                      isRequired
                                                      label={t("Execution Status")}
                                                      placeholder={t("Select Execution Status")}
                                                      options={props?.options?.unit_execution_status}
                                                      value={props?.data?.execution_status}
                                                      onChange={(value) => {
                                                            props?.updateState("execution_status", value);
                                                      }}
                                                      isError={props?.data?.error?.execution_status?.length > 0}
                                                      errorMessage={props?.data?.error?.execution_status} />
                                          </Grid>
                                          <Grid item xs={12} md={3} lg={3}>
                                                <Grid item xs={12}>
                                                      <Label label={t("Status")} isRequired />
                                                </Grid>
                                                <Grid container rowGap={"2px"} columnGap={"2px"}>
                                                      {activeOptionsList.map((value) => {
                                                            return <Button className={
                                                                  value?.value === props?.data?.status?.value ?
                                                                        classes.buttonSelected :
                                                                        classes.buttonUnSelected}
                                                                  onClick={() => { props?.updateState("status", value) }}>
                                                                  <Typography className={
                                                                        value?.value === props?.data?.status?.value ?
                                                                              classes.buttonTextSelected :
                                                                              classes.buttonTextUnSelected}>
                                                                        {value?.label}</Typography>
                                                            </Button>
                                                      })}
                                                </Grid>
                                                {props?.data?.error?.status?.length > 0 && (
                                                      <Grid item xs={12}>
                                                            <Typography variant={"caption"} color={"error"}>
                                                                  {props?.data?.error?.status}
                                                            </Typography>
                                                      </Grid>
                                                )}
                                          </Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: '8px' }}>
                                          <Grid item xs={12}>
                                                <Editor
                                                      value={props?.data?.description}
                                                      label={t("Description")}
                                                      handleChange={(e) => {
                                                            props?.updateState("description", e);
                                                      }}
                                                      id={props?.id}
                                                      height={"80px"} />
                                          </Grid>
                                    </Grid>
                              </Box>
                        </Grid>
                  </Grid>

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          isrequired
                                          isReadonly
                                          label={t("Property Type")}
                                          placeholder={t("Select Property Type")}
                                          value={props?.data?.propertyType?.label ?? ""}
                                          isError={props?.data?.error?.propertyType?.length > 0}
                                          errorMessage={props?.data?.error?.propertyType} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          isReadOnly={props?.data?.is_external && main?.unitID && main?.unitID?.length > 0}
                                          label={t("Purpose")}
                                          placeholder={t("Select Purpose")}
                                          options={props?.options?.purpose_global_type ?? []}
                                          value={props?.data?.unitPurpose ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("unitPurpose", value);
                                          }}
                                          isError={props?.data?.error?.unitPurpose?.length > 0}
                                          errorMessage={props?.data?.error?.unitPurpose} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          isReadOnly={props?.data?.is_external && main?.unitID && main?.unitID?.length > 0}
                                          label={t("Revenue Type")}
                                          placeholder={t("Select Type")}
                                          options={props?.options?.revenue_type ?? []}
                                          value={props?.data?.revenueType ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("revenueType", value);
                                          }}
                                          isError={props?.data?.error?.revenueType?.length > 0}
                                          errorMessage={props?.data?.error?.revenueType} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label={t("Payment Period")}
                                          placeholder={t("Select Payment Period")}
                                          options={props?.options?.payment_period}
                                          value={props?.data?.paymentPeriod ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("paymentPeriod", value);
                                          }}
                                          isError={props?.data?.error?.paymentPeriod?.length > 0}
                                          errorMessage={props?.data?.error?.paymentPeriod} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          isReadOnly={props?.data?.is_external && main?.unitID && main?.unitID?.length > 0}
                                          label={t("Category")}
                                          placeholder={t("Select Category")}
                                          options={props?.data?.unitPurpose?.unitCategoryList ?? []}
                                          value={props?.data?.unitCategory ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("unitCategory", value);
                                          }}
                                          isError={props?.data?.error?.unitCategory?.length > 0}
                                          errorMessage={props?.data?.error?.unitCategory} />
                              </Grid>
                              {props?.data?.unitCategory?.field_permission?.is_parent_unit_id &&
                                    <>
                                          <Grid item xs={12} md={3} lg={2}>
                                                <SelectBox
                                                      key={props?.data?.showAllUnits || props?.data?.floorID?.value}
                                                      isReadOnly={props?.data?.showAllUnits ? false :
                                                            !(props?.data?.floorID?.value)}
                                                      label={t("Parent ID")}
                                                      placeholder={t("Select ID")}
                                                      value={props?.data?.parentUnitID}
                                                      onChange={(value) => props?.updateState("parentUnitID", value)}
                                                      loading={loading === "parent_unit"}
                                                      isPaginate
                                                      debounceTimeout={800}
                                                      loadOptions={(search, array) => loadOptions(search, array, 'parent_unit')}
                                                      isError={props?.data?.error?.parentUnitID?.length > 0}
                                                      errorMessage={props?.data?.error?.parentUnitID} />
                                          </Grid>
                                          <Grid item xs={12} md={3} lg={2}>
                                                <Grid item xs={12}>
                                                      <Label label={t("Show All Units")} />
                                                </Grid>
                                                <Grid container height={"45px"} alignItems={"center"}>
                                                      <Avatar
                                                            className={
                                                                  props?.data?.showAllUnits
                                                                        ? classes.completedCheckboxStyle
                                                                        : classes.checkboxStyle
                                                            }
                                                            variant="rounded"
                                                            onClick={() => {
                                                                  props?.updateState(
                                                                        "showAllUnits",
                                                                        !props?.data?.showAllUnits
                                                                  );
                                                            }}
                                                      >
                                                            <DoneOutlinedIcon className={classes.checkboxIconStyle} />
                                                      </Avatar>
                                                </Grid>
                                          </Grid>
                                    </>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label={t("Year Built")}
                                          placeholder={t("Year Built")}
                                          value={props?.data?.yearBuilt ?? ""}
                                          isRequired
                                          onChange={(value) => props?.updateState("yearBuilt", value)}
                                          isError={props?.data?.error?.yearBuilt?.length > 0}
                                          errorMessage={props?.data?.error?.yearBuilt}
                                          maxDate={new Date()}
                                          newDate
                                          noTime />

                              </Grid>
                              {props?.data?.revenueType?.value === enumName.sale &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <NewDatePicker
                                                label={t("Handover Date")}
                                                placeholder={t("Handover Date")}
                                                value={props?.data?.handoverDate ?? ""}
                                                isRequired
                                                onChange={(value) => props?.updateState("handoverDate", value)}
                                                minDate={props?.data?.yearBuilt}
                                                isError={props?.data?.error?.handoverDate?.length > 0}
                                                errorMessage={props?.data?.error?.handoverDate}
                                                newDate
                                                noTime />

                                    </Grid>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          label={t("Title Deed")}
                                          placeholder={t("Enter Title Deed")}
                                          value={props?.data?.titleDeed ?? ""}
                                          onChange={(e) => props?.updateState("titleDeed", e.target.value)}
                                          isError={props?.data?.error?.titleDeed?.length > 0}
                                          errorMessage={props?.data?.error?.titleDeed} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label={t("Title Deed Date")}
                                          placeholder={t("Select Date")}
                                          value={props?.data?.deedDate ?? ""}
                                          onChange={(value) => props?.updateState("deedDate", value)}
                                          isError={props?.data?.error?.deedDate?.length > 0}
                                          errorMessage={props?.data?.error?.deedDate}
                                          newDate
                                          noTime />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          isReadonly
                                          label={t("Hijri Date")}
                                          placeholder={"-"}
                                          value={props?.data?.hijriDate ? hijriFormatter.format(new Date(props?.data?.hijriDate)) : "-"} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          type='number'
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          label={t("Documented Land Area")}
                                          placeholder={t("Enter Area")}
                                          value={props?.data?.landArea ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("landArea", e.target.value);
                                          }}
                                          endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                {props?.data?.measurementUnit?.label}</Typography></InputAdornment>}
                                          isError={props?.data?.error?.landArea?.length > 0}
                                          errorMessage={props?.data?.error?.landArea}
                                    />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          type='number'
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          label={t("Market Price")}
                                          placeholder={t("Enter Price")}
                                          value={props?.data?.marketPrice ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("marketPrice", e.target.value);
                                          }}
                                          startAdornment={<InputAdornment position="start" ><Typography className={classes.fontSize}>
                                                {selectedCompany?.code}</Typography></InputAdornment>}
                                          isError={props?.data?.error?.marketPrice?.length > 0}
                                          errorMessage={props?.data?.error?.marketPrice}
                                    />
                              </Grid>
                        </Grid>
                  </Box>
                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              {
                                    (props?.data?.unitPurpose?.value === "Commercial" &&
                                          props?.data?.unitCategory?.value === 9) &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                isrequired
                                                label={t("Seat")}
                                                placeholder={t("Enter Seat")}
                                                value={props?.data?.seat ?? ""}
                                                onChange={(e) => {
                                                      handleChangeSeat(e.target.value)
                                                }}
                                                isError={props?.data?.error?.seat?.length > 0}
                                                errorMessage={props?.data?.error?.seat}
                                          />
                                    </Grid>
                              }
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label={t("Unit Type")}
                                          placeholder={t("Select Type")}
                                          value={props?.data?.unitType}
                                          onChange={(value) => {
                                                props?.updateState("unitType", value);
                                          }}
                                          loading={loading === "unit_type_master"}
                                          isPaginate
                                          key={JSON.stringify(props?.data?.unitPurpose?.value)}
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'unit_type_master')}
                                          isError={props?.data?.error?.unitType?.length > 0}
                                          errorMessage={props?.data?.error?.unitType} />
                              </Grid>
                              {props?.data?.unitCategory?.field_permission?.is_total_rooms &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label={t("Number Of Room")}
                                                placeholder={t("Enter Count")}
                                                value={props?.data?.numberOfRoom ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("numberOfRoom", e.target.value);
                                                }}
                                                isError={props?.data?.error?.numberOfRoom?.length > 0}
                                                errorMessage={props?.data?.error?.numberOfRoom} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_total_baths &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label={t("Number Of Baths")}
                                                placeholder={t("Enter Count")}
                                                value={props?.data?.numberOfBaths ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("numberOfBaths", e.target.value);
                                                }}
                                                isError={props?.data?.error?.numberOfBaths?.length > 0}
                                                errorMessage={props?.data?.error?.numberOfBaths} />
                                    </Grid>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label={t("Unit of Measure")}
                                          placeholder={t("Select Unit of Measure")}
                                          value={props?.data?.measurementUnit}
                                          onChange={(value) => {
                                                props?.updateState("measurementUnit", value);
                                          }}
                                          loading={loading === "uom_master"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'uom_master')}
                                          isError={props?.data?.error?.measurementUnit?.length > 0}
                                          errorMessage={props?.data?.error?.measurementUnit}
                                          isReadOnly={props?.data?.seat !== null} />
                              </Grid>
                              {props?.data?.unitCategory?.field_permission?.is_carpet_area &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <CustomtoolTip title={`${buildupAreades}`}>
                                                <InfoOutlinedIcon sx={{ fontSize: "1rem", float: "right", color: "#4E5A6B", marginLeft: "4px" }} />
                                          </CustomtoolTip>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Carpet Area")}
                                                placeholder={t("Enter Carpet Area")}
                                                value={props?.data?.carpetArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("carpetArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.carpetArea?.length > 0}
                                                errorMessage={props?.data?.error?.carpetArea} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_build_up_area &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Buildup Area")}
                                                placeholder={t("Enter Buildup Area")}
                                                value={props?.data?.buildupArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("buildupArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.buildupArea?.length > 0}
                                                errorMessage={props?.data?.error?.buildupArea} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_total_area &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <CustomtoolTip title={`${superbuilup}`}>
                                                <InfoOutlinedIcon sx={{ fontSize: "1rem", float: "right", color: "#4E5A6B", marginLeft: "4px" }} />
                                          </CustomtoolTip>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label={t("Super Built-Up Area")}
                                                placeholder={t("Enter Super Built-Up Area")}
                                                value={props?.data?.totalArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("totalArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.totalArea?.length > 0}
                                                errorMessage={props?.data?.error?.totalArea}
                                                isReadonly={props?.data?.seat !== null} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_balconies &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Number Of Balcony")}
                                                placeholder={t("Enter Count")}
                                                value={props?.data?.noOfBalcony ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("noOfBalcony", e.target.value);
                                                }}
                                                isError={props?.data?.error?.noOfBalcony?.length > 0}
                                                errorMessage={props?.data?.error?.noOfBalcony} />
                                    </Grid>}
                              {(props?.data?.unitCategory?.field_permission?.is_balcony_area && props?.data?.noOfBalcony > 0) &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Balcony Area")}
                                                placeholder={t("Enter Area")}
                                                value={props?.data?.balconyArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("balconyArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.balconyArea?.length > 0}
                                                errorMessage={props?.data?.error?.balconyArea} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_terrace_area &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Terrace Area")}
                                                placeholder={t("Enter Terrace Area")}
                                                value={props?.data?.terraceArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("terraceArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.terraceArea?.length > 0}
                                                errorMessage={props?.data?.error?.terraceArea} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_furnishing &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <SelectBox
                                                isRequired
                                                label={t("Furnishing Type")}
                                                placeholder={t("Select Type")}
                                                options={props?.options?.furnish}
                                                value={props?.data?.unitFurnishingType ?? ""}
                                                onChange={(value) => {
                                                      props?.updateState("unitFurnishingType", value);
                                                }}
                                                isError={props?.data?.error?.unitFurnishingType?.length > 0}
                                                errorMessage={props?.data?.error?.unitFurnishingType} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_orientation &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <SelectBox
                                                label={t("Orientation")}
                                                placeholder={t("Select Orientation")}
                                                options={props?.options?.orientation}
                                                value={props?.data?.orientation ?? ""}
                                                onChange={(value) => {
                                                      props?.updateState("orientation", value);
                                                }}
                                                isError={props?.data?.error?.orientation?.length > 0}
                                                errorMessage={props?.data?.error?.orientation} />
                                    </Grid>}
                              {(props?.data?.unitPurpose?.value === enumName?.residential &&
                                    props?.data?.unitCategory?.field_permission?.is_pet_policy) &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <SelectBox
                                                isRequired
                                                label={t("Pet Policy")}
                                                placeholder={t("Select Pet Policy")}
                                                options={props?.options?.pet_policy_type}
                                                value={props?.data?.pet_policy}
                                                onChange={(value) => {
                                                      props?.updateState("pet_policy", value);
                                                }}
                                          />
                                    </Grid>}
                              {/* <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Label label={"Asset Functional Location"} isRequired />
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.assetFunc?.value ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("assetFunc", value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.assetFunc?.value ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                                    {props?.data?.error?.assetFunc?.length > 0 && (
                                          <Grid item xs={12}>
                                                <Typography variant={"caption"} color={"error"}>
                                                      {props?.data?.error?.assetFunc}
                                                </Typography>
                                          </Grid>
                                    )}
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Label label={"Asset Property"} isRequired />
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.assetProperty?.value ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("assetProperty", value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.assetProperty?.value ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                                    {props?.data?.error?.assetProperty?.length > 0 && (
                                          <Grid item xs={12}>
                                                <Typography variant={"caption"} color={"error"}>
                                                      {props?.data?.error?.assetProperty}
                                                </Typography>
                                          </Grid>
                                    )}
                              </Grid> */}
                              {/* As per CR Short Term Rental and Under Promotion commended */}
                              {/* {props?.data?.revenueType?.value === enumName.lease &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <Grid item xs={12}>
                                                <Label label={"Short Term Rental"} isRequired />
                                          </Grid>
                                          <Grid container rowGap={"2px"} columnGap={"2px"}>
                                                {YesNoOption.map((value) => {
                                                      return <Button className={
                                                            value?.value === props?.data?.shortTermRental?.value ?
                                                                  classes.buttonSelected :
                                                                  classes.buttonUnSelected}
                                                            onClick={() => { props?.updateState("shortTermRental", value) }}>
                                                            <Typography className={
                                                                  value?.value === props?.data?.shortTermRental?.value ?
                                                                        classes.buttonTextSelected :
                                                                        classes.buttonTextUnSelected}>
                                                                  {value?.label}</Typography>
                                                      </Button>
                                                })}
                                          </Grid>
                                          {props?.data?.error?.shortTermRental?.length > 0 && (
                                                <Grid item xs={12}>
                                                      <Typography variant={"caption"} color={"error"}>
                                                            {props?.data?.error?.shortTermRental}
                                                      </Typography>
                                                </Grid>
                                          )}
                                    </Grid>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Label label={"Under Promotion"} isRequired />
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.underPromotion?.value ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("underPromotion", value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.underPromotion?.value ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                                    {props?.data?.error?.underPromotion?.length > 0 && (
                                          <Grid item xs={12}>
                                                <Typography variant={"caption"} color={"error"}>
                                                      {props?.data?.error?.underPromotion}
                                                </Typography>
                                          </Grid>
                                    )}
                              </Grid> */}
                              {/* {props?.data?.unitCategory?.field_permission?.is_listing_status &&
                                    <Grid item xs={12} md={4} lg={4}>
                                          <Grid item xs={12}>
                                                <Label label={"Public Listing"} isRequired />

                                          </Grid>
                                          <Grid container rowGap={"2px"} columnGap={"2px"}>
                                                {ListingTypeOptions?.map((value) => {
                                                      return <Button className={
                                                            value?.value === props?.data?.publicListing?.value ?
                                                                  classes.buttonSelected :
                                                                  classes.buttonUnSelected}
                                                            onClick={() => { props?.updateState("publicListing", value) }}>
                                                            <Typography className={
                                                                  value?.value === props?.data?.publicListing?.value ?
                                                                        classes.buttonTextSelected :
                                                                        classes.buttonTextUnSelected}>
                                                                  {value?.label}</Typography>
                                                      </Button>
                                                })}
                                          </Grid>
                                          {props?.data?.error?.publicListing?.length > 0 && (
                                                <Grid item xs={12}>
                                                      <Typography variant={"caption"} color={"error"}>
                                                            {props?.data?.error?.publicListing}
                                                      </Typography>
                                                </Grid>
                                          )}
                                    </Grid>} */}
                              {props?.data?.unitCategory?.field_permission?.is_living_room_size &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Living Room Size")}
                                                placeholder={t("Enter Size")}
                                                value={props?.data?.livingRoomSize ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("livingRoomSize", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                      {props?.data?.measurementUnit?.label}</Typography></InputAdornment>}
                                                isError={props?.data?.error?.livingRoomSize?.length > 0}
                                                errorMessage={props?.data?.error?.livingRoomSize}
                                          />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_master_room_size &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Master Room Size")}
                                                placeholder={t("Enter Size")}
                                                value={props?.data?.masterRoomSize ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("masterRoomSize", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                      {props?.data?.measurementUnit?.label}</Typography></InputAdornment>}
                                                isError={props?.data?.error?.masterRoomSize?.length > 0}
                                                errorMessage={props?.data?.error?.masterRoomSize}
                                          />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_guest_room_size &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Guest Room Size")}
                                                placeholder={t("Enter Size")}
                                                value={props?.data?.guestRoomSize ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("guestRoomSize", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                      {props?.data?.measurementUnit?.label}</Typography></InputAdornment>}
                                                isError={props?.data?.error?.guestRoomSize?.length > 0}
                                                errorMessage={props?.data?.error?.guestRoomSize}
                                          />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_children_room_size &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Children Room Size")}
                                                placeholder={t("Enter Size")}
                                                value={props?.data?.childrenRoomSize ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("childrenRoomSize", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                      {props?.data?.measurementUnit?.label}</Typography></InputAdornment>}
                                                isError={props?.data?.error?.childrenRoomSize?.length > 0}
                                                errorMessage={props?.data?.error?.childrenRoomSize}
                                          />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_kitchen_room_size &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Kitchen Room Size")}
                                                placeholder={t("Enter Size")}
                                                value={props?.data?.kitchenRoomSize ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("kitchenRoomSize", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                      {props?.data?.measurementUnit?.label}</Typography></InputAdornment>}
                                                isError={props?.data?.error?.kitchenRoomSize?.length > 0}
                                                errorMessage={props?.data?.error?.kitchenRoomSize}
                                          />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_servant_room_size &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                label={t("Servant Room Size")}
                                                placeholder={t("Enter Size")}
                                                value={props?.data?.servantRoomSize ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("servantRoomSize", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                      {props?.data?.measurementUnit?.label}</Typography></InputAdornment>}
                                                isError={props?.data?.error?.servantRoomSize?.length > 0}
                                                errorMessage={props?.data?.error?.servantRoomSize}
                                          />
                                    </Grid>}
                        </Grid >
                  </Box >


                  <div className='map-wrapper'>
                        <Box className={classes.imagebox1} style={{ marginTop: '24px', position: 'relative' }}>
                              <CustomTypography>{t("Address")}</CustomTypography>
                              {
                                    props?.load &&
                                    <MapWithFields
                                          fullScreenMap={fullScreenMap}
                                          fullScreenControl={fullScreenControl}
                                          mapOptions={{
                                                isInput: true,
                                                center: {
                                                      lat: props?.data?.latitude,
                                                      lng: props?.data?.longitude
                                                },
                                                lat: props?.data?.latitude,
                                                lng: props?.data?.longitude,
                                                mapLoad
                                          }}
                                          mapHeight={"370px"}
                                          breakpoints={{
                                                map: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 5,
                                                      lg: 6,
                                                      xl: 6
                                                },
                                                components: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 7,
                                                      lg: 6,
                                                      xl: 6
                                                }
                                          }}
                                          mapResult={props?.mapResult}
                                          autoCompletePlacement={{
                                                top: 14,
                                          }}
                                          fields={[
                                                {
                                                      label: t("Door Number"),
                                                      component: "TextField",
                                                      value: props?.data?.doorNo,
                                                      state_name: 'doorNo',
                                                      isrequired: false,
                                                      placeholder: t("Enter Door Number"),
                                                      error: props?.data?.error?.doorNo,
                                                      errorMessage: props?.data?.error?.doorNo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Address Line 1"),
                                                      component: "TextField",
                                                      value: props?.data?.addressLineOne,
                                                      state_name: 'addressLineOne',
                                                      isrequired: false,
                                                      placeholder: t("Enter Address Line 1"),
                                                      errorMessage: props?.data?.error?.addressLineOne,
                                                      error: props?.data?.error?.addressLineOne?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: t("Address Line 2"),
                                                      component: "TextField",
                                                      value: props?.data?.addressLineTwo,
                                                      state_name: 'addressLineTwo',
                                                      isrequired: false,
                                                      placeholder: t("Enter Address Line 2"),
                                                      errorMessage: props?.data?.error?.addressLineTwo,
                                                      error: props?.data?.error?.addressLineTwo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: t("Landmark"),
                                                      component: "TextField",
                                                      value: props?.data?.landmark,
                                                      state_name: 'landmark',
                                                      isrequired: false,
                                                      placeholder: t("Enter Landmark"),
                                                      errorMessage: props?.data?.error?.landmark,
                                                      error: props?.data?.error?.landmark?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Area"),
                                                      component: "TextField",
                                                      value: props?.data?.area,
                                                      state_name: 'area',
                                                      isrequired: false,
                                                      placeholder: t("Enter Area"),
                                                      errorMessage: props?.data?.error?.area,
                                                      error: props?.data?.error?.area?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("City"),
                                                      component: "TextField",
                                                      value: props?.data?.city,
                                                      state_name: 'city',
                                                      isrequired: false,
                                                      placeholder: t("Enter City"),
                                                      errorMessage: props?.data?.error?.city,
                                                      error: props?.data?.error?.city?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("State"),
                                                      component: "TextField",
                                                      state_name: 'state',
                                                      value: props?.data?.state,
                                                      isrequired: false,
                                                      placeholder: t("Enter State"),
                                                      errorMessage: props?.data?.error?.state,
                                                      error: props?.data?.error?.state?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Country"),
                                                      component: "TextField",
                                                      value: props?.data?.country,
                                                      state_name: 'country',
                                                      isrequired: false,
                                                      placeholder: t("Enter Country"),
                                                      errorMessage: props?.data?.error?.country,
                                                      error: props?.data?.error?.country?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Pincode"),
                                                      component: "TextField",
                                                      state_name: 'zipcode',
                                                      value: props?.data?.zipcode,
                                                      isrequired: false,
                                                      placeholder: t("Enter Pincode"),
                                                      errorMessage: props?.data?.error?.zipcode,
                                                      error: props?.data?.error?.zipcode?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Latitude"),
                                                      component: "TextField",
                                                      state_name: 'latitude',
                                                      value: props?.data?.latitude,
                                                      isrequired: false,
                                                      placeholder: t("Enter Latitude"),
                                                      errorMessage: props?.data?.error?.latitude,
                                                      error: props?.data?.error?.latitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Longitude"),
                                                      component: "TextField",
                                                      state_name: 'longitude',
                                                      value: props?.data?.longitude,
                                                      isrequired: false,
                                                      placeholder: t("Enter Longitude"),
                                                      errorMessage: props?.data?.error?.longitude,
                                                      error: props?.data?.error?.longitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Smart Geo Address"),
                                                      component: "TextField",
                                                      state_name: 'smartGeoAddress',
                                                      value: props?.data?.smartGeoAddress,
                                                      isrequired: false,
                                                      placeholder: t("Enter Smart Geo Address"),
                                                      errorMessage: props?.data?.error?.smartGeoAddress,
                                                      error: props?.data?.error?.smartGeoAddress?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                          ]}
                                          onChangeFields={(key, value) => props?.updateState(key, value)}
                                    />
                              }
                        </Box>
                  </div>
                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={t("Business Phone")}
                                          placeholder={t("Business Phone")}
                                          value={props?.data?.businessPhone}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("businessPhone", value);
                                          }}
                                          isError={props?.data?.error?.businessPhone?.length > 0}
                                          errorMessage={props?.data?.error?.businessPhone}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={t("Mobile Phone")}
                                          placeholder={t("Mobile Phone")}
                                          value={props?.data?.mobilePhone}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("mobilePhone", value);
                                          }}
                                          isError={props?.data?.error?.mobilePhone?.length > 0}
                                          errorMessage={props?.data?.error?.mobilePhone}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          isrequired
                                          isReadonly
                                          label={t("Website")}
                                          placeholder={t("Enter Website")}
                                          value={props?.data?.website ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("website", e.target.value);
                                          }}
                                          isError={props?.data?.error?.website?.length > 0}
                                          errorMessage={props?.data?.error?.website} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          isrequired
                                          label={t("Email Address")}
                                          placeholder={t("Enter Email Address")}
                                          value={props?.data?.emailAddress ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("emailAddress", e.target.value);
                                          }}
                                          isError={props?.data?.error?.emailAddress?.length > 0}
                                          errorMessage={props?.data?.error?.emailAddress} />
                              </Grid>
                        </Grid>
                  </Box>

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                              <Typography className={classes.title}>
                                    {t("Registration Office Information")}
                              </Typography>
                              {props?.data?.registrationOffice?.value &&
                                    <Typography className={classes.title}
                                          sx={{
                                                color: "#ff4b4b !important",
                                                cursor: "pointer",
                                          }}
                                          onClick={() => props?.updateState("registrationOffice", "")}>
                                          {t("Clear")}
                                    </Typography>}
                        </Stack>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3}>
                                    <SelectBox
                                          noSearch
                                          label={t("Registration Office")}
                                          placeholder={t("Search Registration Office")}
                                          value={props?.data?.registrationOffice ?? ""}
                                          customOptionComponent={(option) => {
                                                return (
                                                      <Stack direction={"row"} spacing={2}
                                                            p={1} alignItems={"center"}
                                                            style={{
                                                                  border: (option?.isSelected)
                                                                        ? "1px solid #5078E1"
                                                                        : "1px solid #E4E8EE",
                                                                  backgroundColor: (option?.isSelected)
                                                                        ? "#F1F7FF" : "#fff"
                                                            }}>
                                                            <Avatar className={classes.optionAvatar}
                                                                  src={option?.data?.image_url}
                                                                  {...stringAvatar(option?.data?.name)}></Avatar>
                                                            <Stack>
                                                                  <Typography className={classes.optionHeader}>
                                                                        {concat_string(
                                                                              {
                                                                                    vendor_no: option?.data?.vendor_no,
                                                                                    vendor_name: option?.data?.name,
                                                                              },
                                                                              ["vendor_no", "vendor_name"],
                                                                              " - "
                                                                        )}
                                                                  </Typography>
                                                                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                                        <Typography className={classes.optionSubTitle}>
                                                                              {concat_string(
                                                                                    {
                                                                                          mobile: concat_string(
                                                                                                {
                                                                                                      mobile_code: option?.data?.mobile_no_country_code,
                                                                                                      mobile_no: option?.data?.mobile_no,
                                                                                                },
                                                                                                ["mobile_code", "mobile_no"], " - "
                                                                                          ),
                                                                                          email_id: option?.data?.email_id,
                                                                                    },
                                                                                    ["mobile", "email_id"], ", "
                                                                              )}
                                                                        </Typography>
                                                                  </Stack>
                                                            </Stack>
                                                      </Stack>
                                                )
                                          }}
                                          loadOptions={(search, array, handleLoading) =>
                                                loadOptionsApis(
                                                      "vendor/list",
                                                      { company_id: selectedCompany?.value },
                                                      search,
                                                      array,
                                                      handleLoading,
                                                      "data",
                                                      {},
                                                      manualVendorResponse
                                                )
                                          }
                                          isPaginate={true}
                                          menuOptionHeight={"250px"}
                                          onChange={(val) => props?.updateState("registrationOffice", val)}
                                          isError={props?.data?.error?.registrationOffice?.length > 0}
                                          errorMessage={props?.data?.error?.registrationOffice} />
                              </Grid>
                              {props?.data?.registrationOffice?.value &&
                                    <>
                                          {props?.data?.registrationOffice?.officeName &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Office Name")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.officeName ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.mobile &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Mobile Number")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.mobile ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.email &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Email Address")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.email ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.website &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Website")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.website ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.address &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Address")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.address ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.latitude &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Latitude")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.latitude ?? ""} />
                                                </Grid>}
                                          {props?.data?.registrationOffice?.longitude &&
                                                <Grid item xs={12} md={3}>
                                                      <TextBox
                                                            isReadonly
                                                            label={t("Longitude")}
                                                            placeholder={""}
                                                            value={props?.data?.registrationOffice?.longitude ?? ""} />
                                                </Grid>}
                                    </>}
                        </Grid>
                  </Box>
                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                              <Typography className={classes.title}>
                                    {t("Share Holders")}
                              </Typography>
                              {props?.data?.shareHolders?.filter?.(_ => !_?.isDeleted)?.length === 0 &&
                                    <Button variant="text"
                                          onClick={() => props?.updateShareHolders("add")}
                                          sx={{
                                                marginBottom: "12px",
                                                "&:hover": { backgroundColor: "white" }
                                          }}>
                                          <Typography className={classes.addShareHolder}>
                                                {t("+ Add")}
                                          </Typography>
                                    </Button>}
                        </Stack>
                        {props?.data?.shareHolders?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Grid container rowSpacing={"4px"} columnSpacing={"4px"} sx={{ overflow: "overlay" }}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                          {shareHolderTableHeader?.map(_ => {
                                                return (<Box className={classes.headCell} sx={{ width: _?.width ?? "auto" }}>
                                                      <Typography className={classes.headText}> {_?.header} </Typography>
                                                </Box>)
                                          })}
                                    </Stack>
                                    {props?.data?.shareHolders?.map((_, i) => {
                                          return (_?.isDeleted
                                                ? <></>
                                                : <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[0]?.width }}>
                                                            <Box className={classes.contactDropdown}
                                                                  onClick={() => handleOpenShareHolderDialog(_?.contact, i)}>
                                                                  {_?.contact?.value ?
                                                                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                                                                              <Avatar className={classes.optionAvatar}
                                                                                    src={_?.contact?.image_url} {...stringAvatar(_?.contact?.first_name)} />
                                                                              <Typography className={classes.contactDropdownValue} noWrap width={"196px"}>
                                                                                    {concat_string(
                                                                                          {
                                                                                                user_no: _?.contact?.user_no,
                                                                                                first_name: _?.contact?.first_name,
                                                                                          },
                                                                                          ["user_no", "first_name"],
                                                                                          " - "
                                                                                    )}
                                                                              </Typography>
                                                                        </Stack>
                                                                        : <Typography className={classes.contactDropdownPlaceholder} noWrap width={"244px"}>
                                                                              {t("Search Contact")}
                                                                        </Typography>}
                                                                  <KeyboardArrowDownIcon color="#333333" />
                                                            </Box>
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[1]?.width }}>
                                                            <TextBox
                                                                  isReadonly={!_?.contact?.id}
                                                                  label={""}
                                                                  placeholder={t("0.0")}
                                                                  value={_?.value ?? ""}
                                                                  onChange={(e) => props?.updateShareHolders("edit", "value", e.target.value, i)}
                                                                  endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>%</Typography></InputAdornment>} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[2]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contact?.first_name ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[3]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={concat_string(
                                                                        {
                                                                              mobile_code: _?.contact?.mobile_no_country_code,
                                                                              mobile_no: _?.contact?.mobile_no,
                                                                        },
                                                                        ["mobile_code", "mobile_no"], " - "
                                                                  )} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: shareHolderTableHeader?.[4]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contact?.email_id ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell}>
                                                            <IconButton onClick={() => props?.updateShareHolders("edit", "isDeleted", true, i)}>
                                                                  <DeleteIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Stack>)
                                    })}
                              </Grid>}
                        {props?.data?.shareHolders?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Button variant="text"
                                    onClick={() => props?.updateShareHolders("add")}
                                    sx={{
                                          marginTop: "16px", height: "40px",
                                          "&:hover": { backgroundColor: "white" }
                                    }}>
                                    <Typography className={classes.addShareHolder}>
                                          {t("+ Add Another Share Holder")}
                                    </Typography>
                              </Button>}
                  </Box>
                  <DialogBox
                        maxWidth={'sm'}
                        open={isShareHolderDialogOpen}
                        onClose={handleCloseShareHolderDialog}
                        handleClose={handleCloseShareHolderDialog}
                        header={t("Share Holder Contacts")}
                        component={
                              <Stack direction={"column"} rowGap={"16px"} padding={"16px 24px"} sx={{ backgroundColor: "#ffffff" }}>
                                    <SearchFilter value={searchShareHolderContactText} placeholder={t("Search Contacts")}
                                          handleChange={(value) => handleSearchShareHolderContact(value)} />
                                    {isShareHolderContactListLoading
                                          ? (<Box sx={{ height: size?.height - 400 }}>
                                                <Stack divider={<Divider />}>
                                                      {[1, 2, 3, 4]?.map?.(_ => <ShareHolderContactLoader classes={classes} />)}
                                                </Stack>
                                          </Box>)
                                          : (shareHolderContacts?.rows?.length > 0
                                                ? (<InfiniteScroll
                                                      dataLength={shareHolderContacts?.rows?.length}
                                                      next={fetchMoreShareHolderContacts}
                                                      height={size?.height - 400}
                                                      hasMore={shareHolderContacts?.rows?.length < shareHolderContacts?.total_rows_count}
                                                      loader={<ShareHolderContactLoader classes={classes} />}
                                                      endMessage={
                                                            <Box width={"100%"} m={"16px 0px"}
                                                                  display={"flex"} justifyContent={"center"}>
                                                                  <Typography className={classes.shareHolderContactEnd}>
                                                                        {t("End Of Contacts")}
                                                                  </Typography>
                                                            </Box>
                                                      }>
                                                      <Stack divider={<Divider />}>
                                                            {shareHolderContacts?.rows?.map?.(_ => {
                                                                  return <ShareHolderContactCard
                                                                        classes={classes}
                                                                        data={_}
                                                                        onClick={handleOnClickShareHolderContact} />
                                                            })}
                                                      </Stack>
                                                </InfiniteScroll>)
                                                : (<Box className={classes.shareHolderContactNoDataBox}
                                                      sx={{ height: size?.height - 400 }}>
                                                      <NoDataFound />
                                                </Box>))}
                              </Stack>
                        } />

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                              <Typography className={classes.title}>
                                    {t("Contracts")}
                              </Typography>
                              {props?.data?.unitContracts?.filter?.(_ => !_?.isDeleted)?.length === 0 &&
                                    <Button variant="text"
                                          onClick={() => props?.updateUnitContracts("add")}
                                          sx={{
                                                marginBottom: "12px",
                                                "&:hover": { backgroundColor: "white" }
                                          }}>
                                          <Typography className={classes.addShareHolder}>
                                                {t("+ Add")}
                                          </Typography>
                                    </Button>}
                        </Stack>
                        {props?.data?.unitContracts?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Grid container rowSpacing={"4px"} columnSpacing={"4px"} sx={{ overflow: "overlay" }}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                          {unitContractTableHeader?.map(_ => {
                                                return (<Box className={classes.headCell} sx={{ width: _?.width ?? "auto" }}>
                                                      <Typography className={classes.headText}> {_?.header} </Typography>
                                                </Box>)
                                          })}
                                    </Stack>
                                    {props?.data?.unitContracts?.map((_, i) => {
                                          return (_?.isDeleted
                                                ? <></>
                                                : <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                                      <Box className={classes.bodyCell} sx={{ width: unitContractTableHeader?.[0]?.width }}>
                                                            <Box className={classes.contactDropdown}
                                                                  onClick={() => handleOpenContractDialog(_?.contract, i)}>
                                                                  {_?.contract?.value ?
                                                                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                                                                              <Avatar className={classes.optionAvatar}
                                                                                    {...stringAvatar(_?.contract?.contract_no)} />
                                                                              <Typography className={classes.contactDropdownValue} noWrap width={"196px"}>
                                                                                    {_?.contract?.contract_no}
                                                                              </Typography>
                                                                        </Stack>
                                                                        : <Typography className={classes.contactDropdownPlaceholder} noWrap width={"244px"}>
                                                                              {t("Search Contract")}
                                                                        </Typography>}
                                                                  <KeyboardArrowDownIcon color="#333333" />
                                                            </Box>
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: unitContractTableHeader?.[1]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={concat_string(
                                                                        {
                                                                              start_date: _?.contract?.start_date ? timeZoneConverter(_?.contract?.start_date) : "",
                                                                              end_date: _?.contract?.end_date ? timeZoneConverter(_?.contract?.end_date) : "",
                                                                        },
                                                                        ["start_date", "end_date"], " - ", "-")
                                                                  } />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: unitContractTableHeader?.[2]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={concat_string(
                                                                        {
                                                                              period_value: _?.contract?.period_value?.toString(),
                                                                              period: _?.contract?.period,
                                                                        },
                                                                        ["period_value", "period"], " ", "-")
                                                                  } />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: unitContractTableHeader?.[3]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contract?.account?.account_no ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: unitContractTableHeader?.[4]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contract?.account?.vendor?.vendor_code ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: unitContractTableHeader?.[5]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.contract?.account?.vendor?.name ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell}>
                                                            <IconButton onClick={() => props?.updateUnitContracts("edit", "isDeleted", true, i)}>
                                                                  <DeleteIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Stack>)
                                    })}
                              </Grid>}
                        {props?.data?.unitContracts?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Button variant="text"
                                    onClick={() => props?.updateUnitContracts("add")}
                                    sx={{
                                          marginTop: "16px", height: "40px",
                                          "&:hover": { backgroundColor: "white" }
                                    }}>
                                    <Typography className={classes.addShareHolder}>
                                          {t("+ Add Another Contract")}
                                    </Typography>
                              </Button>}
                  </Box>
                  <DialogBox
                        maxWidth={'sm'}
                        open={isContractDialogOpen}
                        onClose={handleCloseContractDialog}
                        handleClose={handleCloseContractDialog}
                        header={t("Contracts")}
                        component={
                              <Stack direction={"column"} rowGap={"16px"} padding={"16px 24px"} sx={{ backgroundColor: "#ffffff" }}>
                                    <SearchFilter value={searchContractText} placeholder={t("Search Contracts")}
                                          handleChange={(value) => handleSearchContract(value)} />
                                    {isContractListLoading
                                          ? (<Box sx={{ height: size?.height - 400 }}>
                                                <Stack divider={<Divider />}>
                                                      {[1, 2, 3, 4]?.map?.(_ => <ContractLoader classes={classes} />)}
                                                </Stack>
                                          </Box>)
                                          : (unitContracts?.rows?.length > 0
                                                ? (<InfiniteScroll
                                                      dataLength={unitContracts?.rows?.length}
                                                      next={fetchMoreContracts}
                                                      height={size?.height - 400}
                                                      hasMore={unitContracts?.rows?.length < unitContracts?.total_rows_count}
                                                      loader={<ContractLoader classes={classes} />}
                                                      endMessage={
                                                            <Box width={"100%"} m={"16px 0px"}
                                                                  display={"flex"} justifyContent={"center"}>
                                                                  <Typography className={classes.shareHolderContactEnd}>
                                                                        {t("End Of Contracts")}
                                                                  </Typography>
                                                            </Box>
                                                      }>
                                                      <Stack divider={<Divider />}>
                                                            {unitContracts?.rows?.map?.(_ => {
                                                                  return <ContractCard
                                                                        classes={classes}
                                                                        data={_}
                                                                        onClick={handleOnClickContract} />
                                                            })}
                                                      </Stack>
                                                </InfiniteScroll>)
                                                : (<Box className={classes.shareHolderContactNoDataBox}
                                                      sx={{ height: size?.height - 400 }}>
                                                      <NoDataFound />
                                                </Box>))}
                              </Stack>
                        } />

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                              <Typography className={classes.title}>
                                    {t("Projects")}
                              </Typography>
                              {props?.data?.unitProjects?.filter?.(_ => !_?.isDeleted)?.length === 0 &&
                                    <Button variant="text"
                                          onClick={() => props?.updateUnitProjects("add")}
                                          sx={{
                                                marginBottom: "12px",
                                                "&:hover": { backgroundColor: "white" }
                                          }}>
                                          <Typography className={classes.addShareHolder}>
                                                {t("+ Add")}
                                          </Typography>
                                    </Button>}
                        </Stack>
                        {props?.data?.unitProjects?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Grid container rowSpacing={"4px"} columnSpacing={"4px"} sx={{ overflow: "overlay" }}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                          {unitProjectTableHeader?.map(_ => {
                                                return (<Box className={classes.headCell} sx={{ width: _?.width ?? "auto" }}>
                                                      <Typography className={classes.headText}> {_?.header} </Typography>
                                                </Box>)
                                          })}
                                    </Stack>
                                    {props?.data?.unitProjects?.map((_, i) => {
                                          return (_?.isDeleted
                                                ? <></>
                                                : <Stack direction={"row"} alignItems={"center"} columnGap={"16px"}>
                                                      <Box className={classes.bodyCell} sx={{ width: unitProjectTableHeader?.[0]?.width }}>
                                                            <Box className={classes.contactDropdown}
                                                                  onClick={() => handleOpenProjectDialog(_?.project, i)}>
                                                                  {_?.project?.value ?
                                                                        <Stack direction={"row"} columnGap={"16px"} alignItems={"center"}>
                                                                              <Avatar className={classes.optionAvatar}
                                                                                    {...stringAvatar(_?.project?.project_no)} />
                                                                              <Typography className={classes.contactDropdownValue} noWrap width={"196px"}>
                                                                                    {_?.project?.project_no}
                                                                              </Typography>
                                                                        </Stack>
                                                                        : <Typography className={classes.contactDropdownPlaceholder} noWrap width={"244px"}>
                                                                              {t("Search Project")}
                                                                        </Typography>}
                                                                  <KeyboardArrowDownIcon color="#333333" />
                                                            </Box>
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: unitProjectTableHeader?.[1]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.project?.title} />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: unitProjectTableHeader?.[2]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={concat_string(
                                                                        {
                                                                              planned_start_date: _?.project?.planned_start_date ? timeZoneConverter(_?.project?.planned_start_date) : "",
                                                                              planned_end_date: _?.project?.planned_end_date ? timeZoneConverter(_?.project?.planned_end_date) : "",
                                                                        },
                                                                        ["planned_start_date", "planned_end_date"], " - ", "-")
                                                                  } />
                                                      </Box>
                                                      <Box className={classes.bodyCell} sx={{ width: unitProjectTableHeader?.[3]?.width }}>
                                                            <TextBox
                                                                  isReadonly
                                                                  label={""}
                                                                  value={_?.project?.type ?? "-"} />
                                                      </Box>
                                                      <Box className={classes.bodyCell}>
                                                            <IconButton onClick={() => props?.updateUnitProjects("edit", "isDeleted", true, i)}>
                                                                  <DeleteIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Stack>)
                                    })}
                              </Grid>}
                        {props?.data?.unitProjects?.filter?.(_ => !_?.isDeleted)?.length > 0 &&
                              <Button variant="text"
                                    onClick={() => props?.updateUnitProjects("add")}
                                    sx={{
                                          marginTop: "16px", height: "40px",
                                          "&:hover": { backgroundColor: "white" }
                                    }}>
                                    <Typography className={classes.addShareHolder}>
                                          {t("+ Add Another Project")}
                                    </Typography>
                              </Button>}
                  </Box>
                  <DialogBox
                        maxWidth={'sm'}
                        open={isProjectDialogOpen}
                        onClose={handleCloseProjectDialog}
                        handleClose={handleCloseProjectDialog}
                        header={t("Projects")}
                        component={
                              <Stack direction={"column"} rowGap={"16px"} padding={"16px 24px"} sx={{ backgroundColor: "#ffffff" }}>
                                    <SearchFilter value={searchProjectText} placeholder={t("Search Projects")}
                                          handleChange={(value) => handleSearchProject(value)} />
                                    {isProjectListLoading
                                          ? (<Box sx={{ height: size?.height - 400 }}>
                                                <Stack divider={<Divider />}>
                                                      {[1, 2, 3, 4]?.map?.(_ => <ProjectLoader classes={classes} />)}
                                                </Stack>
                                          </Box>)
                                          : (unitProjects?.rows?.length > 0
                                                ? (<InfiniteScroll
                                                      dataLength={unitProjects?.rows?.length}
                                                      next={fetchMoreProjects}
                                                      height={size?.height - 400}
                                                      hasMore={unitProjects?.rows?.length < unitProjects?.total_rows_count}
                                                      loader={<ProjectLoader classes={classes} />}
                                                      endMessage={
                                                            <Box width={"100%"} m={"16px 0px"}
                                                                  display={"flex"} justifyContent={"center"}>
                                                                  <Typography className={classes.shareHolderContactEnd}>
                                                                        {t("End Of Projects")}
                                                                  </Typography>
                                                            </Box>
                                                      }>
                                                      <Stack divider={<Divider />}>
                                                            {unitProjects?.rows?.map?.(_ => {
                                                                  return <ProjectCard
                                                                        classes={classes}
                                                                        data={_}
                                                                        onClick={handleOnClickProject} />
                                                            })}
                                                      </Stack>
                                                </InfiniteScroll>)
                                                : (<Box className={classes.shareHolderContactNoDataBox}
                                                      sx={{ height: size?.height - 400 }}>
                                                      <NoDataFound />
                                                </Box>))}
                              </Stack>
                        } />
            </div >
      )
}