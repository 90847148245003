import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AmenitiesMappingPropertyView from "../screens/amenitiesMapping/amenitiesMappingPropertyView";
import AmenitiesMappingUnitView from "../screens/amenitiesMapping/amenitiesMappingUnitView";
// import CreateAnnounsment from "../screens/announsment/createannounsment";

import {
  Communication,
  CommunityDiscussion,
  Master,
} from "../screens/communityManager";
import { DbTask } from "../screens/dbTask/dbTask";
import CreateGeneralRequest from "../screens/genralrequest/createrequest";
import InsPropertyDetail from "../screens/inspectionItem/propertyDetails";
import MapPropertyDetails from "../screens/inspectionMapping/propertyDetails";
import MapUnitDetails from "../screens/inspectionMapping/unitDetails";
import InventoryMaster from "../screens/inventoryMaster";
import CreateMaintanceRequest from "../screens/maintenancerequest/createrequest";
import ManagementCommittee from "../screens/managementcommittee";
import ManagementCommitteeView from "../screens/managementcommittee/managementcommitteeview";
import {
  DeliveryOrderCollection,
  DomesticHelpRequest,
  Master as MasterData,
  ParkingSlot,
  ParkingSlotMaster,
  ServiceProvider,
  VendorEntry,
  VisitorAndWorkedGatePass,
} from "../screens/securityManager";
import ViewServiceInspection from "../screens/serviceInspection/components/viewInspection";
import ViewSiteVisitDetails from "../screens/siteVisit/components/viewSiteVisitDetails";
import UtilitiesMappingPropertyView from "../screens/utilitiesMapping/utilitiesMappingPropertyView";
import UtilitiesMappingUnitView from "../screens/utilitiesMapping/utilitiesMappingUnitView";
import {
  AccessGates,
  AccessGatesPropertyView,
  AccountDetails,
  Accounts,
  ActivityBoard,
  ActivityCategory,
  ActivitySubCategory,
  AggreementsList,
  AgreementDetails,
  AgreementRenewal,
  AgreementTermination,
  AgreementUnitAccess,
  AlreadyPaid,
  Amenities,
  AmenitiesBooking,
  AmenitiesMapping,
  AmenityBoard,
  AmenityCategory,
  AmenityDashBoard,
  AmenityMappingNew,
  AnnouncementBoard,
  AnnounsmentNew,
  BackupRestore,
  BlockDetails,
  BlockList,
  BlockView,
  BusinessMaster,
  COAMaster,
  CheckListGroup,
  CheckListGroupView,
  CheckListItemView,
  ClientManagerDashboard,
  ClientSettings,
  CommunityDashboard,
  Communitybanner,
  Company,
  CompanyCreation,
  ConfigurartionNew,
  ConfigurationMaster,
  Configure,
  ContactView,
  Contacts,
  Contract,
  Contract360,
  ContractManagerDashboard,
  CorrespondenceType,
  Correspondences,
  CostingApproval,
  CreateAccount,
  CreateAnnounsmentNew,
  CreateBlock,
  CreateContactNew,
  CreateDeliveryMilestoneTemplate,
  CreateEditContract,
  CreateFixedMaintenancePlanner,
  CreateFloor,
  CreateInvoiceFreeText,
  CreateOpportunity,
  CreateQuotation,
  CreateReceipt,
  CreateUnit,
  CustomerAccountDetails,
  CustomerAccountList,
  CustomerFeedBack,
  CustomerGroupMaster,
  CustomerViewNew,
  Dashboardbanner,
  DeliveryMilestone,
  DeliveryMilestoneTemplate,
  DeliveryWorkOrder,
  Department,
  DomesticContacts,
  DomesticHelperCreateEdit,
  DomesticHelperList,
  DomesticHelperView,
  DutyRoster,
  EmergencyContact,
  EmployeesBooking,
  ExpenseGroupMaster,
  ExploreTemplate,
  FacilityManagerDashboard,
  FeedBackCategoryMaster,
  FeedBackSubCategoryMaster,
  FileManager,
  FloorDetails,
  FloorList,
  ForgotPassword,
  FormBuilder,
  FreeTextInvoice,
  FunctionalLocation,
  GeneralInspection,
  GeneralRequest,
  Import,
  ImportFileUpload,
  LeadImportFileUploadWithNavBars,
  InspectionItems,
  InspectionList,
  InspectionManagerDashboard,
  InspectionMapping,
  InspectionMaster,
  InspectionReq,
  InternalLeadSource,
  InviteTrigger,
  Invoice,
  InvoiceView,
  ItemCategoryMaster,
  ItemSubCategoryMaster,
  Jobs,
  KycDetails,
  KycDocumentType,
  KycScreen,
  LeadNew,
  LeadView,
  Leads,
  LeaseAndSalesManagerDashboard,
  LeaveMaster,
  LedgerMaster,
  ListingDashBoard,
  Login,
  LogoApperances,
  MaintanceRequest,
  MaintenancePlanner,
  ManageInvoice,
  ManagementCommitteRoleMaster,
  ManagementRoleMaster,
  ManufacturerMaster,
  MarketingPortalConfiguration,
  Masters,
  MoveoutInspection,
  MyOrg,
  NotFound,
  Occupants,
  OperationsManagerDashboard,
  OppertunityView,
  Otp,
  OwnerAccountCreate,
  OwnerAccountDetails,
  OwnerAccountList,
  OwnerTableDetails,
  OwnerTableView,
  OwnerViewNew,
  ParkingArea,
  ParkingBooking,
  ParkingGroupingMaster,
  PricingAppraisal,
  PricingComponent,
  PricingTable,
  PricingTablePropertyView,
  PricingTableUnitView,
  PricingTableViewAll,
  Profession,
  ProfileEdit,
  ProfileView,
  Properties,
  PropertyBoard,
  PropertyCreation,
  PropertyManagerDashboard,
  PropertyDetails,
  PropertyFinder4,
  PropertyFinderDetails,
  PropertyMaster,
  PropertyView,
  Propspect,
  PublicListing,
  QuotationDetails,
  QuotationsList,
  Receipt,
  ReceiptView,
  RentalIndex,
  Report,
  Reports,
  ReqTable,
  Request,
  RequestView,
  Reservation,
  ReservationDetails,
  ResetPassword,
  ResetYourPassword,
  ResidentOnboarding,
  ResidentTableDetails,
  ResidentTableView,
  ResourceBoard,
  ResourceBooking,
  ResourceManagerDashboard,
  ResourceMaster,
  SalesAgentCreateNew,
  SalesAgentViewNew,
  SalesManagerDashboard,
  ScheduleBoard,
  ServiceHubDashboard,
  ServiceInspection,
  ServiceMaster,
  ServiceProviderCreateEdit,
  ServiceProviderView,
  ServiceProviderViewNew,
  SetPassword,
  Settlements,
  ShortList,
  ShortTermPricing,
  ShortTermPricingPropertyView,
  ShortTermPricingUnitView,
  SiteVisit,
  StatementOfAccount,
  SubLedgerMaster,
  Subscriptions,
  Teams,
  TimeOffRequest,
  TimeOffResources,
  ToolsMaster,
  UnitBlockingList,
  UnitDetails,
  UnitHandOver,
  UnitItemLocationMaster,
  UnitList,
  UnitReadiness,
  UnitTable,
  UnitTableDetails,
  UnitVacancy,
  UnitVacancyAllList,
  UnitVacancyDetails,
  UnitVacancyPropertyList,
  UnitVacancyPropertyView,
  UploadApi,
  UserManageMent,
  UtilitiesMapping,
  UtilityManagerDashboard,
  UtilityReadings,
  VehicleMaster,
  VendorAccountCreate,
  VendorAccountDetail,
  VendorAccountList,
  VendorCreation,
  VendorGroupMaster,
  VendorViewNew,
  VisitorandSecurityDashboard,
  Welcome,
  WelcomePanner,
  WorkingHoursMaster,
  DynamicMaintenancePlanner,
  UtilitiesCategory,
  UtilityMaster,
  CustomerAccountCreate,
  CashBankAccountList,
  CashBankAccountCreate,
  CashBankAccountDetails,
  MarketPlace,
  SurveyForm,
  SelfCheckInEntries,
  WorkspaceManagerDashboard,
  SchedulePlanView,
  ScheduleWorkOrderView,
  ExecutedJobsView,
  ExecutedWorkOrdersView,
  Project,
  ResourceGroupMaster,
  ProjectCategory,
  PaymentDashboard,
} from "./../screens";
import AnnounsmentDetails from "./../screens/announsmentNew/announsmentDetails";
import SalesDetails from "./../screens/salesagentview";
// import SalesAgentList from "./../screens/salesagentview/saleagenttabel";
// import SalesCreation from "./../screens/salseagent";
import CheckAvailability from "../screens/amenitiesBooking/checkAvailability";
import CorrespondencesView from "../screens/correspondences/correspondencesView";
import CreateEditCorrespondences from "../screens/createEditCorrespondences";
import ViewDeliveryOrderDetails from "../screens/deliveryWorkOrders/components/viewDeliveryOrderDetails";
import FinalSettlement from "../screens/finalSettlement";
import FinalSettlementView from "../screens/finalSettlement/finalSettlementView";
import CreateFeedBackForm from "../screens/formBuilder/components/createform";
import FunctionalHeirarchy from "../screens/functionalLocation/functionalHeirarchy";
import ViewGeneralInspection from "../screens/generalInspection/components/viewInspection";
import KycDetailView from "../screens/kycDetails/kycDetailView";
import ShareUnit from "../screens/propertyBoard/components/shareUnitCard";
import ResidentOnboardingViewEdit from "../screens/residentOnboarding/residentOnboardingViewEdit";
import RestoreErrorHandling from "../screens/restoreErrorHandling";
import ViewUnitReadinessDetails from "../screens/unitReadiness/components/viewUnitReadiness";
import Shortlistedlead from "./../screens/shortlistlead";
import PrivateRouter from "./privateRouter";
import { Routes } from "./routes";
import CustomizeQuote from "../screens/customizeQUote/customizeQuote";
import ViewMaintenancePlanner from "../screens/viewMaintenancePlanner";
import MarketPlaceView from "../screens/marketingPlace/marketPlaceView";
import CreateSurveyForm from "../screens/surveryForm/components/createform";
import VideoRecorder from "../screens/videoRecorder";
const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* property creation */}
        <PrivateRouter exact path={Routes.propertycreation}>
          <PropertyCreation />
        </PrivateRouter>
        {/* Create Block New */}
        <PrivateRouter path={Routes.createBlockNew}>
          <CreateBlock />
        </PrivateRouter>
        {/* Create Floor New */}
        <PrivateRouter exact path={Routes.createFloorNew}>
          <CreateFloor />
        </PrivateRouter>
        {/* Create Unit New */}
        <PrivateRouter exact path={Routes.createUnitNew}>
          <CreateUnit />
        </PrivateRouter>
        {/* property creation */}
        <PrivateRouter exact path={Routes.propertyview}>
          <PropertyView />
        </PrivateRouter>
        {/* block view */}
        <PrivateRouter exact path={Routes.blockView}>
          <BlockView />
        </PrivateRouter>
        {/* Login Route */}
        <Route exact path={Routes.login}>
          <Login />
        </Route>
        {/* otp */}
        <Route exact path={Routes.otp}>
          <Otp />
        </Route>
        {/* Master */}
        <PrivateRouter exact path={Routes.propertyMaster}>
          <PropertyMaster />
        </PrivateRouter>
        <Route exact path={Routes.businessMaster}>
          <BusinessMaster />
        </Route>
        <PrivateRouter exact path={Routes.servicesMaster}>
          <ServiceMaster />
        </PrivateRouter>
        {/* forgotPassword */}
        <Route exact path={Routes.forgotPassword}>
          <ForgotPassword />
        </Route>
        {/* Properties */}
        <PrivateRouter exact path={Routes.properties}>
          <Properties />
        </PrivateRouter>
        {/*  Property Details */}
        <PrivateRouter exact path={Routes.propertyDetails}>
          <PropertyDetails />
        </PrivateRouter>
        {/*  Block Details */}
        <PrivateRouter exact path={Routes.blockDetails}>
          <BlockDetails />
        </PrivateRouter>
        {/*  Floor Details */}
        <PrivateRouter exact path={Routes.floorDetails}>
          <FloorDetails />
        </PrivateRouter>
        {/*  PropertyFinder */}
        <PrivateRouter exact path={Routes.propertyFinder}>
          {/* <PropertryFinder  /> */}
          {/* <PropertyFinders  /> */}
          <PropertyFinder4 />
        </PrivateRouter>
        {/*  PropertyFinder Details */}
        <PrivateRouter exact path={Routes.propertyFinderDetails}>
          <PropertyFinderDetails />
        </PrivateRouter>
        {/*  shortlisted units */}
        <PrivateRouter exact path={Routes.shortlisted}>
          <ShortList />
        </PrivateRouter>
        {/* create CreateQuotation Route */}
        <PrivateRouter exact path={Routes.createQuotation}>
          <CreateQuotation />
        </PrivateRouter>
        {/* Leads Route */}
        <PrivateRouter exact path={Routes.leads}>
          <Leads />
        </PrivateRouter>
        {/* LeadsDetails Route */}
        <PrivateRouter exact path={Routes.leadsDetails}>
          <OppertunityView />
        </PrivateRouter>
        {/* reset password Route */}
        <Route exact path={Routes.resetPassword}>
          <ResetPassword />
        </Route>
        {/* Unit Table Route */}
        <PrivateRouter exact path={Routes.unitTable}>
          <UnitTable />
        </PrivateRouter>
        {/* UnitTableDetails Route */}
        <PrivateRouter exact path={Routes.unitTableDetails}>
          <UnitTableDetails />
        </PrivateRouter>
        {/* UploadApi Route */}
        <PrivateRouter exact path={Routes.upload}>
          <UploadApi />
        </PrivateRouter>
        {/* Agreement Unit Access */}
        <PrivateRouter exact path={Routes.agreementUnitAccess}>
          <AgreementUnitAccess />
        </PrivateRouter>
        {/* Resident Table Route */}
        <PrivateRouter exact path={Routes.residentTable}>
          {/* <ResidentTable /> */}
          <CustomerViewNew />
        </PrivateRouter>
        {/* Owner Table Route */}
        <PrivateRouter exact path={Routes.ownerTable}>
          {/* <OwnerTable /> */}
          <OwnerViewNew />
        </PrivateRouter>
        {/* ResidentTableDetails Route */}
        <PrivateRouter exact path={Routes.residentTableDetails}>
          <ResidentTableDetails />
        </PrivateRouter>
        {/* OwnerTableDetails Route */}
        <PrivateRouter exact path={Routes.ownerTableDetails}>
          <OwnerTableDetails />
        </PrivateRouter>
        {/* Pricing Component */}
        <PrivateRouter exact path={Routes.pricingComponent}>
          <PricingComponent />
        </PrivateRouter>
        {/* Pricing Table view */}
        <PrivateRouter exact path={Routes.pricingTable}>
          <PricingTable />
        </PrivateRouter>
        {/* Pricing Table View All */}
        <PrivateRouter exact path={Routes.pricingTableAll}>
          <PricingTableViewAll />
        </PrivateRouter>
        {/* Shortlist Lead */}
        <PrivateRouter exact path={Routes.shortlistLead}>
          <Shortlistedlead />
        </PrivateRouter>
        {/* Pricing Table Property View */}
        <PrivateRouter exact path={Routes.pricingTablePropertyView}>
          <PricingTablePropertyView />
        </PrivateRouter>
        {/* Pricing Table Property View */}
        <PrivateRouter exact path={Routes.pricingTableUnitView}>
          <PricingTableUnitView />
        </PrivateRouter>
        {/* requestAdd Route */}
        <PrivateRouter exact path={Routes.request}>
          <Request />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.quotation}>
          <QuotationsList title="Quatation" />
        </PrivateRouter>
        {/* view quotation */}
        <PrivateRouter exact path={Routes.quotation_view}>
          <QuotationDetails title="Quatation" backURL="goBack" />
        </PrivateRouter>
        {/* property search result  */}
        <PrivateRouter exact path={Routes.propertyResult}>
          {/* <PropertyResult  /> */}
          <PropertyFinder4 />
        </PrivateRouter>
        {/* Utilities  */}
        <PrivateRouter exact path={Routes.utilities}>
          <UtilityMaster />
        </PrivateRouter>
        {/* Utilities Mapping */}
        <PrivateRouter exact path={Routes.utilitiesMapping}>
          <UtilitiesMapping />
        </PrivateRouter>
        {/* Utilities Mapping Property View */}
        <PrivateRouter exact path={Routes.utilitiesMappingPropertyView}>
          <UtilitiesMappingPropertyView />
        </PrivateRouter>
        {/* Utilities Mapping unit View */}
        <PrivateRouter exact path={Routes.utilitiesMappingUnitView}>
          <UtilitiesMappingUnitView />
        </PrivateRouter>
        {/* Amenities  */}
        <PrivateRouter exact path={Routes.amenities}>
          <Amenities />
        </PrivateRouter>
        {/* Amenities Mapping */}
        <PrivateRouter exact path={Routes.amenitiesMapping}>
          <AmenitiesMapping />
        </PrivateRouter>
        {/* Amenities Mapping Property View */}
        <PrivateRouter exact path={Routes.amenitiesMappingPropertyView}>
          <AmenitiesMappingPropertyView />
        </PrivateRouter>
        {/* Amenities Mapping unit View */}
        <PrivateRouter exact path={Routes.amenitiesMappingUnitView}>
          <AmenitiesMappingUnitView />
        </PrivateRouter>
        {/* Property Dashboard */}
        <PrivateRouter exact path={Routes.dasdboard}>
          <PropertyManagerDashboard />
        </PrivateRouter>
        {/* Client Dashboard */}
        <PrivateRouter exact path={Routes.clientDashboard}>
          <ClientManagerDashboard />
        </PrivateRouter>
        {/* Sales Dashboard */}
        <PrivateRouter exact path={Routes.salesDashboard}>
          <SalesManagerDashboard />
        </PrivateRouter>
        {/* Inspection Dashboard */}
        <PrivateRouter exact path={Routes.inspectionDashboard}>
          <InspectionManagerDashboard />
        </PrivateRouter>
        {/* Operations Dashboard */}
        <PrivateRouter exact path={Routes.operationsDashboard}>
          <OperationsManagerDashboard />
        </PrivateRouter>
        {/* Facility Dashboard */}
        <PrivateRouter exact path={Routes.facilityDashboard}>
          <FacilityManagerDashboard />
        </PrivateRouter>
        {/* Finance Dashboard */}
        <PrivateRouter exact path={Routes.financeDashboard}>
          <LeaseAndSalesManagerDashboard />
        </PrivateRouter>
        {/* Contract Dashboard */}
        <PrivateRouter exact path={Routes.contractDashboard}>
          <ContractManagerDashboard />
        </PrivateRouter>
        {/*community manager routes ------------------>*/}
        {/* CommunityCard Route */}
        <PrivateRouter exact path={Routes.communitycard}>
          <CommunityDiscussion />
        </PrivateRouter>
        {/* master Route */}
        <PrivateRouter exact path={Routes.master}>
          <Master />
        </PrivateRouter>
        {/* communication Route */}
        <PrivateRouter exact path={Routes.communication}>
          <Communication />
        </PrivateRouter>
        {/*security manager routes ------------------------> */}
        {/* MasterData Route */}
        <PrivateRouter exact path={Routes.masterData}>
          <MasterData />
        </PrivateRouter>
        {/* Parking Slot Route */}
        <PrivateRouter exact path={Routes.parkingSlot}>
          <ParkingSlot />
        </PrivateRouter>
        {/* Parking Slot master Route */}
        <PrivateRouter exact path={Routes.parkingSlotMaster}>
          <ParkingSlotMaster />
        </PrivateRouter>
        {/* Visitor And Worked Gate Pass Route */}
        <PrivateRouter exact path={Routes.visitorAndWorkedGatePass}>
          <VisitorAndWorkedGatePass />
        </PrivateRouter>
        {/* Delivery Order Collection Route */}
        <PrivateRouter exact path={Routes.deliveryOrderCollection}>
          <DeliveryOrderCollection />
        </PrivateRouter>
        {/* Domestic Help Request Route */}
        <PrivateRouter exact path={Routes.domesticHelpRequest}>
          <DomesticHelpRequest />
        </PrivateRouter>
        {/* Vendor Entry Route */}
        <PrivateRouter exact path={Routes.vendorEntry}>
          <VendorEntry />
        </PrivateRouter>
        {/* Service Provider Route */}
        <PrivateRouter exact path={Routes.serviceProvider}>
          <ServiceProvider />
        </PrivateRouter>
        {/* announsmwnt */}
        <PrivateRouter exact path={Routes.announcement}>
          <AnnounsmentNew />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createannounsment}>
          <CreateAnnounsmentNew />
        </PrivateRouter>
        {/* Configuraiton Listing */}
        <PrivateRouter exact path={Routes.configuration}>
          <ConfigurartionNew title="Configuration" />
        </PrivateRouter>
        {/* Configuraiton details */}
        <PrivateRouter exact path={Routes.configuration_details}>
          <ConfigurationMaster title="Configuration" backURL="goBack" />
        </PrivateRouter>
        {/* Configuraiton details */}
        <PrivateRouter exact path={Routes.configuration_add}>
          <ConfigurartionNew />
        </PrivateRouter>
        {/* Block */}
        <PrivateRouter exact path={Routes.block}>
          <BlockList />
        </PrivateRouter>
        {/* fllor */}
        <PrivateRouter exact path={Routes.floor}>
          <FloorList />
        </PrivateRouter>
        {/* unit */}
        <PrivateRouter exact path={Routes.unit}>
          <UnitList />
        </PrivateRouter>
        {/* unitDetails */}
        <PrivateRouter exact path={Routes.unitDetails}>
          <UnitDetails />
        </PrivateRouter>
        {/* unitDetails */}
        <PrivateRouter exact path={Routes.floorDetails}>
          <FloorDetails />
        </PrivateRouter>
        {/* Company Master */}
        <PrivateRouter exact path={Routes.companyMaster}>
          <Masters />
        </PrivateRouter>
        {/* Property Type */}
        <PrivateRouter exact path={Routes.propertyType}>
          <Masters />
        </PrivateRouter>
        {/* Block Type */}
        <PrivateRouter exact path={Routes.blockType}>
          <Masters />
        </PrivateRouter>
        {/* Unit Type */}
        <PrivateRouter exact path={Routes.unitType}>
          <Masters />
        </PrivateRouter>
        {/* Tax Group */}
        <PrivateRouter exact path={Routes.taxGroup}>
          <Masters />
        </PrivateRouter>
        {/* Tax Item */}
        <PrivateRouter exact path={Routes.taxItem}>
          <Masters />
        </PrivateRouter>
        {/* residnettableDetails */}
        <PrivateRouter exact path={Routes.residentTableDetail}>
          <ResidentTableView />
        </PrivateRouter>
        {/* owner tableDetails */}
        <PrivateRouter exact path={Routes.ownerTableDetail}>
          <OwnerTableView />
        </PrivateRouter>
        {/* ManagementCommittee */}
        <PrivateRouter exact path={Routes.managementcommittee}>
          <ManagementCommittee />
        </PrivateRouter>
        {/* managementcommitteeView */}
        <PrivateRouter exact path={Routes.managementcommitteeView}>
          <ManagementCommitteeView />
        </PrivateRouter>
        {/* companyCreation */}
        <PrivateRouter exact path={Routes.companyCreation}>
          <CompanyCreation />
        </PrivateRouter>
        {/* companyDetails */}
        <PrivateRouter exact path={Routes.editcompany}>
          <CompanyCreation />
        </PrivateRouter>
        {/*vendor Listing */}
        <PrivateRouter exact path={Routes.vendorListing}>
          {/* <VendorCreation /> */}
          <VendorViewNew />
        </PrivateRouter>
        {/*vendorCreation */}
        <PrivateRouter exact path={Routes.vendorCreation}>
          <VendorCreation />
        </PrivateRouter>
        {/* vendorDetails */}
        <PrivateRouter exact path={Routes.editVendor}>
          <VendorCreation />
        </PrivateRouter>
        {/* configure */}
        <PrivateRouter exact path={Routes.configure}>
          <Configure />
        </PrivateRouter>
        {/* configure */}
        <PrivateRouter exact path={Routes.salesagent}>
          {/* <SalesCreation /> */}
          <SalesAgentCreateNew />
        </PrivateRouter>
        {/* SalesDetails */}
        <PrivateRouter exact path={Routes.salesview}>
          <SalesDetails />
        </PrivateRouter>
        {/* SalesAgentList */}
        <PrivateRouter exact path={Routes.salesagentlist}>
          {/* <SalesAgentList /> */}
          <SalesAgentViewNew />
        </PrivateRouter>
        {/* Country Master */}
        <PrivateRouter exact path={Routes.countryMaster}>
          <Masters />
        </PrivateRouter>
        {/* Currency Master */}
        <PrivateRouter exact path={Routes.currencyMaster}>
          <Masters />
        </PrivateRouter>
        {/* General Category Master */}
        <PrivateRouter exact path={Routes.generalCategory}>
          <Masters />
        </PrivateRouter>
        {/* General Sub-Category Master */}
        <PrivateRouter exact path={Routes.generalSubCategory}>
          <Masters />
        </PrivateRouter>
        {/* Maintenance Category Master */}
        <PrivateRouter exact path={Routes.maintenanceCategory}>
          <Masters />
        </PrivateRouter>
        {/* Maintenance Sub-Category Master */}
        <PrivateRouter exact path={Routes.maintenanceSubCategory}>
          <Masters />
        </PrivateRouter>
        {/* LeadNew */}
        <PrivateRouter exact path={Routes.leadnew}>
          <LeadNew />
        </PrivateRouter>
        {/* Contact List */}
        {/* <PrivateRouter exact path={Routes.contactsList}>
          <ContactList  />
        </PrivateRouter> */}
        {/* Contact List */}
        <PrivateRouter exact path={Routes.contactsList}>
          <Contacts />
        </PrivateRouter>
        {/* Create Contact */}
        <PrivateRouter exact path={Routes.createContact}>
          <CreateContactNew />
        </PrivateRouter>
        {/* Aggreements */}
        <PrivateRouter exact path={Routes.aggreements}>
          <AggreementsList />
        </PrivateRouter>
        {/* Aggreement view */}
        <PrivateRouter exact path={Routes.agreementView}>
          <AgreementDetails />
        </PrivateRouter>
        {/* Contact View */}
        <PrivateRouter exact path={Routes.contactView}>
          <ContactView />
        </PrivateRouter>
        {/* Jobs */}
        <PrivateRouter exact path={Routes.jobs}>
          <Jobs />
        </PrivateRouter>
        {/* Department */}
        <PrivateRouter exact path={Routes.department}>
          <Department />
        </PrivateRouter>
        {/* Parking Area */}
        <PrivateRouter exact path={Routes.parkingArea}>
          <ParkingArea />
        </PrivateRouter>
        {/* Access Gates */}
        <PrivateRouter exact path={Routes.accessGates}>
          <AccessGates />
        </PrivateRouter>
        {/* Access Gates Property View */}
        <PrivateRouter exact path={Routes.accessGatesPropertyView}>
          <AccessGatesPropertyView />
        </PrivateRouter>
        {/* Service Provider Listing */}
        <PrivateRouter exact path={Routes.serviceProviderListing}>
          {/* <ServiceProviderListing /> */}
          <ServiceProviderViewNew />
        </PrivateRouter>
        {/* Service Provider View */}
        <PrivateRouter exact path={Routes.serviceProviderView}>
          <ServiceProviderView />
        </PrivateRouter>
        {/* Service Provider Create Edit */}
        <PrivateRouter exact path={Routes.serviceProviderCreateEdit}>
          <ServiceProviderCreateEdit />
        </PrivateRouter>
        {/* company select in login screen */}
        <Route exact path={Routes.companyselect}>
          <Company />
        </Route>
        {/* Welcome  in login screen */}
        <Route exact path={Routes.welcome}>
          <Welcome />
        </Route>
        {/* SetPassword  in login screen */}
        <Route exact path={Routes.setpassword}>
          <SetPassword />
        </Route>
        {/*LeaseManagerDashboard */}
        <PrivateRouter exact path={Routes.leasemanagerdashboard}>
          <LeaseAndSalesManagerDashboard />
          {/* <LeaseManagerDashboard  /> */}
        </PrivateRouter>

        {/* Workspace Manager Dashboard */}
        <PrivateRouter exact path={Routes.WorkspaceManagerDashboard}>
         <WorkspaceManagerDashboard />
        </PrivateRouter>    

        {/* SecurityDashboard */}
        <PrivateRouter exact path={Routes.securitydashboard}>
          <VisitorandSecurityDashboard />
          {/* <SecurityDashboard  /> */}
        </PrivateRouter>
        {/*unit vacancy */}
        <PrivateRouter exact path={Routes.unit_vacancy}>
          <UnitVacancy />
        </PrivateRouter>
        {/*unit vacancy */}
        <PrivateRouter exact path={Routes.vacancy_details}>
          <UnitVacancyDetails />
        </PrivateRouter>
        {/*create opportunity*/}
        <PrivateRouter exact path={Routes.createOpportunity}>
          <CreateOpportunity />
        </PrivateRouter>
        {/* Profession */}
        <PrivateRouter exact path={Routes.profession}>
          <Profession />
        </PrivateRouter>
        {/* Emergency Number */}
        <PrivateRouter exact path={Routes.emergencyContact}>
          <EmergencyContact />
        </PrivateRouter>
        {/* Domestic Helper List */}
        <PrivateRouter exact path={Routes.domesticHelperList}>
          <DomesticHelperList />
        </PrivateRouter>
        {/* Domestic Helper View */}
        <PrivateRouter exact path={Routes.domesticHelperView}>
          <DomesticHelperView />
        </PrivateRouter>
        {/* Domestic Helper Create Edit */}
        <PrivateRouter exact path={Routes.domesticHelperCreateEdit}>
          <DomesticHelperCreateEdit />
        </PrivateRouter>
        {/*account*/}
        <PrivateRouter exact path={Routes.accounts}>
          <Accounts />
        </PrivateRouter>
        {/*create account*/}
        <PrivateRouter exact path={Routes.createAccount}>
          <CreateAccount />
        </PrivateRouter>
        {/*account details*/}
        <PrivateRouter exact path={Routes.accountDetails}>
          <AccountDetails />
        </PrivateRouter>
        {/*account details*/}
        {/* <PrivateRouter exact path={Routes.dashboard} >
          <PropertyDashBoard  />
        </PrivateRouter> */}
        {/*ClientSettings*/}
        <PrivateRouter exact path={Routes.clientSettings}>
          <ClientSettings />
        </PrivateRouter>
        {/*userManagement */}
        <PrivateRouter exact path={Routes.userManagement}>
          <UserManageMent />
        </PrivateRouter>
        {/*invoice */}
        <PrivateRouter exact path={Routes.invoice}>
          <Invoice />
        </PrivateRouter>
        {/*invoiceView */}
        <PrivateRouter exact path={Routes.invoiceView}>
          <InvoiceView />
        </PrivateRouter>
        {/* Agreement Termination*/}
        <PrivateRouter exact path={Routes.agreementTermination}>
          <AgreementTermination />
        </PrivateRouter>
        {/* Short Term Pricing view */}
        <PrivateRouter exact path={Routes.shortTermPricing}>
          <ShortTermPricing />
        </PrivateRouter>
        {/* Short Term Pricing Property View */}
        <PrivateRouter exact path={Routes.shortTermPricingPropertyView}>
          <ShortTermPricingPropertyView />
        </PrivateRouter>
        {/* Short Term Pricing Unit View */}
        <PrivateRouter exact path={Routes.shortTermPricingUnitView}>
          <ShortTermPricingUnitView />
        </PrivateRouter>
        {/* DeliveryMilestone*/}
        <PrivateRouter exact path={Routes.deliveryMilestone}>
          <DeliveryMilestone />
        </PrivateRouter>
        {/* DeliveryMilestone*/}
        <PrivateRouter exact path={Routes.deliveryMilestoneTemplate}>
          <DeliveryMilestoneTemplate />
        </PrivateRouter>
        {/* Community Dashboard*/}
        <PrivateRouter exact path={Routes.communityDashboard}>
          <CommunityDashboard />
        </PrivateRouter>
        {/* Activity Category*/}
        <PrivateRouter exact path={Routes.activityCategory}>
          <ActivityCategory />
        </PrivateRouter>
        {/* Activity Sub-Category*/}
        <PrivateRouter exact path={Routes.activitySubCategory}>
          <ActivitySubCategory />
        </PrivateRouter>
        {/* Propspect*/}
        <PrivateRouter exact path={Routes.propspect}>
          <Propspect />
        </PrivateRouter>
        {/* Profile View */}
        <PrivateRouter exact path={Routes.profileView}>
          <ProfileView />
        </PrivateRouter>
        {/* Profile Edit */}
        <PrivateRouter exact path={Routes.profileEdit}>
          <ProfileEdit />
        </PrivateRouter>
        {/* Agreement Renewal */}
        <PrivateRouter exact path={Routes.agreementRenewal}>
          <AgreementRenewal />
        </PrivateRouter>
        {/* Unit Vacancy Property List */}
        <PrivateRouter exact path={Routes.unitVacancyPropertyList}>
          <UnitVacancyPropertyList />
        </PrivateRouter>
        {/* Unit Vacancy All List */}
        <PrivateRouter exact path={Routes.unitVacancyAll}>
          <UnitVacancyAllList />
        </PrivateRouter>
        {/* Unit Vacancy Property View */}
        <PrivateRouter exact path={Routes.unitVacancyPropertyView}>
          <UnitVacancyPropertyView />
        </PrivateRouter>
        {/* DeliveryMilestone Template*/}
        <PrivateRouter exact path={Routes.createDeliveryMilestoneTemplate}>
          <CreateDeliveryMilestoneTemplate />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.leadview}>
          <LeadView />
        </PrivateRouter>
        {/* "/" Route */}
        <Route exact path={Routes.home}>
          <Login />
        </Route>
        <Route exact path={Routes.dbTask}>
          <DbTask />
        </Route>
        {/* INSPECTION START*/}
        {/* move-in */}
        <PrivateRouter exact path={Routes.moveInInspectionOrders}>
          <ReqTable />
        </PrivateRouter>
        {/* inspection */}
        <PrivateRouter exacth path={Routes.moveInInspectionDetails}>
          <InspectionList />
        </PrivateRouter>
        {/* inspection Request */}
        <PrivateRouter exacth path={Routes.moveInInspectionItemDetails}>
          <InspectionReq />
        </PrivateRouter>
        {/* moveoutInspection */}
        <PrivateRouter exact path={Routes.moveOutInspectionOrders}>
          <MoveoutInspection />
        </PrivateRouter>
        {/* move-out inspection details */}
        <PrivateRouter exacth path={Routes.moveOutInspectionDetails}>
          <InspectionList />
        </PrivateRouter>
        {/* move-out inspection item details */}
        <PrivateRouter exacth path={Routes.moveOutInspectionItemDetails}>
          <InspectionReq />
        </PrivateRouter>
        {/*Unit HandOver Inspection */}
        <PrivateRouter exact path={Routes.unitHandOverInspectionOrders}>
          <UnitHandOver />
        </PrivateRouter>
        {/* unit HandOver inspection details */}
        <PrivateRouter exacth path={Routes.unitHandOverInspectionDetails}>
          <InspectionList />
        </PrivateRouter>
        {/* unit HandOver inspection item details */}
        <PrivateRouter exacth path={Routes.unitHandOverInspectionItemDetails}>
          <InspectionReq />
        </PrivateRouter>
        {/* InspectionItems */}
        <PrivateRouter exact path={Routes.inspectionItems}>
          <InspectionItems />
        </PrivateRouter>
        {/* InspectionItems property details */}
        <PrivateRouter exact path={Routes.insItemPropertyDetails}>
          <InsPropertyDetail />
        </PrivateRouter>
        {/* inspectionMapping */}
        <PrivateRouter exact path={Routes.inspectionMapping}>
          <InspectionMapping />
        </PrivateRouter>
        {/* inspectionMapping-propertyDetails  */}
        <PrivateRouter exact path={Routes.mapPropertyDetails}>
          <MapPropertyDetails />
        </PrivateRouter>
        {/* inspectionMapping-unitDetails  */}
        <PrivateRouter exact path={Routes.mapUnitDetails}>
          <MapUnitDetails />
        </PrivateRouter>
        {/* inventoryMaster */}
        <PrivateRouter exact path={Routes.inventoryMaster}>
          <InventoryMaster />
        </PrivateRouter>
        {/* resource Master */}
        <PrivateRouter exact path={Routes.resourceMaster}>
          <ResourceMaster />
        </PrivateRouter>
        {/* INSPECTION END*/}
        {/* Resource board  */}
        <PrivateRouter exact path={Routes.resourceBoard}>
          <ResourceBoard />
        </PrivateRouter>
        {/* Schedule Board */}
        <PrivateRouter exact path={Routes.scheduleBoard}>
          <ScheduleBoard />
        </PrivateRouter>
        {/* Employees Booking */}
        <Route exact path={Routes.employeesBooking}>
          <EmployeesBooking />
        </Route>
        {/* Already paid */}
        <PrivateRouter exact path={Routes.alreadypaid}>
          <AlreadyPaid />
        </PrivateRouter>
        {/* MaintanceRequest */}
        <PrivateRouter exact path={Routes.maintancerequest}>
          <MaintanceRequest />
        </PrivateRouter>
        {/* CreateMaintanceRequest */}
        <PrivateRouter exact path={Routes.createmaintanceRequest}>
          <CreateMaintanceRequest />
        </PrivateRouter>
        {/* CreateMaintanceRequest */}
        <PrivateRouter exact path={Routes.generalRequest}>
          <GeneralRequest />
        </PrivateRouter>
        {/* CreateGeneralRequest */}
        <PrivateRouter exact path={Routes.creategeneralrequest}>
          <CreateGeneralRequest />
        </PrivateRouter>
        {/* receipt */}
        <PrivateRouter exact path={Routes.receipt}>
          <Receipt />
        </PrivateRouter>
        {/*CreateReceipt */}
        <PrivateRouter exact path={Routes.createReceipt}>
          <CreateReceipt />
        </PrivateRouter>
        {/*ReceiptView */}
        <PrivateRouter exact path={Routes.receiptView}>
          <ReceiptView />
        </PrivateRouter>
        {/*kyc */}
        <PrivateRouter exact path={Routes.kyclist}>
          <KycScreen />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.pricingAppraisal}>
          <PricingAppraisal />
        </PrivateRouter>
        {/* inspection master */}
        <PrivateRouter exact path={Routes.inspectionMaster}>
          <InspectionMaster />
        </PrivateRouter>
        {/* rental index */}
        <PrivateRouter exact path={Routes.rentalIndex}>
          <RentalIndex />
        </PrivateRouter>
        {/* Working Hours Master */}
        <PrivateRouter exact path={Routes.workingHoursMaster}>
          <WorkingHoursMaster />
        </PrivateRouter>
        {/* vehicle master */}
        <PrivateRouter exact path={Routes.vehicleMaster}>
          <VehicleMaster />
        </PrivateRouter>
        {/* tools master */}
        <PrivateRouter exact path={Routes.toolMaster}>
          <ToolsMaster />
        </PrivateRouter>
        {/* ManageInvoice */}
        <PrivateRouter exact path={Routes.manageInvoice}>
          <ManageInvoice />
        </PrivateRouter>
        {/* statement of account */}
        <PrivateRouter exact path={Routes.statementOfAccount}>
          <StatementOfAccount />
        </PrivateRouter>
        {/* Reservation */}
        <PrivateRouter exact path={Routes.reservation}>
          <Reservation />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.reservationDetails}>
          <ReservationDetails />
        </PrivateRouter>
        {/* Amenities Booking */}
        <PrivateRouter exact path={Routes.amenitiesBooking}>
          <AmenitiesBooking />
        </PrivateRouter>
        {/* domestic Contacts */}
        <PrivateRouter exact path={Routes.domesticContactsList}>
          <DomesticContacts />
        </PrivateRouter>
        {/* Customer Account list route */}
        <PrivateRouter exact path={Routes.customerAccountList}>
          <CustomerAccountList />
        </PrivateRouter>
        {/* Vendor Account List route */}
        <PrivateRouter exact path={Routes.vendorAccountList}>
          <VendorAccountList />
        </PrivateRouter>
        {/* Vendor Account Create route */}
        <PrivateRouter exact path={Routes.vendorAccountCreate}>
          <VendorAccountCreate />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.reports}>
          <Reports />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.report}>
          <Report />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.fileManager}>
          <FileManager />
        </PrivateRouter>
        {/* owner account create */}
        <PrivateRouter exact path={Routes.ownerAccountCreate}>
          <OwnerAccountCreate />
        </PrivateRouter>
        {/* owner account list */}
        <PrivateRouter exact path={Routes.ownerAccountList}>
          <OwnerAccountList />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.managementCommitteRoleMaster}>
          <ManagementCommitteRoleMaster />
        </PrivateRouter>
        {/* vendor account detail */}
        <PrivateRouter exact path={Routes.vendorAccountDetail}>
          <VendorAccountDetail />
        </PrivateRouter>
        {/* owner account detail */}
        <PrivateRouter exact path={Routes.ownerAccountDetails}>
          <OwnerAccountDetails />
        </PrivateRouter>
        {/* Customer account detail */}
        <PrivateRouter exact path={Routes.customerAccountDetails}>
          <CustomerAccountDetails />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.parkingBooking}>
          <ParkingBooking />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.parkingGroupingMaster}>
          <ParkingGroupingMaster />
        </PrivateRouter>
        <Route exact path={Routes.announcement_details}>
          <AnnounsmentDetails />
        </Route>
        {/* Invite trigger */}
        <PrivateRouter exact path={Routes.inviteTrigger}>
          <InviteTrigger />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.maintanceRequestView}>
          <RequestView />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.generalRequestView}>
          <RequestView />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.freeTextInvoice}>
          <FreeTextInvoice />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createInvoiceFreeText}>
          <CreateInvoiceFreeText />
        </PrivateRouter>
        {/* TimeOffRequest */}
        <PrivateRouter exact path={Routes.timeOffRequest}>
          <TimeOffRequest />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.teams}>
          <Teams />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.settlements}>
          <Settlements />
        </PrivateRouter>
        {/* Resource Booking */}
        <PrivateRouter exact path={Routes.resourceBookingDetails}>
          <ResourceBooking />
        </PrivateRouter>
        {/*Service Inspection */}
        <PrivateRouter exact path={Routes.serviceInspection}>
          <ServiceInspection />
        </PrivateRouter>
        {/*view service Inspection */}
        <PrivateRouter exact path={Routes.viewServiceInspection}>
          <ViewServiceInspection />
        </PrivateRouter>
        {/*General Inspection */}
        <PrivateRouter exact path={Routes.generalInspection}>
          <GeneralInspection />
        </PrivateRouter>
        {/*view general Inspection */}
        <PrivateRouter exact path={Routes.viewGeneralInspection}>
          <ViewGeneralInspection />
        </PrivateRouter>
        {/*Site Visit */}
        <PrivateRouter exact path={Routes.siteVisit}>
          <SiteVisit />
        </PrivateRouter>
        {/*view siteVisit Inspection */}
        <PrivateRouter exact path={Routes.viewSiteVisitDetails}>
          <ViewSiteVisitDetails />
        </PrivateRouter>
        {/*Unit Readiness*/}
        <PrivateRouter exact path={Routes.unitReadiness}>
          <UnitReadiness />
        </PrivateRouter>
        {/*view siteVisit Inspection */}
        <PrivateRouter exact path={Routes.viewUnitReadinessDetails}>
          <ViewUnitReadinessDetails />
        </PrivateRouter>
        {/* KYC Details */}
        <PrivateRouter exact path={Routes.kycDetails}>
          <KycDetails />
        </PrivateRouter>
        {/* KYC Details view*/}
        <PrivateRouter exact path={Routes.kycDetailView}>
          <KycDetailView />
        </PrivateRouter>
        {/* Time Off resources */}
        <PrivateRouter exact path={Routes.timeOffResources}>
          <TimeOffResources />
        </PrivateRouter>
        {/* Inspection FeedBack Category Master */}
        <PrivateRouter exact path={Routes.inspectionFeedbackCategory}>
          <FeedBackCategoryMaster />
        </PrivateRouter>
        {/* Inspection FeedBack Sub Category Master */}
        <PrivateRouter exact path={Routes.inspectionFeedbackSubcategory}>
          <FeedBackSubCategoryMaster />
        </PrivateRouter>
        {/* Correspondences */}
        <PrivateRouter exact path={Routes.correspondences}>
          <Correspondences />
        </PrivateRouter>
        {/* Create Correspondences */}
        <PrivateRouter exact path={Routes.create_correspondences}>
          <CreateEditCorrespondences />
        </PrivateRouter>
        {/* Edit Correspondences */}
        <PrivateRouter exact path={Routes.edit_correspondences}>
          <CreateEditCorrespondences />
        </PrivateRouter>
        {/* Activity Board */}
        <PrivateRouter exact path={Routes.activityBoard}>
          <ActivityBoard />
        </PrivateRouter>
        {/* Public Listing */}
        <PrivateRouter exact path={Routes.publicListing}>
          <PublicListing />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.deliveryOrders}>
          <DeliveryWorkOrder />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.viewDeliveryOrders}>
          <ViewDeliveryOrderDetails />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.utilityReadings}>
          <UtilityReadings />
        </PrivateRouter>
        {/* {customer FeedBack} */}
        <PrivateRouter exact path={Routes.customerFeedBack}>
          <CustomerFeedBack />
        </PrivateRouter>
        <Route exact path={Routes.resetyourpassword}>
          <ResetYourPassword />
        </Route>
        <PrivateRouter exact path={Routes.shortlistedoppertunityunits}>
          <ShortList isOppertunity={true} />
        </PrivateRouter>
        <Route exact path={Routes.resetyourpassword}>
          <ResetYourPassword />
        </Route>
        {/* Contract */}
        <PrivateRouter exact path={Routes.contract}>
          <Contract />
        </PrivateRouter>
        {/* Contract create and edit */}
        <PrivateRouter exact path={Routes.contractCreateEdit}>
          <CreateEditContract />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createOppertunityQuotation}>
          <CreateQuotation />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.amenityMappingNew}>
          <AmenityMappingNew />
        </PrivateRouter>
        {/* check availability */}
        <Route exact path={Routes.checkAvailability}>
          <CheckAvailability />
        </Route>
        <PrivateRouter exact path={Routes.oppertunityQuotation}>
          <QuotationsList title="Quatation" />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.propertyBoard}>
          <PropertyBoard />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.creditNote}>
          <FreeTextInvoice />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.debitNote}>
          <FreeTextInvoice />
        </PrivateRouter>
        {/*form Builder*/}
        <PrivateRouter exact path={Routes.formList}>
          <FormBuilder />
        </PrivateRouter>
        {/* create form */}
        <PrivateRouter exact path={Routes.formBuilder}>
          <CreateFeedBackForm />
        </PrivateRouter>
        {/*unit Blocking */}
        <PrivateRouter exact path={Routes.unitBlocking}>
          <UnitBlockingList />
        </PrivateRouter>
        {/*Inspection management service dashboard */}
        <PrivateRouter exact path={Routes.serviceHubDashboard}>
          <ServiceHubDashboard />
        </PrivateRouter>
        {/*KYC Document Type */}
        <PrivateRouter exact path={Routes.kyc_document_type}>
          <KycDocumentType />
        </PrivateRouter>
        {/*Correspondence Type */}
        <PrivateRouter exact path={Routes.correspondence_type}>
          <CorrespondenceType />
        </PrivateRouter>
        {/*Amenity Category */}
        <PrivateRouter exact path={Routes.amenity_category}>
          <AmenityCategory />
        </PrivateRouter>
        {/*Leave Master */}
        <PrivateRouter exact path={Routes.leave_master}>
          <LeaveMaster />
        </PrivateRouter>
        {/*Share Unit */}
        <Route exact path={Routes.shareUnit}>
          <ShareUnit />
        </Route>
        <PrivateRouter exact path={Routes.tenantOnboarding}>
          <ResidentOnboarding type="tenant" />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.residentOnboarding}>
          <ResidentOnboarding type="resident" />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.quickLeaseOnboarding}>
          <ResidentOnboarding type="leaseType" />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.residentOnboardingViewEdit}>
          <ResidentOnboardingViewEdit />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.marketingConfiguration}>
          <MarketingPortalConfiguration />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.amenityMappingNew}>
          <AmenityMappingNew />
        </PrivateRouter>
        {/*Import */}
        <PrivateRouter exact path={Routes.import}>
          <Import />
        </PrivateRouter>
        {/*Import File Upload */}
        <PrivateRouter exact path={Routes.import_file_upload}>
          <ImportFileUpload />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.lead_import_file_upload}>
          <LeadImportFileUploadWithNavBars />
        </PrivateRouter>
        {/* Listing Dashboard */}
        <PrivateRouter exact path={Routes.listingDashBoard}>
          <ListingDashBoard />
        </PrivateRouter>
        {/*Backup & Restore */}
        <PrivateRouter exact path={Routes.backup_restore}>
          <BackupRestore />
        </PrivateRouter>
        {/* Final Settlement */}
        <PrivateRouter exact path={Routes.finalSettlement}>
          <FinalSettlement />
        </PrivateRouter>
        {/* Final Settlement View */}
        <PrivateRouter exact path={Routes.finalSettlementView}>
          <FinalSettlementView />
        </PrivateRouter>
        {/* welcome banner */}
        <PrivateRouter exact path={Routes.welcome_banner}>
          <WelcomePanner />
        </PrivateRouter>
        {/* dashboard banner */}
        <PrivateRouter exact path={Routes.dashboard_banner}>
          <Dashboardbanner />
        </PrivateRouter>
        {/* community banner */}
        <PrivateRouter exact path={Routes.community_banner}>
          <Communitybanner />
        </PrivateRouter>
        {/* community banner */}
        <PrivateRouter exact path={Routes.logo_apperence}>
          <LogoApperances />
        </PrivateRouter>
        {/*Subcriptions */}
        <PrivateRouter exact path={Routes.subscribe}>
          <Subscriptions />
        </PrivateRouter>
        {/* Restore Error handling Image */}
        <Route exact path={Routes?.restoreErrorHandling}>
          <RestoreErrorHandling />
        </Route>
        {/*Amenity Board*/}
        <PrivateRouter exact path={Routes.amenity_board}>
          <AmenityBoard />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.correspondencesView}>
          <CorrespondencesView />
        </PrivateRouter>
        {/* Costing Approval */}
        <PrivateRouter exact path={Routes.costingApprovalRequests}>
          <CostingApproval />
        </PrivateRouter>
        {/* Contract 360 */}
        <PrivateRouter exact path={Routes.contract360}>
          <Contract360 />
        </PrivateRouter>
        {/* Announcement Board */}
        <PrivateRouter exact path={Routes.announcementBoard}>
          <AnnouncementBoard />
        </PrivateRouter>
        {/* Item Category Master */}
        <PrivateRouter exact path={Routes.item_category_master}>
          <ItemCategoryMaster />
        </PrivateRouter>
        {/* Item Sub Category Master */}
        <PrivateRouter exact path={Routes.item_sub_category_master}>
          <ItemSubCategoryMaster />
        </PrivateRouter>
        {/* Manufacturer Master */}
        <PrivateRouter exact path={Routes.manufacturer_master}>
          <ManufacturerMaster />
        </PrivateRouter>
        {/* Unit Item Location Master */}
        <PrivateRouter exact path={Routes.unit_item_location_master}>
          <UnitItemLocationMaster />
        </PrivateRouter>
        {/* Occupants */}
        <PrivateRouter exact path={Routes.occupants}>
          <Occupants />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.checkListGroup}>
          <CheckListGroup />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.checkListGroupView}>
          <CheckListGroupView />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.checkListItemView}>
          <CheckListItemView />
        </PrivateRouter>
        <Route exact path={Routes.ExploreTemplate}>
          <ExploreTemplate />
        </Route>
        {/* My Org */}
        <PrivateRouter exact path={Routes.myOrg}>
          <MyOrg />
        </PrivateRouter>
        {/* Management Role Master */}
        <PrivateRouter exact path={Routes.managementRoleMaster}>
          <ManagementRoleMaster />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.functionalLocation}>
          <FunctionalLocation />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.functionalHeirarchy}>
          <FunctionalHeirarchy />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.internal_lead_source}>
          <InternalLeadSource />
        </PrivateRouter>
        {/* Duty Roster */}
        <PrivateRouter exact path={Routes.dutyRoster}>
          <DutyRoster />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.selfCheckInEntries}>
          <SelfCheckInEntries />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.ledgerMaster}>
          <LedgerMaster />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.subLedgerMaster}>
          <SubLedgerMaster />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.COAMaster}>
          <COAMaster />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.utilityDashboard}>
          <UtilityManagerDashboard />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.resourceManagerDashboard}>
          <ResourceManagerDashboard />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.customer_group_master}>
          <CustomerGroupMaster />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.vendor_group_master}>
          <VendorGroupMaster />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.expense_group_master}>
          <ExpenseGroupMaster />
        </PrivateRouter>
        {/*Utility Category */}
        <PrivateRouter exact path={Routes.utilities_category}>
          <UtilitiesCategory />
        </PrivateRouter>
        <Route exact path={Routes.customQuote}>
          <CustomizeQuote />
        </Route>
        <PrivateRouter exact path={Routes.amenityDashboard}>
          <AmenityDashBoard />
        </PrivateRouter>
        {/*Maintenance Planner*/}
        <PrivateRouter exact path={Routes.fixedMaintenancePlanner}>
          <MaintenancePlanner />
        </PrivateRouter>
        {/* {Dynamic Maintenance planner} */}
        <PrivateRouter exact path={Routes.dynamicMaintenancePlanner}>
          <DynamicMaintenancePlanner />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createMaintenancePlanner}>
          <CreateFixedMaintenancePlanner />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.viewMaintenancePlanner}>
          <ViewMaintenancePlanner />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.customerAccountCreate}>
          <CustomerAccountCreate />
        </PrivateRouter>
        {/* Cash and Bank Account */}
        <PrivateRouter exact path={Routes.cashBankAccountList}>
          <CashBankAccountList />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.cashBankAccountCreate}>
          <CashBankAccountCreate />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.cashBankAccountDetails}>
          <CashBankAccountDetails />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.marketPlace}>
          <MarketPlace />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.marketPlaceView}>
          <MarketPlaceView />
        </PrivateRouter>
        {/* Survey Form */}/
        <PrivateRouter exact path={Routes.surveyForm}>
          <SurveyForm />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createSurveyForm}>
          <CreateSurveyForm />
        </PrivateRouter>
        {/* Schedule Plan View */}
        <PrivateRouter exact path={Routes.schedulePlan}>
          <SchedulePlanView />
        </PrivateRouter>
        {/* Schedule Work order View */}
        <PrivateRouter exact path={Routes.scheduledWorkOrder}>
          <ScheduleWorkOrderView />
        </PrivateRouter>
        {/* Executed Work Orders View */}
        <PrivateRouter exact path={Routes.executedWorkOrder}>
          <ExecutedWorkOrdersView />
        </PrivateRouter>
        {/* Executed Jobs View */}
        <PrivateRouter exact path={Routes.executedJobs}>
          <ExecutedJobsView />
        </PrivateRouter>
        {/* Project */}
        <PrivateRouter exact path={Routes.project}>
          <Project />
        </PrivateRouter>
        {/* Resource Group Master */}
        <PrivateRouter exact path={Routes.resourceGroupMaster}>
          <ResourceGroupMaster />
        </PrivateRouter>
        {/* Project Category */}
        <PrivateRouter exact path={Routes.projectCategory}>
          <ProjectCategory />
        </PrivateRouter>
        <Route exact path={Routes.videoRecorder}>
          <VideoRecorder />
        </Route>
        <PrivateRouter exact path={Routes.paymentDashboard}>
          <PaymentDashboard />
          </PrivateRouter>
        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
