import { useApolloClient } from "@apollo/client";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import TextField from '@mui/material/TextField';
import React, { useMemo, useState } from 'react';
import { BedBath, CustomTypography } from '.';
import { GETUOMMASTER } from '../../../graphql/quotationQueries';
import { Bold, LocalStorageKeys, Regular, SemiBold } from "../../../utils";
const Wrapper = styled(Stack)({
    border: '1px solid #E4E8EE',
    borderRadius: '4px',
    backgroundColor: '#fff',
    position: 'relative',
    // overflow: 'hidden',
    cursor: 'pointer',
    padding: '9px',
})

const Container = styled(Stack)({
    padding: 16,
    boxShadow: '0px 8px 24px #0717411F',
    backgroundColor: "#FFFFFF",
    borderRadius: 4
})

const Div = styled('div')({
    display: 'flex'
})

const CustomSelect = styled(Select)(({ theme }) => ({
    minWidth: 110,
    backgroundColor: "#F2F4F7",
    '& .MuiSelect-select': {
        paddingBlock: "4.5px",
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#F2F4F7',
        borderRadius: 4
    },
    '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F2F4F7',
        },
    }
}))


const NumberInput = ({ value, onChange }) => (
    <TextField
        value={value}
        onChange={e => onChange(e.target.value)}
        type={"number"}
        sx={{
            '& input': {
                maxWidth: 85,
                py: '4.5px'
            },
            ' & .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E4E8EE',
                borderRadius: '6px'
            }
        }}
        onKeyPress={(e) => {
            if (['e', 'E']?.includes(e.key)) {
                e.preventDefault();
            }
        }}
    />
)


export const RequiredArea = (props) => {

    const {
        label,
        value = "",
        data = null,
        onChange = null,
        disabled = false,
    } = props;

    const [anchorEl, setAnchorE1] = useState(null)
    const client = useApolloClient();
    const [enumValue, setEnumValue] = React.useState([])
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const is_workspace = ['49', '35'].includes(moduleId)

    const onChangeValue = (key, val, label) => {

        let updateValue = {
            ...data,
            [key]: val,
            mesurmentLabel: label
        }
        if (key === "measurement") {
            setAnchorE1(null)
        }
        onChange && onChange(updateValue)
    }

    const returnValue = useMemo(() => Boolean(value?.replace('-', '')?.trim()?.length),
        // eslint-disable-next-line
        [value])

    const getUomMaster = async () => {
        client.query({
            query: GETUOMMASTER(is_workspace ? [2,3,9,8]:[] ),
            fetchPolicy: 'network-only',
            variables:{
                id: is_workspace ? [2,3,9,8]:[]
            }
        }).then((res) => {
            setEnumValue(res.data.uom_master)
        }).catch((err) => {
            console.log(err)
        })
    }
    React.useEffect(() => {
        getUomMaster()
        // eslint-disable-next-line 
    }, [])

    return (
        <div>
            <Wrapper onClick={e => setAnchorE1(e.target)}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                >
                    <div style={{ flex: 1, width: '80%' }}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <BedBath width={15} height={15} />
                            {label &&
                                <CustomTypography
                                    fontFamily={SemiBold}
                                    fontSize={12}
                                    color={"#98A0AC"}
                                >
                                    {label}
                                </CustomTypography>
                            }
                        </Stack>
                        <CustomTypography
                            fontFamily={returnValue ? SemiBold : Regular}
                            fontSize={14}
                            color={returnValue ? '#091B29' : '#98A0AC'}
                            noWrap={!(returnValue)}
                            marginTop={'3px'}
                        >
                            {returnValue ? value : 'Select Area'}
                        </CustomTypography>
                    </div>
                    <Div>
                        <ArrowDropDownIcon
                            style={{
                                fontSize: "26px",
                                color: "#98A0AC",
                                transform: anchorEl ? 'rotate(180deg)' : 'rotate(0)',
                                transition: '0.25s',
                            }} />
                    </Div>
                </Stack>
            </Wrapper>
            {
                anchorEl &&
                <Popover
                    open={true}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorE1(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{
                        marginTop:"22px"
                    }}
                >
                    <Container
                        direction={"row"}
                        alignItems={"center"}
                        gap={2}
                    >
                        <NumberInput
                            value={data?.minArea}
                            placeholder={'Min Area'}
                            onChange={(value) => onChangeValue('minArea', value)}
                        />
                        <CustomTypography
                            fontFamily={Bold}
                            fontSize={12}
                            color={"#98A0AC"}
                        > - </CustomTypography>
                        <NumberInput
                            value={data?.maxArea}
                            placeholder={'Max Area'}
                            onChange={(value) => onChangeValue('maxArea', value)}
                        />
                        <CustomSelect
                            disabled={disabled}
                            value={data?.measurement}
                            displayEmpty
                            renderValue={value => value ? enumValue?.find(_ => _.value === value)?.label : <CustomTypography sx={{ lineHeight: 1.643 }} fontFamily={SemiBold} color={'#98A0AC'}>{'Sq.Ft'}</CustomTypography>}
                        >
                            {
                                enumValue?.map(({ label, value }) => (
                                    <MenuItem onClick={(e) => onChangeValue('measurement', value, label)} id={label} name={label} value={value}>{label}</MenuItem>
                                ))
                            }
                        </CustomSelect>
                    </Container>
                </Popover>
            }
        </div>
    )
}

