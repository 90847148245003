/* eslint-disable array-callback-return */
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  // Drawer,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  useHistory,
} from "react-router-dom";
import { SideNavBar } from "..";
import LogOut from "../../../assets/logout";
import MyProfile from "../../../assets/myprofile";
import { DialogBox } from "../../../components";
import { config } from "../../../config";
import {
  AlertContext,
  AuthContext,
  BackdropContext,
  FirebaseContext
} from "../../../contexts";
import { UNREADNOTIFICATION } from "../../../graphql/mutations";
import { NetworkCall } from "../../../networkcall";
import { LoginSuccess } from "../../../router/access";
import {
  AlertProps, clearAccess,
  getAccessRoutes, getCompanyOption,
  getDirectRoutePermission, LocalStorageKeys,

  NetWorkCallMethods,
  useWindowDimensions,
  timeZoneConverter,
  supporDropdown,
  SemiBold,
  LanguageOptions,
  RetainLocalStorage,
  mobileApps,
  stringAvatar,
  WebPortals
} from "../../../utils";
import { UserCard } from "../../userRole";
import { NotificationCard } from "./notificationComponent";
import { useStylesTopNavBar } from "./style";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { Notification } from "../../../assets";
import { DocumentViewer } from "../../../components/fileViewer";
import { useStyles } from "../../../screens/announsmentNew/style";
import { EmailPreview } from "../../../screens/announsmentNew/viewAnnounsment";
import MenuIcon from '@mui/icons-material/Menu';
import powered_by_logo from '../../../utils/login/assets/powered_by_logo.png';
import { BackendRoutes, Routes } from "../../../router/routes";
import { LightTooltip } from "../../../screens/salesManagerDashboard/components/style";

const roleOptions = (data) => {
  let arry = [];

  // if (data?.length > 0) {
  //   data?.map((val) => {
  //     let options = {
  //       label: val?.name,
  //       value: val?.id,
  //       ...val,
  //     };
  //     arry.push(options);
  //   });
  // }
  // let role = Object.keys(RolesData).map(v => RolesData[v]);
  // let rolelist = arry.filter((va) => role.includes(va.label)) ?? [];
  // if (rolelist?.length > 0) {
  //   rolelist = rolelist.reduce((unique, o) => {
  //     if (!unique.some(obj => obj.label === o.label && obj.value === o.value)) {
  //       unique.push(o);
  //     }
  //     return unique;
  //   }, []);
  // }

  if (data?.length > 0) {
    data?.map((val) => {
      let options = {
        label: val?.role?.name,
        value: val?.client_roles?.[0]?.id,
        role_id: val?.client_roles?.[0]?.id,
        ...val,
      };
      arry.push(options);
    });
  }
  return arry;
};

const TopNavBar = (props) => {
  const { t, handleLoading } = props;
  const version = localStorage.getItem(LocalStorageKeys.version)
  const auth = React.useContext(AuthContext);
  const alert = React.useContext(AlertContext);
  const firebaseContext = React.useContext(FirebaseContext);
  const classes = useStylesTopNavBar({ ...props, app_Env: config?.app_env.toLowerCase() });
  const classes1 = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const [roleID, setRoleID] = React.useState({
    name: localStorage.getItem(LocalStorageKeys.activeRole),
    role_id: localStorage.getItem(LocalStorageKeys.roleID),
  });
  const [top_nav_bar_state, set_top_nav_bar_state] = React.useState({
    openSideNavBar: false,
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [details, setDetails] = React.useState({
    details: "",
    created_by: ""
  });
  const toogleSideNavBar = () => {
    set_top_nav_bar_state({
      ...top_nav_bar_state,
      openSideNavBar: !top_nav_bar_state.openSideNavBar,
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState(false);
  const [value, setValue] = React.useState("All");
  const [notificationList, setNotificationList] = React.useState([]);
  const [web_portal_list, set_web_portal_list] = React.useState(WebPortals);
  const [offset, setOffset] = React.useState(0);

  const [selectedLanguage, setSelectedLanguage] = React.useState(LanguageOptions?.[0])
  const [openLanguage, setOpenLanguage] = React.useState(false)
  const [company, setCompany] = React.useState({
    companyList: [],
    selectedCompany: {},
  });
  const [pdf, setPdf] = React.useState({
    bool: false,
    data: "",
  });
  const [loading, setLoading] = React.useState(false)
  const [menuItem, setMenuItem] = React.useState(null);
  const [subMenuItem, setSubMenuItem] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loadingSideBar, setLoadingSideBar] = React.useState(true);
  const handleLoadingSideBar = (value) => { setLoadingSideBar(value) }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "All") {
      getNotification(0, false, [
        "Announcement",
        "Push-Notification",
      ], company?.companyList?.map((x) => x?.value));
    } else {
      getNotification(0, false, [newValue], company?.companyList?.map((x) => x?.value));
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const notificationopen = Boolean(notificationOpen);
  const helpopen = Boolean(helpOpen);
  // const languageopen = Boolean(languageOpen);
  //const id = open ? "simple-popover" : undefined;
  const backdrop = React.useContext(BackdropContext);

  // Go to profile page
  const profileView = () => {
    history.push(Routes.profileView);
  };

  // LANGUAGE SWITCH FUNCTIONS

  const updateLanguage = (data) => {
    setSelectedLanguage(data);
    props?.i18n.changeLanguage(data?.value);
    setOpenLanguage(false)
    window.location.reload();
  }
 
  const openLanguageSwitcher = (event) => {
    let getselectedlag = props?.i18n?.language
    setSelectedLanguage(LanguageOptions.find((val) => val.value === getselectedlag) ?? LanguageOptions[0])
    setOpenLanguage(event.currentTarget)
  }

  const switchLanguage = (t) => {
    let getselectedlag = props?.i18n?.language
    const languageDisplay = (LanguageOptions.find((val) => val.value === getselectedlag) ?? LanguageOptions[0])
    return t(languageDisplay?.label);
  }

  const changeRole = (e) => {
    handleLoading(true)
    handleCloseModule();
    handleClose();
    let path = LoginSuccess(auth?.auth?.auth?.routes);
    if (path !== "*") {
      localStorage.setItem(LocalStorageKeys.activeRole, e?.name ?? "");
      localStorage.setItem(LocalStorageKeys.activeRoleModuleId, e?.module_id ?? "");
      localStorage.setItem(LocalStorageKeys.roleID, e?.role_id);
      axios
        .get(`${config.authapi}/auth/select_role?role_id=${e.role_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LocalStorageKeys.authToken
            )}`,
          },
        })
        .then((res) => {
          localStorage.removeItem(LocalStorageKeys.selectedCompany);
          localStorage.setItem(LocalStorageKeys.authToken, res?.data?.token);
          localStorage.setItem(
            LocalStorageKeys.clinetID,
            res?.data?.selected_client?.id
          );
          let access = res?.data?.access;
          let permissionData = Object.keys(access);
          let permissionJson =
            access?.[permissionData?.[0]]?.role?.permissions?.[0]?.structure;
          clearAccess();
          let permisionlist = getAccessRoutes(permissionJson);
          let permissionRoutes = permisionlist?.map((val) => val?.route);
          localStorage.setItem(
            LocalStorageKeys.permission,
            JSON.stringify(permissionRoutes)
          );
          path = LoginSuccess(permissionRoutes);
          history.push(path);
          // auth.setAuth({
          //   ...auth,
          //   auth: {
          //     ...auth.auth.auth,
          //     activeRole: e?.name ?? "",
          //     access: access,
          //     routes: permissionRoutes,
          //     prevHistoryKey: window?.history?.state?.key
          //   },
          // });
        })
        .catch(() => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Sorry You Don't Have Access"),
          });
          RetainLocalStorage();
          history.push(Routes.login);
        });
      setTimeout(() => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }, 2000);
    } else {
      history.push(path);
    }
    // let pathroute = LoginSuccess(auth?.auth?.auth?.routes);
  };
  // handleCloseModule
  const handleCloseModule = () => {
    setDialogOpen(!dialogOpen);
  };
  const selectRoleFunction = (e) => {
    setRoleID(e);
    changeRole(e);
  };

  const selectAppFunction = (val) => {
    if (val?.link && val?.link !== "undefined") {
      window.open(val?.link, "_blank");
    }
  };

  // openNotification
  const openNotification = (event) => {
    let company = getCompanyOption(backdrop, auth, alert);
    if (company) {
      setCompany({
        ...company,
        companyList: company?.list,
        selectedCompany: company?.selected,
      });
      getNotification(0, false, ["Announcement", "Push-Notification",], company?.list?.map((x) => x?.value) ?? []);
      setNotificationOpen(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setNotificationOpen(null);
  };
  // openHelp
  const openHelp = (event) => {
    setHelpOpen(event.currentTarget);
  };
  const handleCloseHelp = () => {
    setHelpOpen(null);
  };
  //get notification
  const getNotification = (offSet, filter, type, company) => {
    if (!filter) {
      setLoading(true)
    }
    const params = {
      offset: offSet,
      limit: 10,
      is_draft: false,
      user_profile_id:
        type !== ["Push-Notification"]
          ? localStorage.getItem(LocalStorageKeys?.profileID)
          : null,
      type: type,
      company_id: company ?? [],
      to_builds: [config.app_x_build],
    };
    NetworkCall(
      `${config.api_url}/notifications/list`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setNotificationList(
            notificationList?.concat(response?.data?.data?.response)
          );
        } else {
          setNotificationList(response?.data?.data?.response);
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong."),
        });
      });
  };
  // fetchMoreData
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getNotification(
      offset,
      true,
      value === "All"
        ? ["Announcement", "Push-Notification"]
        : [value],
      company?.companyList?.map((x) => x?.value)
    );
  };

  // read nitification
  const viewNotification = (id) => {
    const variables = {
      query: UNREADNOTIFICATION,
      variables: {
        id: id,
        updatedData: {
          is_read: true,
        },
      },
    };

    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        getDetails(id)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong."),
        });
      });
  };
  // logout
  const getLogoutdeviceToken = () => {
    const params = {
      token: localStorage.getItem(LocalStorageKeys?.deviceToken),
      app_build: config.app_x_build,
    };
    NetworkCall(
      `${config.api_url}/logout`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        RetainLocalStorage();
        firebaseContext.deleteToken();
        history.push(Routes.login);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong."),
        });
      });
  };


  // edit function
  const getDetails = (id) => {
    const payload = {
      id: id ?? "",
      user_profile_id: localStorage.getItem(LocalStorageKeys.profileID),
      company_id: company?.selectedCompany?.value,
    };
    NetworkCall(
      `${config.api_url}/notifications/view`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      setDetails({
        details: res?.data?.data?.notification_details,
        created_by: res?.data?.data?.created_user

      })
      setDialog(true)
    })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong. Please try again"),
        });
      });
  };

  const openPdf = (value) => {
    setPdf({
      ...pdf,
      data: value,
      bool: !pdf?.bool,
    });
  };


  const openExternalLink = (url, type) => {
    switch (type) {
      case "email":
        // add CC and Subject use commented line 
        // window.open("mailto:email@example.com?subject=[Help]  Base Leisure&cc=email2@example.com;email3@example.com")
        window.open(`mailto:${url}`)
        break;
      case "link":
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
        break;
      default:
        break;
    }
  }
  let app_name = localStorage.getItem(LocalStorageKeys.displayName) ? JSON.parse(localStorage.getItem(LocalStorageKeys.displayName)) : ""
  const planLogo = localStorage.getItem(LocalStorageKeys.planLogo) ? JSON.parse(localStorage.getItem(LocalStorageKeys.planLogo)) : ""




  useEffect(() => {
    getClientDetails();
    // eslint-disable-next-line
  }, []);

  const getClientDetails = () => {
    NetworkCall(
      `${config.api_url}${BackendRoutes?.client_get_app_configuration}`,
      NetWorkCallMethods.get, {}, null, true, false
    ).then((response) => {
      const listing_portal = {
        src: response?.data?.data?.client_custom_url ? response?.data?.data?.client_logo : "images/switcher/realty.svg",
        name: response?.data?.data?.client_custom_url ? response?.data?.data?.client_name : "ListingGOTO",
        link: response?.data?.data?.client_custom_url ?? `${config.listing_goto}`,
      }

      set_web_portal_list([...web_portal_list, listing_portal]);
    }).catch((error) => {
      const listing_portal = {
        src: "images/switcher/realty.svg",
        name: "ListingGOTO",
        link: `${config.listing_goto}`,
      }

      set_web_portal_list([...web_portal_list, listing_portal]);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong. Please try again"),
      });
    });
  };

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container className={classes.app_bar_grid}>
          <Toolbar className={classes.toolbar}>
            <Stack className={classes.toolbar_stack} direction={"row"}
              display={"flex"} justifyContent={"space-between"}>

              <IconButton className={classes.menuIcon} onClick={toogleSideNavBar} size="large">
                <MenuIcon htmlColor="white" />
              </IconButton>
              <Stack direction={"row"} alignItems={"center"}>
                <img
                  className={classes.logo}
                  onClick={() => history.push("/")}
                  src={planLogo?.length > 0 ? planLogo : "/images/propgoto logo-01.svg"}
                  height="45px"
                  alt="logo"
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.border1}
                  style={{ margin: "24px 0px" }}
                />
                <Typography
                  className={classes.titleLogo}
                  onClick={() => history.push("/")}
                  style={{
                    fontSize: "0.875rem",
                    color: "#FFFFFF",
                    fontFamily:
                      SemiBold,
                  }}
                >
                  {app_name ?? ""}
                </Typography>
                {(config?.app_env !== undefined && config?.app_env !== "undefined") ?
                  <Typography className={classes.env_tag}>
                    {config?.app_env ?? ""}
                  </Typography> : <></>}
              </Stack>
              <Box>
                {/* For Medium and below deviceses */}
                <Hidden mdDown>
                  <div className={classes.titleContainer}>
                    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.moduleNametag}
                      >
                        &nbsp;{auth?.auth?.auth?.activeRole}&nbsp;
                      </Typography>
                      <Stack direction="row" justifyContent="center" alignItems="center"
                        divider={<Divider className={classes.divider} variant="middle" orientation="vertical" flexItem />}>
                        {getDirectRoutePermission(auth, Routes.fileManager).read &&
                          <Typography
                            className={classes.textButton}
                            onClick={() => history.push(Routes.fileManager)}>
                            {t("Files")}
                          </Typography>}
                        {getDirectRoutePermission(auth, Routes.reports).read &&

                          <Typography
                            className={classes.textButton}
                            onClick={() => history.push(Routes.reports)}>
                            {t("Reports")}
                          </Typography>}
                        <Typography
                          className={classes.textButton}
                          onClick={openHelp}>
                          {t("Support")}
                        </Typography>
                        <Typography
                          className={classes.textButton}
                          onClick={openLanguageSwitcher}
                        >
                          {switchLanguage(t)}
                        </Typography>
                      </Stack>

                      <Notification
                        onClick={openNotification} />
                      <Avatar onClick={handleClick}
                        className={classes.username}
                        src={auth?.auth?.auth?.profile_img ?? "-"}
                        alt={auth?.auth?.auth?.first_name ?? " "}
                        {...stringAvatar(auth?.auth?.auth?.first_name, null, "50%")}
                      />
                    </Stack>
                  </div>
                </Hidden>

                {/* For Medium and above Device  */}
                <Hidden mdUp>
                  <div className={classes.titleContainer}>
                    <Stack direction="row" spacing={2}>
                      <div className={classes.user}>
                        <Avatar
                          src={auth?.auth?.auth?.profile_img ?? "-"}
                          alt="user"
                          {...stringAvatar(auth?.auth?.auth?.first_name, null, "50%")}
                        />

                        <IconButton
                          size="small"
                          className={classes.arrow}
                          onClick={handleClick}
                        >
                          <KeyboardArrowDownOutlined
                            style={{ color: "#ffffff" }}
                            id="fade-button"
                            aria-controls={open ? "fade-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                          />{" "}
                        </IconButton>
                      </div>
                    </Stack>
                  </div>
                </Hidden>
              </Box>
            </Stack>
          </Toolbar>
        </Grid>
      </AppBar>

      {/* profile switcher */}
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Grid container width="324px">
          <Grid item xs={12} className={classes.switcher}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.borders}
            >
              <Box>
                {auth?.auth?.auth?.profile_img ? (
                  <img
                    src={auth?.auth?.auth?.profile_img}
                    className={classes.userimg}
                    alt="user"
                  />
                ) : (
                  <Avatar className={classes.userimg} {...stringAvatar(auth?.auth?.auth?.first_name, null, "50%")} />
                )}
              </Box>
              <Box flexGrow={1} sx={{ marginInlineStart: "12px" }}>
                <Typography
                  noWrap
                  variant="body2"
                  className={classes.username1}
                >
                  {auth?.auth?.auth?.first_name ?? " "}&nbsp;
                  {auth?.auth?.auth?.last_name ?? " "}
                </Typography>
                <Typography
                  noWrap
                  variant="body2"
                  className={classes.email}
                >
                  {auth?.auth?.auth?.email_id ?? " "}
                </Typography>
                <Typography
                  noWrap
                  variant="body2"
                  className={classes.tag}
                >
                  &nbsp;{auth?.auth?.auth?.activeRole}&nbsp;
                </Typography>
              </Box>
            </Box>
            {/* profile switch card*/}
            <Box >
              <Button
                className={classes.switchBtn}
                onClick={handleCloseModule}
              >
                {t("Switch_Workspace")}
              </Button>
              <Stack direction={"row"} spacing={"4px"} marginTop={"12px"}
                justifyContent={"space-between"} alignItems={"center"}>
                <Typography className={classes.lostLogin}>
                  {t("Last_login")}:{" "}
                  {timeZoneConverter(
                    new Date(auth?.auth?.auth?.last_logged_in)
                  )
                  }
                </Typography>
                {version && <Typography className={classes.lostLogin}>
                  {"v-" + version}
                </Typography>}
              </Stack>
            </Box>
            <Box height={"10px"} />
          </Grid>
          <Grid item xs={12} className={classes.profileCard}>
            <Grid container spacing={1}>
              {getDirectRoutePermission(auth, Routes.profileView).read && <Grid
                item
                xs={12}
                onClick={profileView}
                style={{ cursor: "pointer" }}>
                <Box display="flex" alignItems="center">
                  <MyProfile />
                  <Typography className={classes.label}>
                    {t("My_Profile")}
                  </Typography>
                </Box>
              </Grid>}
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <LogOut />
                  <Typography
                    className={classes.label2}
                    onClick={getLogoutdeviceToken}
                  >
                    {t("Sign_Out")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box height={"10px"} display={"flex"} justifyContent={"end"}>
                  <img style={{ objectFit: "contain" }} src={powered_by_logo} alt="" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
      {/* noticfication */}
      <Menu
        className={classes.menu}
        anchorEl={notificationOpen}
        open={notificationopen}
        onClose={handleCloseNotification}
        PaperProps={{ sx: { width: '435px', marginTop: "5px" } }}
      >
        <TabContext value={value} p={0}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className={classes.tabs}
            >
              <Tab label={t("All")} value="All" />
            </TabList>
          </Box>
          <InfiniteScroll
            dataLength={notificationList?.length}
            next={fetchMoreData}
            hasMore={true}
            height={size?.height - 100}
          >
            {loading ? (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            ) : (
              <div className={classes.notificationBox}>
                {notificationList?.length > 0 ? notificationList?.map((item) => {
                  return (
                    <NotificationCard
                      data={item}
                      onCardClick={viewNotification}
                    />
                  );
                })
                  :
                  <Box mt={4}>
                    <Typography textAlign={"center"} className={classes.nodataFound}>{t("No data Found")}</Typography>
                  </Box>
                }

              </div>
            )}
          </InfiniteScroll>
        </TabContext>
      </Menu>
      {/* help */}
      <Menu
        className={classes.menu2}
        anchorEl={helpOpen}
        open={helpopen}
        onClose={handleCloseHelp}
      >
        {
          supporDropdown?.map((e) => {
            return (
              <MenuItem>
                <Typography className={classes.helpText} onClick={() => openExternalLink(e?.link, e?.type)}>
                  {e?.name}
                </Typography>
              </MenuItem>
            )
          })
        }
      </Menu>
      {/* language */}
      <Menu
        className={classes.menu2}
        anchorEl={openLanguage}
        open={openLanguage}
        onClose={() => setOpenLanguage(false)}
      >
        {
          LanguageOptions?.map((item) => {
            return (
              <MenuItem onClick={() => updateLanguage(item)}>
                <Stack direction={"row"}>
                  <Typography sx={{ marginInlineEnd: "16px" }} className={classes.helpText}>
                    {t(item?.label)}
                  </Typography>
                  {
                    (auth?.auth?.auth?.language === item?.value || selectedLanguage?.value === item?.value) &&

                    <img src="/images/ticknew.svg" alt="tick_img" />
                  }
                </Stack >
              </MenuItem >
            )
          })
        }
      </Menu >
      {/* client select dialog */}
      < DialogBox
        header={t("Switch_Workspace")}
        onClose={handleCloseModule}
        open={dialogOpen}
        background
        // maxWidth={"md"}
        component={
          < Box style={{ minHeight: "50vh", padding: "16px" }}>
            <Typography className={classes.text}>{t("Modules")}</Typography>
            <Grid container spacing={1} p={1}>
              {roleOptions(
                auth.modules
                ?? []
              )?.map((val) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <UserCard
                      t={t}
                      val={val}
                      active={
                        roleID?.role_id === val?.role_id ? true : false
                      }
                      onClick={selectRoleFunction}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Box height={"18px"} />
            <Divider className={classes.border} />
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} columnGap={2} pt={"8px"}>
              <Typography className={classes.text}>{t("Mobile and Web Apps")}</Typography>
              <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} columnGap={2}>
                {/* <LightTooltip placement="top" title={t("Install Gateway")}>
                  <img src="/images/install_as_pwa.png" alt="Install as PWA" height={"32px"} />
                </LightTooltip> */}
                <LightTooltip placement="top" title={t("Google Play")}>
                  <Box sx={{ cursor: "pointer", alignItems: "center", display: "flex" }} onClick={() => window.open(config?.playstore, '_blank')}>
                    <img src="/images/google_play.png" alt="Google Play" height={"32px"} />
                  </Box>
                </LightTooltip>
                <LightTooltip placement="top" title={t("App Store")}>
                  <Box sx={{ cursor: "pointer", alignItems: "center", display: "flex" }} onClick={() => window.open(config?.appstore, '_blank')}>
                    <img src="/images/app_store.png" alt="App Store" height={"32px"} />
                  </Box>
                </LightTooltip>
              </Stack>
            </Stack>
            <Grid container spacing={1} p={1}>
              {mobileApps?.map((val) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <UserCard
                      t={t}
                      val={val}
                      onClick={() => selectAppFunction(val)}
                      mobApp
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Box height={"18px"} />
            <Divider className={classes.border} />
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} columnGap={2}>
              <Typography className={classes.text}>{t("Web Portals")}</Typography>
            </Stack>
            <Grid container spacing={1} p={1}>
              {web_portal_list?.map((val) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <UserCard
                      t={t}
                      val={val}
                      onClick={() => selectAppFunction(val)}
                      mobApp
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box >
        }
      />
      {top_nav_bar_state.openSideNavBar &&
        <>
          <SideNavBar
            isMobile={top_nav_bar_state.openSideNavBar}
            menuItem={menuItem}
            setMenuItem={setMenuItem}
            subMenuItem={subMenuItem}
            setSubMenuItem={setSubMenuItem}
            handleLoading={handleLoadingSideBar}
          />
        </>
      }
      <DialogBox
        id={"id_123"}
        open={dialog}
        onClose={() => setDialog(false)}
        handleClose={() => setDialog(false)}
        header={t("Announcement")}
        isNormal
        width={"710px !important"}
        maxWidth={"sm"}
        component={
          <Box>
            <EmailPreview details={details?.details} user={details?.created_by} classes={classes1} is_show openPdf={openPdf} />
          </Box>
        }
      />
      <DialogBox
        id={"id_123"}
        open={pdf?.bool}
        onClose={() => openPdf(false)}
        handleClose={() => openPdf(false)}
        header={pdf?.data?.name ?? ""}
        isNormal
        // width={"710px !important"}
        maxWidth={"sm"}
        component={
          <Box>
            <DocumentViewer url={pdf?.data?.url ?? ""} />
          </Box>
        }
      />
    </div >
  );
};
export default withNamespaces("profileswitcher")(TopNavBar);
