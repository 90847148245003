import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions, ValidateEmail } from "../../../utils";
import { AccountForm } from "./accountForm";
import { AccountList } from "./accountList";
import CreateForm from "./createContactForm";
import { LeadUnitDetails } from "./leadUnitDetails";
import { componantsStyles } from "./styles";
import { withNamespaces } from "react-i18next";
const initialState = {
    name: "",
    url: "",
    accountNo: "",
    mobile: "",
    relationShip: {
        label: "Customer",
        value: "Customer"
    },
    currency: "",
    tax: "",
    email: "",
    error: {
        name: "",
        email: "",
        accountNo: "",
        mobile: "",
        relationShip: "",
        currency: "",
        tax: "",
    }
}
const CreateAgreementContact = ({t, onClose = () => false, setSelectedAcc = () => false, quotation_id = "", selectedAcc = {}, company_id = "", contactId = "", disable = false, onSubmit = () => false, data = {}, total = 0, contact = {}, updateState = () => false }) => {
    const classes = componantsStyles()
    const size = useWindowDimensions()
    const history = useHistory()
    const [open, setOpen] = React.useState(false)
    const [acc, setAcc] = React.useState({ ...initialState })
    const alert = React.useContext(AlertContext);
    const [list, setList] = React.useState([])
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const is_workspace = ['49', '35'].includes(moduleId)
    const [disableAcc, setDisableAcc] = React.useState(false)
    //get account list by contact id
    const getAccountList = (search = "") => {
        const payload = {
            contact_id: data?.quott?.contact_id,
            tenantId: `${config.tenantId}`,
            search: search
        };
        NetworkCall(
            `${config.api_url}/account-statement/lead-accounts`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setList(main)
                if (main?.length > 0) {
                    if (selectedAcc?.length === 0) {
                        setSelectedAcc(main[0])
                    }
                }
            })
            .catch((error) => {

            });
    }
    //initial load
    React.useEffect(() => {
        getAccountList()
        setAcc({
            ...acc, currency: {
                value: data?.quott?.currency_id,
                label: data?.quott?.symbol
            },
            tax: {
                value: data?.quott?.vat_group_id,
                label: data?.quott?.group_name
            }
        })
        // eslint-disable-next-line
    }, [])
    //update state of Account
    const updateStateAcc = (key, value) => {
        let error = acc.error;
        error[key] = "";
        setAcc({ ...acc, [key]: value, error });
    };
    //validate account form
    const validateAccountForm = () => {
        let isValid = true;
        let error = acc.error;
        //validate first name
        if (acc?.name?.length === 0) {
            isValid = false;
            error.name = "Account Name is Required";
        }

        //validate email
        if (acc?.email?.length === 0) {
            isValid = false;
            error.email = "Email ID is Required";
        }
        //validate email
        if (acc?.email?.length > 0) {
            if (ValidateEmail(acc?.email) === false) {
                isValid = false;
                error.email = "Invalide Email ID";
            }
        }

        //validate Mobile Number
        if (acc?.mobile?.length === 0) {
            isValid = false;
            error.mobile = "Mobile Number is Required";
        }

        setAcc({ ...acc, error });
        return isValid;
    }
    //on submit create account
    const onSubmitAccount = () => {
        if (validateAccountForm()) {
            setDisableAcc(true)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Account Successfully Created",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
            const formData = new FormData();
            for (let i = 0; i < acc?.url?.length; i++) {
                formData.append("files", acc?.url[i]);
            }
            formData.append("tenantId", `${config.tenantId}`);
            formData.append("name", `${acc?.name}`);
            formData.append("email_id", `${acc?.email}`);
            formData.append("mobile_no", `${acc?.mobile?.mobile}`);
            formData.append("mobile_no_country_code", `${acc?.mobile?.mobile_code}`);
            formData.append("currency_id", `${acc?.currency?.value}`);
            formData.append("vat_group_id", `${acc?.tax?.value}`);
            formData.append("contact_id", `${contactId}`);
            formData.append("company_id", `${company_id}`);
            NetworkCall(
                `${config.api_url}/account/create`,
                NetWorkCallMethods.post,
                formData,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: "Account Successfully Created",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                    setDisableAcc(false)
                    setOpen(false)
                    setAcc(initialState)
                    getAccountList()
                })
                .catch((error) => {
                    setDisableAcc(false)
                });
        } else {
            return false
        }
    }
    //on create Agreement
    const createAgreemment = () => {
        if (selectedAcc?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.warning,
                msg: "Please Select Account If You Don't Have Account Please Create",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        } else {
            setDisableAcc(true)
            const data = {
                tenantId: `${config.tenantId}`,
                account_id: selectedAcc?.account_no,
                quotationId: quotation_id,
                is_workspace:is_workspace?true:false
            }
            NetworkCall(
                `${config.api_url}/agreement/create`,
                NetWorkCallMethods.post,
                data,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: "Agreement Successfully Created",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                    setDisableAcc(false)
                    history.push(Routes.aggreements)
                })
                .catch((error) => {
                    setDisableAcc(false)

                });
        }
    }
    return (
        <div>
            {/*dialog header */}
            <Box display="flex" alignItems="center" p={1} className={classes.titleroot}>
                <Box flexGrow={1}>
                    <Typography className={classes.titleText}>{t("Convert To Agreement")}</Typography>
                </Box>
                <Box>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box height={size?.height - 300} overflow="auto">

                <Box p={3}>

                    {
                        data?.quott?.is_contact &&
                        <center>
                            <img src="/images/createAgree.svg" alt="" />
                        </center>
                    }

                    <Box height="20px" />
                    {/*lead and unit details */}
                    <Typography className={classes.title}>AGREEMENT DETAILS</Typography>
                    <LeadUnitDetails data={{
                        name: `${data?.quott?.first_name} ${data?.quott?.last_name ?? ""}`,
                        img: null,
                        phone: `${data?.quott?.mobile_no_country_code} ${data?.quott?.mobile_no}`,
                        mail: data?.quott?.email_id,
                    }}
                        quott={{
                            unit: data?.units?.length,
                            startDate: data?.quott?.start_date,
                            endDate: data?.quott?.end_date,
                            symbol: data?.quott?.symbol
                        }}
                        total={total}
                    />
                    {/*Account section*/}
                    {
                        data?.quott?.is_contact &&
                        <>
                            <Box height="20px" />
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    <Typography className={classes.title}>ACCOUNT DETAILS</Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        onClick={() => setOpen(true)}
                                        className={classes.title}
                                        style={{ color: "#5078E1", cursor: "pointer" }}
                                    >Create New Account</Typography>
                                </Box>
                            </Box>
                            {/*Account List*/}
                            {
                                list?.length > 0 || selectedAcc?.length !== 0 ?
                                    <AccountList getAccountList={getAccountList} value={selectedAcc} onChange={(value) => setSelectedAcc(value)} data={list} />
                                    : ""}

                        </>
                    }


                </Box>
                {/*create contact form*/}
                {
                    !data?.quott?.is_contact && <CreateForm is_contact={data?.quott?.is_contact} updateState={updateState} contact={contact} />
                }

            </Box>
            <Box p={3}>
                {/*create button section*/}
                {
                    data?.quott?.is_contact ?
                        <Button disabled={disableAcc} fullWidth className={classes.btn} onClick={createAgreemment} variant="contained">Create Agreement</Button>
                        :
                        <Button disabled={disable} fullWidth className={classes.btn} onClick={onSubmit} variant="contained">Create Contact</Button>
                }
            </Box>
            {/*Account Create Form*/}
            <Dialog className={classes.dialog} open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
                <AccountForm t={t} disableAcc={disableAcc} onSubmitAccount={onSubmitAccount} updateStateAcc={updateStateAcc} account={acc} onClose={() => setOpen(false)} />
            </Dialog>
        </div>
    )
}
export default withNamespaces("quotationsList")(CreateAgreementContact)
