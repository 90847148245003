import Grid from '@mui/material/Grid';
import React, { useMemo, useState } from 'react';
import { Location } from '../../../components';
import { GET_UNIT_CATEGORY_LEASE } from "../../../graphql/propertySearch";
import { GETLEADBYOWNERDROPDOWN } from "../../../graphql/quotationQueries";
import { LocalStorageKeys } from '../../../utils';
import { loadOptions as loadOptionsQuery } from "../../../utils/asyncPaginateLoadOptions";
import { CustomAsyncSelect, CustomRangePicker, LeadSelect, LocationTextField, RequiredArea } from '../components';
import { Purpose, SearchIcon, UnitCategory } from '../components/svg';
import { loadOptions, measurementUnits, returnMomentFormat } from '../utils';


export const ShortTermRental = (props) => {

    const {
        data = {},
        onChangeState,
        location,
        handleLead,
        selectedLead
    } = props;

    const [loading, setLoading] = useState(null);

    const handleLoadOptions = (search, array, type) => {
        setLoading(type)
        return loadOptions(search, array, type, { revenue: 'Commercial' }, setLoading, props?.company)
    }
    const returnRequiredArea = useMemo(() => {
        const { maxArea = "", minArea = "", mesurmentLabel = "" } = data?.requiredArea ?? {}
        return minArea && maxArea ? `${minArea} - ${maxArea} ${mesurmentLabel}` : ""
    }, [data?.requiredArea])

    const returnCheckedInOut = useMemo(() => {
        const { startDate = "", endDate = "" } = data?.checkInOut ?? {}
        return `${startDate ? returnMomentFormat(startDate) : ''} - ${endDate ? returnMomentFormat(endDate) : ''}`
    }, [data?.checkInOut])

    const manualResponse = (array) => array?.map(_ => {
        return {
            ..._,
            label: _?.contact?.first_name,
            value: _?.contact?.id,
        }
    });
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <LeadSelect
                        label={"Search"}
                        icon={<SearchIcon width={12} height={12} />}
                        value={selectedLead ?? null}
                        onChange={(value) => handleLead(value)}
                        isPaginate
                        loadOptions={(search, array, handleLoading) => loadOptionsQuery(
                            search,
                            array,
                            handleLoading,
                            GETLEADBYOWNERDROPDOWN,
                            'lead',
                            { company: props?.company, client: localStorage.getItem(LocalStorageKeys.clinetID) },
                            {},
                            {},
                            manualResponse
                        )}
                        debounceTimeout={800}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Location
                        value={location}
                        handleChange={(value) => onChangeState('location', value)}
                        textFieldComponent={(ref, value, handleChange) => (
                            <LocationTextField
                                label={"Location"}
                                inputRef={ref}
                                handleChange={handleChange}
                                value={value}
                                placeholder={'Select City'}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomRangePicker
                        label={"Check In & Out"}
                        data={returnCheckedInOut}
                        value={data?.checkInOut}
                        onChange={value => onChangeState('checkInOut', value)}
                        startDateTitle={"Check In"}
                        endDateTitle={"Check Out"}
                        placeholder={"Enter Check In & Out"}
                    />
                </Grid>
                <Grid item xs={4} md={4}>
                    <CustomAsyncSelect
                        placeholder={"Select Type"}
                        value={data?.propertyType ?? null}
                        onChange={value => onChangeState('propertyType', value)}
                        label={"Property Type"}
                        icon={<Purpose width={15} height={15} />}
                        loading={loading === "property_group_master"}
                        isPaginate
                        debounceTimeout={800}
                        loadOptions={(search, array) => handleLoadOptions(search, array, 'property_group_master')}
                    />
                </Grid>
                <Grid item xs={4} md={4}>
                    <CustomAsyncSelect
                        placeholder={"Select Category"}
                        value={data?.unitCategory ?? null}
                        onChange={value => onChangeState('unitCategory', value)}
                        label={"Unit Category"}
                        icon={<UnitCategory width={15} height={15} />}
                        loadOptions={(search, array, handleLoading) => loadOptionsQuery(
                            search,
                            array,
                            handleLoading,
                            GET_UNIT_CATEGORY_LEASE,
                            'unit_category_master',
                            { all: true }
                        )}
                        isPaginate
                    />
                </Grid>
                <Grid item xs={4}>
                    <RequiredArea
                        // disabled={true}
                        label={"Required Area"}
                        data={data?.requiredArea}
                        onChange={value => onChangeState('requiredArea', value)}
                        options={measurementUnits}
                        value={returnRequiredArea}
                    />
                </Grid>
            </Grid>
        </div >
    )
}