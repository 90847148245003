import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import "../../../App.css";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { propertyStyles } from "./style";
import ImageViewer from "react-simple-image-viewer";
import { returnValue } from "../../companyCreation/utils";
// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';

import { LocalStorageKeys, concat_string, convertTimeUtcToZoneCalander, rich_text_replacer_condition, stringAvatar, textReplacer, timeZoneConverter } from '../../../utils';


export const PropertyDetailsViewing = (props) => {
      const localStorageCompany = localStorage?.getItem?.(LocalStorageKeys?.selectedCompany);
      const currency_code = localStorageCompany ? JSON?.parse?.(localStorageCompany)?.code : "";
      const { t } = (props);
      const [isSeeMore, setIsSeeMore] = React.useState(true)
      const classes = propertyStyles({ ...props, isSeeMore })
      const size = useWindowDimensions()
      const seeMoreDivRef = React.useRef(null);
      const [seeMoreHeight, setSeeMoreHeight] = React.useState(null)
      const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            era: 'short',
            calendar: 'islamic-umalqura' // Specify the Hijri calendar
      };

      const hijriFormatter = new Intl.DateTimeFormat('ar-SA-u-ca-islamic-umalqura', options);
      // const { data } = props;
      // const { address } = data ?? {};


      // const GoogleMap = React.useCallback(() => {
      //       return (
      //             <MyGoogleMap
      //                   lat={address?.latitude}
      //                   lng={address?.longitude}
      //                   center={{
      //                         lat: address?.latitude,
      //                         lng: address?.longitude
      //                   }}
      //                   zoom={13}
      //                   readonly

      //             />
      //       )
      // }, [address])
      React.useEffect(() => {
            setSeeMoreHeight(seeMoreDivRef?.current?.clientHeight ?? 0)
      }, [seeMoreDivRef?.current?.clientHeight, props]);

      return (

            <div style={{ height: size?.height - 220, padding: "4px", margin: "-4px" }}>
                  {/*property detials first card */}
                  <Grid container className={classes.card} style={{ padding: "16px 16px 16px 0px", }}>
                        <Grid item xs={12} sm={12} md={2} lg={2} >
                              <div className={classes.imgDiv}>
                                    <img src={props?.data?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                                          onClick={() => props?.openImageViewer([props?.data?.logo])}

                                    />
                                    <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.property_no}&nbsp;&nbsp;</Typography>
                              </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2}>
                              <Typography className={classes.title}>{t("Property Details")}</Typography>
                              <Grid container spacing={2}>
                                    <Grid item xs={2} md={3} lg={3}>
                                          <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                          <Typography className={classes.sub}>{props?.data?.company?.name ? props?.data?.company?.name : " - "}</Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                          <Typography className={classes.heading}>{t("Name")}</Typography>
                                          <Typography className={classes.sub}>
                                                {props?.data?.name ? props?.data?.name : " - "}
                                          </Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                          <Typography className={classes.heading}>{t("Type")}</Typography>
                                          <Typography className={classes.sub}>
                                                {props?.data?.property_groupByID?.group_name ? props?.data?.property_groupByID?.group_name : "- "}
                                          </Typography> </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                          <Typography className={classes.heading}>{t("Purpose")}</Typography>
                                          <Typography className={classes.sub}>
                                                {props?.data?.property_purpose ? props?.data?.property_purpose : "-"}
                                          </Typography></Grid>
                                    <Grid item xs={2} md={2} lg={2}>

                                          <Typography className={classes.heading}> {t("Payment Period")}</Typography>
                                          <Typography className={classes.sub}>
                                                {props?.data?.payment_period ? props?.data?.payment_period : "-"}
                                          </Typography> </Grid>
                                    <Grid item xs={2} md={1} lg={1}>
                                          <Typography className={classes.heading}>{t("Status")}</Typography>
                                          <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In active"}</Typography> </Grid>
                                    <Grid item xs={2} md={1} lg={1}>
                                          <Typography className={classes.heading}>{t("Source")}</Typography>
                                          <Typography className={classes.sub}>{props?.data?.is_external ? t("External") : t("Internal")}</Typography> </Grid>

                              </Grid>
                              {props?.data?.description &&
                                    <>
                                          <Grid container marginTop="14px">
                                                <Grid item>
                                                      <Typography className={classes.heading}>{t("Property Description")}</Typography>
                                                </Grid>
                                          </Grid>
                                          <Grid ref={seeMoreDivRef} container className={classes.descriptionDiv}>
                                                <Grid item>
                                                      <Typography
                                                            dangerouslySetInnerHTML={{
                                                                  __html: returnValue(textReplacer({
                                                                        text: props?.data?.description,
                                                                        condition: rich_text_replacer_condition,
                                                                  }), "-")
                                                            }} />
                                                </Grid>
                                          </Grid>
                                          {seeMoreHeight > 199 &&
                                                <div
                                                      className={classes.seeMore}
                                                      onClick={() => setIsSeeMore(!isSeeMore)}>
                                                      {isSeeMore ? "See More" : "See Less"}
                                                </div>
                                          }
                                    </>
                              }

                              {/* <Box marginTop="14px">
                                    {props?.data?.description &&
                                          <TitleDesc
                                                title={"Property Description"}
                                                dangerouslySetInnerHTML={returnValue(props?.data?.description, "-")}
                                          />
                                    }
                              </Box> */}
                        </Grid>
                        <Grid item md={0} lg={4.5} />
                  </Grid>
                  <br />
                  {/*property detials second card */}
                  <Grid container className={classes.card}>
                        <Grid item md={12} >
                              <Grid container>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Hierarchy")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.property_hierarchyByID ? props?.data?.property_hierarchyByID?.label : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>

                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Revenue Type")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.revenue_type ? props?.data?.revenue_type : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    {/* <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Public Listing</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.is_listing ? "Yes" : "No"}
                                                </Typography>
                                          </Box>
                                    </Grid> */}
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Unit of Measure")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.uomByID ? props?.data?.uomByID?.label : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Total Area")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.total_area ?? "-"}
                                                      <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.total_area &&
                                                            (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Set Back Area")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.set_back_area ?? "-"}
                                                      <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.set_back_area &&
                                                            (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Year Built")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.year_built ? convertTimeUtcToZoneCalander(props?.data?.year_built) : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Handover Date")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.planned_hand_over_date ? convertTimeUtcToZoneCalander(props?.data?.planned_hand_over_date) : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Swimming Pools")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.swimming_pools ? props?.data?.swimming_pools : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Elevators")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.elevators ? props?.data?.elevators : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>


                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Pets Policy")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.pet_policy ?? "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    {/* As per CR commented */}
                                    {/* <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}> Discussion Group</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.discussion_forum ? "Enabled" : "Disabled"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}> Amenities Booking</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.amenities_booking ? "Enabled" : "Disabled"}
                                                </Typography>
                                          </Box>
                                    </Grid> */}
                                    {/* <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Asset Functional Location</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.is_asset_functional_location ? "Yes" : "No"}
                                                </Typography>
                                          </Box>
                                    </Grid> */}
                                    {/* <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Asset Property</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.is_asset_property ? "Yes" : "No"}
                                                </Typography>
                                          </Box>
                                    </Grid> */}
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Community")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.is_community ? "Yes" : "No"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Title Deed")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.title_deed ?? "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Title Deed Date")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.deed_date ? convertTimeUtcToZoneCalander(props?.data?.deed_date) : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Hijri Calendar Date")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.hijri_date ? hijriFormatter.format(new Date(props?.data?.hijri_date)) : "-"}                                                      
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Documented Land Area")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.land_area ?? "-"}
                                                      <span className={classes.matric} style={{ marginInlineStart: "4px"}}>{props?.data?.land_area &&
                                                            (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Market Price")}</Typography>
                                                <Typography className={classes.sub}>
                                                      <span className={classes.matric} style={{ marginInlineEnd: "4px"}}>{props?.data?.market_price &&
                                                            (currency_code ?? "")}</span>
                                                      {props?.data?.market_price ?? "-"}</Typography>
                                          </Box>
                                    </Grid>
                              </Grid>
                        </Grid>
                  </Grid>
                  <br />
                  {/*property detials third card */}
                  <div className={classes.card}>
                        <Grid container spacing={2}>
                              {/* <Grid item xs={5}>
                                    <div className={classes.map}>
                                          <GoogleMap />
                                    </div>
                              </Grid> */}
                              {/* when map component is needed plz use below commented grid item */}

                              {/* <Grid item xs={3}> */}
                              <Grid item xs={6}>
                                    <div className={classes.addressRoot} style={{height: "100%"}}>
                                          <Stack direction="row" >
                                                <img src='/images/loc.svg' alt='' />
                                                <Typography className={classes.title}>&nbsp;{t("Address")}</Typography>
                                          </Stack>
                                          <Box height="12px" />
                                          <Typography className={classes.sub}>
                                                {props?.data?.address?.street_1 + "," ?? ""}
                                                {props?.data?.address?.street_2 + "," ?? ""}
                                                {props?.data?.address?.landmark + "," ?? ""}
                                                {/* {props?.data?.address?.street_3 + "," ?? ""} */}
                                                {props?.data?.address?.district + "," ?? ""}
                                                {props?.data?.address?.country + "," ?? ""}
                                                {props?.data?.address?.zipcode ?? ""}
                                          </Typography>
                                          <Box height="16px" />
                                          <Stack direction="row" >
                                                <Typography className={classes.heading}>{t("Latitude")} : </Typography>
                                                <Typography className={classes.sub1} >&nbsp;{props?.data?.address?.latitude}</Typography>
                                          </Stack>
                                          <Box height="12px" />
                                          <Stack direction="row" >
                                                <Typography className={classes.heading}>{t("Longitude")} : </Typography>
                                                <Typography className={classes.sub1} >&nbsp;{props?.data?.address?.longitude}</Typography>
                                          </Stack>
                                          <Box height="12px" />
                                          <Stack direction="row" >
                                                <Typography className={classes.heading}>{t("Smart Geo Address")} : </Typography>
                                                <Typography className={classes.sub1} >&nbsp;{props?.data?.address?.smart_geo_address}</Typography>
                                          </Stack>
                                    </div>
                              </Grid>
                              {/* when map component is needed plz use below commented grid item */}
                              {/* <Grid item xs={4}> */}
                              <Grid item xs={6}>
                                    <div className={classes.addressRoot} style={{height: "100%"}}>
                                          <Stack direction="row" >
                                                <img src='/images/Group 7015.svg' alt='' />
                                                <Typography className={classes.title}>&nbsp;{t("Contact & Other Information")}</Typography>
                                          </Stack>
                                          <Grid container>
                                                <Grid item xs={6}>
                                                      <Box marginTop="18px">
                                                            <Typography className={classes.heading}>{t("Business Phone")} :</Typography>
                                                            <Typography className={classes.sub}>
                                                                  {props?.data?.business_phone_country_code + " " + props?.data?.business_phone}
                                                            </Typography>
                                                      </Box>
                                                      <Box marginTop="12px">
                                                            <Typography className={classes.heading}>{t("Website")} :</Typography>
                                                            <Typography className={classes.sub} noWrap>{props?.data?.website}</Typography>
                                                      </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                      <Box marginTop="18px">
                                                            <Typography className={classes.heading}>{t("Mobile Phone")} :</Typography>
                                                            <Typography className={classes.sub}>
                                                                  {props?.data?.mobile_country_code + " " + props?.data?.mobile_phone}
                                                            </Typography>
                                                      </Box>
                                                      <Box marginTop="12px">
                                                            <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                                                            <Typography className={classes.sub} noWrap>{props?.data?.email}</Typography>
                                                      </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                      <Box marginTop="12px">
                                                            <Typography className={classes.heading}>{t("Alerts Email")} :</Typography>
                                                            <Typography className={classes.sub} noWrap>{props?.data?.communication_email ?? "-"}</Typography>
                                                      </Box>
                                                </Grid>
                                          </Grid>
                                    </div>
                              </Grid>
                        </Grid>
                  </div>
                  <br />
                  {props?.data?.vendorByID?.id &&
                        <>
                              <Grid container className={classes.card} style={{ padding: "16px 16px 16px 0px", }}>
                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                          <div className={classes.imgDiv}>
                                                <Avatar src={props?.data?.vendorByID?.image_url ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                                                      onClick={() => props?.openImageViewer([props?.data?.vendorByID?.image_url])}
                                                />
                                          </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2}>
                                          <Typography className={classes.title}>{t("Developer Information")}</Typography>
                                          <Grid container spacing={2}>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Vendor ID")}</Typography>
                                                      <Typography className={classes.sub}>{props?.data?.vendorByID?.vendor_no ? props?.data?.vendorByID?.vendor_no : " - "}</Typography>
                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Developer Name")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {concat_string(
                                                                  {
                                                                        name: props?.data?.vendorByID?.name,
                                                                        code: props?.data?.vendorByID?.vendor_code,
                                                                  },
                                                                  ["name", "code"],
                                                                  " - ",
                                                                  "-"
                                                            )}</Typography>
                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Mobile No")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {concat_string(
                                                                  {
                                                                        mobile_code: props?.data?.vendorByID?.vendor_address?.[0]?.mobile_code,
                                                                        mobile_no: props?.data?.vendorByID?.vendor_address?.[0]?.mobile_no,
                                                                  },
                                                                  ["mobile_code", "mobile_no"],
                                                                  " - ",
                                                                  "-"
                                                            )}
                                                      </Typography></Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Email")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.vendorByID?.vendor_address?.[0]?.email_id ? props?.data?.vendorByID?.vendor_address?.[0]?.email_id : "-"}
                                                      </Typography></Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Website")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.vendorByID?.vendor_address?.[0]?.website ? props?.data?.vendorByID?.vendor_address?.[0]?.website : "-"}
                                                      </Typography></Grid>
                                          </Grid>
                                    </Grid>
                              </Grid>
                              <br />
                        </>}
                  {props?.data?.registration_officeByID?.id &&
                        <>
                              <Grid container className={classes.card} alignItems={"center"} style={{ padding: "16px 16px 16px 0px", }}>
                                    <Grid item xs={12} sm={12} md={2} lg={2} >
                                          <div className={classes.imgDiv}>
                                                <Avatar src={props?.data?.registration_officeByID?.image_url ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                                                      onClick={() => props?.openImageViewer([props?.data?.registration_officeByID?.image_url])}
                                                />
                                          </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2}>
                                          <Typography className={classes.title}>{t("Registration Office")}</Typography>
                                          <Grid container spacing={2}>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Vendor ID")}</Typography>
                                                      <Typography className={classes.sub}>{props?.data?.registration_officeByID?.vendor_no ? props?.data?.registration_officeByID?.vendor_no : " - "}</Typography>
                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Office Name")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {concat_string(
                                                                  {
                                                                        name: props?.data?.registration_officeByID?.name,
                                                                        code: props?.data?.registration_officeByID?.vendor_code,
                                                                  },
                                                                  ["name", "code"],
                                                                  " - ",
                                                                  "-"
                                                            )}</Typography>
                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Mobile No")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {concat_string(
                                                                  {
                                                                        mobile_code: props?.data?.registration_officeByID?.vendor_address?.[0]?.mobile_code,
                                                                        mobile_no: props?.data?.registration_officeByID?.vendor_address?.[0]?.mobile_no,
                                                                  },
                                                                  ["mobile_code", "mobile_no"],
                                                                  " - ",
                                                                  "-"
                                                            )}
                                                      </Typography></Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Email")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.registration_officeByID?.vendor_address?.[0]?.email_id ? props?.data?.registration_officeByID?.vendor_address?.[0]?.email_id : "-"}
                                                      </Typography></Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Website")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.registration_officeByID?.vendor_address?.[0]?.website ? props?.data?.registration_officeByID?.vendor_address?.[0]?.website : "-"}
                                                      </Typography></Grid>
                                                <Grid item xs={12} md={9} lg={9}>
                                                      <Typography className={classes.heading}>{t("Address")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {concat_string(
                                                                  {
                                                                        door_no: props?.data?.registration_officeByID?.vendor_address?.[0]?.door_no,
                                                                        street_1: props?.data?.registration_officeByID?.vendor_address?.[0]?.street_1,
                                                                        street_2: props?.data?.registration_officeByID?.vendor_address?.[0]?.street_2,
                                                                        landmark: props?.data?.registration_officeByID?.vendor_address?.[0]?.landmark,
                                                                        area: props?.data?.registration_officeByID?.vendor_address?.[0]?.area,
                                                                        city: props?.data?.registration_officeByID?.vendor_address?.[0]?.city,
                                                                        state: props?.data?.registration_officeByID?.vendor_address?.[0]?.state,
                                                                        country: props?.data?.registration_officeByID?.vendor_address?.[0]?.country,
                                                                        pincode: props?.data?.registration_officeByID?.vendor_address?.[0]?.pincode,
                                                                  },
                                                                  [
                                                                        "door_no", "street_1", "street_2",
                                                                        "landmark", "area", "city",
                                                                        "state", "country", "pincode",
                                                                  ], ", ", "-"
                                                            )}
                                                      </Typography></Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Latitude")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.registration_officeByID?.vendor_address?.[0]?.latitude ? props?.data?.registration_officeByID?.vendor_address?.[0]?.latitude : "-"}
                                                      </Typography></Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                      <Typography className={classes.heading}>{t("Longitude")}</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.registration_officeByID?.vendor_address?.[0]?.longitude ? props?.data?.registration_officeByID?.vendor_address?.[0]?.longitude : "-"}
                                                      </Typography></Grid>
                                          </Grid>
                                    </Grid>
                              </Grid>
                              <br />
                        </>}
                  {props?.shareHolders?.length > 0 &&
                        <Grid container className={classes.card} rowGap={"8px"}>
                              <Grid item xs={12}><Typography className={classes.title}>{t("Share Holders Details")}</Typography></Grid>
                              {props?.shareHolders?.map?.(_ => {
                                    return (<Grid item xs={12}>
                                          <div className={classes.shareHolderCard}>
                                                <Avatar className={classes.shareHolderImage}
                                                      src={_?.contactByID?.image_url ?? "/images/imagesproperty.svg"} alt=""
                                                      onClick={() => props?.openImageViewer([_?.contactByID?.image_url])} />
                                                <Grid container columnSpacing={"4px"}>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Name")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {_?.contactByID?.first_name ?? "-"}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Mobile Number")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {concat_string(
                                                                        {
                                                                              mobile_code: _?.contactByID?.mobile_no_country_code,
                                                                              mobile_no: _?.contactByID?.mobile_no,
                                                                        },
                                                                        ["mobile_code", "mobile_no"],
                                                                        " - ",
                                                                        "-"
                                                                  )}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Email Address")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {_?.contactByID?.email_id ?? "-"}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={1} sm={1} md={1} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Share")}</Typography>
                                                            <Typography className={classes.sub}>
                                                                  {_?.value ?? "-"}
                                                                  {_?.value && <span className={classes.matric} style={{ marginInlineStart: "4px"}}>%</span>}
                                                            </Typography>
                                                      </Grid>
                                                </Grid>
                                          </div>
                                    </Grid>)
                              })}
                        </Grid>}
                  <br />
                  {props?.propertyContracts?.length > 0 &&
                        <Grid container className={classes.card} rowGap={"8px"}>
                              <Grid item xs={12}><Typography className={classes.title}>{t("Contracts Details")}</Typography></Grid>
                              {props?.propertyContracts?.map?.(_ => {
                                    return (<Grid item xs={12}>
                                          <div className={classes.shareHolderCard}>
                                                <Avatar className={classes.shareHolderImage}
                                                      {...stringAvatar(_?.contractByID?.contract_no, 24)} alt="" />
                                                <Grid container columnSpacing={"4px"}>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Contract ID")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {_?.contractByID?.contract_no ?? "-"}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Contract Date")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {concat_string(
                                                                        {
                                                                              start_date: _?.contractByID?.start_date ? timeZoneConverter(_?.contractByID?.start_date) : "",
                                                                              end_date: _?.contractByID?.end_date ? timeZoneConverter(_?.contractByID?.end_date) : "",
                                                                        },
                                                                        ["start_date", "end_date"],
                                                                        " - ",
                                                                        "-"
                                                                  )}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Period")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {concat_string(
                                                                        {
                                                                              period_value: _?.contractByID?.period_value?.toString(),
                                                                              period: _?.contractByID?.period,
                                                                        },
                                                                        ["period_value", "period"],
                                                                        " ",
                                                                        "-"
                                                                  )}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Account ID")}</Typography>
                                                            <Typography className={classes.sub}>
                                                                  {_?.contractByID?.contact_accountByID?.account_no ?? "-"}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Vendor ID")}</Typography>
                                                            <Typography className={classes.sub}>
                                                                  {_?.contractByID?.contact_accountByID?.vendor_masterByID?.vendor_no ?? "-"}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Vendor Name")}</Typography>
                                                            <Typography className={classes.sub}>
                                                                  {_?.contractByID?.contact_accountByID?.vendor_masterByID?.name ?? "-"}
                                                            </Typography>
                                                      </Grid>
                                                </Grid>
                                          </div>
                                    </Grid>)
                              })}
                        </Grid>}
                  <br />
                  {props?.propertyProjects?.length > 0 &&
                        <Grid container className={classes.card} rowGap={"8px"}>
                              <Grid item xs={12}><Typography className={classes.title}>{t("Project Details")}</Typography></Grid>
                              {props?.propertyProjects?.map?.(_ => {
                                    return (<Grid item xs={12}>
                                          <div className={classes.shareHolderCard}>
                                                <Avatar className={classes.shareHolderImage}
                                                      {...stringAvatar(_?.projectByID?.title, 24)} />
                                                <Grid container columnSpacing={"4px"}>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Project ID")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {_?.projectByID?.project_no ?? "-"}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Name")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {_?.projectByID?.title ?? "-"}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Period")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {concat_string(
                                                                        {
                                                                              planned_start_date: timeZoneConverter(_?.projectByID?.planned_start_date , "DD MMM YYYY"),
                                                                              planned_end_date: timeZoneConverter(_?.projectByID?.planned_end_date , "DD MMM YYYY"),
                                                                        },
                                                                        ["planned_start_date", "planned_end_date"],
                                                                        " - ",
                                                                        "-"
                                                                  )}
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                                            <Typography className={classes.heading}>{t("Project Type")}</Typography>
                                                            <Typography className={classes.sub} noWrap>
                                                                  {_?.projectByID?.type ?? "-"}
                                                            </Typography>
                                                      </Grid>
                                                </Grid>
                                          </div>
                                    </Grid>)
                              })}
                        </Grid>}
                  <br />
                  {props?.isViewerOpen && (
                        <ImageViewer
                              src={props?.currentImage}
                              currentIndex={0}
                              onClose={props?.closeImageViewer}
                              disableScroll={false}
                              backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.9)",
                              }}
                              className={classes.popupimg}
                              closeOnClickOutside={true}
                        />
                  )}
            </div>
      )
}