import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";
export const LeadStylesParent = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    margin: "16px",
    backgroundColor: "#fff",
    height:`calc(100vh - 151px)`,
    [theme.breakpoints.down("md")]:{
      overflow: "auto",
      height:`calc(100vh - 171px)`
    },
    '&::-webkit-scrollbar': {
      display: "none" /* for Chrome, Safari, and Opera */
    }
  },
  profileTilte: {
    color: "#091B29",
    fontSize:"0.875rem",
    fontFamily: Bold,
  },
  leadnumber: {
    color: "#091B29",
    fontSize:"0.875rem",
    fontFamily: Bold,
    backgroundColor: "#E1E8F8",
    padding: "4px",
    borderRadius: "4px",
    marginInlineStart: "10px",
  },
  leadcontact: {
    color: "#4E5A6B",
    fontSize:"0.875rem",
    fontFamily: Regular,
    display: "flex",
    alignItems: "center",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  profileroot: {
    backgroundColor: "#F2F4F7",
    borderRadius: "8px",
  },
  profileDescription: {
    color: "#98A0AC",
    fontSize:"0.875rem",
    fontFamily: SemiBold,
  },
  profileDescriptioncontant: {
    color: "#4E5A6B",
    fontSize:"0.875rem",
    fontFamily: Regular,
    marginTop: "8px",
    paddingRight: "5px",
  },
  allocatedroot: {
    border: "1px solid #E4E8EE",
    marginTop: "8px",
    borderRadius: theme.palette.borderRadius,
  },
  timedot: {
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
  },
  timedotborder: {
    borderRadius: "50%",
    padding: "3px",
    border: "1px dashed #CED3DD",
  },
  timelinecreated: {
    color: "#4E5A6B",
    fontSize:"0.875rem",
    fontFamily: Regular,
  },
  timelinecreatedat: {
    color: "#98A0AC",
    fontSize:"0.875rem",
    fontFamily: Regular,
  },
  message: {
    color: "#091B29",
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    fontWeight: 600,
  },
  commandinput: {
    width: "100%",
    height: "35px",
    border: "none !important",
    outline: "none !important",
    color: "#091B29",
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    fontWeight: 600,
    padding:'0px !important',

    "&:focus": {
      border: "none !important",
      outline: "none !important",
    },
  },
  commandbox: {
    width: "100%",
    padding: "12px",
    borderRadius: "10px",
    border: "1px solid #E4E8EE",
    margin: "0 auto",
  },
  profileroot2: {
    backgroundColor: "#F2F4F7",
    borderRadius: "8px",
    width: "100%",
    padding: "24px",
    margin: "0 auto",
  },
  commandbtn: {
    width: "100%",
    background: "#5078E1",
    color: "#fff",
    height: "32px",
    marginTop: "10px",
    "&:hover": {
      background: "#5078E1",
      color: "#fff",
    },
  },
  options: {
    color: "#091B29",
    fontSize:"0.875rem",
    fontFamily: Bold,
  },
  leadtransfersbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    cursor: "pointer",
    backgroundColor: "#fff",
    margin: "10px 0px",
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    width: "100%"
  },
  dot1: {
    border: "2px solid #CED3DD",
  },
  doted: {
    color: "#CED3DD",
    marginLeft: 6,
  },
  dot_line: {
    border: "1px dotted #CED3DD",
  },
  menuItem: {
    "&.MuiTimeline-root": {
      "& .MuiTimelineItem-root": {
        "&:before": {
          content: "",
          padding: 0,
        },
      },
    },
  },
  activityBorder: {
    borderLeft: "1px solid #E4E8EE",
    padding: "24px",
  },
  activityPadding: {
    paddingLeft: "0 !important",
  },
  detailsub: {
    fontFamily: Regular,
    color: "#98A0AC",
    fontSize:"0.875rem",
  },
  seeMoreLessTextStyle: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    color: theme.palette.primary.main,
  },
  fontArrow: {
    fontSize: "21px !important",
  },
  profilecard: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  profilecardContent: {
    display: "flex",
    alignItems: "center",
  },
  memberboxes: {
    flexFlow: "wrap !important",
    alignItems: "center",
    display: "flex",
    marginTop: "4px",
  },
  deleteIcon: {
    color: "red",
    fontSize: "19px",
    position: "absolute",
  },
  profilePadding: {
    padding: "14px",
  },
  cardBottomLine: {
    borderBottom: "1px solid #E4E8EE",
  },
  detailsParent: {
    backgroundColor: "#F5F7FA",
    padding: "16px",
    height: "calc(100vh - 144px)",
    position: "relative",
  },
  transferParent: {
    marginTop: "5px",
  },
  tabtitle: {
    fontSize:"0.875rem",
    color: "#4E5A6B",
    fontFamily: Bold,
  },
  tabtitle1: {
    fontSize:"0.875rem",
    color: "#5078E1",
    fontFamily: Bold,
  },
  selectBack: {
    display: "flex",
    alignItems: "center",
    padding: "16px 24px",
    justifyContent: 'center',
    textAlign: 'center'
  },
  padding: {
    paddingLeft: "16px !important",
    paddingTop: "16px !important",
  },
  paddingTab: {
    paddingLeft: "0px !important",
    paddingTop: "16px !important",
  },
  bottombtn: {
    padding: "11px 16px",
    backgroundColor: "#fff",
    // boxShadow: "0px 0px 16px #00000014",
    display: "flex",
    justifyContent: "space-between",
    // border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
  },
  bottombtn1: {
    padding: "11px 16px",
    backgroundColor: "#fff",
    // boxShadow: "0px 0px 16px #00000014",
    // display: "flex",
    // justifyContent: "space-between",
    // border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
  },
  next: {
    marginLeft: "10px",
    fontFamily: SemiBold,
    color: "#fff",
    backgroundColor: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  nextfull: {
    // marginLeft: "10px",
    width:"100%",
    fontFamily: SemiBold,
    color: "#fff",
    // backgroundColor: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  Cancel: {
    backgroundColor: "#FFFFFF ",
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: SemiBold,
    border: "1px solid #E4E8EE",
    "&:hover": {
      backgroundColor: "#FFFFFF ",
    },
  },
  menuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  menuItem1: {
    borderBottom: "1px solid #E4E8EE",
    margin: "0px 4px",
    fontSize: "0.875rem",
    color: "#071741",
    fontFamily: `${SemiBold}`,
    padding:"12px",
    cursor:"pointer",
    "&:last-child": {
      border: 0,
    },
  },
  
}));
