/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from '@apollo/client';
import { Badge, Box, Divider, Grid, IconButton, Stack } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import FilterIMG from "../../assets/filter";
import {
    FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce
} from '../../components';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import {
    GET_PROPERTIES_FOR_ACCESS_GATES_BY_COMPANY_ID,
    // GET_PROPERTY_TYPE
} from '../../graphql/queries';
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys } from '../../utils';
import {
    AccessGatesPropertyHeading, AccessGatesPropertyPath, AccessGatesPropertyType,
    StatusOptionListLang
} from '../../utils/accessGates/accessGatesListUtils';
// import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import { useStyles } from './style';
import { withNamespaces } from 'react-i18next';

const AccessGates = (props) => {
    const defaultFilterState = { status: [true] };
    const { loading, handleLoading, t } = props;
    const history = useHistory()
    const classes = useStyles()
    const debounce = UseDebounce()
    const client = useApolloClient()

    // useContext
    const alert = React.useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [accessGatesPropertyList, setAccessGatesPropertyList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState(defaultFilterState)
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // useEffect to get property list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedCompany?.value) {
            getPropertyByCompanyID()
        }
    }, [selectedCompany])

    // Function to get property list based on the input data
    const getPropertyByCompanyID = (offset = 0, limit = 10, search = "",filterDetails={}) => {
        // backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        let propertyGroudID = filterData.property_type?.length ?
            filterData?.property_type?.map(({ value }) => value) : []
        client.query({
            query: GET_PROPERTIES_FOR_ACCESS_GATES_BY_COMPANY_ID(propertyGroudID),
            fetchPolicy: "network-only",
            variables: {
                clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
                status: (!filterDetails?.status || filterDetails?.status?.length === 0) ?
                    filterData?.status : filterDetails?.status,
                companyID: selectedCompany?.value,
                search,
                offset,
                limit,
            }
        }).then((r) => {
            setAccessGatesPropertyList({
                data: r?.data?.property,
                totalRowsCount: r?.data?.count?.[0]?.count
            })
            handleLoading(false)
            // backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            // backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    const AccessGatesPropertyRow = React.useCallback(accessGatesPropertyList?.data?.map((_) => {
        let p
        try {
            p = {
                id: _?.id,
                name: _?.name,
                location: _?.addressByID?.city ? _?.addressByID?.city : _?.addressByID?.district,
                gates: _?.access_gates_masterByID?.[0]?.count,
                propertyType: _?.property_groupByID?.group_name,
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return p
    }), [accessGatesPropertyList])

    // Function to change the company
    const handleCompanyChange = (value) => {
        handleLoading(true)
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in property list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getPropertyByCompanyID(0, limit, e)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        if (type === "view") { history.push(Routes.accessGatesPropertyView + "?propertyID=" + data?.id) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getPropertyByCompanyID(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getPropertyByCompanyID(0, value, searchText)
    }
    const onApplyFilter = (value)=>{
        setFilterData(value)
        getPropertyByCompanyID(0, 10, "",value)
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title={t("Access Gates")}
                select options={companyList} value={selectedCompany} onchange={(e) => { handleCompanyChange(e) }} />
            <div className={classes.root}>
                <Grid container className={classes.content} spacing={1}>
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder={t("Search Properties")}
                            handleChange={(value) => handleSearch(value)} 
                            customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}
                            />
                    </Grid>
                    <Grid item xs={8}>
                        <Box display={"flex"} justifyContent={"end"}>
                            <Stack direction="row" spacing={2}
                                divider={<Divider orientation="vertical" flexItem />}>
                                <IconButton onClick={() => setFilterDrawer(!fiterDrawer)}
                                    className={classes.filterButton}>
                                    <Badge variant="dot" color="primary"
                                        invisible={!(filterData.status?.length > 0)}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>
                            </Stack>
                        </Box>
                    </Grid>
                   
                </Grid>
                <TableWithPagination
                            heading={AccessGatesPropertyHeading(t)}
                            rows={AccessGatesPropertyRow}
                            path={AccessGatesPropertyPath}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={AccessGatesPropertyType}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={accessGatesPropertyList?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => onApplyFilter(value)}
                    defaultState={defaultFilterState}
                    components={[
                        // {
                        //     component: "select",
                        //     value: filterData?.property_type,
                        //     options: [],
                        //     isMulti: true,
                        //     label: t("Property Type"),
                        //     state_name: "property_type",
                        //     placeholder: t("Select Property Type"),
                        //     loadOptions: (search, array, handleLoading) => loadOptions(
                        //         search, array, handleLoading,
                        //         GET_PROPERTY_TYPE, 'property_group_master',
                        //     ),
                        //     debounceTimeout: 800,
                        //     isPaginate: true,
                        // },
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: StatusOptionListLang(t)?.map((x)=>{
                                return{
                                    ...x,
                                    label:x?.label_lng
                                }
                            }),
                            isMulti: true,
                            state_name: "status",
                            label: t("Status"),
                        },
                    ]} />
            </div>
        </>
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}
export default withNamespaces("accessGates")(AccessGates); 
