import { Avatar, Box, Grid, Popover, Stack, Typography } from '@mui/material'
import React from 'react'
import { ChooseCard } from './components/chooseCard'
import { PropertyTypeCard } from './components/propertyTypeCard'
import { StepsStyle } from './style'
import { CompanyToggle, DatePickerNew, MobileNumberInputComponent, SelectBox, StyledSwitch, TextBox, ToggleButtonComponent } from '../../components'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { durationOption } from './utils/residentOnboard'
import { ProfileCreateEdit } from './components/profileCreateEdit'
import { DurationBox } from '../../components/durationbox'
import PrioritySelect from '../../components/prioritySelect/prioritySelect'
import { ContactSelect } from "./components/contactSelect";
import { ContactTypeSelect } from "./components/contactTypeSelect";
import { ProfileUpload } from "../../components/LogoUpload/profileUpload"
import { SelectContract } from './components/selectContract'
import { stringAvatar, timeZoneConverter } from '../../utils'
import { AgreementCustomDate } from '../../components/agreementCycle'

export const Steps = ({ component = []  , is_toggle, t = () => false}) => {
    const classes = StepsStyle()

    // popover 

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    return (
        <Grid container spacing={2}>
            {
                // eslint-disable-next-line
                component?.map((val) => {
                    if (val?.isActive) {
                        switch (val?.type) {
                            case "propertyType":
                                return (
                                    <Grid item md={12} sm={12} lg={12} xs={12}>
                                        <PropertyTypeCard
                                            data={val?.data}
                                            handleClick={val?.handleClick}
                                            selected={val?.selected}
                                            header={val?.header}
                                            error={val?.error}
                                            errorMsg={val?.errorMsg}
                                            isRequired={val?.isRequired}
                                            isReadOnly={val?.isReadOnly}
                                        />
                                    </Grid>
                                )
                            case "chooseComponent":
                                return (
                                    <Grid item md={12} sm={12} lg={12} xs={12}>
                                        <ChooseCard
                                            header={val?.header}
                                            title={val?.title}
                                            subTitle={val?.subTitle}
                                            noSelectedTitle={val?.noSelectedTitle}
                                            icon={val?.icon}
                                            options={val?.options}
                                            showRemove={val?.showRemove}
                                            visible={val?.visible}
                                            handleRemove={val?.handleRemove}
                                            topHeader={val?.topHeader}
                                            onchange={val?.onchange}
                                            value={val?.value}
                                            onDelete={val?.onDelete}
                                            loadOptions={val?.loadOptions}
                                            error={val?.error}
                                            errorMsg={val?.errorMsg}
                                            isRequired={val?.isRequired}
                                            isMulti={val?.isMulti}
                                            onDeleteMuti={val?.onDeleteMuti}
                                            onDeleteMutiCheck={val?.onDeleteMutiCheck}
                                            key={val?.key}
                                            menuPlacement={val?.menuPlacement}
                                            border={val?.border}
                                            isReadOnly={val?.isReadOnly}
                                            boldHeader={val?.boldHeader}
                                            isBorder={val?.isBorder}
                                            is_toggle={val?.is_toggle}
                                            state1={val?.state1}
                                            setState1={val?.setState1}
                                            t={t}
                                        />
                                    </Grid>
                                )
                            case "contactSelect":
                                return (
                                    <Grid item md={12} sm={12} lg={12} xs={12}>
                                        <ContactSelect t={t} val={val} />
                                    </Grid>
                                )
                            case "contactTypeSelect":
                                return (
                                    <Grid item md={12} sm={12} lg={12} xs={12}>
                                        <ContactTypeSelect t={t} val={val} options={val?.options} value={val?.value} onChange={val?.onChange} />
                                    </Grid>
                                )
                            case "addButton":
                                return (
                                    <>
                                        {
                                            !val?.hidden &&
                                            <Grid item md={12} sm={12} lg={12}>
                                                <Typography className={classes.addBtn} onClick={val?.click}>{val?.text}</Typography>
                                            </Grid>
                                        }
                                    </>
                                )

                            case "createContact":
                                return (
                                    <>
                                        <Typography className={classes.boldHeader} >Create New Contact</Typography>

                                    </>
                                )
                            case "upload":
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <ProfileUpload
                                            logo_title={val?.label}
                                            handleChange={val?.onChange}
                                            data={val?.value}
                                            float={val?.float}
                                        />
                                    </Grid>
                                );
                            case "multiLineText":
                                return (
                                    <Grid item md={12} sm={12} lg={12}>
                                        <Stack spacing={1}>
                                            <Typography className={classes.header}>{val?.header}{val?.isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                                *
                                            </Typography>}</Typography>
                                            <TextBox
                                                multiline
                                                value={val?.value}
                                                isError={val?.error}
                                                errorMessage={val?.errorMsg}
                                                onChange={(e) => val?.handleChange(e.target.value)}
                                                label=""
                                            />
                                        </Stack>
                                    </Grid>
                                )
                            case "textBox":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <TextBox
                                            value={val?.value}
                                            isError={val?.error}
                                            errorMessage={val?.errMsg}
                                            isRequired={val?.isRequired}
                                            isReadonly={val?.isReadOnly}
                                            onChange={(e) => val?.handleChange(e.target.value)}
                                            label={val?.header}
                                            placeholder={val?.placeHolder} />
                                    </Grid>
                                )
                            case "textWithSelect":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <TextBox
                                            id={"textWithSelect"}
                                            type={"number"}
                                            label={val?.header}
                                            placeholder=""
                                            value={val?.value}
                                            onChange={(value) => val.handleChange(value.target.value)}
                                            endAdornment={
                                                <Stack direction={"row"}
                                                    spacing={1}
                                                    alignItems={"center"}
                                                    onClick={handleClick}
                                                    className={classes.endAdornment}
                                                >
                                                    <Typography className={classes.selectedValue} noWrap>
                                                        {val?.selectedValue}
                                                    </Typography>
                                                    <KeyboardArrowDownIcon sx={{ fontSize:"0.875rem", marginTop: "4px" }} />
                                                </Stack>
                                            } />
                                        <Popover
                                            id={"textWithSelect"}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClick}
                                            anchorOrigin={{ vertical: "bottom", horizontal: "left", }}>
                                            <Box p={1}>
                                                {
                                                    durationOption?.map((e) => {
                                                        return (
                                                            <Typography
                                                                className={classes.selectedValue}
                                                                onClick={() => {
                                                                    val?.selectChange(e)
                                                                    handleClick()
                                                                }}
                                                                sx={{ cursor: "pointer" }}
                                                            >
                                                                {e?.label}
                                                            </Typography>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Popover>
                                    </Grid>
                                )
                            case "datePicker":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <DatePickerNew
                                            label={val?.header}
                                            placeholder={val?.header}
                                            value={val?.value}
                                            handleChange={(value) => val?.handleChange(value)}
                                            isrequired={val?.isrequired}
                                            isReadonly={val?.isReadonly}
                                            isError={val?.isError}
                                            errorMessage={val?.errorMessage}
                                        />
                                    </Grid>
                                )
                            case "selectBox":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <SelectBox
                                            label={val?.header}
                                            placeholder={val?.header}
                                            onChange={(value) => val?.handleChange(value)}
                                            value={val?.value}
                                            options={val?.options}
                                            isRequired={val?.isrequired}
                                            isError={val?.isError}
                                            errorMessage={val?.errorMessage}
                                            isReadOnly={val?.isReadOnly}
                                            fontFamily={val?.fontFamily}
                                            menuPlacement={val?.menuPlacement}
                                            isPaginate={val?.isPaginate}
                                            loadOptions={val?.loadOptions}
                                        />
                                    </Grid>
                                )
                            case "toggleButton":
                                return (
                                    <Grid item md={val?.md ?? 12} sm={val?.sm ?? 12} lg={val?.lg ?? 12} xs={val?.xs ?? 12}>
                                        <ToggleButtonComponent
                                            label={val.header}
                                            options={val?.option}
                                            value={val?.value}
                                            onChange={(value) => val?.handleChange(value)}
                                            isMulti={false}
                                            fullWidth={false}
                                            isrequired={val?.isrequired}
                                            isError={val?.isError}
                                            errorMessage={val?.errorMessage}
                                            gap={val?.gap}
                                            isReadOnly={val?.isReadOnly}
                                        />
                                    </Grid>
                                )
                            case "mobileNo":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <MobileNumberInputComponent
                                            xs={val?.moxs}
                                            value={val?.value}
                                            handleChange={val?.handleChange}
                                            label={val?.header}
                                            placeholder={val?.placeHolder}
                                            isError={val?.error}
                                            errorMessage={val?.errMsg}
                                            isRequired={val?.isRequired}
                                            disabled={val?.isReadOnly}

                                        />
                                    </Grid>
                                )
                            case "profileCreateEdit":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <ProfileCreateEdit
                                            isError={val?.error}
                                            errorMessage={val?.errMsg}
                                            value={val?.value}
                                            onChange={val?.handleChange}
                                            isRequired={val?.isRequired}
                                            isReadOnly={val?.isReadOnly}
                                            is_file={val?.is_file}

                                        />
                                    </Grid>
                                )
                            case "customSelectBox":
                                return (
                                    <Grid item md={val?.md ?? 12} sm={val?.sm ?? 12} lg={val?.lg ?? 12} sx={{paddingTop:val?.paddingTop}}>
                                        <SelectBox
                                            isRequired={true}
                                            label={val?.labelText ?? ""}
                                            placeholder={val?.placeHolder}
                                            borderRadius={"8px"}
                                            options={val?.options}
                                            loadOptions={val?.loadOptions}                                            // isPaginate
                                            debounceTimeout={800}
                                            onChange={(value) => {
                                                val?.handleChange(value)
                                            }}
                                            color={val?.color}
                                            selectHeight="40px"
                                            menuOptionHeight={val?.menuOptionHeight ?? 300}
                                            isPaginate={true}
                                            value={val?.value}
                                            key={val?.key}
                                            customOptionComponent={(props) => {
                                                return (
                                                    <Stack direction={"row"} p={0.5} alignItems={"center"} className={classes.selectParent} style={{ border: (props?.isSelected) ? "1px solid #5078E1" : "none", backgroundColor: (props?.isSelected) ? "#F1F7FF" : "#fff", margin: "5px" }}>
{val?.is_noAvatar ? "":  <Avatar className={classes.optionAvatar} {...stringAvatar(props?.data?.label)} src={props?.data?.url}></Avatar>
}                                                        <Stack>
                                                            <Typography className={classes.optionHeader}>{props?.data?.label}</Typography>
                                                            <Stack direction={"row"} alignItems={"center"}>
                                                                {
                                                                val?.is_date ?   <Typography className={classes.optionSubTitle}>{timeZoneConverter(props?.data?.start_date)} - {timeZoneConverter(props?.data?.end_date)}</Typography> :   <Typography className={classes.optionSubTitle}>{props?.data?.value1}</Typography>

                                                            }                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                )
                                                // <div style={{ padding: "8px", backgroundColor: props?.isSelected ? "red" : "white" }}>{`${props?.data?.label} - ${props?.isSelected ? "Selected" : "Unselected"}`}</div>
                                            }}
                                            isError={val?.isError}
                                            errorMessage={val?.errorMessage}
                                        />
                                        {val?.is_notHeight ? "" :<Box height={'10px'} /> }
                                        <>
                                            {
                                                val?.value &&
                                                <Stack direction={"row"} spacing={1} p={0.5} alignItems={"center"} className={classes.selectParent} style={{ border: "1px solid #E4E8EE", backgroundColor: "#fff", padding: "12px" }}>
                                                    <Box className={classes.AccountImg1}>
                                                        {/* <AccountIMG color="#98A0AC" /> */}
                                                     {val?.is_noAvatar ? "":  <Avatar className={classes.optionAvatar} {...stringAvatar(val?.value?.label)} src={val?.value?.url}></Avatar>}  

                                                    </Box>
                                                    <Stack>
                                                        <Typography className={classes.optionHeader}>{val?.value?.label}</Typography>
                                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                            {
                                                                val?.is_date ?   <Typography className={classes.optionSubTitle}>{timeZoneConverter(val?.value?.start_date)} - {timeZoneConverter(val?.value?.end_date)}</Typography> :   <Typography className={classes.optionSubTitle}>{val?.value?.value1}</Typography>

                                                            }
                                                            {/* <Box className={classes.dot}></Box>
                                                            <Typography className={classes.optionSubTitle}>{val?.value?.value1}</Typography> */}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            }
                                        </>




                                    </Grid>
                                )
                            case "duration":
                                return (
                                    <Grid
                                        item md={val?.md} sm={val?.sm} lg={val?.lg}
                                    >
                                        <DurationBox
                                            val={val}
                                            value={val?.value}
                                            options={val?.options}
                                            onChange={val?.onChange}
                                            isError={val?.isError}
                                            errorMessage={val?.errorMessage}
                                            isReadonly={val?.isReadonly}
                                            isRequired={val?.isRequired}
                                            // size={val?.size}
                                            size={
                                                {
                                                    item: 3,
                                                    item1: 9
                                                  }
                                            }
                                        />
                                    </Grid>
                                );
                            case "toggle":
                                return (
                                    <>
                                        <Grid
                                            item md={val?.md} sm={val?.sm} lg={val?.lg}

                                        >
                                            <Stack direction="row" >

                                                <Box>
                                                    <Typography className={classes.durationLabel} noWrap>
                                                        <Stack direction="row" spacing={1}>
                                                            {val.label}
                                                            {val.isRequired && (
                                                                <Typography
                                                                    variant="caption"
                                                                    style={{ color: "red", marginLeft: 4 }}
                                                                >
                                                                    *
                                                                </Typography>
                                                            )}
                                                        </Stack>
                                                    </Typography>

                                                    <Box display={'flex'} alignItems={'center'}>
                                                        <CompanyToggle
                                                            value={val?.value}
                                                            onChange={val?.onChange}
                                                            isMulti={true}
                                                            fullWidth={false}
                                                            openCustom={val?.openCustom}
                                                            options={val?.options}
                                                            height="46px"
                                                            customValue={val?.customValue}
                                                            custom={val?.custom}
                                                            disabled={val?.isReadonly}
                                                            selectBillingCycle={val?.selectBillingCycle}
                                                            date={val?.billingCycleDate}
                                                            onDateChange={val?.onDateChange}
                                                            day={val?.value}
                                                            onCustomChange={val?.onChange}
                                                            is_Oppertunity={val?.is_Oppertunity}
                                                            textBoxIsReadOnly={val?.textBoxIsReadOnly}
                                                            gap={val?.gap}

                                                        />
                                                        {
                                                            val?.customDay && <Box width={'10px'} />
                                                        }
                                                        {
                                                            val?.customDay &&
                                                            <>
                                                                <TextBox
                                                                    value={val?.customValue}
                                                                    onChange={val?.onCustom}
                                                                    type="number"
                                                                    isError={val?.error}
                                                                    errorMessage={val?.errMsg}
                                                                    label={null}
                                                                    placeholder="Custom"
                                                                    isReadonly={val?.isReadonly}
                                                                    customWidth={val?.customWidth}
                                                                    fullWidth={val?.fullWidth ?? true}
                                                                /></>
                                                        }


                                                    </Box>


                                                    {val.error?.length > 0 && (
                                                        <Typography variant={"caption"} color={"error"}>
                                                            {val.error}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </>
                                );
                            case "selectContract":
                                    return (
                                        <Grid item md={val?.md ?? 12} sm={val?.sm ?? 12} lg={val?.lg ?? 12}>

                                        <SelectContract data={val?.data}/>
                                        </Grid>
                                    )
                            case "priority":
                                return (
                                    <Grid
                                        item
                                        xs={val?.xs ?? 6}
                                        sm={val?.sm ?? 6}
                                        md={val?.md ?? 6}
                                        lg={val?.lg ?? 6}
                                    >
                                        <PrioritySelect
                                            options={val.options}
                                            value={val?.value}
                                            onChange={val?.onChange}
                                            isRequired={val?.isRequired}
                                        />
                                    </Grid>
                                );
                            case "grace_period":
                                return (
                                    <>
                                        <Grid
                                            item
                                            sm={val?.sm ?? 6}
                                            md={val?.md ?? 6}
                                            lg={val?.lg ?? 6}
                                        >
                                            <Stack direction="row" >
                                                <Box style={{ display: val?.is_Oppertunity ? "flex" : "block" }}>

                                                    <Box>
                                                        <Typography className={classes.durationLabel} noWrap>
                                                            <Stack direction="row" spacing={1}>
                                                                {val.label}
                                                                {val.isRequired && (
                                                                    <Typography
                                                                        variant="caption"
                                                                        style={{ color: "red", marginLeft: 4 }}
                                                                    >
                                                                        *
                                                                    </Typography>
                                                                )}
                                                            </Stack>
                                                        </Typography>

                                                        <CompanyToggle
                                                            value={val?.value}
                                                            onChange={val?.onChange}
                                                            isMulti={true}
                                                            fullWidth={false}
                                                            openCustom={val?.openCustom}
                                                            options={val?.options}
                                                            height="46px"
                                                            customValue={val?.customValue}
                                                            custom={val?.custom}
                                                            disabled={val?.isReadonly}
                                                            selectBillingCycle={val?.selectBillingCycle}
                                                            date={val?.billingCycleDate}
                                                            onDateChange={val?.onDateChange}
                                                            day={val?.value}
                                                            onCustomChange={val?.onChange}
                                                            is_Oppertunity={val?.is_Oppertunity}
                                                            textBoxIsReadOnly={val?.textBoxIsReadOnly}

                                                        />
                                                    </Box>
                                                    {
                                                        val?.is_Oppertunity &&
                                                        <Box>
                                                            <Box style={{ width: "10px" }} />
                                                        </Box>
                                                    }

                                                    {val?.customDay && (
                                                        <>
                                                            {
                                                                <Box>

                                                                    <Box width="110px">
                                                                        <TextBox
                                                                            value={val?.customValue}
                                                                            onChange={val?.onCustom}
                                                                            type="number"
                                                                            isError={val?.customerror?.length > 0}
                                                                            errorMessage={val?.customerror}
                                                                            label={null}
                                                                            placeholder="Custom"
                                                                            isReadonly={val?.isReadonly}
                                                                        />
                                                                    </Box>
                                                                </Box>

                                                            }</>
                                                    )}
                                                    {val?.is_notHeight ? "" :<Box height={'7px'} />}
                                                    
                                                    {val.applyGracePeriod &&
                                                        <Stack direction="row" spacing={1}>
                                                            <Typography className={classes.durationLabel}>Beginning</Typography>
                                                            <StyledSwitch inputProps={{ 'aria-label': 'ant design' }} checked={val?.applyGracePeriodValue}
                                                                onChange={(e) =>
                                                                    val?.OnApplyGracePeriod(e.target.checked)
                                                                } />
                                                            <Typography className={classes.durationLabel}>End</Typography>
                                                        </Stack>
                                                    }
                                                    {val.error?.length > 0 && (
                                                        <Typography variant={"caption"} color={"error"}>
                                                            {val.error}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </>
                                );
                       
                                case "toggle1":
                                    return (
                                      <>
                                        <Grid
                                          item
                                          xs={val?.xs}
                                          sm={val?.sm}
                                          md={val?.md}
                                          lg={val?.lg}
                                        >
                                          <Stack  >
                                            <Box style={{ display: val?.is_Oppertunity ? "flex" : "block", width: "100%" }} alignItems="flex-end">
                          
                                              <Box>
                                                <Stack direction="row" justifyContent="space-between" width="100%">
                                                  <Typography className={classes.Label} noWrap>
                                                    <Stack direction="row" spacing={1}>
                                                      {val.label}
                                                      {val.isRequired && (
                                                        <Typography
                                                          variant="caption"
                                                          style={{ color: "red", marginLeft: 2 }}
                                                        >
                                                          *
                                                        </Typography>
                                                      )}
                                                    </Stack>
                                                  </Typography>
                                                </Stack>
                          
                          
                                                <CompanyToggle
                                                 value={val?.value}
                                                 onChange={val?.onChange}
                                                 isMulti={true}
                                                 fullWidth={false}
                                                 openCustom={val?.openCustom}
                                                 options={val?.options}
                                                 height="45px"
                                                 t={t}
                                                 customValue={val?.customValue}
                                                 custom={val?.custom}
                                                 disabled={val?.isReadonly}
                                                 selectBillingCycle={val?.selectBillingCycle}
                                                 date={val?.billingCycleDate}
                                                 onDateChange={val?.onDateChange}
                                                 day={val?.value}
                                                 onCustomChange={val?.onChange}
                                                 is_Oppertunity={val?.is_Oppertunity}
                                                 textBoxIsReadOnly={val?.textBoxIsReadOnly}
                                                 is_billing={true}
                                                />
                                              </Box>
                          
                                              {val?.customDay && (
                                                <>
                                                  {
                                                    <Box marginLeft="4px">
                                                      <AgreementCustomDate
                                                        value={val?.customValue}
                                                        onChange={(e) => {
                                                          val?.onCustom(e)
                                                        }}
                                                        disabled={val?.isReadonly}
                                                      />
                                                    </Box>
                          
                                                  }</>
                                              )}
                          
                          
                          
                                            </Box>
                          
                                            {
                                              val.error?.length > 0 && (
                                                <Typography variant={"caption"} color={"error"}>
                                                  {val.error}
                                                </Typography>
                                              )
                                            }
                                          </Stack >
                                        </Grid >
                                      </>
                          
                                    );
                                
                            default:
                                break;
                        }
                    }
                })
            }
        </Grid>
    )
}