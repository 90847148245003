import gql from "graphql-tag";


export const GET_CONTACTS_BYCOMPANY = gql`
query {
  contact(
    where: {
      company_id: { eq: $company_id }
      is_active: { eq: true }
      first_name: { iregex: $search }
      client:$client
      relationship: "Company Employee"
    }
    offset: $offset
    limit: $limit
    orderby: { created_at: desc }
  ) {
    id
    first_name
    last_name
    relationship
    job_title
    image_url
  }
}
`

export const GET_LEAVELIST = gql`
query{
  leave_master (where: {is_active: true,is_delete:false, client:$client}){
  id
  name
  type
  is_active
  is_delete
  }
  }`
