import { useApolloClient } from "@apollo/client";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import React from "react";
import { SearchFilter, SelectBox } from "../../../components";
import { AlertContext } from "../../../contexts";
import { UPDATE_TRANSFER_LEAD } from "../../../graphql/oppertunityQueries";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  useWindowDimensions,
} from "../../../utils/constants";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import InfiniteScroll from "react-infinite-scroll-component";
import { Card } from "../../leadnew/addmembercard";
import { LeadStylesParent } from "../../leadnew/style";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";


export const TransferOppertunity = ({
  t,
  oppertunity_id = "",
  onClose = () => false,
  company_id,
  user_id,
  onTransferClose = () => false,
  type,
  team,
  setReload = () => false,
  reload,
}) => {
  const classes = LeadStylesParent();
  const client = useApolloClient();
  const [data, setData] = React.useState({ id:user_id });
  const [loading, setLoading] = React.useState(null);
  const alert = React.useContext(AlertContext);
  const [userDetails, setUserDetails] = React.useState([]);
  const [searchdata, setSearchdata] = React.useState("");
  const [offset, setOffset] = React.useState(0);
  const size = useWindowDimensions();
  const [disable, setDisable] = React.useState(false);
  const [teams, setTeams] = React.useState(team);

  //on submit
  const onSubmit = () => {
    setDisable(true);

    client
      .mutate({
        mutation: UPDATE_TRANSFER_LEAD,
        variables: {
          leadID: oppertunity_id,
          updatePayload: {
            transfer_remarks: data?.remark,
            lead_owner: data?.id,
          },
        },
      })
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Oppertunity Transfer Successfully"),
        });
        onClose();
        setDisable(false);
        reload();
      })
      .catch((err) => {
        setDisable(false);
        console.log(err);
      });
  };
  const getUserProfile = (offset, search, is_load, team) => {
    if (!is_load) {
      setLoading(true);
    }
    NetworkCall(
      `${config.api_url}/lead/transfer_owners`,
      NetWorkCallMethods.post,
      {
        start: offset,
        length: 10,
        search: search ?? "",
        team: type === "against_team" ? team : undefined,
        module_id: localStorage.getItem(LocalStorageKeys.activeRoleModuleId),
      },
      null,
      true,
      false
    )
      .then((res) => {
        const user = res?.data?.data?.map((x) => {
          return {
            ...x,
            is_active: (data?.id || user_id) === x?.id ? true : false,
          };
        });
        if (is_load) {
          setUserDetails(userDetails?.concat(user));
        } else {
          setUserDetails(user);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleSearchTransfer = (v) => {
    setSearchdata(v);
    getUserProfile(0, v, false, teams?.value);
  };
  // infinity scroll transfer lead
  const fetchMoreDataTransfer = () => {
    setOffset(offset + 10);
    getUserProfile(offset + 10, searchdata, true, teams?.value);
  };
  React.useEffect(() => {
    if (type === "against_team") {
        if (team?.value) {
          getUserProfile(0, "", false, team?.value);
        }        
    } else {
      getUserProfile(0);
    }
    setTeams(team);
  }, [type]);
    
  return (
    <div id={"infinity"}>
      {/* search box */}
      {type === "against_team" && (
        <div style={{ padding: "16px" }}>
          <SelectBox
            alignItem="start"
            isRequired
            label={t("Team")}
            placeholder={t("Select Team")}
            value={teams}
            onChange={(value) => {
              getUserProfile(0, "", false, value?.value);
                setTeams(value);
                setData({id:null})
            }}
            isPaginate
            debounceTimeout={800}
            loadOptions={(search, array, handleLoading) =>
              loadOptionsApis(
                "hierarchy-team/get_all_team",
                {
                  // company_id: 1,
                  module: localStorage.getItem(
                    LocalStorageKeys.activeRoleModuleId
                  ),
                },
                search,
                array,
                handleLoading,
                "data",
                {}
              )
            }
            isReadOnly={team?.value}
          />
        </div>
      )}

      <div style={{ padding: "24px 16px 16px 16px" }}>
        <SearchFilter
          value={searchdata}
          handleChange={(value) => handleSearchTransfer(value)}
          placeholder={t("Search Member Details")}
        />
      </div>
      <div style={{ padding: "0px 16px 16px" }}>
        {loading ? (
          <Stack spacing={0.5} height={size?.height - 400} overflow={"auto"}>
            {Array.from(Array(8).keys())?.map(() => {
              return <Skeleton width={"100%"} height={"150px"} />;
            })}
          </Stack>
        ) : (
          <InfiniteScroll
            dataLength={userDetails?.length ?? ""}
            next={fetchMoreDataTransfer}
            hasMore={true}
            height={size?.height - 400}
          >
            <Card
              data={userDetails}
              selectUser={(c) => setData(c)}
              select={data}
              t={t}
            />
          </InfiniteScroll>
        )}
      </div>
      {/* button */}
      <Box className={classes.bottombtn}>
        <Box>
          <Button className={classes.Cancel} onClick={onTransferClose}>
            {t("Cancel")}
          </Button>
        </Box>
        <Box>
          <Button
            className={classes.next}
            onClick={() => onSubmit()}
            disabled={!data?.id || disable}
          >
            {t("Transfer Lead")}
          </Button>{" "}
        </Box>
      </Box>
    </div>
  );
};
