import { useApolloClient } from "@apollo/client";
import { Box, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ShowMoreText from "react-show-more-text";
import { LoadingSection, TextBox, UseDebounce } from "../../../components";
import { GET_PROPRTY_AND_UNIT } from "../../../graphql/queries";
import { AssetMasterType, convertTimeUtcToZoneCalander } from "../../../utils";
import { AssetView } from "./assetView";
import { UnitSingleDetails } from "./singleUnitDetail";
import { filterStyles } from "./styles";
import UnitCard from "./unitCard";
import { Search } from "@mui/icons-material";
import { CardView, CompactView, ListView, SearchUnit } from "../../../assets";
import ListViewCard from "./listView";
import CompactViewCard from "./compactViewCard"
import NoDataFound from "../../../assets/noData";

export const PropertyDetails = (props) => {
    const { t } = (props);
    const classes = filterStyles();
    const [open, setOpen] = React.useState(false)
    const client = useApolloClient();
    const debounce = UseDebounce();
    const [details, setDetails] = React.useState({
        property: {},
        assets: {},
        asset: []
    })
    const [selectedUnits, setSelectedunit] = React.useState("")
    const [loading, setLoading] = React.useState(true)
    const [offset, setOffset] = React.useState(0);
    const [unit, setUnit] = React.useState([])
    const [selectedView, setSelectedView] = React.useState(1)
    const [search,setSearch]=React.useState("");
    const [is_search,setIssearch]=React.useState(false)

    const openDetails = (value) => {
        setOpen(true)
        setSelectedunit(value)
    }
    const getUnitDetails = (offset,searchValue , isInfinity) => {
        if(!isInfinity){
            setIssearch(true)
        }
       
        client.query({
            query: GET_PROPRTY_AND_UNIT,
            fetchPolicy: 'network-only',
            variables: {
                masterType: AssetMasterType.image,
                id: props?.selectedProperty?.id,
                offset: offset,
                limit: 10,
                unit_id: props?.selectedProperty?.units?.map((val) => val?.id),
                search:searchValue??""
            }
        }).then((res) => {
            if(!isInfinity){
                setIssearch(false)
            }
            setDetails({
                property: res.data?.property?.[0],
                assets: res.data?.assets,
                asset: res.data?.asset
            })
           
            if(isInfinity){
                setUnit(unit.concat(res.data?.property?.[0]?.unit))
             
             }else{
                setUnit(res.data?.property?.[0]?.unit)
             }
            setLoading(false)
        })
    }
    React.useEffect(() => {
        if (props?.selectedProperty?.id) {
            getUnitDetails(0)
        }

        // eslint-disable-next-line
    }, [])
    const handleShortlist = (id) => {
        if (props?.shortlistid?.some((val) => val === id)) {
            props?.setShortlistId(props?.shortlistid.filter((val) => val !== id));
        } else {
            props?.setShortlistId(props?.shortlistid.concat(id))
        }
    }
    const fetchMoreData = () => {
        if(details?.property?.unitCount?.[0]?.count_id > unit?.length){
        setOffset(offset + 10)
        getUnitDetails(offset + 10,search, true);
        }
    }

    const returnViews = () => {
        return [
            {
                id: 1,
                icon: <CardView color={selectedView === 1 ? "#5078E1" : null} />,
                title: "Card View",
            },
            {
                id: 2,
                icon: <ListView color={selectedView === 2 ? "#5078E1" : null} />,
                title: "List View"
            },
            {
                id: 3,
                icon: <CompactView color={selectedView === 3 ? "#5078E1" : null} />,
                title: "Compact View"
            }
        ]
    }
    const onSearch=(val)=>{
        setSearch(val)
        debounce(() => getUnitDetails(0,val),800)
    }

    return (
        <>
            {
                loading ? <LoadingSection /> :
                    <>
                        <InfiniteScroll
                            dataLength={details?.property?.unit}
                            next={fetchMoreData}
                            hasMore={true}
                            height={`calc(100vh - 170px)`}
                        >
                            <div className={classes.Detailroot1} style={{padding:"12px"}}>
                                <AssetView title={t("Property View")} asset={details?.asset} assetAll={details?.assets} referenceId={props?.selectedProperty} />

                                <Typography className={classes.detailTitle}>{details?.property?.name}</Typography>

                                <ShowMoreText
                                    lines={2}
                                    more="Show More"
                                    less="Show Less"
                                    className={classes.detailsub}
                                    anchorClass={classes.seeMoreLessTextStyle}
                                    expanded={false}
                                    truncatedEndingComponent={"... "}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: details?.property?.description }} />
                                </ShowMoreText>
                                <Box display="flex" alignItems="center" marginTop="12px">
                                    <Box>
                                        <Stack direction="row" alignItems="center">
                                            <img src="/images/build.svg" alt="" />
                                            <Typography className={classes.build}>&nbsp;&nbsp; &#x202a;{convertTimeUtcToZoneCalander(details?.property?.year_built)}&#x202c;<span className={classes.build1}>&nbsp;{t("Build")}</span></Typography>
                                        </Stack>
                                    </Box>
                                    <Box className={classes.dot} />
                                    <Box>
                                        <Typography className={classes.availabe}>{details?.property?.unitCount?.[0]?.count_id}&nbsp;{t("Units Available")}</Typography>
                                    </Box>
                                </Box>
                                <Box height="14px" className={classes.border} />
                                <Box height="14px" />
                                {/* <Box display="flex">
                                    <Box>
                                        <img src="/images/locblue.svg" alt="" />
                                    </Box>
                                    <Box>
                                        <Typography className={classes.loc}>{details?.property?.address?.street_1 ?? ""}{details?.property?.address?.street_1 ? ", " : ""}{details?.property?.address?.street_2 ?? ""}{details?.property?.address?.street_2 ? ", " : ""} {details?.property?.address?.street_3 ?? ""}{details?.property?.address?.street_3 ? ", " : ""}
                                            {details?.property?.address?.city ?? ""}{details?.property?.address?.city ? "," : ""}
                                            {details?.property?.address?.state ?? ""}{details?.property?.address?.state ? ", " : ""}{details?.property?.address?.country ?? ""}{details?.property?.address?.country ? ", " : ""}{details?.property?.address?.zipcode ?? ""}{details?.property?.address?.zipcode ? ", " : ""}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box height="18px" /> */}
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <img src="/images/locblue.svg" alt="" />
                                            <Typography className={classes.loc}>{details?.property?.address?.street_1 ?? ""}{details?.property?.address?.street_1 ? ", " : ""}{details?.property?.address?.street_2 ?? ""}{details?.property?.address?.street_2 ? ", " : ""} {details?.property?.address?.street_3 ?? ""}{details?.property?.address?.street_3 ? ", " : ""}
                                                {details?.property?.address?.city ?? ""}{details?.property?.address?.city ? "," : ""}
                                                {details?.property?.address?.state ?? ""}{details?.property?.address?.state ? ", " : ""}{details?.property?.address?.country ?? ""}{details?.property?.address?.country ? ", " : ""}{details?.property?.address?.zipcode ?? ""}{details?.property?.address?.zipcode ? ", " : ""}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Stack spacing={1}>
                                            <Stack direction={"row"} alignItems={"center"}>
                                                <img src="/images/calblue.svg" alt="" />
                                                <Typography className={classes.loc}>{details?.property?.business_phone_country_code ?? ""} {details?.property?.business_phone ?? ""}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} alignItems={"center"}>
                                                <img src="/images/mailnew.svg" alt="" height="20px" />
                                                <Typography className={classes.loc}>&nbsp;{details?.property?.email ?? ""}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack spacing={1}>
                                            <Stack direction={"row"} alignItems={"center"}>
                                                <img src="/images/calblue.svg" alt="" />
                                                <Typography className={classes.loc}>{details?.property?.mobile_country_code ?? ""} {details?.property?.mobile_phone ?? ""}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                <img src="/images/web-new.svg" alt="" height="20px" />
                                                <Typography className={classes.loc}>&nbsp;{details?.property?.website ?? ""}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Box height="14px" className={classes.border} />
                                <Box height="14px" />
                                <Box display="flex" alignItems="center">
                                    <Box flexGrow={1}>
                                        <Typography className={classes.detailTitle}>{details?.property?.unitCount?.[0]?.count_id} {t("Vacant Units")}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className={classes.viewall} onClick={props?.updateView}>{t("View All")}</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" marginTop={"12px"}>
                                    <Box flexGrow={1}>
                                        <TextBox
                                            startAdornment={<Search style={{ color: "gray", fontSize: "20px" }} />}
                                            color="#F5F7FA"
                                            label={null}
                                            borderRadius="8px"
                                            placeholder={"Search"}
                                            value={search}
                                            onChange={(e)=>onSearch(e.target.value)}
                                        />
                                    </Box>
                                    <Box marginInlineStart={"4px"}>
                                        <Stack flexDirection="row">
                                            {
                                                returnViews()?.map((val) => {
                                                    return <IconButton onClick={() => setSelectedView(val?.id)}>{val?.icon}</IconButton>
                                                })
                                            }

                                        </Stack>
                                    </Box>
                                </Box>
                                <Box height="14px" />
                                  {
                                    is_search ?
                                     <center>
                                    <SearchUnit/>
                                    </center>
                                    :
                                    unit?.length>0?
                                <Grid container spacing={1.3}>
                                        
                                        {unit?.map((val) => {
                                            return (
                                                <Grid item xs={selectedView === 1 ? 6 : 12}>

                                                    {selectedView === 1 &&
                                                        <UnitCard
                                                            type={val?.unit_purpose}
                                                            currency={val?.currencyByID?.symbol}
                                                            shortlistid={props?.shortlistid}
                                                            data={{ ...val, unit_type: val?.unit_type?.name }} handleShortlist={handleShortlist} onClick={openDetails}
                                                            t={t} />}


                                                    {selectedView === 2 &&
                                                        <ListViewCard
                                                            type={val?.unit_purpose}
                                                            currency={val?.currencyByID?.symbol}
                                                            shortlistid={props?.shortlistid}
                                                            data={{ ...val, unit_type: val?.unit_type?.name }} handleShortlist={handleShortlist} onClick={openDetails}
                                                            t={t} />}

                                                    {selectedView === 3 &&
                                                        <CompactViewCard
                                                            type={val?.unit_purpose}
                                                            currency={val?.currencyByID?.symbol}
                                                            shortlistid={props?.shortlistid}
                                                            data={{ ...val, unit_type: val?.unit_type?.name }} handleShortlist={handleShortlist} onClick={openDetails}
                                                            t={t} />
                                                            }


                                                </Grid>
                                            )
                                        })}
                                                          
                                </Grid>
                                     :
                                     <center>
                                     <NoDataFound/>
                                     </center>
                                 }
                            </div>
                        </InfiniteScroll>


                        <Dialog maxWidth="xs" className={classes.dialog} fullWidth open={open} onClose={() => setOpen(false)}>
                            <UnitSingleDetails
                                revenue_type={props?.revenue_type}
                                grace={props?.grace}
                                startDate={props?.startDate}
                                endDate={props?.endDate}
                                t={t}
                                shortlistid={props?.shortlistid} handleShortlist={handleShortlist} selectedUnits={selectedUnits} onClose={() => setOpen(false)} />
                        </Dialog>
                    </>


            }

        </>
    )
}