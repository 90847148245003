import { Box, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import { FormGenerator } from "../../../components";
import { GET_LEAD_QUOTATION } from "../../../graphql/oppertunityQueries";
import {
  // LocalStorageKeys,
  addDaysWithLess,
  billGenENUM,
  enumSelect,
  enum_types,
  getEnumValueOppertunity
} from "../../../utils";
import {
  Billing_cycle_date,
  Billing_cycle_method,
  quotation_validity,
  validateCustom,
} from "../../../utils/companySettings";
import { LeadDetailsCard } from "./index";
import { useStyles } from "./styles";
import { useApolloClient } from "@apollo/client";
// import { GET_COUNTRY_MASTER } from "../../../graphql/queries";
// import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";


export const LeaseQuotationForm = ({
  data,
  updateState,
  user = {},
  type = "",
  purpose = "",
  setData = () => false,
  setSelectedAccount = () => false,
  selectedAccount,
  t = () => false,
  opperEdit= false,
  setOpperEdit= () =>false
}) => {
  const state = useLocation()?.state
  // const { t } = React.useContext(createQuotationContext);
  const classes = useStyles();
  const [enumValue, setEnum] = React.useState({
    unit_usage: [],
    priority: [],
    source: [],
    lease_type: [],
    billGenCycle: [],
    billing_cycle_date: [],
    apply_grace_period_type: [],
    payment_mode: [],
    turnover_type: [],
    auto_renewal_escalation: [],
    payment_period: [],
  });
  const marketplace = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    }
  ]

  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.urgent_type,
      enum_types.l_period,
      enum_types.lead_source,
      enum_types.billing_cycle,
      enum_types.billing_cycle_date,
      enum_types?.apply_grace_period_type,
      enum_types.payment_mode,
      enum_types?.turnover_type,
      enum_types?.auto_renewal_escalation,
      enum_types.unit_payment_period,
    ]);
    setEnum({
      priority: result?.urgent_type,
      lease_type: result?.l_period
        ?.filter((val) => val?.value !== "Quarterly")
        .filter((val) => val?.value !== "Daily"),
      source: result?.lead_source,
      billGenCycle: result?.billing_cycle,
      billing_cycle_date: result?.billing_cycle_date
        ?.filter((i) => i?.value !== billGenENUM.last_day)
        .filter((i) => i?.value !== billGenENUM.mid_date)
        .filter((i) => i?.value !== billGenENUM.first),
      apply_grace_period_type: result?.apply_grace_period_type,
      payment_mode: result?.payment_mode,
      turnover_type: result?.turnover_type,
      auto_renewal_escalation: result?.auto_renewal_escalation
        ?.filter((val) => val?.value !== "Escalation %")
        .filter((val) => val?.value !== "RERA Index Chart"),
      payment_period: result?.payment_value_type
        .filter((val) => val?.value !== "On Completion")
        .filter((val) => val?.value !== "Milestone Based")
        .filter((val) => val?.value !== "Hourly")
        .filter((val) => val?.value !== "Weekly"),
    });
  };
  React.useEffect(() => {
    getEnum();
    if (state?.type === "existing lead") {
      getQuotation()

    } else {
      updateState("quotation_description", `${data?.lead_no}/01`)
    }
    // eslint-disable-next-line
  }, []);

  const client = useApolloClient();
  // const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
  //get Quotation list
  const getQuotation = () => {
    client.query({
      query: GET_LEAD_QUOTATION,
      fetchPolicy: 'network-only',
      variables: {
        id: data?.lead_id,
        offset: 0,
        limit: 10
      }
    }).then((response) => {
      let result = (response?.data?.count[0]?.count + 1).toString()
      updateState("quotation_description", `${data?.lead_details?.lead_no}/${result?.length === 1 ? '0' : ""}${response?.data?.count[0]?.count + 1}`)
    }).catch((err) => {
      console.log(err)
    })

  }
  //on change grace peroid
  const onChangeGracePeroid = (value) => {
    setData({
      ...data,
      quotation_billing_start_date: value
        ? moment(data?.quotation_billing_start_date).add("days", value)
        : data?.lease_start_date,
      quotation_grace_period: value,
    });
  };
  //on change grace peroid value
  const onChangeGracePeroidValue = (value) => {
    setData({
      ...data,
      billing_start_date:
        data?.apply_grace_peroid === "In the Beginning"
          ? addDaysWithLess(data?.lease_start_date, value)
          : data?.lease_start_date,
      grace_peroid: value,
    });
  };

  React.useEffect(() => {
    if (!data?.custom_date && new Date(data?.lease_start_date)?.getDate() > 28) {
      updateState("custom_date", 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.lease_start_date])



  const formLease1 = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 7,
        lg: 9
      },
      isActive: true,
      component: "text",
      label: t("Quotation Description"),
      value: data?.quotation_description,
      placeholder: t("Quotation Description"),
      onChange: (value) =>
        updateState("quotation_description", value.target.value),
      error: data?.error?.quotation_description,
      isRequired: true,
      options: [],
      height: "60px",
      // multiline: true
    },

    {
      size: {
        xs: 12,
        sm: 12,
        md: 5,
        lg: 3
      },
      isActive: true,
      component: "priority",
      label: t("Priority"),
      value: data?.priority,
      placeholder: t("Priority"),
      onChange: (value) => updateState("priority", value?.value),
      error: data?.error?.priority,
      isRequired: true,
      options: enumValue?.priority,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label:
        data?.revenue_type?.value === "Lease"
          ? "Lease Start Date"
          : "Manage Start Date",
      value: data?.lease_start_date,
      placeholder:
        data?.revenue_type?.value === "Lease"
          ? "Lease Start Date"
          : "Manage Start Date",
      isReadonly: true,
      isRequired: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "duration",
      label:
        data?.revenue_type?.value === "Lease"
          ? t("Lease Duration")
          : t("Manage Duration"),
      value: data?.duration,
      durationValue: data?.duration.select,
      placeholder:
        data?.revenue_type?.value === "Lease"
          ? "Lease Duartion"
          : "Manage Duration",
      isReadonly: data?.shortlist_convert ? true : false,
      isRequired: true,
      item_size: {
        item: 3,
        item1: 9
      }
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "toggle",
      label: t("Grace Period"),
      value: data?.grace_peroid,
      placeholder: t("Grace Period"),
      onChange: (value) => onChangeGracePeroidValue(value),
      error: data?.error?.grace_peroid,
      isRequired: true,
      options: quotation_validity,
      isMulti: true,
      openCustom: validateCustom([null], data?.grace_peroid),
      customValue: 0,
      custom: true,
      applyGracePeriod: data?.grace_peroid && true,
      applyGracePeriodValue:
        data?.apply_grace_peroid === "In the Beginning" ? false : true,
      OnApplyGracePeriod: (value) => onChangeGracePeroid(value),
      isReadonly: data?.shortlist_convert ? true : false,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label:
        data?.revenue_type?.value === "Lease"
          ? "Lease End Date"
          : "Manage End Date",
      value: data?.lease_end_date,
      placeholder:
        data?.revenue_type?.value === "Lease"
          ? "Lease End Date"
          : "Manage End Date",
      isReadonly: true,
      isRequired: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label: t("Billing Start Date"),
      value: data?.quotation_billing_start_date,
      placeholder: t("Billing Start Date"),
      onChange: (value) => updateState("quotation_billing_start_date", value),
      error: data?.error?.quotation_billing_start_date,
      isRequired: true,
      isReadonly: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: data?.quotation_payment_peroid?.value === "Prepaid" ? false : true,
      component: "toggle",
      label: t("Billing Cycle Method"),
      value: data?.bill_cycle_method,
      placeholder: t("Billing Cycle Method"),
      onChange: (value) => updateState("bill_cycle_method", value),
      error: data?.error?.bill_cycle_method,
      // isRequired: true,
      options: Billing_cycle_method,
      isMulti: true,
      // isReadonly: data?.shortlist_convert ? true : false,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      // isActive: data?.payment_period?.value === "Prepaid" ? false : true,
      // component: "toggle",
      // label: "Billing Cycle Date",
      // value: data?.bill_cycle_date,
      // placeholder: "Billing Cycle Date",
      // onChange: (value) => updateState("bill_cycle_date", value),
      // error: data?.error?.bill_cycle_date,
      // isRequired: true,
      // options: Billing_cycle_date,
      // isMulti: true,
      // billingCycleDate: data?.custom_date,
      // onDateChange: (e) => updateState("custom_date", e),
      // custom: true,
      // selectBillingCycle: true,
      // openCustom:data?.bill_cycle_date === "As per the Agreement Date" ? false : true,
      // isReadonly: data?.shortlist_convert ? true : false,

      isActive: data?.quotation_payment_peroid?.value === "Prepaid" ? false : true,
      component: "toggle",
      label: "Billing Cycle Date",
      value: data?.bill_cycle_date,
      placeholder: "Billing Cycle Date",
      onChange: (value) => updateState("bill_cycle_date", value === null ? "" : value),
      error: data?.error?.bill_cycle_date,
      isRequired: true,
      options: Billing_cycle_date ?? [],
      isMulti: true,
      textBoxIsReadOnly: data?.lease_start_date ? new Date(data?.lease_start_date).getDate() > 28 ? true : false : false,
      openCustom: false,
      customValue: data?.bill_cycle_date === "As per the Agreement Date" ? "" : data?.custom_date,
      custom: data?.bill_cycle_date === "As per the Agreement Date" ? true : false,
      is_Oppertunity: true,
      customDay: data?.bill_cycle_date === "As per the Agreement Date" ? false : true,
      onCustom: (value) => updateState("custom_date", value),
      customerror: data?.error?.bill_cycle_date,
      selectBillingCycle: true,
      // isReadonly: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "select",
      label: t("Payment Period"),
      value: data?.quotation_payment_peroid,
      placeholder: t("Payment Period"),
      onChange: (value) => updateState("quotation_payment_peroid", value),
      error: data?.error?.quotation_payment_peroid,
      isRequired: true,
      options: getEnumValueOppertunity(data?.duration?.select, data?.duration?.value, enumValue?.payment_period),

    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "select",
      label: t("Payment Options"),
      value: data?.payment_option,
      placeholder: t("Payment Options"),
      onChange: (value) => updateState("payment_option", value),
      error: data?.error?.payment_option,
      isRequired: true,
      options: enumValue?.payment_mode,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "toggle",
      label: "Agreement Lock In Period",
      value: data?.agreement_lock,
      placeholder: "Agreement Lock In Period",
      onChange: (value) => updateState("agreement_lock", value),
      error: data?.error?.agreement_lock,
      isRequired: true,
      options: marketplace,
      // isReadonly: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "toggle",
      label: t("Wallet Credits Carry Over"),
      value: data?.wallet_carry_over,
      placeholder: t("Wallet Credits Carry Over"),
      onChange: (value) => updateState("wallet_carry_over", value),
      error: data?.error?.wallet_carry_over,
      isRequired: true,
      options: marketplace
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "toggle",
      label: "Renewals",
      value: data?.renewal,
      placeholder: "Renewals",
      onChange: (value) => updateState("renewal", value),
      error: data?.error?.renewal,
      isRequired: true,
      options: marketplace,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: data?.renewal ? true : false,
      component: "toggle",
      label: "Allow Extension",
      value: data?.allow_extension,
      placeholder: "Allow Extension",
      onChange: (value) => updateState("allow_extension", value),
      error: data?.error?.allow_extension,
      isRequired: true,
      options: marketplace,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: data?.renewal ? true : false,
      component: "toggle",
      label: "Auto Renewals",
      value: data?.quotation_auto_renewal,
      placeholder: "Auto Renewals",
      onChange: (value) => updateState("quotation_auto_renewal", value),
      error: data?.error?.quotation_auto_renewal,
      isRequired: true,
      options: marketplace,
      // isReadonly: true

    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: data?.renewal ? true : false,
      component: "select",
      label: t("Renewal Based on"),
      value: data?.quotation_renewal_based_on,
      placeholder: t("Renewal Based on"),
      onChange: (value) => updateState("quotation_renewal_based_on", value),
      error: data?.error?.quotation_renewal_based_on,
      isRequired: true,
      options: enumValue?.auto_renewal_escalation,
      aysnc: true,
      // isReadonly: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive:
      !data?.renewal ? false : (data?.quotation_renewal_based_on?.value === "Escalation %" ||
          data?.quotation_renewal_based_on?.value === "Increment %"
          ? true
          : false),
      component: "text",
      label: t("Increment/Escalation Percentage"),
      value: data?.quotation_escalculation_percent ?? "",
      placeholder: t("Increment/Escalation Percentage"),
      onChange: (value) =>
        updateState("quotation_escalculation_percent", value.target.value),
      error: data?.error?.quotation_escalculation_percent,
      isRequired: true,
      // isReadonly: true,
      type: "number"
    },


  ];

  // const formLease2 = [
  //   {
  //     size: {
  //       xs: 12,
  //       sm: 6,
  //       md: 4,
  //       lg: 3
  //     },
  //     isActive: true,
  //     component: "text",
  //     label: t("Company Name"),
  //     value: data?.company_name,
  //     placeholder: t("Company Name"),
  //     onChange: (value) =>
  //       updateState("company_name", value.target.value),
  //     options: [],
  //     // multiline: true
  //   },
  //   {
  //     size: {
  //       xs: 12,
  //       sm: 6,
  //       md: 4,
  //       lg: 3
  //     },
  //     isActive: true,
  //     component: "text",
  //     label: t("City"),
  //     value: data?.city,
  //     placeholder: t("City"),
  //     onChange: (value) =>
  //       updateState("city", value.target.value),
  //     options: [],
  //     // multiline: true
  //   },
  //   {
  //     size: {
  //       xs: 12,
  //       sm: 6,
  //       md: 4,
  //       lg: 3
  //     },
  //     isActive: true,
  //     component: "select",
  //     label: t("Country"),
  //     value: data?.country,
  //     placeholder: t("Country"),
  //     onChange: (value) =>
  //       updateState("country", value),
  //     isPaginate: true,
  //     loadOptions: (search, array, handleLoading) =>
  //       loadOptions(
  //         search,
  //         array,
  //         handleLoading,
  //         GET_COUNTRY_MASTER,
  //         "country_master",
  //       )
  //     // multiline: true
  //   },
  //   {
  //     size: {
  //       xs: 12,
  //       sm: 6,
  //       md: 4,
  //       lg: 3
  //     },
  //     isActive: true,
  //     component: "text",
  //     label: t("Budget Amount"),
  //     value: data?.budget_amount,
  //     placeholder: t("Budget Amount"),
  //     onChange: (value) =>
  //       updateState("budget_amount", value.target.value),
  //     options: [],
  //     type: "number",
  //     endAdornment: <Typography className={classes.endAdornment}>{selectedCompany?.currency_symbol}</Typography>
  //   },
  //   {
  //     size: {
  //       xs: 12,
  //       sm: 6,
  //       md: 4,
  //       lg: 3
  //     },
  //     isActive: true,
  //     component: "text",
  //     label: t("Probability"),
  //     value: data?.probability,
  //     placeholder: t("Probability"),
  //     onChange: (value) =>
  //       updateState("probability", value.target.value),
  //     options: [],
  //     type: "number",
  //     endAdornment: <Typography className={classes.endAdornment}>%</Typography>
  //     // multiline: true
  //   },
  //   {
  //     size: {
  //       xs: 12,
  //       sm: 6,
  //       md: 4,
  //       lg: 3,
  //     },
  //     isActive: true,
  //     component: "date",
  //     label: t("Expected Close Period"),
  //     value: data?.expected_close_period,
  //     placeholder: t("Expected Close Period"),
  //     onChange: (value) => updateState("expected_close_period", value),
  //     minDate: new Date()
  //   },

  // ];
  return (
    <Box>
      <Grid container className={classes.leadDetailsRoot}>
        <Grid item lg={3} md={12} sm={12} p={1} style={{ position: "relative" }}>
          <Box p={2} className={classes.leadDetailsRootDivider}>
            <LeadDetailsCard
              data={{
                name: data?.name,
                image: data?.image_url?.length !== 0 ? (data?.image_url?.src || data?.image_url) : data?.image_url?.src,
                email: data?.email_id,
                mobile: ` ${data.mobile?.mobile_code ?? ""} ${data.mobile?.mobile ?? "-"
                  }`,
                no: null,
                type: data?.revenue_type?.value,
                purpose: data?.purpose?.value,
                source: data?.source?.value,
                units: data?.units,
              }}
              hideDetails={true}
              source_enum={enumValue?.source ?? ""}
              dataNew={data}
              setDataNew={setData}
              updateState={updateState}
              setSelectedAccount={setSelectedAccount}
              account={selectedAccount}
              t={t}
              opperEdit={opperEdit}
              setOpperEdit={setOpperEdit}
              isReadOnly={true}
            />
            <Box height="18px" />
            {/* <FormGenerator components={formLease} /> */}
          </Box>
        </Grid>
        <Grid item lg={9} md={12} sm={12} p={1}>
          <Box p={2} className={classes.leadDetailsRootDivider}>
            <FormGenerator t={t} components={formLease1} />
            {/* <Box py={2}>
              <Divider></Divider>
            </Box>
            <FormGenerator components={formLease2} /> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
