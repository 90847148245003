import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingSection, SearchFilter, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { TEMPLATE_LIST } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { TemplateCard } from "./components";
import { StepCard } from "./components/stepCard";
import { CreateDeliveryMilestoneTemplateStyles } from "./style";
import { withNamespaces } from "react-i18next";



const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.palette.borderRadius
}))

const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    margin: theme.spacing(3),
    // paddingLeft: "16px",
}))


export const CreateDeliveryMilestoneTemplate = ({
    t = () => false
}) => {

    const classes = CreateDeliveryMilestoneTemplateStyles()
    const size = useWindowDimensions()
    const { state } = useLocation();
    const [loading, setLoading] = React.useState(true)
    const [disable, setDisable] = React.useState(false)

    const [offset, setOffset] = React.useState(0)
    const backdrop = React.useContext(BackdropContext);
    const [searchText, setSearchText] = React.useState("");
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileID)
    const [arr, SetArr] = React.useState([])
    const [list, SetList] = React.useState([])
    const [deletedList, SetDeletedList] = React.useState([])
    const [mileStoneLimit, SetmileStoneLimit] = React.useState(0)
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)
    const history = useHistory()
    const debounce = UseDebounce();
    const [permission, setPermission] = React.useState({})
    const [type, setType] = React.useState("percentage")

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                if (state?.main?.company_id) {
                    getTemplateList(searchText, 0, false)
                    setOffset(0)
                }
                if (state?.main?.milestoneTemplateId) {

                    SetList(state?.main?.milestoneTemplateItems)
                    let value = state?.main?.milestoneTemplateItems?.map(item => Number(item?.value)).reduce((a, b) => a + b, 0)
                    SetmileStoneLimit(value)
                    if (state?.main?.milestoneTemplateItems?.some((x) => x?.value_type === "percentage")) {
                        setType("percentage")
                    }
                    else if (state?.main?.milestoneTemplateItems?.some((x) => x?.value_type === "amount")) {
                        setType("amount")
                    }
                    else {
                        setType("percentage")
                    }

                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const ValidateProof = (object) => {
        if (state?.main?.template_type === "variable") {
            return true
        }
        let newObj = object?.filter((val) => (val?.is_active || val?.item_is_active))
        if (newObj?.length > 0) {
            if(type === "percentage"){
                for (let obj of newObj) {
                    if (!obj?.value) {
                        return false
                    }
                }
            }
            if(type === "amount"){
                const filterAmount = newObj?.filter((c , i)=> i !== 0)
                for (let obj of filterAmount) {
                    if (!obj?.value) {
                        return false
                    }
                }
            }

        }
        return true
    }
    //add item
    const Add = (data) => {
        if (type === "amount" && list?.length === 0) {
            return SetList([...list, data])
        }
        if (type === "amount") {
            if (ValidateProof(list)) {
                return SetList([...list, data])
            } else {
                SetList([...list])
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please fill all mandatory field"),
                });
                return false
            }
        }
        if (state?.main?.template_type === "variable") {
            return SetList([...list, data])
        }
        if (mileStoneLimit <= 100) {
            if (ValidateProof(list)) {
                SetList([...list, data])
            } else {
                SetList([...list])
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please fill all mandatory field"),
                });
                return false
            }
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Over all percentage not more than 100%"),
            });
            return false
        }
    }
    const deleteStep = (data) => {
        let newList = list.filter((val, index) => index !== data)
        SetList(newList)
        let deleteList = list.filter((val, index) => index === data).flatMap(item => {
            return {
                ...item,
                item_is_active: false
            }
        })
        SetDeletedList(deleteList)
    }

    //handlesearch
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        getTemplateList(e, 0, false)
    }

    //fetch more
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getTemplateList(searchText, offset + 10, true)
    }
    //template list
    const getTemplateList = (search = "", offSet, fetchMore) => {
        const payload = {
            query: TEMPLATE_LIST(clientId, offSet, 10, search, [state?.main?.company_id]).loc.source.body,
        }
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload, null, true, false).then((response) => {
                if (fetchMore) {
                    let datas = response?.data?.data?.delivery_milestone_masters?.map((val) => {
                        return {
                            ...val,
                            value_type: "percentage",
                            value: "",
                        }
                    })
                    SetArr(arr.concat(datas))
                } else {
                    let data = response?.data?.data?.delivery_milestone_masters?.map((val) => {
                        return {
                            ...val,
                            value_type: "percentage",
                            value: "",
                        }
                    })
                    SetArr(data)
                }
                // let data = response?.data?.data?.delivery_milestone_masters?.map((val) => {
                //     return {
                //         ...val,
                //         value_type: "percentage",
                //         value: "",
                //     }
                // })
                // SetArr(data)
                setLoading(false)
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }).catch(() => {
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                setLoading(false)

            });
    }

    //update state
    const updateState = (key, value, index) => {
        let allProperty = [...list]
        if (key === "value_type") {
            allProperty[index] = {
                ...allProperty[index],
                [key]: value,
                value: value === "amount" ? "0" : ""
            };
            setType(value)
            return SetList(allProperty.filter((x, index) => index === 0))
        } else {
            allProperty[index] = {
                ...allProperty[index],
                [key]: value,
                value_type: type
            };
        }
        SetList([...allProperty]);
        setDisable(false)
        SetmileStoneLimit([...allProperty].map(item => Number(item?.value)).reduce((a, b) => a + b))

    };


    const validateAmount = () => {
        if (mileStoneLimit > 100 && type === "percentage") {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Over all percentage not more than 100%"),
            });
        }
    }

    //upsert
    const UpsertDeliveryMilestoneTemplate = () => {
        setDisable(true)

        if (state?.main?.template_type !== "variable") {
            if (mileStoneLimit !== 100) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Over all percentage should not be equal to or less than 100%"),
                });
                return false
            }
        }

        if (ValidateProof(list)) {
            let payload;
            if (state?.main?.milestoneTemplateId) {
                let milestoneItemsData = list?.map((val, index) => {
                    return (
                        {
                            delivery_milestone_id: val?.id,
                            is_active: val?.item_is_active,
                            value: val?.value !== "" ? val?.value : 0,
                            order_by: index + 1,
                            value_type: val?.value_type ?? null,
                            company_id: state?.main?.company_id,
                            id: val?.milestoneTemplateItemId, name: val?.name,
                            description: val?.description,
                            updated_by: userProfileId,
                        }
                    )
                }).concat(deletedList?.map((val, index) => {
                    return (
                        {
                            delivery_milestone_id: val?.id,
                            is_active: val?.item_is_active,
                            value: val?.value !== "" ? val?.value : 0,
                            order_by: index + 1,
                            value_type: val?.value_type ?? "",
                            company_id: state?.main?.company_id,
                            id: val?.milestoneTemplateItemId, name: val?.name,
                            description: val?.description,
                            updated_by: userProfileId,
                        }
                    )
                }))
                payload = {
                    milestone_template: {
                        id: state?.main?.milestoneTemplateId,
                        name: state?.main?.name,
                        is_active: state?.main?.is_active,
                        template_type: type === "amount" ? "amount" : state?.main?.template_type,
                        company_id: state?.main?.company_id,
                        updated_by: userProfileId,

                    },
                    milestone_items: milestoneItemsData
                }
            } else {
                let initialMilestoneItemsData = list?.map((val, index) => {
                    return (
                        {
                            delivery_milestone_id: val?.id,
                            is_active: val?.item_is_active,
                            value: val?.value !== "" ? val?.value : 0,
                            order_by: index + 1,
                            value_type: state?.main?.template_type === "variable" ? "variable" : val?.value_type,
                            company_id: state?.main?.company_id,
                            name: val?.name,
                            description: val?.description,
                            created_by: userProfileId,
                        }
                    )
                })
                payload = {
                    milestone_template: {
                        name: state?.main?.name,
                        is_active: state?.main?.is_active,
                        template_type: type === "amount" ? "amount" : state?.main?.template_type,
                        company_id: state?.main?.company_id,
                        created_by: userProfileId,
                    },
                    milestone_items: initialMilestoneItemsData
                }
            }
            NetworkCall(
                `${config.api_url}/milestone_template/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: state?.main?.id ? t("Delivery Milestone Template Updated successfully") : t("Delivery Milestone Template Added successfully"),
                    });
                    setDisable(false)

                    history.push(Routes.deliveryMilestoneTemplate)
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Internal error. Please try again later."),
                    });

                });

        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
            setDisable(false)

            return false
        }
    }

    const checkSave = () => {
        setDisable(true)
        if (state?.main?.milestoneTemplateId) {
            if (permission.update) {

                UpsertDeliveryMilestoneTemplate()
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                    severity: AlertProps.severity.error,
                })
            }
        } else if (permission.create) {
            UpsertDeliveryMilestoneTemplate()
        } else {
            alert.setSnack({
                ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                severity: AlertProps.severity.error,
            })
        }
    }

    //goback
    const goBack = () => {
        history.goBack()
    }

    const render = () => {
        return <>
            {
                loading ? <LoadingSection bottom={"45vh"} message={t("Loading Delivery Milestone Listing...")} />
                    :
                    <>
                        <div>
                            <Subheader title={t("Create Delivery Milestone Template")} count={false} select
                                value={state?.main?.company} isReadOnly goBack={goBack}
                            />
                            <CustomPaper>
                                <Grid container>
                                    <Grid item xs={4} sx={{ borderInlineEnd: "1px solid #E4E8EE" }} padding="16px">
                                        <Grid item xs={12}>
                                            <SearchFilter placeholder={t("Search by delivery milestone name")} value={searchText} handleChange={(value) => handleSearch(value)} />
                                        </Grid>
                                        <br />
                                        <InfiniteScroll
                                            dataLength={arr?.length ?? ""}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            height={size?.height - 250}
                                            className={classes.infiniteScroll}>
                                            <Grid container spacing={2} overflow={"scroll"}>
                                                {arr?.map((val) => {
                                                    return (
                                                        <Grid item xs={12} >
                                                            <TemplateCard t={t} data={val} Add={Add} list={list} />
                                                        </Grid >
                                                    )
                                                })
                                                }
                                            </Grid >
                                        </InfiniteScroll >
                                    </Grid >
                                    <Grid item xs={8} className={classes.bg} padding="0px !important">
                                        <Grid container bgcolor={"white"} padding="16px">
                                            <Grid item xs={4}>
                                                <Typography className={classes.heading}>{state?.main?.name ?? ""}</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Box
                                                    display={"flex"}
                                                    sx={{ justifyContent: "end" }}
                                                >
                                                    <Box>
                                                        <CustomButton
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={checkSave}
                                                            disabled={list?.length > 0 ? disable : true}

                                                        >
                                                            {state?.main?.milestoneTemplateId ? t("Update") : t("Create")}
                                                        </CustomButton>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <div className={classes.scrollDiv}>
                                            {list?.map((val, index) => {
                                                return (
                                                    <div>
                                                        <StepCard t={t} updateState={updateState} list={val} index={index} length={list?.length} delete={deleteStep} validateFunc={validateAmount} template_type={state?.main?.template_type} />
                                                    </div >
                                                )
                                            })}
                                        </div >
                                    </Grid >
                                </Grid >
                            </CustomPaper >
                        </div >

                    </>
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};

export default withNamespaces("unitVacancyPropertyList")(CreateDeliveryMilestoneTemplate);
