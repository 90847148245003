import gql from "graphql-tag";
import { LocalStorageKeys } from "../utils";

export const COUNTRY_MASTER = gql`
query {
  count: country_master(
     where:{
      country_name:{iregex:$searchText}
     }
  ) {
    count: count_id
  }
  country_master
  (
     where:{
      country_name:{iregex:$searchText}
     }
  
    offset: $offset
    limit: $limit
    orderby: { created_at: desc }
  ) {
    name:country_name
    status:is_active
  }
}
`

export const CURRENCY_MASTER = gql`
query {
  count: currency_master(
     where:{
      or: {
        name: { iregex: $searchText }
        code: { iregex: $searchText }
        symbol: { iregex: $searchText }
      }
     }
  )  {
    count: count_id
  }
  currency_master(
     where:{
      or: {
        name: { iregex: $searchText }
        code: { iregex: $searchText }
        symbol: { iregex: $searchText }
      }
     }
    offset: $offset
    limit: $limit
    orderby: { created_at: desc }
  ) {
    country_id
    curreny_name:name
    code
    symbol
    status:is_active
    country {
      id
      country_name
    }
  }
}
`

export const TAX_GROUP = gql`
query {
  count: vat_group_master(where: 
    { is_delete: { eq: false }
          group_name: { iregex: $searchText }
          client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
  } 
    ) {
    count: count_id
  }
  vat_group_master(
    offset: $offset
    limit: $limit
   where: { is_delete: { eq: false }
            group_name: { iregex: $searchText }
            client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}

  }
    orderby: { created_at: desc }
  ) {
    tax_group:group_name
    country_id
    country {
      id
      country_name
    }
    tax_code:vat_group_code
    status:is_active
  }
}
`

export const CREATE_TAX_GROUP = gql`
  mutation($insert: [vat_group_masterInput!]) {
    vat_group_master(insert: $insert ) {
      id
    }
  }`

export const VAT_GROUP_ITEM = gql`
 query GET_VAT_GROUP_ITEM {
  count: vat_group_item(where: { is_delete: { eq: false }
            group_name: { iregex: $searchText }

  }) {
    count: count_id
  }
  vat_group_item(
     offset: $offset
    limit: $limit
    order_by: { created_at: desc, group_name: asc }
    where: { is_delete: { eq: false }
            group_name: { iregex: $searchText }

  }
  ) {
    id
    tax_item:group_name
    tax_code:vat_group_item_code
    tax_group:vat_group_master {
      group_name
      id
    }
    total_rate:rate
    status:is_active
    is_external
  }
}

`

export const CREATE_TAX_GROUP_ITEM = gql`
  mutation($insert: [vat_group_itemInput!]) {
    vat_group_item(insert: $insert ) {
      id
    }
  }`


export const GET_COMPANY_MASTER = gql`
query GET_COMPANY {
  count: company_master(
    where: { is_delete: { eq: false }, client: { eq: $client } ,name: { iregex: $search } }
  ) {
    count: count_id
  }
  company_master(
    offset: $offset
    limit: $limit
    order_by: { created_at: desc, name: asc }
    where: { is_delete: { eq: false }, client: { eq: $client } ,  name: { iregex: $search } }
  ) {
    id
    name
    logo
    welcome_screen_logo
  }
}
  
`

export const UPDATE_COMPANY = gql`
mutation($id: ID, $data: company_masterInput) {
  company_master(
    where: { id: { eq: $id } }
        update: $data
  ) {
    id
  }
}
`

export const GET_COMPANY_BANNERS = gql`
query GET_COMPANY_BANNERS {
  count: company_master(
    where: { is_delete: { eq: false },is_active: { eq: true }, client: { eq: $client } ,name: { iregex: $search }}
  ) {
    count: count_id
    __typename
  }
  company_master(
    offset: $offset
    limit: $limit
    order_by: { created_at: desc, name: asc }
    where: { is_delete: { eq: false },is_active: { eq: true }, client: { eq: $client } ,name: { iregex: $search } }
  ) {
    id
    name
    logo
    welcome_screen_logo
    company_banner(where: { type: $type }) {
      id
      count: company_banner_assets(where:{is_active:true}){
        count: count_id
      }
    }
  }
}

`