import { Box, Grid } from "@mui/material";
import React from "react";
import { loadOptions } from '../../../utils/asyncPaginateLoadOptions';
import { SettingList, SettingSave } from "../components";
import { AdvancedSettings, BasicSettings, ResidentSetting } from "../setttings";
import { GET_ALL_CURRENCIES } from '../utils';
import { useStyles } from "./styles";
import { GlobalControls } from "../setttings/globalControls";
export const Settings = ({
    data = {},
    updateState = () => false,
    countryID = "",
    t,
    updateState1 = () => false,
    COMPANY_ID = "",
    setSettings = () => false
    // dataLogo = {},
    // updateStateLogo = () => false

}) => {

    const [selected, setSelected] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const classes = useStyles()

    const list = [
        {
            label: t("Basic Settings"),
            value: 1,
            subtitle: ""
        },
        {
            label: t("Advance Setting"),
            value: 2,
            subtitle: ""
        },
        {
            label: t("Controls"),
            value: 3,
            subtitle: ""
        },
        // {
        //     label: "Logo & Appearance",
        //     value: 3,
        //     subtitle: "Logo upload and its visual appearance in interface screen"
        // },
        // {
        //     label: "Integration",
        //     value: 4,
        //     subtitle: "Payment Integration API key configuration"
        // },
        {
            label: t("Gateway Controls"),
            value: 5,
            subtitle: ""
        },
    ]
    React.useEffect(() => {
        setSelected(list[0])
        // eslint-disable-next-line
    }, [])
    const loadOptionData = async (search, array, type) => {

        setLoading(type);

        if (type === "currency") {
            return loadOptions(search, array, setLoading, GET_ALL_CURRENCIES, 'currency_master', { countryID });

            // if (countryID) {
            //     return loadOptions(search, array, setLoading, GET_ALL_CURRENCIES, 'currency_master', { countryID });
            // }
            // else {
            //     alert.setSnack({
            //         ...alert,
            //         open: true,
            //         severity: AlertProps.severity.error,
            //         msg: "Please select country's company in company profile tab",
            //         vertical: AlertProps.vertical.top,
            //         horizontal: AlertProps.horizontal.center,
            //     });
            //     setLoading(null);
            //     return {
            //         options: [],
            //         hasMore: false
            //     }
            // }
        }

        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }
    let components = {
        1: <BasicSettings
            data={data}
            updateState={updateState}
            loadOptionData={loadOptionData}
            loading={loading}
            t={t}
            updateState1={updateState1}
            COMPANY_ID={COMPANY_ID}
            setSettings={setSettings}
        />,
        2: <AdvancedSettings
            data={data}
            updateState={updateState}
            t={t}

        />,
        3: <ResidentSetting
            data={data}
            updateState={updateState}
            t={t}

        />,
        // 3: <Logo
        //     updateState={(value) => { updateStateLogo('', "logo", value) }}
        //     data={dataLogo}
        // />,
        // 4: <PaymentSection />
        5: <GlobalControls
            data={data}
            updateState={updateState}
            t={t}
        />,
    }
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box className={classes.box}>
                        <SettingList t={t} list={list} selected={selected?.value} setSelected={setSelected} />
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box className={classes.box}>
                        <SettingSave title={selected?.label} subTitle={selected?.subtitle} />
                        <Box className={classes.box2}>
                            {components[selected?.value]}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    )
}