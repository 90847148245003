/* eslint-disable no-useless-escape */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CityProperty from '../../assets/cityproperty';
import ContractProperty from '../../assets/contractproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { PropertyTabs, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import {
  DUPLICATE_PROPERTY_NAME_VALIDATOR,
  GET_PROPERTY_DETAILS_BY_ID_FOR_EDIT_ as queryOne
} from '../../graphql/queries';
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, allowed_file_size, enumName, enumSelect, enum_types, LocalStorageKeys, NetWorkCallMethods, PaymentPeriod, ValidateEmail, deleteS3File } from "../../utils";
import { Assests } from './createproperty/assests';
import { Bussiness } from './createproperty/bussiness';
import { PropertyDetails } from './createproperty/propertydetails';
import { useStylesCreation } from "./createproperty/style";
import { mapResult, singleFileUpload, assestType, getRoutePermissionNew, concat_string, stringAvatar, convertTimeUtcToZoneCalander } from '../../utils/common';
import { useApolloClient } from '@apollo/client';
import { withNamespaces } from 'react-i18next';
import { Avatar, Stack, Typography } from '@mui/material';

const PropertyCreation = ({
  t
}) => {
  const userProfileID = localStorage.getItem("profileID");
  const { state } = useLocation();
  const client = useApolloClient();
  const companyData = state?.main?.data?.company_master?.[0]
  const selectedCompany = state?.main?.company
  const initial = {
    img: "",
    Company: state?.main?.company ?? "",
    Teams: state?.main?.teams ?? "",
    property: '',
    oldName: "",
    period: companyData?.payment_period ?
      {
        value: companyData?.payment_period,
        label: companyData?.payment_period
      } : "",
    status: { value: true, label: "Active" },
    des: "",
    type: '',
    purpose: '',
    revenue: '',
    unit: selectedCompany?.uom ?
      {
        value: selectedCompany?.uom?.value,
        label: selectedCompany?.uom?.label
      } : "",
    total: '',
    built: '',
    handover: '',
    publiclist: 'None',
    pet: {
      "label": "Not Allowed",
      "value": "Not Allowed"
    },

    doorNo: '',
    addressLineOne: "",
    addressLineTwo: "",
    landmark: "",
    area: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    initials: true,
    email: companyData?.company_addressByID?.[0]?.email_id ?? '',
    website: companyData?.company_addressByID?.[0]?.website ?? '',
    bussinessmobile: (companyData?.company_addressByID?.[0]?.mobile_no_country_code ||
      companyData?.company_addressByID?.[0]?.mobile_no) ? {
      mobile: companyData?.company_addressByID?.[0]?.mobile_no ?? "",
      mobile_code: companyData?.company_addressByID?.[0]?.mobile_no_country_code ?? ""
    } : "",
    mobile: (companyData?.company_addressByID?.[0]?.alternative_mobile_no_country_code ||
      companyData?.company_addressByID?.[0]?.alternative_mobile_no) ? {
      mobile: companyData?.company_addressByID?.[0]?.alternative_mobile_no ?? "",
      mobile_code: companyData?.company_addressByID?.[0]?.alternative_mobile_no_country_code ?? ""
    } : "",
    landNumber: '',
    utilitieselectricity: '',
    municipality: '',
    electricity: '',
    naturalGas: '',
    gas: '',
    water: '',
    geographic: '',
    step: 0,
    isBlock: false,
    isFloor: false,
    swimmingPool: 0,
    elevator: 0,
    discussionForum: false,
    amenitiesBooking: false,
    propertyHierarchy: "",
    ownerAccount: "",
    setBackArea: "",
    assetFunc: false,
    assetProperty: false,
    is_community: false,
    communicationEmail: "",
    titleDeed: "",
    deedDate: "",
    hijriDate: "",
    landArea: "",
    marketPrice: "",
    smartGeoAddress: "",
    vendor: "",
    shareHolders: [],
    registrationOffice: "",
    is_external: false,
    propertyContracts: [],
    propertyProjects: [],
    error: {
      img: "",
      Company: '',
      Teams: '',
      property: '',
      period: '',
      status: '',
      des: '',
      type: '',
      purpose: '',
      revenue: '',
      propertyHierarchy: "",
      unit: '',
      total: '',
      built: '',
      handover: '',
      email: '',
      website: '',
      bussinessmobile: '',
      mobile: '',
      setBackArea: "",
      communicationEmail: "",
      titleDeed: "",
      deedDate: "",
      hijriDate: "",
      landArea: "",
      marketPrice: "",
      smartGeoAddress: "",
      vendor: "",
      registrationOffice: "",
      propertyContracts: "",
      propertyProjects: "",
    }
  }

  const backdrop = React.useContext(BackdropContext);
  const [data, setData] = React.useState({ ...initial })
  const [value, setValue] = React.useState(1);
  const [images, setImages] = React.useState([]);
  const [disable, setDisable] = React.useState(true);
  const [mapLoad, setMapLoad] = React.useState(!Boolean(state?.main?.isEdit))
  const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
  const [deleted_s3_urls, set_deleted_s3_urls] = React.useState([]);
  const [finalShareHolders, setFinalShareHolders] = React.useState([]);
  const [finalPropertyContracts, setFinalPropertyContracts] = React.useState([]);
  const [finalPropertyProjects, setFinalPropertyProjects] = React.useState([]);
  const [load, setLoad] = React.useState(false)

  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext)
  const history = useHistory();
  const classes = useStylesCreation();
  const [permission, setPermission] = React.useState({})
  const debounce = UseDebounce()
  const [propertyNameDup, setPropertyNameDup] = React.useState(false)
  const [enumValue, setEnum] = React.useState({
    property_purpose: [],
    revenue_type: [],
    pet_policy_type: [],
  })

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (enumValue?.revenue_type?.length > 0) {
          if (state?.main?.propertyID) { getPropertyByCompanyID() }
          else { setLoad(true) }
        }
      }
    }
    // eslint-disable-next-line
  }, [auth, state?.main?.propertyID, enumValue]);

  React.useEffect(() => { getEnum() }, [])

  const updateState = (k, v) => {
    let error = data?.error;
    error[k] = "";
    if (k === "revenue") {
      setData({ ...data, [k]: v, period: "", handover: "", error });
    } else if (k === "purpose") {
      setData({ ...data, [k]: v, type: "", error });
    } else if (k === "vendor") {
      let vendor_value = v;
      if (v?.value) {
        vendor_value = {
          ...v,
          developerName: v?.name ?? "",
          mobile: concat_string(
            {
              mobile_code: v?.mobile_code,
              mobile_no: v?.mobile_no,
            },
            ["mobile_code", "mobile_no"],
            " - ",
            "-"
          ),
          email: v?.email_id ?? "",
          website: v?.website ?? "",
        };
      }
      setData({ ...data, [k]: vendor_value, error });
    } else if (k === "project") {
      let project_value = v;
      if (v?.value) {
        project_value = {
          ...v,
          title: v?.title ?? "",
          project_no: v?.project_no ?? "",
          planned_start_date: v?.planned_start_date ? convertTimeUtcToZoneCalander(v?.planned_start_date) : "",
          planned_end_date: v?.planned_end_date ? convertTimeUtcToZoneCalander(v?.planned_end_date) : "",
        };
      }
      setData({ ...data, [k]: project_value, error });
    } else if (k === "deedDate") {
      setData({ ...data, [k]: v, hijriDate: v, error });
    } else if (k === "registrationOffice") {
      let registration_office_value = v;
      if (v?.value) {
        registration_office_value = {
          ...v,
          officeName: v?.name ?? "",
          mobile: concat_string(
            {
              mobile_code: v?.mobile_code,
              mobile_no: v?.mobile_no,
            },
            ["mobile_code", "mobile_no"],
            " - ",
            "-"
          ),
          email: v?.email_id ?? "",
          website: v?.website ?? "",
          address: concat_string(
            {
              door_no: v?.door_no,
              street_1: v?.street_1,
              street_2: v?.street_2,
              landmark: v?.landmark,
              area: v?.area,
              city: v?.city,
              state: v?.state,
              country: v?.country,
              pincode: v?.pincode,
            },
            [
              "door_no", "street_1", "street_2",
              "landmark", "area", "city",
              "state", "country", "pincode",
            ], ", ", "-"
          ),
          latitude: v.latitude,
          longitude: v.longitude,
        };
      }
      setData({ ...data, [k]: registration_office_value, error });
    } else { setData({ ...data, [k]: v, error }); }
  }

  const handlePropertyName = (e) => {
    updateState("property", e)
    if (data?.oldName !== e) {
      debounce(() => propertyNameValidator(e), 800)
    }
  }

  const propertyNameValidator = (e) => {
    client.query({
      query: DUPLICATE_PROPERTY_NAME_VALIDATOR,
      fetchPolicy: 'network-only',
      variables: {
        clientID: localStorage.getItem(LocalStorageKeys.clinetID),
        companyID: selectedCompany?.value,
        propertyName: e ?? ""
      }
    }).then((_) => {
      if (_?.data?.property?.[0]?.duplicate > 0) {
        setPropertyNameDup(true)
        alert.setSnack({
          ...alert, open: true, severity: AlertProps.severity.error,
          msg: t("Property Name Already Existed."),
        })
      } else {
        setPropertyNameDup(false)
      }
    }).catch((e) => {
      setPropertyNameDup(true)
      alert.setSnack({
        ...alert, open: true, severity: AlertProps.severity.error,
        msg: ("Internal error. Please try again later."),
      })
    })
  }

  const getEnum = async () => {

    let tempEnumValue, result, revenue_type = 0

    result = await enumSelect([
      enum_types.purpose_global_type, enum_types.revenue_type,
      enum_types.payment_value_type, enum_types.pet_policy_type])

    revenue_type = result?.revenue_type
    for (let i = 0; i < revenue_type?.length; i++) {

      switch (revenue_type[i].value) {

        case enumName.sale:
          revenue_type[i].payment_period = PaymentPeriod.sale
          break;

        case enumName.lease:
          revenue_type[i].payment_period = PaymentPeriod.lease
          break;

        case enumName.manage:
          revenue_type[i].payment_period = PaymentPeriod.manage
          break;

        default:
          revenue_type[i].payment_period = result?.payment_value_type
          break;
      }
    }

    tempEnumValue = {
      property_purpose: result?.purpose_global_type,
      revenue_type: revenue_type,
      pet_policy_type: result?.pet_policy_type
    }

    setEnum(tempEnumValue)
  }

  const next = () => {
    if (value === 1) {
      if (validate()) {
        const current_date = new Date()?.toISOString();

        let tempShareHolders = [];
        let tempFinalShareHolders = [];

        if (data?.shareHolders?.length > 0) {
          tempShareHolders = [
            ...data?.shareHolders?.filter?.(_ => _?.isEdited)?.map?.(_ => {
              let tempShareHolder = {
                contact_id: _.contact.id,
                name: _?.name,
                email_id: _?.email_id,
                mobile_no: _?.mobile_no,
                mobile_no_country_code: _?.mobile_no_country_code,
                is_active: _?.is_active,
                is_delete: _?.is_delete,
                updated_at: current_date,
                updated_by: userProfileID,
              };
              if (_?.id) {
                tempShareHolder["id"] = _?.id;
              } else {
                tempShareHolder["created_at"] = current_date;
                tempShareHolder["created_by"] = userProfileID;
              }
              if (_?.value?.length > 0) {
                tempShareHolder["value"] = parseFloat(_?.value);
              }
              return tempShareHolder;
            })
          ]
        }

        if (tempShareHolders?.length > 0) {
          tempFinalShareHolders = [
            ...tempShareHolders?.filter?.(_ => !(!_?.is_active && _?.is_delete && !Boolean(_?.id)))
          ];
          setFinalShareHolders(tempFinalShareHolders);
        }

        let tempPropertyContracts = [];
        let tempFinalPropertyContracts= [];

        if (data?.propertyContracts?.length > 0) {
          tempPropertyContracts = [
            ...data?.propertyContracts?.filter?.(_ => _?.isEdited)?.map?.(_ => {
              let tempPropertyContract = {
                contract_id: _.contract.id,
                is_active: _?.is_active,
                is_delete: _?.is_delete,
                updated_at: current_date,
                updated_by: userProfileID,
              };
              if (_?.id) {
                tempPropertyContract["id"] = _?.id;
              } else {
                tempPropertyContract["created_at"] = current_date;
                tempPropertyContract["created_by"] = userProfileID;
              }
              return tempPropertyContract;
            })
          ]
        }

        if (tempPropertyContracts?.length > 0) {
          tempFinalPropertyContracts = [
            ...tempPropertyContracts?.filter?.(_ => !(!_?.is_active && _?.is_delete && !Boolean(_?.id)))
          ];
          setFinalPropertyContracts(tempFinalPropertyContracts);
        }

        let tempPropertyProjects = [];
        let tempFinalPropertyProjects= [];

        if (data?.propertyProjects?.length > 0) {
          tempPropertyProjects = [
            ...data?.propertyProjects?.filter?.(_ => _?.isEdited)?.map?.(_ => {
              let tempPropertyProject = {
                project_id: _.project.id,
                is_active: _?.is_active,
                is_delete: _?.is_delete,
                updated_at: current_date,
                updated_by: userProfileID,
              };
              if (_?.id) {
                tempPropertyProject["id"] = _?.id;
              } else {
                tempPropertyProject["created_at"] = current_date;
                tempPropertyProject["created_by"] = userProfileID;
              }
              return tempPropertyProject;
            })
          ]
        }

        if (tempPropertyProjects?.length > 0) {
          tempFinalPropertyProjects = [
            ...tempPropertyProjects?.filter?.(_ => !(!_?.is_active && _?.is_delete && !Boolean(_?.id)))
          ];
          setFinalPropertyProjects(tempFinalPropertyProjects);
        }

        let totalShare = 0.0;
        tempFinalShareHolders?.forEach(_ => {
          totalShare = totalShare + parseFloat(_?.value);
        })

        if (totalShare > 100) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Total Share % should not exceed 100%"),
          });
        } else {
          setValue(2)
        }
      }
      else {
        return false
      }
    }
    if (value === 2) {
      setDisable(false);
      setValue(3);
    }
  };
  const previous = () => {
    setValue(prevValue => prevValue - 1);
    if (!state?.main?.isEdit) {
      setMapLoad(false)
    }
  };

  const removeimg = () => {
    set_deleted_s3_urls([...deleted_s3_urls, data?.img]);
    setData({ ...data, img: "" })
  }

  const tabTittle = [
    {
      label: t("Property Details"),
      sub: t("Enter Property, Address & Contact Details"),
      imgSelect: value === 2 || value === 3 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
      value: 1,
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t("Attachments"),
      sub: t("Upload assets like Image, 360 Image, Video"),
      imgSelect: value === 3 ? <img src="/images/correct_icon.svg" alt='icon' /> : <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
      value: 2,
      className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t("Other fields"),
      sub: t("Enter Authority, Utilities Details"),
      imgSelect: <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
      value: 3,
      className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
    }
  ]
  const getPropertyByCompanyID = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    const payload = {
      query: queryOne().loc.source.body,
      variables: {
        propertyID: state?.main?.propertyID?.length > 0 ? state?.main?.propertyID : "",
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false).then(async (res) => {
        let edit = res?.data?.data?.property?.[0];
        const responseShareHolders = res?.data?.data?.share_holder ?? [];
        const responsePropertyContracts = res?.data?.data?.property_contracts ?? [];
        const responsePropertyProjects = res?.data?.data?.property_projects ?? [];
        let tempRevenueType = "";
        for (let i = 0; i < enumValue.revenue_type.length; i++) {
          if (enumValue.revenue_type[i].value === edit?.revenue_type) {
            tempRevenueType = enumValue.revenue_type[i];
          }
        }

        let contact_payload = {
          filter: { contact_ids: responseShareHolders?.map?.(_ => _?.contact_id) },
          is_full_list: true,
        }

        const shareHolderContactsResponse = await NetworkCall(
          `${config.api_url}/contact/list`,
          NetWorkCallMethods.post, contact_payload, null, true, false
        ).catch((e) => {
          alert.setSnack({
            ...alert, open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
          });
        })

        const shareHolderContacts = shareHolderContactsResponse?.data?.data ?? [];

        let contract_payload = {
          contract_type: [9],
          company_id: selectedCompany?.value,
          filter: { contract_ids: responsePropertyContracts?.map?.(_ => _?.contract_id) },
          is_full_list: true,
        }

        const propertyContractsResponse = await NetworkCall(
          `${config.api_url}/contract/get_all_contract`,
          NetWorkCallMethods.post, contract_payload, null, true, false
        ).catch((e) => {
          alert.setSnack({
            ...alert, open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
          });
        })

        const propertyContracts = propertyContractsResponse?.data?.data ?? [];

        let project_payload = {
          company_id: selectedCompany?.value,
          filter: { project_ids: responsePropertyProjects?.map?.(_ => _?.project_id) },
          is_full_list: true,
        }

        const propertyProjectsResponse = await NetworkCall(
          `${config.api_url}/project/list`,
          NetWorkCallMethods.post, project_payload, null, true, false
        ).catch((e) => {
          alert.setSnack({
            ...alert, open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
          });
        })

        const propertyProjects = propertyProjectsResponse?.data?.data?.data ?? [];

        const temp_data = {
          ...data,
          img: edit?.logo,
          bussinessmobile: {
            mobile: edit?.business_phone,
            mobile_code: edit?.business_phone_country_code,
          },
          mobile: {
            mobile: edit?.mobile_phone,
            mobile_code: edit?.mobile_country_code,
          },
          Company: edit?.company,
          Teams: edit?.team_property_access?.[0]?.teams ?? { label: "noteams", value: "noteams" },
          property: edit?.propertyName,
          oldName: edit?.propertyName,
          period: {
            value: edit?.payment_period,
            label: edit?.payment_period
          },
          status: {
            value: edit?.is_active,
            label: edit?.is_active === true ? "Active" : "In-Active",
          },
          //edit?.propertyDescription
          des: edit?.propertyDescription,
          type: edit?.propertyType,
          purpose: {
            value: edit?.property_purpose,
            label: edit?.property_purpose
          },
          revenue: tempRevenueType,
          propertyHierarchy: edit?.property_hierarchyByID ? {
            label: edit?.property_hierarchyByID?.label,
            value: edit?.property_hierarchyByID?.value
          } : "",
          unit: edit?.uomByID ? {
            label: edit?.uomByID?.label,
            value: edit?.uomByID?.value
          } : "",
          total: edit?.totalArea,
          built: new Date(edit?.year_built),
          handover: new Date(edit?.planned_hand_over_date),
          publiclist: edit?.listing_status,
          pet: edit?.pet_policy ? {
            label: edit?.pet_policy,
            value: edit?.pet_policy
          } : "",
          addressLineOne: edit?.addressByID?.addressLineOne,
          addressLineTwo: edit?.addressByID?.addressLineTwo,
          landmark: edit?.addressByID?.landmark,
          area: edit?.addressByID?.area,
          city: edit?.addressByID?.city,
          state: edit?.addressByID?.state,
          zipcode: edit?.addressByID?.zipcode,
          country: edit?.addressByID?.country,
          latitude: edit?.addressByID?.latitude,
          longitude: edit?.addressByID?.longitude,
          initials: true,
          email: edit?.emailAddress,
          website: edit?.website,
          utilitieselectricity: edit?.Utilties,
          municipality: edit?.Municipality,
          electricity: edit?.Electricity,
          gas: edit?.Natural,
          water: edit?.Water,
          geographic: edit?.Geographic,
          landNumber: edit?.land_number,
          naturalGas: edit?.util_natural_gas_connection,
          step: 0,
          doorNo: edit?.addressByID?.door_no,
          isBlock: edit?.isPropertyContainsBlock,
          isFloor: edit?.isPropertyContainsFloor,
          swimmingPool: edit?.swimming_pools,
          elevator: edit?.elevators,
          discussionForum: edit?.discussion_forum,
          amenitiesBooking: edit?.amenities_booking,
          ownerAccount: edit?.ownerByID,
          setBackArea: edit?.set_back_area,
          assetFunc: edit?.is_asset_functional_location,
          assetProperty: edit?.is_asset_property,
          is_community: edit?.is_community,
          communicationEmail: edit?.communication_email ?? "",
          titleDeed: edit?.title_deed ?? "",
          deedDate: edit?.deed_date ? new Date(edit?.deed_date) : "",
          hijriDate: edit?.hijri_date ? new Date(edit?.hijri_date) : "",
          landArea: edit?.land_area ?? "",
          marketPrice: edit?.market_price ?? "",
          smartGeoAddress: edit?.addressByID?.smart_geo_address ?? "",
          vendor: edit?.vendorByID?.id
            ? {
              label: <Stack direction={"row"} columnGap={2} alignItems={"center"}>
                <Avatar className={classes.optionAvatar}
                  src={edit?.vendorByID?.image_url} {...stringAvatar(edit?.vendorByID?.name)} />
                <Typography className={classes.optionHeader}>
                  {concat_string(
                    {
                      vendor_no: edit?.vendorByID?.vendor_no,
                      vendor_name: edit?.vendorByID?.name,
                    },
                    ["vendor_no", "vendor_name"],
                    " - "
                  )}
                </Typography>
              </Stack>,
              value: edit?.vendorByID?.id,
              developerName: edit?.vendorByID?.name ?? "",
              mobile: concat_string(
                {
                  mobile_code: edit?.vendorByID?.vendor_address?.[0]?.mobile_code,
                  mobile_no: edit?.vendorByID?.vendor_address?.[0]?.mobile_no,
                },
                ["mobile_code", "mobile_no"],
                " - ",
                "-"
              ),
              email: edit?.vendorByID?.vendor_address?.[0]?.email_id ?? "",
              website: edit?.vendorByID?.vendor_address?.[0]?.website ?? "",
            }
            : "",
          shareHolders: responseShareHolders?.map?.(_ => {
            const shareHolderContact = shareHolderContacts?.find?.(__ => __?.id === _?.contact_id);

            return {
              ..._,
              contact: {
                ...shareHolderContact,
                label: (<Stack direction={"row"} columnGap={2} alignItems={"center"}>
                  <Avatar className={classes.optionAvatar}
                    src={shareHolderContact?.image_url} {...stringAvatar(shareHolderContact?.first_name)} />
                  <Stack>
                    <Typography className={classes.optionHeader}>
                      {concat_string(
                        {
                          user_no: shareHolderContact?.user_no,
                          first_name: shareHolderContact?.first_name,
                        },
                        ["user_no", "first_name"],
                        " - "
                      )}
                    </Typography>
                  </Stack>
                </Stack>),
                value: shareHolderContact?.id,
              },
              isDeleted: false,
              isEdited: false,
            }
          }),
          propertyContracts: responsePropertyContracts?.map?.(_ => {
            const propertyContract = propertyContracts?.find?.(__ => __?.id === _?.contract_id);

            return {
              ..._,
              contract: {
                ...propertyContract,
                label: (<Stack direction={"row"} columnGap={2} alignItems={"center"}>
                  <Avatar className={classes.optionAvatar}
                    {...stringAvatar(propertyContract?.contract_no)} />
                  <Stack>
                    <Typography className={classes.optionHeader}>
                      {propertyContract?.contract_no ?? "-"}
                    </Typography>
                  </Stack>
                </Stack>),
                value: propertyContract?.id,
              },
              isDeleted: false,
              isEdited: false,
            }
          }),
          propertyProjects: responsePropertyProjects?.map?.(_ => {
            const propertyProject = propertyProjects?.find?.(__ => __?.id === _?.project_id);

            return {
              ..._,
              project: {
                ...propertyProject,
                label: (<Stack direction={"row"} columnGap={2} alignItems={"center"}>
                  <Avatar className={classes.optionAvatar}
                    {...stringAvatar(propertyProject?.project_no)} />
                  <Stack>
                    <Typography className={classes.optionHeader}>
                      {propertyProject?.project_no ?? "-"}
                    </Typography>
                  </Stack>
                </Stack>),
                value: propertyProject?.id,
              },
              isDeleted: false,
              isEdited: false,
            }
          }),
          registrationOffice: edit?.registration_officeByID?.id
            ? {
              label: <Stack direction={"row"} columnGap={2} alignItems={"center"}>
                <Avatar className={classes.optionAvatar}
                  src={edit?.registration_officeByID?.image_url} {...stringAvatar(edit?.registration_officeByID?.name)} />
                <Typography className={classes.optionHeader}>
                  {concat_string(
                    {
                      vendor_no: edit?.registration_officeByID?.vendor_no,
                      vendor_name: edit?.registration_officeByID?.name,
                    },
                    ["vendor_no", "vendor_name"],
                    " - "
                  )}
                </Typography>
              </Stack>,
              value: edit?.registration_officeByID?.id,
              officeName: edit?.registration_officeByID?.name ?? "",
              mobile: concat_string(
                {
                  mobile_code: edit?.registration_officeByID?.vendor_address?.[0]?.mobile_code,
                  mobile_no: edit?.registration_officeByID?.vendor_address?.[0]?.mobile_no,
                },
                ["mobile_code", "mobile_no"],
                " - ",
                "-"
              ),
              email: edit?.registration_officeByID?.vendor_address?.[0]?.email_id ?? "",
              website: edit?.registration_officeByID?.vendor_address?.[0]?.website ?? "",
              address: concat_string(
                {
                  door_no: edit?.registration_officeByID?.vendor_address?.[0]?.door_no,
                  street_1: edit?.registration_officeByID?.vendor_address?.[0]?.street_1,
                  street_2: edit?.registration_officeByID?.vendor_address?.[0]?.street_2,
                  landmark: edit?.registration_officeByID?.vendor_address?.[0]?.landmark,
                  area: edit?.registration_officeByID?.vendor_address?.[0]?.area,
                  city: edit?.registration_officeByID?.vendor_address?.[0]?.city,
                  state: edit?.registration_officeByID?.vendor_address?.[0]?.state,
                  country: edit?.registration_officeByID?.vendor_address?.[0]?.country,
                  pincode: edit?.registration_officeByID?.vendor_address?.[0]?.pincode,
                },
                [
                  "door_no", "street_1", "street_2",
                  "landmark", "area", "city",
                  "state", "country", "pincode",
                ], ", ", "-"
              ),
              latitude: edit?.registration_officeByID?.vendor_address?.[0]?.latitude,
              longitude: edit?.registration_officeByID?.vendor_address?.[0]?.longitude,
            }
            : "",
          is_external: edit?.is_external,
          error: {
            img: "",
            Company: '',
            Teams: '',
            property: '',
            period: '',
            status: '',
            des: '',
            type: '',
            purpose: '',
            revenue: '',
            propertyHierarchy: "",
            unit: '',
            total: '',
            built: '',
            handover: '',
            email: '',
            website: '',
            bussinessmobile: '',
            mobile: '',
            setBackArea: "",
            communicationEmail: "",
            titleDeed: "",
            deedDate: "",
            hijriDate: "",
            landArea: "",
            marketPrice: "",
            smartGeoAddress: "",
            vendor: "",
            shareHolders: "",
            registrationOffice: "",
            propertyContracts: "",
            propertyProjects: "",
          }
        }
        console.log("temp_data: ", temp_data);
        setData(temp_data);
        setImages(res?.data?.data?.property_assets)
        setLoad(true)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }).catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };
  const handleAssetFile = (data) => {
    if (data.asset_type === 3) {
      setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
      setImages(images?.filter((val) => val?.file_meta?.id !== data?.file_meta?.id));
    } else {
      set_deleted_s3_urls([...deleted_s3_urls, data?.url]);
      setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
      setImages(images?.filter((val) => val?.url !== data?.url));
    }

  };

  const checkSave = (type) => {
    setDisable(true);
    if (state?.main?.propertyID) {
      if (permission?.update) {
        type === "previous" && previous()
        type === "next" && next()
        type === "save" && save()
      } else {
        alert.setSnack({
          ...alert, open: true, msg: t("Access denied. Contact your administrator."),
          severity: AlertProps.severity.error,
        })
      }
    } else if (permission?.create) {
      type === "previous" && previous()
      type === "next" && next()
      type === "save" && save()
    } else {
      alert.setSnack({
        ...alert, open: true, msg: t("Access denied. Contact your administrator."),
        severity: AlertProps.severity.error,
      })
    }
  }
  const save = () => {
    
    deleteS3File({ urls: deleted_s3_urls });

    const datas = {
      tenantId: `${config.tenantId}`,
      name: data?.property,
      description: data?.des,
      logo: data?.img?.length > 0 ? data?.img : null,
      company_id: data?.Company?.value,
      door_no: data?.doorNo,
      street_1: data?.addressLineOne ?? null,
      street_2: data?.addressLineTwo ?? null,
      landmark: data?.landmark,
      area: data?.area,
      locality: data?.area,
      district: data?.city,
      city: data?.city,
      state: data?.state,
      year_built: data?.built ? new Date(data?.built) : null,
      planned_hand_over_date: (data?.revenue?.value === "Sale" && data?.handover) ? new Date(data?.handover) : null,
      zipcode: data?.zipcode?.toString(),
      business_phone: data?.bussinessmobile?.mobile,
      mobile_phone: data?.mobile?.mobile,
      business_phone_country_code: data?.bussinessmobile?.mobile_code,
      mobile_country_code: data?.mobile?.mobile_code,
      country: data?.country,
      latitude: parseFloat(data?.latitude),
      longitude: parseFloat(data?.longitude),
      total_area: parseFloat(data?.total),
      website: data?.website,
      email: data?.email,
      property_group_id: parseInt(data?.type?.value),
      property_purpose: data?.purpose?.value,
      revenue_type: data?.revenue?.value,
      is_active: data?.status?.value,
      assetsData: state?.main?.propertyID
        ? images?.filter((val) => !val?.id)
        : images,
      municipality_authority: data?.municipality ? data?.municipality : null,
      electrical_water_authority: data?.electricity ? data?.electricity : null,
      smart_geographic_addressing: data?.geographic ? data?.geographic : null,
      util_gas_connection: data?.gas ? data?.gas : null,
      util_water_connection: data?.water ? data?.water : null,
      util_electricity_connection: data?.utilitieselectricity ? data?.utilitieselectricity : null,
      listing_status: "None",
      payment_period: data?.period?.value,
      pet_policy: data?.pet?.value,
      land_number: data?.landNumber,
      util_natural_gas_connection: data?.naturalGas,
      created_by: localStorage.getItem("profileID"),
      is_block: data?.isBlock,
      is_floor: data?.isFloor,
      swimming_pools: parseInt(data?.swimmingPool),
      elevators: parseInt(data?.elevator),
      discussion_forum: data?.discussionForum,
      amenities_booking: data?.amenitiesBooking,
      owner_id: data?.ownerAccount?.id ?? null,
      set_back_area: parseFloat(data?.setBackArea),
      is_asset_functional_location: false,
      property_hierarchy_id: parseInt(data?.propertyHierarchy?.value),
      uom_id: data?.unit?.value,
      is_asset_property: false,
      is_community: data?.is_community,
      "size_type": "property_size",
      communication_email: data?.communicationEmail,
      title_deed: data?.titleDeed,
      deed_date: data?.deedDate ? new Date(data?.deedDate) : null,
      hijri_date: data?.hijriDate ? new Date(data?.hijriDate) : null,
      land_area: data?.landArea ? parseFloat(data?.landArea) : null,
      market_price: data?.marketPrice ? parseFloat(data?.marketPrice) : null,
      vendor_id: data?.vendor?.value,
      smart_geo_address: data?.smartGeoAddress,
      share_holders: finalShareHolders,
      registration_office_id: data?.registrationOffice?.value,
      property_contracts: finalPropertyContracts,
      property_projects: finalPropertyProjects,
    };
    if (state?.main?.propertyID) {
      datas["property_id"] = state?.main?.propertyID;
      datas["deleteAssets"] = deletedAssetURL;
    }
    if (state?.main?.teams?.value !== "noteams") {
      datas["teams_id"] = state?.main?.teams?.value;
    }

    NetworkCall(
      `${config.api_url}/property/${state?.main?.propertyID ? `update` : `create`}`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: state?.main?.propertyID ? t("Property Updated successfully.") : t("Property Created successfully."),
        });
        history.goBack(-1)
      })
      .catch((err) => {
        if (err?.response?.status === 406)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: err?.response?.data?.error?.message ? `${err?.response?.data?.error?.message} ${t("Please upgrade your plan!")}` : t("Limit Exceeded"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
          });
        }
        setDisable(false);
      });
  };


  const validate = () => {
    let isValid = true;
    let error = data.error;
    // if (data?.img?.length === 0) {
    //   isValid = false;
    //   error.img = "Property Image is Required";
    // }
    if (data?.Company?.length === 0) {
      isValid = false;
      error.Company = t("Company Name is Required");
    }
    if (data?.Teams?.length === 0) {
      isValid = false;
      error.Teams = t("Teams is Required");
    }
    if (data?.property?.length === 0) {
      isValid = false;
      error.property = t("Property Name is Required");
    } else if (propertyNameDup) {
      isValid = false;
      error.property = t("Property Name Already Existed");
    }
    if (data?.period?.length === 0) {
      isValid = false;
      error.period = t("Payment Period is Required");
    }
    if (data?.status?.length === 0) {
      isValid = false;
      error.status = t("Status is Required");
    }
    if (data?.type?.length === 0) {
      isValid = false;
      error.type = t("Property Type is Required");
    }
    if (data?.purpose?.length === 0) {
      isValid = false;
      error.purpose = t("Property Purpose is Required");
    }
    if (data?.revenue?.length === 0) {
      isValid = false;
      error.revenue = t("Revenue Type is Required");
    }
    if (data?.propertyHierarchy?.length === 0) {
      isValid = false;
      error.propertyHierarchy = t("Property Hierarchy is Required");
    }
    if (data?.area?.length === 0) {
      isValid = false;
      error.area = t("Area is Required");
    }
    if (data?.city?.length === 0) {
      isValid = false;
      error.city = t("City is Required");
    }
    if (data?.state?.length === 0) {
      isValid = false;
      error.state = t("State is Required");
    }
    if (data?.country?.length === 0) {
      isValid = false;
      error.country = t("Country is Required");
    }
    // if (Object.keys(data?.ownerAccount)?.length === 0) {
    //   isValid = false;
    //   error.ownerAccount = "Owner Account is Required";
    // }
    if (data?.unit?.length === 0) {
      isValid = false;
      error.unit = t("Measurement Unit is Required");
    }
    if (data?.total?.length === 0) {
      isValid = false;
      error.total = t("Total Property Area is Required");
    } else if (!Boolean(parseFloat(data?.total))) {
      isValid = false;
      error.total = t("Enter Valid Property Area");
    } else if (parseFloat(data?.total) < 0) {
      isValid = false;
      error.total = t("Do Not Enter Negative Value");
    }

    // if (data?.setBackArea?.length === 0) {
    //   isValid = false;
    //   error.setBackArea = "Set Back Area is Required";
    // } else if (!Boolean(parseFloat(data?.setBackArea))) {
    //   isValid = false;
    //   error.setBackArea = "Enter Valid Set Back Area";
    // } else if (parseFloat(data?.setBackArea) < 0) {
    //   isValid = false;
    //   error.setBackArea = "Do Not Enter Negative Value";
    // }

    // if (data?.swimmingPool?.length === 0) {
    //   isValid = false;
    //   error.swimmingPool = "No. of Swimming Pool is Required";
    // } else if ((parseFloat(data?.swimmingPool) - parseInt(data?.swimmingPool) > 0)) {
    //   isValid = false;
    //   error.swimmingPool = "Enter Valid no. of Swimming Pool";
    // } else if (parseInt(data?.swimmingPool) < 0) {
    //   isValid = false;
    //   error.swimmingPool = "Do Not Enter Negative Value";
    // }
    // if (data?.elevator?.length === 0) {
    //   isValid = false;
    //   error.elevator = "No. of Elevator is Required";
    // } else if ((parseFloat(data?.elevator) - parseInt(data?.elevator) > 0)) {
    //   isValid = false;
    //   error.elevator = "Enter Valid no. of Elevator";
    // } else if (parseInt(data?.elevator) < 0) {
    //   isValid = false;
    //   error.elevator = "Do Not Enter Negative Value";
    // }
    if (data?.built?.length === 0) {
      isValid = false;
      error.built = t("Year Built is Required");
    }
    if (data?.revenue === "Sale" && data?.handover?.length === 0) {
      isValid = false;
      error.handover = t("Handover Date is Required");
    }
    if (state?.main?.propertyID) {
      if (data?.bussinessmobile?.mobile?.length === 0) {
        isValid = false;
        error.bussinessmobile = t("Business Phone is Required");
      }
      if (data?.mobile?.mobile.length === 0) {
        isValid = false;
        error.mobile = t("Mobile Phone is Required");
      }

    }

    else {
      if (data?.bussinessmobile?.mobile?.length === 0) {
        isValid = false;
        error.bussinessmobile = t("Business Phone is Required");
      }
      if (data?.mobile?.mobile?.length === 0) {
        isValid = false;
        error.mobile = t("Mobile Phone is Required");
      }
    }


    // eslint-disable-next-line
    if (data?.email?.length !== 0 && !ValidateEmail(data?.email)) {
      isValid = false;
      error.email = t("Enter valid Email");
    }

    // eslint-disable-next-line
    
    if (data?.communicationEmail?.length === 0) {
      isValid = false;
      error.communicationEmail = t("Alert Email is Required");
    } else if (data?.communicationEmail?.length !== 0 && !ValidateEmail(data?.communicationEmail)) {
      isValid = false;
      error.communicationEmail = t("Enter valid Email");
    }

    // eslint-disable-next-line
    var website = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(data?.website)

    if (data?.website?.length !== 0 && !website) {
      isValid = false;
      error.website = t("Enter valid Website");
    }

    if (!isValid) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }
    setData({ ...data, error });

    return isValid;
  };

  const mapResultData = (map) => {
    let result = mapResult(map);
    let error={ ...data?.error, area: "", city: "", country: "", state: "" }
    setData({ ...data, ...result, error })
  }
  const updateimg = async (data) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    try {
      const uploaded_file = await singleFileUpload(data, { companyId: state?.main?.company?.value, type: assestType?.Thumbnails }, alert, allowed_file_size)
      if (uploaded_file?.[0]?.url) {
        updateState("img", uploaded_file?.[0]?.url)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      } else {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }
    }
    catch (err) {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: t("Some Thing Went Wrong"),
      });
    }

  }

  const updateShareHolders = (t, k, v, i) => {
    let tempShareHolders = [...data?.shareHolders];

    switch (t) {
      case "add":
        const defaultShareHolder = {
          contact: "",
          name: "",
          mobile: {
            mobile: "",
            mobile_code: "",
          },
          email_id: "",
          value: "0.0",
          mobile_no_country_code: "",
          mobile_no: "",
          is_active: false,
          is_delete: false,
          isDeleted: false,
          isEdited: false,
        };
        tempShareHolders?.push(defaultShareHolder);
        break;

      case "edit":
        switch (k) {
          case "mobile":
            tempShareHolders[i] = {
              ...tempShareHolders[i],
              [k]: v,
              mobile_no_country_code: v?.mobile_code,
              mobile_no: v?.mobile,
              is_active: true,
              is_delete: false,
              isEdited: true,
            }
            break;
          case "isDeleted":
            tempShareHolders[i] = {
              ...tempShareHolders[i],
              [k]: v,
              is_active: false,
              is_delete: true,
              isEdited: true,
            }
            break;

          default:
            tempShareHolders[i] = {
              ...tempShareHolders[i],
              [k]: v,
              is_active: true,
              is_delete: false,
              isEdited: true,
            }
            break;
        }

        break;

      default:
        break;
    }

    setData({ ...data, shareHolders: tempShareHolders });
  }

  const updatePropertyContracts = (t, k, v, i) => {
    let tempPropertyContracts = [...data?.propertyContracts];

    switch (t) {
      case "add":
        const defaultPropertyContract = {
          contract: "",
          is_active: false,
          is_delete: false,
          isDeleted: false,
          isEdited: false,
        };
        tempPropertyContracts?.push(defaultPropertyContract);
        break;

      case "edit":
        switch (k) {
          case "isDeleted":
            tempPropertyContracts[i] = {
              ...tempPropertyContracts[i],
              [k]: v,
              is_active: false,
              is_delete: true,
              isEdited: true,
            }
            break;

          default:
            tempPropertyContracts[i] = {
              ...tempPropertyContracts[i],
              [k]: v,
              is_active: true,
              is_delete: false,
              isEdited: true,
            }
            break;
        }

        break;

      default:
        break;
    }

    setData({ ...data, propertyContracts: tempPropertyContracts });
  }

  const updatePropertyProjects = (t, k, v, i) => {
    let tempPropertyProjects = [...data?.propertyProjects];

    switch (t) {
      case "add":
        const defaultPropertyProject = {
          project: "",
          is_active: false,
          is_delete: false,
          isDeleted: false,
          isEdited: false,
        };
        tempPropertyProjects?.push(defaultPropertyProject);
        break;

      case "edit":
        switch (k) {
          case "isDeleted":
            tempPropertyProjects[i] = {
              ...tempPropertyProjects[i],
              [k]: v,
              is_active: false,
              is_delete: true,
              isEdited: true,
            }
            break;

          default:
            tempPropertyProjects[i] = {
              ...tempPropertyProjects[i],
              [k]: v,
              is_active: true,
              is_delete: false,
              isEdited: true,
            }
            break;
        }

        break;

      default:
        break;
    }

    setData({ ...data, propertyProjects: tempPropertyProjects });
  }

  const render = () => {
    return <>
      <Subheader
        title={t("Property Add/Edit")}
        goBack={() => { history.goBack(-1) }}
        previousAndNextBtn={true}
        previousBtn={() => checkSave("previous")}
        nextBtn={() => checkSave("next")}
        previousBtnDisabled={value <= 1 && true}
        nextBtnDisabled={value === 3 ? true : false}
      />
      <PropertyTabs value={value} isHeigth
        t={t}

        tab1={<PropertyDetails updatePropertyContracts={updatePropertyContracts} updatePropertyProjects={updatePropertyProjects} propertyID={state?.main?.propertyID} t={t} id='pushTitle' data={data} updateState={updateState} handlePropertyName={handlePropertyName} enumValue={enumValue} mapResult={mapResultData} removeimg={removeimg} updateimg={updateimg} mapLoad={mapLoad} load={load} updateShareHolders={updateShareHolders} />}
        tab2={<Assests t={t} imageList={images} updateImg={setImages} handleAssetFile={handleAssetFile} companyId={state?.main?.company?.value} />}
        tab3={<Bussiness t={t} data={data} updateState={updateState} />}
        tabTittle={tabTittle}
        showBtns={true}
        isSaveDisable={disable}
        saveBtnText={state?.main?.propertyID ? t("Update") : t("Submit")}

        save={() => checkSave("save")}
      />
    </>
  }

  return (
    <div style={{ position: 'relative' }}>
      {accessCheckRender(render, permission)}
    </div>
  )
}
export default withNamespaces("properties")(PropertyCreation)
