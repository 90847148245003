import React from 'react'
import { nextTenant, editConstractDataTenant } from './function'
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { residentOnboardingStyle } from './style';
import { Steps } from './steps';
import ContactIcon from './utils/contactIcon';
import BillingContactIcon from './utils/billingContactIcon';
import { boardingPropertyType } from './utils/residentOnboard';
import PropertyOwnerIcon from './utils/propertyOwnerIcon';
import UnitIcon from './utils/unitIcon';
import { SELECT_CONTACT, SELECT_ACCOUNT, GET_LEAD_DETAILS, SELECT_CONTRACT, SELECT_ACCOUNT_ALL } from '../../graphql/residentOnbordingQueries';
import { LocalStorageKeys, SemiBold, billGenENUM, enumSelect, enum_types, getEnumValueOppertunity } from '../../utils';
import { loadOptions, loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import { Billing_cycle_date, Billing_cycle_method, quotation_validity, validateCustom, yesorno } from '../../utils/companySettings';
import { getDateValue } from '../propertyFinder4/utils';
import { useHistory } from "react-router-dom";
import { AlertContext, BackdropContext } from "../../contexts";
import { useApolloClient } from "@apollo/client";
import { GETCOMPANYDETAILS } from '../../graphql/quotationQueries';
import moment from 'moment';



export const TenantCreate = ({
    onClose = () => false,
    state,
    company_id,
    quote_id,
    lead_id,
    reload = () => false,
    oppertunity_only = false,
    title = null,
    btnName = null,
    t,
    type,
    open=false,
    input_type=""
}) => {
    const classes = residentOnboardingStyle()
    const history = useHistory();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const client = useApolloClient();
    const [state1,setState1]=React.useState(true)

    // -------------- STATE ---------------------
    const [createState, setCreateState] = React.useState({
        auto_renewal: false,
        priority: "high",
        is_exist: true,
        apply_grace_peroid: "In the Beginning",
        grace_peroid: null,
        billingAccount: false,
        propertyType: null,
        activeOccupents: null,
        subject: "",
        duration: {
            select: "Monthly",
            value: null,
        },
        durationRange: null,
        startDate: null,
        endDate: null,
        billStartDate: null,
        paymentOption: {
            value: "Online Payment",
            label: "Online Payment",
        },
        paymentPeriod: null,
        billingCycleMethod: null,
        billingCycleDate: null,
        contact: null,
        billing: null,
        owner: null,
        property: [],
        custom_date: null,
        bill_cycle_date: null,
        initial_bill_cycle_date: null,
        show_property: false,
        quote_id: null,
        deleted_property: [],
        unit_type: 1,
        property_against: [],
        properties: [],
        account:null,
        duration1:{
            select: "Monthly",
            value: null,
        },
        contract:null,
        startDate1: null,
        endDate1: null,
        error: {
            contact: "",
            propertyType: "",
            subject: "",
            duration: "",
            durationRange: "",
            startDate: "",
            paymentPeriod: "",
            property: "",
            owner: "",
            activeOccupents: "",
            properties: "",
            property_against: "",
            account:"",
            contract:"",
    
        }
    })
    const [step, setStep] = React.useState(1)
    const [enumValue, setEnum] = React.useState({
        unit_usage: [],
        priority: [],
        source: [],
        lease_type: [],
        billGenCycle: [],
        billing_cycle_date: [],
        payment_period: [],
        apply_grace_period_type: [],
        payment_mode: [],
        turnover_type: [],
        auto_renewal_escalation: [],
    });
    const [disable, setDisable] = React.useState(false)


    // update state
    const updateState = (key, value) => {
        if(key === "contract"){
            let error = createState?.error;
            error[key] = "";
          return  setCreateState({ ...createState, [key]: value, error , duration:{
                select:value?.period,
                value:value?.period_value
            } ,startDate:new Date(value?.start_date) , endDate:new Date(value?.start_date)})
    
        }
        if(key === "account"){
            let error = createState?.error;
            error[key] = "";
          return  setCreateState({ ...createState, [key]: value, error , contract:null})

        }
        if(key === "unit_category"){
            let error = createState?.error;
            error[key] = "";
          return  setCreateState({ ...createState, [key]: value, error , unit_category:null})
        }
        let error = createState?.error;
        error[key] = "";
        setCreateState({ ...createState, [key]: value, error })
    }

    const getEnum = async () => {
        const result = await enumSelect([
            enum_types.urgent_type,
            enum_types.l_period,
            enum_types.lead_source,
            enum_types.billing_cycle,
            enum_types.billing_cycle_date,
            enum_types.unit_payment_period,
            enum_types.apply_grace_period_type,
            enum_types.payment_mode,
            enum_types?.turnover_type,
            enum_types?.auto_renewal_escalation,
        ]);
        setEnum({
            priority: result?.urgent_type,
            lease_type: result?.l_period
                ?.filter((val) => val?.value !== "Quarterly")
                .filter((val) => val?.value !== "Daily"),
            billGenCycle: result?.billing_cycle,
            billing_cycle_date: result?.billing_cycle_date
                ?.filter((i) => i?.value !== billGenENUM.last_day)
                .filter((i) => i?.value !== billGenENUM.mid_date)
                .filter((i) => i?.value !== billGenENUM.first),
            payment_period: result?.payment_value_type
                .filter((val) => val?.value !== "On Completion")
                .filter((val) => val?.value !== "Milestone Based")
                .filter((val) => val?.value !== "Hourly")
                .filter((val) => val?.value !== "Weekly"),
            source: result?.lead_source,
            apply_grace_period_type: result?.apply_grace_period_type,
            payment_mode: result?.payment_mode,
            turnover_type: result?.turnover_type,
            auto_renewal_escalation: result?.auto_renewal_escalation,
        });
    };

    const sumbit = () => {
        setDisable(true);
        onClose()
    }

    const getEditData = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "...fetching",
        });
       
        client.query({
            query: GET_LEAD_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: lead_id,
            }
        }).then((res) => {
            const response = editConstractDataTenant(res?.data?.lead?.[0], quote_id)
            setCreateState(response)
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "...fetching",
            });
        }).catch((err) => {
            console.log(err)
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "...fetching",
            });
        })
    }
    //get company details
    const getCompanyDetails = (value) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "...fetching",
        });
        client
            .query({
                query: GETCOMPANYDETAILS,
                fetchPolicy: "network-only",
                variables: {
                    id: company_id,
                },
            })
            .then((res) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "...fetching",
                });
                setCreateState({
                    ...createState,
                    custom_date: res?.data?.company_master[0]?.billing_cycle_date !==
                        "As per the Agreement Date" ? res?.data?.company_master[0]?.billing_day : null,
                    billingCycleMethod: res?.data?.company_master[0]?.billing_cycle,
                    billingCycleDate:
                        res?.data?.company_master[0]?.billing_cycle_date ===
                            "As per the Agreement Date"
                            ? res?.data?.company_master[0]?.billing_cycle_date
                            : res?.data?.company_master[0]?.billing_day,
                    paymentPeriod: {
                        value: res?.data?.company_master[0]?.payment_period,
                        label: res?.data?.company_master[0]?.payment_period,
                    }
                })
            })
            .catch((err) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "...fetching",
                });
                console.log(err)});
    };

    React.useMemo(() => {
        if (createState?.startDate !== null && createState?.duration?.value) {
            let end_date = getDateValue(
                {
                    date: createState?.startDate ?? "",
                    input: createState?.duration?.value ?? "",
                    period: createState?.duration?.select ?? "",
                },
                true,
                0,
                true
            );
            setCreateState({
                ...createState,
                endDate: end_date ?? null,
                billStartDate: createState?.startDate,
                custom_date: new Date(createState?.startDate)?.getDate() > 28 ? 1 : createState?.initial_bill_cycle_date,
                billingCycleDate: new Date(createState?.startDate)?.getDate() > 28 ? null : createState?.billingCycleDate


            });
        }
        else {
            if (createState?.startDate) {
                setCreateState({
                    ...createState,
                    custom_date: new Date(createState?.startDate)?.getDate() > 28 ? 1 : createState?.initial_bill_cycle_date,
                    billingCycleDate: new Date(createState?.startDate)?.getDate() > 28 ? null : createState?.billingCycleDate
                })

            }
            return false
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        createState?.startDate,
        createState?.duration,
        createState?.paymentPeriod
    ]);

    const onCategoryChange=(v)=>{
        setCreateState({
            ...createState,
            unit_category:v,
            properties:[],
            error:{
                ...createState?.error,
                unit_category:""

            }
        })
    }
    React.useEffect(() => {
        getEnum()
        if (lead_id?.length > 0) {
            getEditData()
        }
        else {
            getCompanyDetails()
        }
        // eslint-disable-next-line
    }, [lead_id])


    //on change grace peroid value
    const onChangeGracePeroidValue = (value) => {
        let error = createState.error;
        error.grace_peroid = "";
        setCreateState({
            ...createState,
            grace_peroid: value,
            error,
        });
    };

    const onChangeGracePeroid = (value) => {
        let apply_grace_period = value === true ? "In the End" : "In the Beginning";
        setCreateState({
            ...createState,
            apply_grace_peroid: apply_grace_period,
        });
    };

    return (
        <Box className={classes.drawerBox}>
            <Box className={classes.header}>
                <Stack direction={"row"} spacing={2} alignItems={"center"} p={1.5}>
                    <Typography className={classes.drawerCloseBtn} onClick={onClose}>X</Typography>
                    <Typography className={classes.drawerHeading}>{title??lead_id?.length > 0  ? t("Edit Tenant On-Boarding") : t("Create Tenant On-Boarding")}</Typography>
                </Stack>
                <Divider></Divider>
            </Box>
            <Box className={classes.body}>
                <Box p={2}>
                    {
                        step === 1 &&
                        <Steps t={t}
                            component={[
                                {
                                    type: "propertyType",
                                    data: boardingPropertyType,
                                    selected: createState?.propertyType,
                                    handleClick: (value) => updateState("propertyType", value),
                                    header: t("Boarding Property Type"),
                                    error: createState?.error?.propertyType?.length > 0,
                                    errorMsg: createState?.error?.propertyType,
                                    isActive: lead_id?.length > 0 ? false : true,
                                    isRequired: true
                                },
                                {
                                    type: "selectContract",
                                    data: "Contract details",
                                    isActive: true,

                                   
                                },
                                {
                                    type: "customSelectBox",
                                    labelText:"Select Account",
                                    placeHolder: "Choose the Account",
                                    handleChange: (value) => updateState("account", value),
                                    value: createState?.account,
                                    is_notHeight:true,
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptions(
                                            search,
                                            array,
                                            handleLoading,
                                            SELECT_ACCOUNT_ALL,
                                            "contact_account",
                                            { isActive: true, client: localStorage.getItem(LocalStorageKeys.clinetID), company_id: company_id ?? 1 },
                                            { contact: true },
                                            false,
                                            false,
                                        ),
                                    isActive: true,
                                    isRequired: false,
                                    menuPlacement: 'auto',
                                    border: "none",
                                     isError: createState?.error?.account?.length > 0,
                                    errorMessage: createState?.error?.account,
                                },
                                {
                                    type: "customSelectBox",
                                    labelText:"Contract Type",
                                    placeHolder: "Choose the contract",
                                    handleChange: (value) => updateState("contract", value),
                                    value: createState?.contract,
                                    key:JSON.stringify(createState?.account),
                                    is_notHeight:true,
                                    is_date:true,
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptions(
                                            search,
                                            array,
                                            handleLoading,
                                            SELECT_CONTRACT,
                                            "contract",
                                            { isActive: true, client: localStorage.getItem(LocalStorageKeys.clinetID), account_id: createState?.account?.value },
                                            { contact: true },
                                            false,
                                            false,
                                        ),
                                    isActive: true,
                                    isRequired: false,
                                    menuPlacement: 'auto',
                                    border: "none",
                                    is_noAvatar:true,
                                    isError: createState?.error?.contract?.length > 0,
                                    errorMessage: createState?.error?.contract,
                                },
                                // {
                                //     sm: 12,
                                //     md: 12,
                                //     lg: 12,
                                //     isActive: true,
                                //     is_notHeight:true,
                                //     type: "duration",
                                //     label: t("Duration"),
                                //     value: createState?.duration1,
                                //     onChange: (value) => updateState("duration1", value),
                                //     placeholder: "Duration",
                                //     isRequired: true,
                                //     options: enumValue?.lease_type?.filter((x) => x?.value !== "Weekly"),
                                //     isError: createState?.error?.duration?.length > 0,
                                //     errorMessage: createState?.error?.duration,
                                //     size : {
                                //         item: 8.5,
                                //         item1: 3.5,
                                //       },
                                // },
                                // {
                                //     type: "datePicker",
                                //     header: t("Start Date"),
                                //     value: createState?.startDate1,
                                //     handleChange: (value) => updateState("startDate", value),
                                //     sm: 6,
                                //     md: 6,
                                //     lg: 6,
                                //     isActive: true,
                                //     isrequired: true,
                                //     isError: createState?.error?.startDate1?.length > 0,
                                //     errorMessage: createState?.error?.startDate1,

                                // },
                                // {
                                //     type: "datePicker",
                                //     header: t("End Date"),
                                //     value: createState?.endDate1,
                                //     handleChange: (value) => updateState("endDate", value),
                                //     sm: 6,
                                //     md: 6,
                                //     lg: 6,
                                //     isActive: true,
                                //     isrequired: true,
                                //     isReadonly: true,

                                // },
                            ]}
                        />
                    }
                      {
                        (lead_id?.length > 0  ? step === 0 : step === 2) &&
                        <Steps t={t}
                            component={[
                                {
                                    type: "chooseComponent",
                                    header: t("Choose the contact"),
                                    title: t("Choose contact"),
                                    subTitle: t("Pick contact from registered contact"),
                                    noSelectedTitle: t("No contacted selected to display"),
                                    icon: <ContactIcon />,
                                    options: [],
                                    topHeader: t("Choose The Contact"),
                                    onchange: (value) => updateState("contact", value),
                                    value: createState?.contact,
                                    onDelete: () => updateState("contact", null),
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptions(
                                            search,
                                            array,
                                            handleLoading,
                                            SELECT_CONTACT,
                                            "contact",
                                            { isActive: true, client: localStorage.getItem(LocalStorageKeys.clinetID)},
                                            { contact: true },
                                            false,
                                            false,
                                        ),
                                    error: createState?.error?.contact?.length > 0,
                                    errorMsg: createState?.error?.contact,
                                    isActive: true,
                                    // border: "none"

                                },
                                {
                                    type: "addButton",
                                    text: t("Add Billing Account"),
                                    hidden: createState?.billingAccount,
                                    click: () => updateState("billingAccount", true),
                                    isActive: true,
                                },
                                {
                                    type: "chooseComponent",
                                    header: t("Choose the Billing Account"),
                                    title: t("Choose Billing Account"),
                                    // subTitle: t("Choose a billing account for existing resident"),
                                    noSelectedTitle: t("No account selected to display"),
                                    icon: <BillingContactIcon />,
                                    options: [],
                                    visible: createState?.billingAccount,
                                    handleRemove: () => updateState("billingAccount", false),
                                    onchange: (value) => updateState("billing", value),
                                    value: createState?.billing,
                                    onDelete: () => updateState("billing", null),
                                    loadOptions: (search, array, handleLoading, primary) => {

                                        if (primary) {
                                            return loadOptionsApis(
                                                "contact/primary_account",
                                                {
                                                    contact_id: createState?.contact?.value ?? null,
                                                    email_id: createState?.contact?.email_id,
                                                    company_id
                                                },
                                                search,
                                                array,
                                                handleLoading,
                                                "data"
                                            )
                                        }
                                        return loadOptions(
                                            search,
                                            array,
                                            handleLoading,
                                            SELECT_ACCOUNT,
                                            "contact_account",
                                            { isActive: true, client: localStorage.getItem(LocalStorageKeys.clinetID), company_id: company_id },
                                            { contact: true },
                                            false,
                                            false,
            
                                        )
                                    },
                                    isActive: true,
                                    isRequired: false,
                                    menuPlacement: 'top',
                                    border: "none",
                                    boldHeader: false,
                        isBorder: false,
                        showRemove: true,
                        is_toggle: true,
                        state1: state1,
                        setState1: (e) => setState1(e ?? false),
                        t
                                },
                            ]}
                        />
                    }
                    {
                      ( lead_id?.length > 0  ? step === 2 :  step === 3) &&
                        <Steps t={t}
                            component={[
                                {
                                    type: "multiLineText",
                                    header: t("Subject"),
                                    value: createState?.subject,
                                    handleChange: (value) => updateState("subject", value),
                                    error: createState?.error?.subject?.length > 0,
                                    errorMsg: createState?.error?.subject,
                                    isActive: true,
                                    isRequired: true

                                },
                                {
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    isActive: true,
                                    type: "duration",
                                    label: t("Manage Duration"),
                                    value: createState?.duration,
                                    onChange: (value) => updateState("duration", value),
                                    placeholder: "Manage Duration",
                                    isRequired: true,
                                    options: enumValue?.lease_type?.filter((x) => x?.value !== "Weekly"),
                                    isError: createState?.error?.duration?.length > 0,
                                    errorMessage: createState?.error?.duration,
                                    size:{
                                        item: 4.5,
                                        item1: 7.5,
                                    }
                                },

                                {
                                    type: "datePicker",
                                    header: t("Start Date"),
                                    value: createState?.startDate,
                                    handleChange: (value) => updateState("startDate", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    isActive: true,
                                    isrequired: true,
                                    isError: createState?.error?.startDate?.length > 0,
                                    errorMessage: createState?.error?.startDate,
                                    isReadonly: true

                                },
                                {
                                    type: "datePicker",
                                    header: t("End Date"),
                                    value: createState?.endDate,
                                    handleChange: (value) => updateState("endDate", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    isActive: true,
                                    isrequired: true,
                                    isReadonly: true,

                                },
                                {
                                    type: "datePicker",
                                    header: t("Bill Start Date"),
                                    value: createState?.billStartDate,
                                    handleChange: (value) => updateState("billStartDate", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    isActive: true,
                                    isrequired: true,
                                    isReadonly: true

                                },
                                {
                                    type: "selectBox",
                                    header: t("Payment Options"),
                                    value: createState?.paymentOption,
                                    handleChange: (value) => updateState("paymentOption", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    options: enumValue?.payment_mode,
                                    isActive: true,
                                    isrequired: true,
                                    isError: createState?.error?.paymentOption?.length > 0,
                                    errorMessage: createState?.error?.paymentOption,
                                    menuPlacement: "top",
                                    fontFamily: SemiBold

                                },
                                {
                                    type: "selectBox",
                                    header: t("Payment Period"),
                                    value: createState?.paymentPeriod,
                                    handleChange: (value) => updateState("paymentPeriod", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    options: getEnumValueOppertunity(createState?.duration?.select, createState?.duration?.value, enumValue?.payment_period),
                                    isActive: true,
                                    isrequired: true,
                                    isError: createState?.error?.paymentPeriod?.length > 0,
                                    errorMessage: createState?.error?.paymentPeriod,
                                    menuPlacement: "top",
                                    fontFamily: SemiBold

                                },
                                {
                                    type: "toggleButton",
                                    header: t("Billing Cycle Method"),
                                    value: createState?.billingCycleMethod,
                                    handleChange: (value) => updateState("billingCycleMethod", value),
                                    option: Billing_cycle_method,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    isActive: createState?.paymentPeriod?.value === "Prepaid" ? false : true,
                                    isrequired: true,
                                    isError: createState?.error?.billingCycleMethod?.length > 0,
                                    errorMessage: createState?.error?.billingCycleMethod,


                                },
                                {
                                    sm: 6,
                                           md: 6,
                                           lg: 6,
                                           isActive: createState?.paymentPeriod?.value === "Prepaid" ? false : true,
                                           type: "toggle1",
                                           label: t("Billing Cycle Date"),
                                           value: createState?.billingCycleDate,
                                           placeholder: t("Billing Cycle Date"),
                                           onChange: (value) => updateState("billingCycleDate", value),
                                           error: createState?.error?.billingCycleDate,
                                           isRequired: true,
                                           options: Billing_cycle_date ?? [],
                                           isMulti: true,
                                           textBoxIsReadOnly: createState?.startDate ? new Date(createState?.lease_start_date).getDate() > 28 ? true : false : false,
                                           openCustom: false,
                                           customValue: createState?.billingCycleDate === "As per the Agreement Date" ? "" : createState?.billingCycleDate,
                                           custom: createState?.billingCycleDate === "As per the Agreement Date" ? true : false,
                                           is_Oppertunity: true,
                                           customDay: createState?.billingCycleDate === "As per the Agreement Date" ? false : true,
                                           onCustom: (value) => updateState("billingCycleDate", value),
                                           customerror: createState?.error?.billingCycleDate,
                                           selectBillingCycle: true,
                                     
                                     
                                         },
                                {
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    type: "toggleButton",
                                    header: "Auto Renewal",
                                    value: createState?.auto_renewal,
                                    handleChange: (value) => updateState("auto_renewal", value),
                                    option: yesorno,
                                    isActive: oppertunity_only,
                                    isrequired: true,
                                    isError: createState?.error?.auto_renewal?.length > 0,
                                    errorMessage: createState?.error?.auto_renewal,
                                },
                                {
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    type: "priority",
                                    label: "Priority",
                                    value: createState?.priority,
                                    placeholder: "Priority",
                                    onChange: (value) => updateState("priority", value),
                                    error: createState?.error?.priority,
                                    isRequired: true,
                                    options: enumValue?.priority,
                                    isActive: oppertunity_only,

                                },


                                {


                                    sm: 12,
                                    md: 12,
                                    lg: 12,

                                    type: "grace_period",
                                    label: "Grace Period",
                                    value: createState?.grace_peroid,
                                    onChange: (value) => onChangeGracePeroidValue(value),
                                    error: createState?.error?.grace_peroid,
                                    isRequired: true,
                                    options: quotation_validity,
                                    isMulti: true,
                                    openCustom: validateCustom([null], createState?.grace_peroid),
                                    customValue: 0,
                                    custom: true,
                                    applyGracePeriod: createState?.grace_peroid ? true : false,
                                    applyGracePeriodValue:
                                        createState?.apply_grace_peroid === "In the Beginning" ? false : true,
                                    OnApplyGracePeriod: (value) => onChangeGracePeroid(value),
                                    isActive: oppertunity_only,
                                    // is_Oppertunity: true


                                },

                            ]}
                        />
                    }
                    {
                        (lead_id?.length > 0  ? step === 3 :step === 4) &&
                        <Steps t={t}
                            component={[
                                {
                                    type: "chooseComponent",
                                    title: t("Choose Property"),
                                    placeholder: t("Search Property"),
                                    noSelectedTitle: t("No Property selected to display"),
                                    icon: <PropertyOwnerIcon />,
                                    options: [],
                                    topHeader: t("Choose Vendor Contact"),
                                    onchange: (value) => updateState("property_against", [...createState?.property_against, value]),
                                    value: createState?.property_against,
                                    onDeleteMuti: (index) => {
                                        setCreateState({
                                            ...createState,
                                            properties:[],
                                            property_against: createState?.property_against?.filter((x, i) => i !== index),
                                            unit_category:null,
                                            // deleted_property: [...createState?.deleted_property, createState?.properties?.filter((x, i) => i === index)?.[0]]
                                        })
                                    }, 
                                    isMulti: true,
                                    menuOptionHeight: "200px",
                          
                                    error: createState?.error?.property_against?.length > 0,
                                    errorMsg: createState?.error?.property_against,
                                    isActive:  true,
                                    isBorder: false,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                    loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "contract/get_contract_unit",
                                        { id: createState?.contract?.id ,property_id:createState?.contract?.contract_scope_of_works?.map((x)=>x?.property_id)?.filter((v)=>v!==null)?.length > 0 ?  [...new Set(createState?.contract?.contract_scope_of_works?.map((x)=>x?.property_id).filter((v)=>v!==null))] : [...new Set(createState?.contract?.contract_scope_of_works?.map((x)=>x?.unitByID?.property_id)?.filter((v)=>v!==null))] },
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {isProperty:true , label:"label",value:"value"}
                                    ),
                                },
                                {
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                    type: "selectBox",
                                    header: t("Unit Category"),
                                    placeholder: t("Choose Unit Category"),
                                    handleChange: (value) => onCategoryChange(value),
                                    value: createState?.unit_category,
                                    isActive: ((createState?.property_against?.length > 0 || createState?.show_property)) ? true : false,
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "queries/unit_category/get",
                                            { type:input_type , purpose: createState?.propertyType?.title ?? "Residential" },
                                            search,
                                            array,
                                            handleLoading,
                                            "unit_category_master"
                                        ),
                                    error: createState?.error?.unit_category?.length > 0,
                                    errorMsg: createState?.error?.unit_category,
                                    isrequired: true,
                                    isPaginate:true

                                },
                                {
                                    type: "chooseComponent",
                                    header: "",
                                    title: t("Choose Units"),
                                    subTitle: "",
                                    noSelectedTitle: t("No Units selected to display"),
                                    icon: <UnitIcon />,
                                    topHeader: t("Choose The Contact"),
                                    onchange: (value) => updateState("properties", [...createState?.properties, value]),
                                    value: createState?.properties,
                                    onDeleteMuti: (index) => {
                                        setCreateState({
                                            ...createState,
                                            properties: createState?.properties?.filter((x, i) => i !== index),
                                            deleted_property: [...createState?.deleted_property, createState?.properties?.filter((x, i) => i === index)?.[0]]
                                        })
                                    },
                                    isActive: (createState?.unit_category?.value && createState?.unit_type === 1) ? true : false,                                    isMulti: true,
                                    onDeleteMutiCheck: (value) => updateState("properties", value),
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "contract/get_contract_unit",
                                            { unit_category_id:createState?.unit_category?.value , id: createState?.contract?.id , is_unit:true ,property_id: createState?.property_against?.map((x)=>x?.value) , type:createState?.contract?.contract_scope_of_works?.map((x)=>x?.property_id)?.filter((v)=>v!==null)?.length > 0 ? true : false , purpose: createState?.propertyType?.title ?? "Residential", lease_start_date: `${moment(createState?.startDate).format("YYYY-MM-DD")} 00:00:00`, lease_end_date: `${moment(createState?.endDate).format("YYYY-MM-DD")} 23:59:59`},
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            { isUnit:true, label: "label", value: "value" }
                                        ),
                                    key: JSON.stringify(createState?.unit_category),
                                    error: createState?.error?.properties?.length > 0,
                                    errorMsg: createState?.error?.properties,
                                    isRequired: false,
                                }
                            ]} />
                    }
                </Box>
            </Box>
            <Box className={classes.bottom} sx={{ width: "100%" }}>
                <Stack direction={"row"} justifyContent={"space-between"}
                    alignItems={"center"} p={1}>

                    <Button
                        disabled={lead_id?.length > 0 ? step <= 1 : step <= 1}
                        onClick={() => setStep(step - 1)}
                        variant='contained'
                        className={classes.previous}
                        disableElevation
                    >
                        {t("Previous")}
                    </Button>

                    <Button
                        onClick={() => {
                            nextTenant({ step, setStep, state, setCreateState, createState, history, alert, backdrop, sumbit, company_id, reload, oppertunity_only , isEdit:lead_id?.length > 0  ?true : false , type:type})
                        }}
                        className={classes.next}
                        disabled={disable}
                        variant='contained'>
                        {lead_id?.length > 0  ? step === 3 ? `${lead_id ? t("Update") : t("Complete")} ${btnName??t("Onboarding")}` : t("Next") : step === 4 ? `${lead_id ? t("Update") : t("Complete")} ${btnName??t("Onboarding")}` : t("Next")}
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}