import { Box, Grid, Typography } from "@mui/material";
import { MobileNumberInputComponent, SelectBox, TextBox } from "../../../components";
import { useStyles } from "../styles";
import { LocalStorageKeys, networkCallback } from "../../../utils";
export const Contact = ({ data = {}, updateState = {}, t = () => false }) => {
    const classes = useStyles()
    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany)) 

    const loadOptionData = async (search, array, handleLoading, type) => {
        let result, payload, offset = 0;
        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }
        if (data?.relation_ship?.value === "Cash") {
            payload = {
                com_type: "Assets",
                comapany_id: selectedCompany?.value,
                offset: offset,
                limit: 10,
                search: search,
            }
            result = await networkCallback(
                payload, "posting_profile/get_com_master"
            )
        } else {
            payload = {
                relationship: data?.relation_ship?.value === "Sales Broker" ? "Vendor" : data?.relation_ship?.value,
                offset: offset,
                limit: 10,
                search: search,
            }
            if (data?.relation_ship?.value !== "Property Owner") {
                payload["company_id"] = selectedCompany?.value ?? undefined;
            }
            result = await networkCallback(
                payload, "account/contact");
        }
        const constructedResult = await result?.data?.map?.((e) => {
            return {
                ...e,
                label: e?.name,
                value: e?.id
            }
        })
        return {
            options: data?.relation_ship?.value === "Cash" ? constructedResult : result?.data,
            hasMore: (array?.length + result?.data?.length) < result?.count
        }

    }

    return (
        <Box className={classes.box} marginTop="16px">
            <Typography className={classes.title}>{t("Contact & Other Information")}</Typography>
            <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <MobileNumberInputComponent
                        xs={4}
                        label={t("Business Phone")}
                        handleChange={(value) => updateState("primary_contact", value)}
                        value={data?.primary_contact}
                        isError={data?.error?.primary_contact?.length > 0}
                        errorMessage={data?.error?.primary_contact}
                        isRequired
                        placeholder={t("Enter Business Phone")}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={4} md={3} lg={3}>
                    <TextBox
                        label="Title"
                        placeholder="Enter Title"
                        onChange={(value) => updateState("title", value.target.value)}
                        value={data?.title}


                    />
                </Grid> */}
                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <MobileNumberInputComponent
                        xs={4}
                        label={t("Mobile Phone")}
                        handleChange={(value) => updateState("primary_telephone", value)}
                        value={data?.primary_telephone}
                        isError={data?.error?.primary_telephone?.length > 0}
                        errorMessage={data?.error?.primary_telephone}
                        isRequired
                        placeholder={t("Enter Mobile Phone")}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <TextBox
                        label={t("Email Address")}
                        placeholder={t("Enter Email Address")}
                        onChange={(value) => updateState("primary_email", value.target.value)}
                        value={data?.primary_email}
                        errorMessage={data?.error?.primary_email}
                        isError={data?.error?.primary_email?.length > 0}
                        isrequired

                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    {
                        data?.relation_ship?.value !== null &&
                        <SelectBox
                            isRequired={data?.relation_ship?.value === "Property Owner"}
                            label={data?.relation_ship
                                ? (data?.relation_ship.value === "Property Owner"
                                    ? t("Contact") : data?.relation_ship.label)
                                : t("Contact")}
                            key={JSON.stringify(data?.relation_ship?.value)}
                            placeholder={`${t("Select")} ${data?.relation_ship
                                ? (data?.relation_ship.value === "Property Owner"
                                    ? t("Contact") : data?.relation_ship.label)
                                : t("Contact")}`}
                            onChange={(value) => updateState("contact", value)}
                            value={data?.contact}
                            isPaginate
                            loadOptions={(search, array, handleLoading) => loadOptionData(search, array, handleLoading)}
                            debounceTimeout={800}
                            errorMessage={data?.error?.contact}
                            isError={data?.error?.contact?.length > 0}
                        />
                    }

                </Grid>
            </Grid>

        </Box>

    )
}