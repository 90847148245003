import { Box, Button, Divider, Grid, Skeleton, Stack, Typography } from "@mui/material"
import { subDays } from "date-fns"
import moment from "moment"
import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useHistory } from "react-router-dom"
import CloseIconComponent from "../../assets/closeIcons"
import NoDataFound from "../../assets/noData"
import { SearchFilter } from "../../components"
import { config } from "../../config"
import { AlertContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { Routes } from "../../router/routes"
import { TimeLine } from "../../screens/leadnew/components/timeline"
import { AlertProps, convertTimeUtcToZoneCalander, NetWorkCallMethods, useWindowDimensions } from "../../utils"
import { LeadStylesParent } from "./styles"

export const LeadComments = ({ t = () => false, closeDrawer = () => false, company_id = "", clientID = "", filterData = {}, selectedCompany = "", type = "Lead", selectedTeams = "" }) => {
    const classes = LeadStylesParent()
    const size = useWindowDimensions()
    const alert = React.useContext(AlertContext)
    const history = useHistory()
    const comment_limit = 10;
    const [searchText, setSearchText] = React.useState("")
    const [commentText, setCommentText] = React.useState("")
    const [comment_offset, set_comment_offset] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [selectedUser, setSelectedUser] = React.useState({})
    const [leadDetails, setLeadDetails] = React.useState([])
    const [commentsData, setCommentsData] = React.useState({ bool: true, list: [], total_rows_count: 0 })
    const [isLoading, setIsLoading] = React.useState(true)
    const [disable, setDisable] = React.useState(false)

    const handleSearch = (value) => {
        setSearchText(value)
        getuserProfile(0, 10, value, false);
    }
    const getuserProfile = (offset = 0, limit = 10, search = "", scroll = false) => {
        if (!scroll) {
            setIsLoading(true)
            setCommentsData({ bool: true, list: [], total_rows_count: 0 })
            setOffset(0)
        }
        const currentDate = new Date()
        const payload = {
            "offset": offset,
            "company_id": company_id,
            "limit": limit,
            "client": clientID,
            "is_active": filterData?.active,
            "search": search,
            "leadSource": filterData?.lead_source?.map((x) => x?.value),
            "status": filterData?.status,
            "lead_owner": filterData?.lead_owner?.map((x) => x?.value) ?? [],
            "purpose": filterData?.purpose?.map((x) => x?.value) ?? [],
            "type": filterData?.type?.map((x) => x?.value) ?? [],
            "startDate": filterData?.date?.value?.from_date,
            "endDate": filterData?.date?.value?.to_date,
            "urgent": filterData?.urgent_type,
            comment_from: moment(subDays(currentDate, 14)).format("YYYY-MM-DD"),
            comment_to: moment(new Date()).format("YYYY-MM-DD"),
            "team": selectedTeams?.value !== "noteams" ? selectedTeams?.value : undefined
        };
        const opportunityPayload = {
            contact: filterData?.name?.length > 0 ? filterData?.name?.map((i) => i?.value) : [],
            company_id: selectedCompany?.value,
            status: filterData?.status?.length > 0 ? filterData?.status : undefined,
            priority: filterData?.periorty?.length > 0 ? filterData?.periorty : undefined,
            leadOwner: filterData?.lead?.length > 0
                ? filterData?.lead?.map((x) => x?.value)
                : undefined,
            offset: offset,
            limit: limit,
            search: search,
            comment_from: moment(subDays(currentDate, 14)).format("YYYY-MM-DD"),
            comment_to: moment(new Date()).format("YYYY-MM-DD"),
            "team": selectedTeams?.value !== "noteams" ? selectedTeams?.value : undefined
        }
        NetworkCall(
            type === "Lead" ? `${config.api_url}/lead_proxy/get_latest_comments` : `${config.api_url}/opportunity/get_latest_comments`,
            NetWorkCallMethods.post,
            type === "Lead" ? payload : opportunityPayload,
            null,
            true,
            false
        ).then((response) => {
            if (scroll) {
                setLeadDetails([...leadDetails, ...response?.data?.data.commemts])
            }
            else {
                setLeadDetails(response?.data?.data.commemts)
                if (response?.data?.data.commemts?.length > 0) {
                    setSelectedUser(response?.data?.data.commemts?.[0])
                    getLatestComments(response?.data?.data.commemts?.[0]?.id)
                }
            }
            setIsLoading(false)
        })
            .catch((error) => {
                setIsLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong please try again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    }
    const getLatestComments = (lead_id = "", offset = 0, limit = 10, scroll = false) => {
        if (!scroll) {
            setCommentsData({ ...commentsData, bool: true })
            set_comment_offset(0)
        }
        const payload = {
            offset: offset,
            limit: limit,
            id: lead_id
        }
        NetworkCall(
            type === "Lead" ? `${config.api_url}/lead_proxy/get_comments` : `${config.api_url}/opportunity/get_comments`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if (scroll) {
                    setCommentsData({ list: [...commentsData?.list, ...response?.data?.data?.list], bool: false, total_rows_count: response?.data?.data?.total })
                }
                else {
                    setCommentsData({ list: response?.data?.data?.list, bool: false, total_rows_count: response?.data?.data?.total })
                }
            })
            .catch((error) => {
                setCommentsData({ ...commentsData, bool: false, total_rows_count: 0 })
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong please try again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    }
    React.useEffect(() => {
        getuserProfile()
    }, [])
    const handleSelectedUser = (val) => {
        setSelectedUser(val)
        getLatestComments(val?.id)
    }
    const handleView = (val) => {
        if (type === "Lead") {
            history.push({
                pathname: Routes.leadview,
                state: {
                    main: {
                        company: selectedCompany,
                        id: val?.id,
                        selectedCompany: selectedCompany,
                        activeTab: 2
                    },
                },
            })
        }
        else {
            history.push({
                pathname: Routes.leadsDetails,
                state: {
                    opportunity: { ...val, is_activity_comment_enabled: ["Open"].includes(val?.status), },
                    company: selectedCompany,
                    id: val?.id,
                    activeTab: "6"
                }
            });
        }
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (commentText?.length > 0) {
            addCommand()
        }
    };
    const addCommand = () => {
        setDisable(true)
        // existing array
        const payload = {
            id: selectedUser?.id,
            comment: commentText,
            created_at: new Date().toISOString(),
        };

        NetworkCall(
            type === "Lead" ? `${config.api_url}/lead_proxy/upsert_comments` : `${config.api_url}/opportunity/upsert_comments`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setDisable(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Comment created successfully"),
                });
                setCommentText("");
                getuserProfile(0, 10, "", false)
            })
            .catch((error) => {
                setDisable(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong please try again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    };
    const fetchMoreComments = () => {
        if (commentsData?.list?.length < commentsData?.total_rows_count) {
            const CommentOffset = comment_offset + comment_limit;
            set_comment_offset(CommentOffset);
            getLatestComments(selectedUser?.id, CommentOffset, 10, true);
        }
    }
    const fetchMoreData = () => {
        const Offset = offset + 10;
        setOffset(Offset);
        getuserProfile(Offset, 10, "", true);
    }
    return (
        <Box width="750px">
            <Box className={classes.drawerHeader} display={"flex"}>
                <Box mr={2} display={'flex'} alignItems={'center'} style={{ cursor: 'pointer' }} onClick={() => closeDrawer()}><CloseIconComponent /></Box>
                <Typography className={classes.drawerTitle}>{t("Latest Comments")}</Typography>
            </Box>
            <Box p={2} sx={{ backgroundColor: "#F2F5F7" }}>
                {(!isLoading && leadDetails?.length === 0 && searchText === "") ?
                    <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}>
                        <Box className={classes.comments_no_data_box}
                            sx={{ height: size?.height - 90 }}>
                            <NoDataFound />
                        </Box>
                    </Box>
                    :
                    <Grid container sx={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}>

                        <Grid item xs={5}>

                            <Box p={2}>
                                <SearchFilter value={searchText} handleChange={(value) => handleSearch(value)} />
                            </Box>

                            {isLoading ?
                                <Box p={2} height={size?.height - 90}>
                                    {Array.from(Array(8).keys())?.map((c) => {
                                        return <Skeleton animation="wave" width={"100%"} height={"70px"} />
                                    })
                                    }
                                </Box>
                                :
                                leadDetails?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={leadDetails?.length}
                                        next={fetchMoreData}
                                        height={size?.height - 190}
                                        hasMore={true}
                                    >
                                        {leadDetails?.map((x, index) => {
                                            return (
                                                <Box className={x?.id === selectedUser?.id ? classes.selectedUser : classes.user} onClick={() => handleSelectedUser(x)} sx={{ cursor: "pointer" }}>
                                                    <Box p={1.5}>
                                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                                            <Box>
                                                                <Typography className={classes.userName}>{x?.lead_name ?? x?.prospect_first_name}</Typography>
                                                                <Stack direction="row" spacing={1} alignItems="center">
                                                                    <Typography className={classes.userSubData}>{x?.reference_no ?? x?.lead_no}</Typography>
                                                                    {x?.mobile_no !== null &&
                                                                        <>
                                                                            <Box className={classes.dot} />
                                                                            <Typography className={classes.userSubData}>{`${x?.mobile_country_code} ${x?.mobile_no}`}</Typography>
                                                                        </>
                                                                    }
                                                                </Stack>
                                                            </Box>
                                                            {x?.id === selectedUser?.id &&
                                                                <Box onClick={() => handleView(x)}>
                                                                    <Typography className={classes.viewText}>{t("View")}</Typography>
                                                                </Box>
                                                            }
                                                        </Box>
                                                        <Typography className={classes.userSubData2}>{`${x?.comments[0]?.created_by} commented on ${convertTimeUtcToZoneCalander(x?.comments?.[0]?.created_at, 'yyyy-MM-DD hh:mm A')}`}</Typography>
                                                    </Box>
                                                    {leadDetails?.length - 1 !== index &&
                                                        <Box><Divider /></Box>
                                                    }
                                                </Box>
                                            )
                                        })}
                                    </InfiniteScroll>
                                    :
                                    <Box mt={2} display={"flex"} justifyContent={"center"}>
                                        <Typography className={classes.userSubData}>{t("No Data Found")}</Typography>
                                    </Box>
                            }
                        </Grid>

                        <Grid item xs={7} sx={{ borderLeft: "1px solid #E4E8EE" }}>
                            <Box p={1}>
                                <Box mt={2}>
                                    <Typography className={classes.commentsHeader}>{t("Comments")}</Typography>
                                </Box>
                                <Box mt={2}>
                                    {(commentsData?.bool && leadDetails?.length > 0) ?
                                        <Box height={size?.height - 170}>
                                            <TimeLine isCustomStyles={true} type="loading" datas={Array.from(Array(6).keys())} />
                                        </Box>
                                        : commentsData?.bool && leadDetails?.length === 0 ?
                                            <Box className={classes.comments_no_data_box}
                                                sx={{ height: size?.height - 170 }}>
                                                <NoDataFound />
                                            </Box>
                                            :
                                            <InfiniteScroll
                                                dataLength={commentsData?.list?.length}
                                                next={fetchMoreComments}
                                                hasMore={true}
                                                height={commentsData?.bool ? size?.height - 180 : size?.height - 276}
                                            >
                                                <TimeLine datas={commentsData?.list} isCustomStyles={true} />
                                            </InfiniteScroll>
                                    }
                                </Box>
                                {!commentsData?.bool &&
                                    <div className={classes.commentbox}>
                                        <form onSubmit={onSubmit}>
                                            <input
                                                className={classes.commandinput}
                                                placeholder={t("Type Comments here")}
                                                value={commentText}
                                                onChange={(e) => setCommentText(e.target.value)}
                                            />
                                            <Button
                                                className={classes.commandbtn}
                                                variant="contained"
                                                fullWidth
                                                disabled={disable ? true : (commentText?.length > 0 ? false : true)}
                                                type="submit"
                                            >
                                                {t("Comment")}
                                            </Button>
                                        </form>
                                    </div>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                }
            </Box>

        </Box>
    )
}