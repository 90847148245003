import { useApolloClient } from "@apollo/client";
import { Badge, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import React from "react";
import { AlertDialog, FilterGenerator, SearchFilter, SelectBox, Subheader, TableWithPagination, TextBox, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE_UTILITIES_MASTER } from "../../graphql/mutations";
import { GET_UTILITIES_MASTER_TABLE_LIST } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew } from "../../utils";
import { UtilitiesMasterStyles } from "./style";
import FilterIMG from "../../assets/filter";
import { withNamespaces } from "react-i18next";
import { UtilitiesMasterDataType, UtilitiesMasterHeading, UtilitiesMasterPath } from "./utils/tableUtils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";



const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    height: '40px'
}))

const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    margin: theme.spacing(3)
}))


const initialState = () => {
    return {
        id: null,
        name: "",
        description: "",
        edit: false,
        view: false,
        itemType: "",
        itemCategory: "",
        itemMaster: "",
        error: {
            description: "",
            name: "",
            itemType: "",
            itemCategory: "",
            itemMaster: "",
        }
    }

}


const UtilitiesMaster = ({
    t = () => false
}) => {
    const defaultFilterState = { status: [true] };
    const classes = UtilitiesMasterStyles()
    const [openDialog, setOpenDialog] = React.useState(false)
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [utilitiesMasterData, setUtilitiesMasterData] = React.useState({ ...initialState() })
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [companyList, setCompanyList] = React.useState([]);
    const [utilitiesMasterList, setUtilitiesMasterList] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileID)
    const [drawer, setDrawer] = React.useState(null);
    const [filterData, setFilterData] = React.useState(defaultFilterState);
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const [permission, setPermission] = React.useState({})
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [is_table_loading, set_is_table_loading] = React.useState(true)
    const [enumValue, setEnumValue] = React.useState({
        inspection_item_type: []
    })
    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const draweropen = () => {
        setDrawer(true);
    };
    const drawerclose = () => {
        setDrawer(false);
    };
    const debounce = UseDebounce();

    const client = useApolloClient();
    //more options
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        UtilitiesMasterTableData(offset, limit, searchText)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        UtilitiesMasterTableData(0, value, searchText)
    }

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        UtilitiesMasterTableData(0, limit, e)
    }


    //update state
    const updateState = (key, value) => {

        let error = utilitiesMasterData?.error;
        error[key] = "";
        setUtilitiesMasterData({ ...utilitiesMasterData, [key]: value, error })


    }
    //validation
    const validate = () => {
        let isValid = true;
        let error = utilitiesMasterData.error;
        if (utilitiesMasterData?.name?.length === 0) {
            isValid = false;
            error.name = t("Name  is Required");
        }

        if (utilitiesMasterData?.name?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
        }

        if (utilitiesMasterData?.itemMaster?.length === 0) {
            isValid = false;
            error.itemMaster = t("Item Master is Required");
        }

        setUtilitiesMasterData({ ...utilitiesMasterData, error });

        return isValid;
    };


    //Upsert API FUNCTION
    const UpsertUtilitiesMaster = () => {
        if (validate()) {
            setIsDisableBtn(true)
            let payload;
            if (utilitiesMasterData?.id) {
                payload = {
                    tenantId: `${config.tenantId}`,
                    utility_name: utilitiesMasterData?.name,
                    updated_by: userProfileId,
                    description: utilitiesMasterData?.description.length > 0 ? utilitiesMasterData?.description : null,
                    company_id: selectedCompany?.value,
                    id: utilitiesMasterData?.id,
                    inspection_item: utilitiesMasterData?.itemMaster?.value
                }
            } else {
                payload = {
                    tenantId: `${config.tenantId}`,
                    utility_name: utilitiesMasterData?.name,
                    description: utilitiesMasterData?.description.length > 0 ? utilitiesMasterData?.description : null,
                    company_id: selectedCompany?.value,
                    created_by: userProfileId,
                    inspection_item: utilitiesMasterData?.itemMaster?.value
                }
            }
            NetworkCall(
                `${config.api_url}/utilities/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    setIsDisableBtn(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: utilitiesMasterData?.id ? t("Utility Updated successfully") : t("Utility Added successfully."),
                    });
                    setUtilitiesMasterData({ ...initialState() })
                    setOpenDialog(false)
                    UtilitiesMasterTableData()
                    setIsDisableBtn(false)
                })
                .catch((error) => {
                    setIsDisableBtn(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Internal error. Please try again later."),
                    });
                    setIsDisableBtn(false)
                });

        }

    }

    //table listing function
    const UtilitiesMasterTableData = async (offset = 0, limit = 10, search = "") => {
        set_is_table_loading(true);
        let companyID = [selectedCompany?.value];
        let is_active = filterData?.status ?? [true];
        const payload = {
            query: GET_UTILITIES_MASTER_TABLE_LIST(clientId, offset, limit, search, companyID, is_active).loc.source.body,

        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setUtilitiesMasterList({
                    data: response?.data?.data?.utilities_master,
                    totalRowsCount: response?.data?.data?.count?.[0]?.count,

                })
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                set_is_table_loading(false);
            }).catch((error) => {
                set_is_table_loading(false);
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    }

    // is active changing function
    const changeactive = async (data, value) => {
        client.mutate({
            mutation: UPDATE_UTILITIES_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Status Updated"),
            });
            UtilitiesMasterTableData()
        }).catch(er => {
            console.log(er)
        })
    }
    const deleteTableData = async (data) => {
        client.mutate({
            mutation: UPDATE_UTILITIES_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_delete: true
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Deleted"),
            });
            UtilitiesMasterTableData()
        }).catch(er => {
            console.log(er)
        })
    }
    //table row constructions
    const UtilitiesMasterRow = utilitiesMasterList?.data?.map((val, index) => {
        let _d;
        try {
            _d = {
                index: ((page - 1) * limit) + index + 1,
                name: val?.name ?? "",
                price: val?.price,
                description: val?.description ?? "",
                status: val?.is_active ? "Active" : "Inactive",
                IsDelete: val?.is_delete,
                icon: "more",
                id: val?.id,
                item_master: val?.item_master
            };
        } catch (err) {

        }
        return _d;
    });


    React.useEffect(() => {
        if (selectedCompany?.value) {
            UtilitiesMasterTableData()
        }
        getEnum()
        //eslint-disable-next-line
    }, [selectedCompany, filterData]);

    const handleIcon = (type, data, status) => {
        if (type === "view") {
            setOpenDialog(true)
            setUtilitiesMasterData({
                ...utilitiesMasterData,
                name: data?.name,
                price: data?.price,
                description: data?.description,
                view: true,
                id: data?.id,
                itemType: {
                    label: data?.item_master?.inspection_item_category?.item_type,
                    value: data?.item_master?.inspection_item_category?.item_type
                },
                itemCategory: data?.item_master?.inspection_item_category,
                itemMaster: data?.item_master,
            })
        }
        else if (type === "edit") {
            setOpenDialog(true)
            setUtilitiesMasterData({
                ...utilitiesMasterData,
                name: data?.name,
                price: data?.price,
                description: data?.description,
                edit: true,
                id: data?.id,
                itemType: data?.item_master?.inspection_item_category?.item_type ? {
                    label: data?.item_master?.inspection_item_category?.item_type,
                    value: data?.item_master?.inspection_item_category?.item_type
                } : "",
                itemCategory: data?.item_master?.inspection_item_category ?? "",
                itemMaster: data?.item_master ?? "",
            })
        }
        else if (type === "active") {
            changeactive(data.id, status)
        }
        else if (type === "delete") {
            deleteTableData(data.id, data.IsDelete)
        }

    }

    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }

    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_item_type])
        setEnumValue({
            inspection_item_type: result?.inspection_item_type
        })
    }

    const itemMasterManualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.name,
                value: i?.id,
                ...i
            }
        })
        return details
    };

    // add and edit modal
    const addAndEdit = () => {
        return (
            <>
                <Grid p={2} container spacing={2}
                >
                    <Grid item xs={12}>
                        <TextBox
                            value={utilitiesMasterData?.name}
                            isrequired
                            label={t("Name")}
                            placeholder={t("Enter Name")}
                            onChange={(e) => {
                                updateState("name", e.target.value);
                            }}
                            isError={utilitiesMasterData?.error?.name?.length > 0}
                            errorMessage={utilitiesMasterData?.error?.name}
                            isReadonly={utilitiesMasterData?.view}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            multiline
                            value={utilitiesMasterData?.description ?? ""}
                            padding={"8px 14px"}
                            label={t("Description")}
                            placeholder={t("Enter Description")}
                            onChange={(e) => {
                                updateState("description", e.target.value);
                            }}
                            isError={utilitiesMasterData?.error?.description?.length > 0}
                            errorMessage={utilitiesMasterData?.error?.description}
                            isReadonly={utilitiesMasterData?.view}
                        />

                    </Grid>

                    <Grid item xs={6}>
                        <SelectBox
                            value={utilitiesMasterData?.itemType}
                            label={t("Item Type")}
                            placeholder={t("Select Item Type")}
                            onChange={(value) => {
                                setUtilitiesMasterData({
                                    ...utilitiesMasterData,
                                    itemType: value,
                                    itemCategory: "",
                                    itemMaster: ""
                                })
                            }}
                            options={enumValue?.inspection_item_type ?? []}
                            isPaginate={true}
                            isReadOnly={utilitiesMasterData?.view}


                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectBox
                            value={utilitiesMasterData?.itemCategory}
                            label={t("Item Category")}
                            placeholder={t("Select Item Category")}
                            onChange={(value) => {
                                setUtilitiesMasterData({
                                    ...utilitiesMasterData,
                                    itemCategory: value,
                                    itemMaster: ""
                                })
                            }}
                            loadOptions={(search, array, handleLoading) =>
                                loadOptionsApis(
                                    "inspection_item_category",
                                    {
                                        item_type: [utilitiesMasterData?.itemType?.value]
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "list"
                                )}
                            debounceTimeout={800}
                            isPaginate={true}
                            key={JSON.stringify(utilitiesMasterData?.itemType)}
                            isReadOnly={utilitiesMasterData?.view ? utilitiesMasterData?.view : !utilitiesMasterData?.itemType}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectBox
                            isRequired
                            value={utilitiesMasterData?.itemMaster}
                            label={t("Item Master")}
                            placeholder={t("Select Item Master")}
                            onChange={(value) => {
                                updateState("itemMaster", value);
                            }}
                            loadOptions={(search, array, handleLoading) => loadOptionsApis(
                                "free_text_invoice/get_free_text_item_types",
                                {
                                    company_id: selectedCompany?.value,
                                    item_type: "Item Master",
                                    item_master_type: utilitiesMasterData?.itemType?.value,
                                    item_master_category: utilitiesMasterData?.itemCategory?.value,
                                    component_type: "item_based"
                                },
                                search,
                                array,
                                handleLoading,
                                "items",
                                {},
                                itemMasterManualResponse
                            )}
                            debounceTimeout={800}
                            isPaginate={true}
                            isError={utilitiesMasterData?.error?.itemMaster?.length > 0}
                            errorMessage={utilitiesMasterData?.error?.itemMaster}
                            isReadOnly={utilitiesMasterData?.view}
                            key={JSON.stringify(utilitiesMasterData?.itemCategory, utilitiesMasterData?.itemCategory)}
                        />
                    </Grid>

                </Grid>
                {
                    (((utilitiesMasterData?.edit || utilitiesMasterData?.view) && permission?.update) ||
                        (!utilitiesMasterData?.view && !utilitiesMasterData?.edit && permission?.create)) &&
                    <Grid container p={2} mt={3}>
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" className={classes.btn}
                                onClick={
                                    utilitiesMasterData?.view ? () =>
                                        setUtilitiesMasterData({
                                            ...utilitiesMasterData,
                                            view: false,
                                            edit: true
                                        })
                                        : UpsertUtilitiesMaster}
                                disabled={isDisableBtn}
                            >
                                {utilitiesMasterData?.view ? "Edit" : utilitiesMasterData?.edit ? t("Update") : t("Create")}

                            </Button>
                        </Grid>
                    </Grid>
                }
            </>
        )
    }

    const render = () => {
        return <>
            <div>
                <Subheader title={t("Utilties Master")}
                    hideBackButton select options={companyList} value={selectedCompany}
                    onchange={(e) =>
                        setSelectedCompany(e)
                        // getProperty(e)
                    }
                />
                <CustomPaper>
                    <Grid container className={classes.content} spacing={2}>
                        <Grid item xs={4}>
                            <SearchFilter placeholder={t("Search by utility name")} value={searchText} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                        </Grid>
                        <Grid item xs={8} textAlign={"right"} display={"flex"} justifyContent={"end"}>
                            <Box
                                display={"flex"}
                                sx={{ float: "right" }}
                            >
                                <Box >
                                    {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                        <IconButton onClick={draweropen} className={classes.img}>
                                            <Badge variant="dot" color="primary">
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>

                                    ) : (
                                        <IconButton onClick={draweropen} className={classes.img}>
                                            <FilterIMG color="#091b29" />
                                        </IconButton>)}

                                </Box>

                                {permission?.create &&
                                    <Box marginInlineStart={"12px"}>
                                        <CustomButton
                                            color="primary"
                                            variant="contained"
                                            onClick={() => setOpenDialog(true)}
                                        >
                                            {t("Add utility")}
                                        </CustomButton>
                                    </Box>}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <TableWithPagination
                                is_loading={is_table_loading}
                                noDataText={t("No Utility Found")}
                                heading={UtilitiesMasterHeading({ t })}
                                rows={UtilitiesMasterRow?.length ? UtilitiesMasterRow : []}
                                path={UtilitiesMasterPath}
                                showpagination={true}
                                tableType="no-side"
                                handleIcon={handleIcon}
                                onClick={() => null}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                dataType={UtilitiesMasterDataType}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={utilitiesMasterList?.totalRowsCount}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 290px)'}
                                view={permission?.read}
                                edit={permission?.update}
                                delete={permission?.delete} />
                        </Grid>
                    </Grid>
                </CustomPaper>

            </div>
            {/* add and edit  and view */}
            <AlertDialog
                isNormal
                header={utilitiesMasterData?.view ? t("View Utility") : utilitiesMasterData?.edit ? t("Edit Utility") : t("Add Utility")}
                onClose={() => {
                    setOpenDialog(false)
                    setUtilitiesMasterData({ ...initialState() })
                }}
                open={openDialog}
                component={
                    <>
                        <div>
                            {

                                addAndEdit()}
                        </div>
                    </>
                }
            />

            {/* filter drawer */}
            {
                drawer &&
                <FilterGenerator
                    open={drawer}
                    onClose={drawerclose}
                    defaultState={defaultFilterState}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [
                                { label: t("Active"), value: true },
                                { label: t('Inactive'), value: false },
                            ],
                            isMulti: true,
                            state_name: "status",
                            label: t("Status"),
                            // required:true
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};

export default withNamespaces("utilitiesMaster")(UtilitiesMaster);
