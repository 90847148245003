import { Button, Stack, Typography } from "@mui/material"
import { DialogDrawer, TableWithPagination, TextBox } from "../../../components"
import { TO_DO_LIST_TABLE_HEADING, TO_DO_LIST_TABLE_PATH, TO_DO_LIST_TABLE_TYPE } from "../utils/quickViewUtils"

export const ToDoListTable = ({
    t = () => false,
    classes = {},
    size = {},
    miniLeadCardHeight = 0,
    is_table_loading = true,
    table_state = {},
    table_page = 1,
    handleTablePagination = () => false,
    table_limit = 10,
    handleTablePageLimit = () => false,
    handleTableIcon = () => false,
    toDoFormCTA = {},
    handleAddToDo = () => false,
    isToDoFormOpen = false,
    handleCloseToDoForm = () => false,
    toDoFormState = {},
    updateToDoFormState = () => false,
    handleToDoFormButton = () => false,
    disable=false
}) => {
    return (
        <>
            <TableWithPagination
                is_loading={is_table_loading}
                tableType="no-side"
                heading={TO_DO_LIST_TABLE_HEADING({ t })}
                path={TO_DO_LIST_TABLE_PATH}
                dataType={TO_DO_LIST_TABLE_TYPE}
                rows={table_state?.rows}
                onClick={() => { }}
                handleIcon={(type, data) => handleTableIcon({ type, data })}
                showpagination={true}
                page={table_page}
                handlePagination={(page) => handleTablePagination({ page })}
                limit={table_limit}
                handleChangeLimit={(limit) => handleTablePageLimit({ limit })}
                totalRowsCount={table_state?.total_rows_count}
                height={size?.height - 230 - miniLeadCardHeight}
                selectedRowColor={"#F5F7FA"}
                is_fab_icon={true}
                handleFabIcon={handleAddToDo}
                view={true}
                edit={true}
                delete={true} />
            <DialogDrawer
                header={t(toDoFormCTA[toDoFormState?.form_type]?.header ?? "")}
                open={isToDoFormOpen}
                onClose={handleCloseToDoForm}
                component={
                    <Stack direction={"column"} rowGap={"16px"} height={200}>
                        <TextBox
                            isrequired
                            isReadonly={toDoFormState?.form_type==="view"}
                            label={t("Name")}
                            placeholder={t("Enter Name")}
                            value={toDoFormState?.name ?? ""}
                            onChange={(e) => updateToDoFormState({ key: "name", value: e?.target?.value })}
                            isError={toDoFormState?.error?.name?.length > 0}
                            errorMessage={toDoFormState?.error?.name} />
                        <TextBox
                            isReadonly={toDoFormState?.form_type==="view"}
                            label={t("Description")}
                            placeholder={t("Enter Description")}
                            multiline
                            value={toDoFormState?.description ?? ""}
                            onChange={(e) => updateToDoFormState({ key: "description", value: e?.target?.value })}
                            isError={toDoFormState?.error?.description?.length > 0}
                            errorMessage={toDoFormState?.error?.description} />
                        <Button variant="contained" className={classes.toDoButton}
                            onClick={handleToDoFormButton} disabled={disable}>
                            <Typography className={classes.toDoButtonText}>
                                {t(toDoFormCTA[toDoFormState?.form_type]?.buttonText ?? "")}
                            </Typography>
                        </Button>
                    </Stack>
                } />
        </>
    )
}