import { useApolloClient } from "@apollo/client";
import React, { createContext, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../contexts";
import {
  ADD_COMMAND_PROXY_LEAD,
  CHECK_PROSPECTIVE,
  CREATE_CONTACT,
  CREATE_LEAD, CREATE_SHORTLIST, UPDATE_LEAD, UPDATE_LEAD_UNIT, UPDATE_PROSPECT
} from "../graphql/quotationMutations";
import { Routes } from "../router/routes";
import { AlertProps, NetWorkCallMethods, makeArrayUniqueByProperty } from "../utils";
import {
  createState,
  returnContactCreatePayload,
  returnConvertLeadPayload,
  returnOppertunityCreatePayload
} from "../utils/createQuotationUtils";
import { NetworkCall } from "../networkcall";
import { config } from "../config";

export const CreateOppertunityContext = createContext();

const CreateOppertunityContextProvider = (props) => {
  const { t } = props
  const [step, setStep] = useState(1);
  const client = useApolloClient();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const [dataNew, setDataNew] = React.useState({ ...createState });
  const [selectedAccount, setSelectedAccount] = React.useState({});
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [opportunityAssets, setOpportunityAssets] = useState([])
  const [opperEdit, setOpperEdit] = React.useState(true)

  //update state
  const updateStateNew = (key, value, key1, value1) => {
    let error = dataNew.error;
    error[key] = "";
    setDataNew({ ...dataNew, [key]: value, [key1]: value1, error });
  };
  //validate sale oppertunity
  const validateSaleOppertunity = () => {
    let isValid = true;
    let error = dataNew.error;
    if (dataNew?.contract_start_date?.length === 0) {
      isValid = false;
      error.contract_start_date = t("Contract Start Date is Required");
    }
    if (dataNew?.payment_period?.value === "Milestone Based") {
      if (dataNew?.delivery_timestone_template?.length === 0) {
        isValid = false;
        error.delivery_timestone_template = t("Delivery Milstone is Required");
      }
    }

    if (dataNew?.payment_period?.length === 0) {
      isValid = false;
      error.payment_period = t("Payment Period is Required");
    }
    if (dataNew?.payment_option?.length === 0) {
      isValid = false;
      error.payment_option = t("Payment Options is Required");
    }
    if (dataNew?.oppertunity_subject?.length === 0) {
      isValid = false;
      error.oppertunity_subject = t("Subject is Required");
    }
    if (dataNew?.source?.length === 0) {
      isValid = false;
      error.source = t("Source is Required");
    }
    if (dataNew?.priority?.length === 0) {
      isValid = false;
      error.priority = t("Priority is Required");
    }
    if (dataNew?.earlist_occupation_date?.length === 0) {
      isValid = false;
      error.earlist_occupation_date = t("Earliest Occupation Date is Required");
    }
    if (dataNew?.payment_period?.length === 0) {
      isValid = false;
      error.payment_period = t("Payment Period is Required");
    }
    // if(dataNew?.confirm_interest === "" || dataNew?.confirm_interest === null){
    //   isValid = false
    //   error.confirm_interest = "Confirm Interest is Required"
    // }
    // if(dataNew?.units?.length === 0){
    //   isValid = false;
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please select unit",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //   });
    // }


    setDataNew({ ...dataNew, error });
    return isValid;
  };
  //validate lead
  const validateLeadDetails = () => {
    let isValid = true;
    let error = dataNew.error;
    if (dataNew?.duration?.value?.length === 0) {
      isValid = false;
      error.duration = t("Duration is Required");
    }
    if (dataNew?.lease_start_date === null) {
      isValid = false;
      error.lease_start_date = t("Lease Start is Required");
    }
    if(dataNew?.bill_cycle_method === null ||  dataNew?.bill_cycle_method === ""){
      isValid = false;
      error.bill_cycle_method = t("Billing Cycle Method is Required");

    }
    if(dataNew?.auto_renewal === null ||  dataNew?.auto_renewal === ""){
      isValid = false;
      error.auto_renewal = t("Auto Renewals is Required");

    }
    if(dataNew?.renewal === null ||  dataNew?.renewal === ""){
      isValid = false;
      error.renewal = t("Renewal is Required");

    }
    if(dataNew?.allow_extension === null ||  dataNew?.allow_extension === ""){
      isValid = false;
      error.allow_extension = t("Allow Extension is Required");

    }
    if(dataNew?.agreement_lock === null ||  dataNew?.agreement_lock === ""){
      isValid = false;
      error.agreement_lock = t("Agreement Lock In Period is Required");

    }
    if(dataNew?.wallet_carry_over === "" || dataNew?.wallet_carry_over === null){
      isValid = false
      error.wallet_carry_over = "Wallet Carry over is Required"
    }
    // if(dataNew?.confirm_interest === "" || dataNew?.confirm_interest === null){
    //   isValid = false
    //   error.confirm_interest = "Confirm Interest is Required"
    // }
    if (dataNew?.payment_period?.length === 0) {
      isValid = false;
      error.payment_period = t("Payment Period is Required");
    }
    if (dataNew?.payment_option?.length === 0) {
      isValid = false;
      error.payment_option = t("Payment Options is Required");
    }
    if (dataNew?.grace_peroid?.length === 0) {
      isValid = false;
      error.grace_peroid = t("Grace Peroid is Required");
    }
    if (dataNew?.grace_peroid?.length !== 0) {
      if (dataNew?.apply_grace_peroid?.length === 0) {
        isValid = false;
        error.apply_grace_peroid = t("Apply Grace Peroid is Required");
      }
    }
    if (
      dataNew?.payment_period?.value !== "Prepaid"
    ) {
      if (dataNew?.bill_cycle_date?.length === 0 || !dataNew?.bill_cycle_date) {
        isValid = false;
        error.bill_cycle_date = t("Billing Cycle Date is Required");
      }
      if (dataNew?.bill_cycle_method?.length === 0) {
        isValid = false;
        error.bill_cycle_method = t("Billing Cycle Method is Required");
      }
      if (new Date(dataNew?.lease_start_date).getDate() > 28) {
        if (dataNew?.bill_cycle_date > 28) {
          isValid = false;
          error.bill_cycle_date = t("Billing Cycle Date must be smaller than 28");
        } else {
          isValid = true;
          error.bill_cycle_date = "";
        }
      }

    }

    // if (
    //   dataNew?.bill_cycle_date === null &&
    //   dataNew?.payment_period?.value !== "Prepaid"
    // ) {
    //   if (dataNew?.custom_date?.length === 0) {
    //     isValid = false;
    //     error.custom_date = "Custom Date is Required";
    //   }
    // }

    if (dataNew?.oppertunity_subject?.length === 0) {
      isValid = false;
      error.oppertunity_subject = t("Subject is Required");
    }
    if (dataNew?.source?.length === 0) {
      isValid = false;
      error.source = t("Source is Required");
    }
    if (dataNew?.priority?.length === 0) {
      isValid = false;
      error.priority = t("Source is Required");
    }
    if (dataNew?.renewal_based_on?.length === 0) {
      isValid = false;
      error.renewal_based_on = t("Renewal Based on is Required");
    }
    
    // if (dataNew?.renewal_based_on?.value === "Escalation %" || dataNew?.renewal_based_on?.value === "Increment %") {
    //     if (dataNew?.esclation_percentage?.length === 0) {
    //         isValid = false;
    //         error.esclation_percentage = "Increment/Escalation Percentage is Required";
    //     }
    // }
    // if(dataNew?.units?.length === 0){
    //   isValid = false;
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please select unit",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //   });
    // }

    setDataNew({ ...dataNew, error });
    return isValid;
  };
  //previous btn function
  const previous = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
const uploadAttachments = (lead_id) =>{
  const payload = {
    "params": opportunityAssets?.map((x) => {
      return (
        {
          "lead_id": lead_id,
          id:x?.id,
          "asset_type": null,
          "url": x?.url,
          "file_meta":x?.file_meta,
          "is_active": x?.is_active
        }
      )
    })
  }
  NetworkCall(
    `${config?.api_url}/opportunity/upsert_asset`,
    NetWorkCallMethods.post,
    payload,
    null,
    true,
    false
  ).then((response) => {

  }).catch((err) => {
    console.log(err)
  })
}
  //create lead
  const createLead = (prospective_id) => {
    let payload = returnOppertunityCreatePayload(
      dataNew,
      prospective_id,
      selectedAccount,
      false
    );


    client
      .mutate({
        mutation: CREATE_LEAD,
        variables: {
          insert: [payload],
        },
      })
      .then((rs) => {
        if(rs?.data?.lead?.[0]?.id){
          uploadAttachments(rs?.data?.lead?.[0]?.id)
        }
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: dataNew?.is_convert ? "Opportunity Conversion Successful" : "lead opportunity created successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        if (dataNew?.proxy_lead_id && dataNew?.proxy_lead_id?.length > 0) {
          convertLost(rs?.data?.lead?.[0]?.id);
        }
        createShortlist(rs?.data?.lead?.[0]?.id);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //create contact
  const createContact = () => {
    const payload = returnContactCreatePayload(dataNew);
    client
      .mutate({
        mutation: CREATE_CONTACT,
        variables: {
          insert: [payload],
        },
      })
      .then((rs) => {
        setDataNew({ ...dataNew, contact_id: rs?.data?.prospective?.[0]?.id });
        createLead(rs?.data?.prospective?.[0]?.id);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //convert lost
  const convertLost = () => {
    const payload = returnConvertLeadPayload(dataNew);
    client
      .mutate({
        mutation: ADD_COMMAND_PROXY_LEAD,
        variables: payload,
      })
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("lead opportunity created successfully"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        history.push(Routes.leadnew);
      })
      .catch((er) => {
        console.log(er);
        setDisableButton(false)
      });
  };
  //check exist prospective
  const checkProspectiveCheck = () => {
    client
      .mutate({
        mutation: CHECK_PROSPECTIVE,
        variables: {
          email_id: dataNew?.email_id,
        },
      })
      .then((rs) => {
        if (rs?.data?.prospective?.length > 0) {
          createLead(rs?.data?.prospective?.[0]?.id);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.info,
            msg: t("This Prospective is Already Exisit Create Oppertuntiy By That Prospect"),
          });
        } else {
          createContact();
        }
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //update lead
  const updateLead = (prospective_id) => {
    let payload = returnOppertunityCreatePayload(
      dataNew,
      dataNew?.contact_id,
      selectedAccount,
      true
    );
    client
      .mutate({
        mutation: UPDATE_LEAD,
        variables: {
          id: dataNew?.lead_id,
          updatePayload: payload,
        },
      })
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Opportunity Successfully Updated"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        if (dataNew?.isEdit) {
          createShortlistEdit(rs?.data?.lead?.[0]?.id);
          updateLeadUnits(rs?.data?.lead?.[0]?.id);
        }
      })
      .catch((er) => {
        console.log(er);
        setDisableButton(false)
      });
  };
  // update prospect
  const updateProspect = () => {
    const payload = returnContactCreatePayload(dataNew);
    client
      .mutate({
        mutation: UPDATE_PROSPECT,
        variables: {
          id: dataNew?.contact_id,
          updatePayload: payload,
        },
      })
      .then((rs) => {
        updateLead();
      })
      .catch((er) => {
        console.log(er);
        setDisableButton(false)
      });
  };
  //next function if newlead
  const next = () => {
    if (step === 1) {
      if (
        dataNew?.revenue_type?.value === "Lease" ||
        dataNew?.revenue_type?.value === "Manage"
      ) {
        if (validateLeadDetails()) {
          setStep(2);
        }
      } else if (dataNew?.revenue_type?.value === "Sale") {
        if (validateSaleOppertunity()) {
          setStep(2);
        }
      }
    } 
    else if (step === 2) {
      setStep(3)
    }
    else if (step === 3) {
      if (dataNew?.lead_id?.length > 0) {
        setDisableButton(true)
        upsertLead()
      } else {
        setDisableButton(true)
        upsertLead()
      }
    }
  };
  const upsertLead = ()=>{
    let unit_id = []
    const prospect = returnContactCreatePayload(dataNew);
    let lead = returnOppertunityCreatePayload(
      dataNew,
      selectedAccount,
    );
    if(dataNew?.units?.length > 0){
      const units = makeArrayUniqueByProperty(dataNew?.units, 'id')
      unit_id =  units?.map((val) => {
        return {
          unit_id: val?.id,
          company_id: dataNew?.company,
          is_active: true,
          property_id: val?.property_id ?? undefined
        };
      })
    }
    const payload = {
      prospective:prospect,
      opportunity:lead,
      assets:opportunityAssets?.map((x) => {
        return (
          {
            id:x?.id,
            "asset_type": null,
            "url": x?.url,
            "file_meta":x?.file_meta,
            "is_active": x?.is_active
          }
        )
      }),
      units:unit_id
    }
    NetworkCall(
      `${config?.api_url}/queries/opportunity/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: `Opportunity ${lead?.id ? "Updated" : "Created"} successfully`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      history.push(Routes.leads)
    }).catch((err) => {
      console.log(err)
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Some thing went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    })
  }
  //create shortlist
  const createShortlist = (id) => {
    if (dataNew?.units?.length > 0) {
      const units = makeArrayUniqueByProperty(dataNew?.units, 'id')
      client
        .mutate({
          mutation: CREATE_SHORTLIST,
          variables: {
            insert: units?.map((val) => {
              return {
                unit_id: val?.id,
                lead_id: id,
                company_id: dataNew?.company,
                is_active: true,
                property_id: val?.property_id ?? null
              };
            }),
          },
        })
        .then((rs) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Opportunity created successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          history.push(Routes.leads);
        })
        .catch((er) => {
          console.log(er);
        });
    }
    else {
      history.push(Routes.leads);
    }

  };
  //update lead
  const updateLeadUnits = (id) => {
    let arr2 = dataNew?.fixUnit?.map((x) => x?.id) ?? [];
    let arr = dataNew?.units?.map((x) => x?.id) ?? [];

    let deleted = arr2
      .map((x) => (!arr.includes(x) ? x : false))
      .filter(Boolean);

    if (deleted?.length > 0) {
      client
        .mutate({
          mutation: UPDATE_LEAD_UNIT,
          variables: {
            lead_id: id,
            id: deleted ?? [],
            updatePayload: {
              is_active: false,
            },
          },
        })
        .then((rs) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Opportunity Successfully Updated"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          history.push(Routes.leads);
        })
        .catch((er) => {
          console.log(er);
          setDisableButton(false)
        });
    } else {
      history.push(Routes.leads);
    }
  };
  //   update short list
  const createShortlistEdit = (id) => {
    let arr2 = dataNew?.fixUnit?.map((x) => x);
    let arr = dataNew?.units?.map((x) => x);
    let addedUnit = arr.filter(item => !arr2.map(i => i.id).includes(item?.id));

    if (addedUnit?.length > 0) {
      const units = makeArrayUniqueByProperty(addedUnit, 'id')
      client
        .mutate({
          mutation: CREATE_SHORTLIST,
          variables: {
            insert: units?.map((val) => {
              return {
                unit_id: val?.id,
                lead_id: id,
                company_id: dataNew?.company,
                is_active: true,
                property_id: val?.property_id ?? null
              };
            }),
          },
        })
        .then((rs) => {

          history.push(Routes.leads);

          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Opportunity Successfully Created"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          history.goBack(-1);
        })
        .catch((er) => {
          console.log(er);
          setDisableButton(false)
        });
    } else {
      history.goBack(-1);
    }
  };
  return (
    <CreateOppertunityContext.Provider
      value={{
        step,
        previous,
        next,
        updateStateNew,
        dataNew,
        setDataNew,
        setShow,
        setStep,
        show,
        setShow1,
        show1,
        setSelectedAccount,
        selectedAccount,
        disableButton,
        setDisableButton,
        t,
        opportunityAssets,
        setOpportunityAssets,
        opperEdit, 
        setOpperEdit
      }}
    >
      {props.children}
    </CreateOppertunityContext.Provider>
  );
};

export default withNamespaces("createOpportunity")(CreateOppertunityContextProvider)