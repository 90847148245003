import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Button, Dialog, Divider, Drawer, Grid, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ApprovedWhite, ConvertToBooking, ConvertToResrvation, Send } from '../../assets';
import { AlertDialog, ContactList, DialogBox, LoadingSection, QuotationContactCreate, QuotationSummery, Subheader, TemplateGenerator } from "../../components";
import { config } from "../../config";
import { AuthContext, BackdropContext, AlertContext } from '../../contexts';
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, getRoutePermissionNew, NetWorkCallMethods, quotationStatus, timeZoneConverter, AlertProps, LocalStorageKeys, getTotalArray } from "../../utils";
import { Booking, CancelBooking, QuotationDetail, QuotationVaersionCard, ReservationCard, ReservationHistory, SendPaymentLink, UnitList, UnitSingleDetails } from "./components";
import { quotationsStyles } from "./styles";
import moment from "moment-timezone";
import { NewLoader } from '../../components/newLoader';
import { withNamespaces } from 'react-i18next';
import CustomizeQuote from '../customizeQUote/customizeQuote';
import { getComponentValue } from '../freeTextInvoice/utlis/freeTextInvoice';
import { Routes } from '../../router/routes';
import { HoleNumber, filterValues } from '../customizeQUote/function';



const QuotationDetails = ({
    t = () => false
}) => {
  const language = localStorage.getItem("i18nextLng");

  const history = useHistory();
  const classes = quotationsStyles();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openUnitDetails, setOpenUnitDetails] = React.useState(false);
  const [selectedUnit, setSelectedUnit] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [selectedAcc, setSelectedAcc] = React.useState(null);
  const [openReserve, setOpenReserve] = React.useState(false);
  const [sendPayment, setSendPayment] = React.useState(false);
  const [openHistory, setHistory] = React.useState(false);
  const [openpdf, setOpenpdf] = React.useState(false);
  const [template, setTemplate] = React.useState({
    bool: false,
    type: "",
  });
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [cancelBooking, setCancel] = React.useState({
    bool: false,
    data: "",
  });
  const [details, setDetails] = React.useState({
    quott: {},
    units: [],
    reservation: {},
    reservationPrice: "",
    is_primary: false,
    unitDetails: [],
  });
  const [type, setType] = React.useState({
    bool: false,
    type: "",
  });
  const [permission, setPermission] = React.useState({});
  const [pdf, setPdf] = React.useState({
    bool: false,
    data: "",
  });
  const [primary, setPrimary] = React.useState(true);
  const [custom, setCustom] = React.useState({
    type: false,
    already: false,
  });
  const [isReservation, setIsReservation] = React.useState({
    bool: false,
    type: "reservation",
    disable: false,
  });
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const is_broker_module = moduleId === "56";

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getQuotDetail();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //get quoation details
  const getQuotDetail = () => {
    const payload = {
      tenantId: `${config.tenantId}`,
      quotation_id: id,
      is_primary: true,
      is_quote: true,
    };
    NetworkCall(
      `${config.api_url}/quotation/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response?.data?.quotation?.map((val) => {
          return {
            ...val,
            id: val?.id,
            is_open: false,
            name: val?.name,
            unit_no: val?.unitNo,
            total_area: val?.totalarea,
            area_metric: val?.areaMetric,
            total_bed_rooms: val?.totalbedroom,
            total_baths: val?.baths,
            unit_type: val?.unitType,
            discount: 0,
            discountValue: 0,
            room_rent: val?.room_rent ?? 0,
            url: val?.logo,
            pricing: val?.pricing?.map((c) => {
              return {
                ...c,
                unitid: val?.id,
                primary: c?.primary ?? false,
                refundable: c?.refundable ?? false,
                isActive: true,
                taxable: c?.taxable ?? false,
                isChargeable: true,
                propertyId: val?.property?.property_id,
                companyId: c?.company_id,
                isOnetime: c?.isOnetime ?? false,
                beforeTax: c?.rent_amount,
                pricing_components: !!c?.inspection
                  ? c?.inspection
                  : c?.component,
                discountType: { label: "%", value: "%" },
                inspection: !!c?.inspection
                  ? {
                      category: c?.inspection?.category,
                      type: {
                        value: c?.inspection?.item,
                        label: c?.inspection?.item,
                      },
                    }
                  : null,
                discountedPrice: c?.displayPercentage ?? 0,
                discountValue: c?.displayPercentage ?? 0,
                discountPercentage: c?.displayPercentage ?? 0,
                fixTax: c?.before_tax,
                refundableAmount: 0,
                vat_id: c?.vatGroup?.VGMid,
                componentType: getComponentValue(
                  c?.component,
                  c?.is_wallet_item
                ),
                subtotal: Number(c?.rent_amount ?? 0) + Number(c?.tax ?? 0),
                componentvalues: c?.value,
                componentvalue: c?.value,
                quantity: !!c?.quantity ? c?.quantity : 1,
                tax: c?.tax ?? 0,
                taxAmountValue: Number(c?.before_tax) ?? 0,
                amountWithoutDiscount: c?.amount_without_discount,
                is_active: true,
                isQuantity: !!c?.quantity ? true : false,
                uom_type: c?.uom_type,
              };
            }),
            total: val?.total,
            totalDiscount: 0,
            totalAmount: val?.totalPricing,
            fixTotal: val?.totalPricing,
            totalrefundableTax: val?.totalrefundableTax,
            totalDiscountValue: null,
            discountPrice: 0,
            discountedPrice: 0,
            tax: 0,
            blockid: val?.blockid,
            propertyid: val?.propertyid,
            floorid: val?.floorid,
          };
        });

        setDetails({
          quott: response.data.quotationDetails[0],
          units: response.data.units,
          reservationPrice: response.data.reservationPrice,
          reservation: response.data.reservation ?? "",
          is_primary: response?.data?.is_primary,
          unitDetails: result,
        });
        setSelectedAcc(
          response.data.quotationDetails[0]?.account_id
            ? {
                id: response.data.quotationDetails[0]?.account_id,
                name: response.data.quotationDetails[0]?.account_name,
                value: response.data.quotationDetails[0]?.account_id,
                label: response.data.quotationDetails[0]?.account_name,
                account_no:
                  response.data.quotationDetails[0]
                    ?.account_mobile_no_country_code,
                value1:
                  response.data.quotationDetails[0]
                    ?.account_mobile_no_country_code,
              }
            : null
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  //on click unit card
  const onClickUnit = (unit_id) => {
    setSelectedUnit(unit_id);
    setOpenUnitDetails(true);
  };
  //reservation json
  const ReservationData = [
    {
      title: t("Convert to Booking"),
      img: <ConvertToBooking />,
      types: "Booked",
      btn: "Booking",
    },
    {
      title: t("Convert to Reservation"),
      img: <ConvertToResrvation />,
      types: "Reserved",
      btn: "Reserve & Send Payment Link",
    },
  ];

  //send quotation
  const sendQuotation = (value) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("...Sending"),
    });
    setTemplate({
      bool: false,
      type: "view",
    });
    const payload = value;
    NetworkCall(
      `${config.api_url}/quotation/send_invite`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setOpen(true);
        getQuotDetail();
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        console.log(err);
      });
  };
  //on click types
  const handleClick = (types) => {
    setType({
      bool: !type?.bool,
      type: types,
    });
    setOpenReserve(false);
  };
  //onclose
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // check if any reservation against the quote
  const checkReservation = (value) => {
    if (details?.reservation?.reservation_status?.length > 0) {
      setIsReservation({
        bool: true,
        type: "reservation",
      });
    } else {
      upDateCustom();
    }
  };
  const isRead =
    details?.quott?.status === "Won" || details?.quott?.status === "Inactive"
      ? true
      : false;

  const isReadConvert = details?.quott?.status === "Inactive" ? true : false;
  //render type of popup

  const markAsWon = () => {
    setIsReservation({
      ...isReservation,
      disable: true,
    });
    NetworkCall(
      `${config.api_url}/quotation/mark_as_won`,
      NetWorkCallMethods.post,
      {
        quotation_id: details?.quott?.id,
        lead_id: details?.quott?.lead_id,
      },
      null,
      true,
      false
    )
      .then((response) => {
        setIsReservation({
          ...isReservation,
          disable: false,
          bool: false,
          type: "",
        });
        getQuotDetail();
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        setIsReservation({
          ...isReservation,
          disable: false,
          bool: false,
          type: "",
        });
        console.log(err);
      });
  };

  const renderType = () => {
    return (
      <Grid container spacing={2} justifyContent="center">
        {ReservationData?.map((val) => {
          return (
            <Grid item xs={5}>
              <Box className={classes.card} onClick={() => handleClick(val)}>
                <Box className={classes.reservationType}>{val?.img}</Box>
                <Typography className={classes.titleReservation}>
                  {val?.title}
                </Typography>
                <center>
                  <IconButton className={classes.iconBtn}>
                    <ArrowForwardIosIcon sx={{ fontSize: "0.875rem" }} />
                  </IconButton>
                </center>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  //on send link
  const onSendLink = () => {
    setSendPayment(true);
  };
  //on cancel Booking
  const onCancelBooking = (datas) => {
    setCancel({
      bool: !cancelBooking?.bool,
      data: datas,
    });
  };
  //on click btn
  const onReserve = () => {
    if (details?.reservation?.reservation_status === "Booked") {
      handleClick(ReservationData[1]);
    } else {
      onSendLink();
    }
  };
  //render btn
  // const renderButton = () => {
  //     return (
  //         <Button onClick={downloadPdf} className={classes.downBtn} variant="outlined" startIcon={<Download />}>
  //             Download
  //         </Button>
  //     )
  // }

  //get list
  const checkDetails = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const payload = {
      vacant: ["Vacant", "Reserved"],
      offset: 0,
      limit: 10,
      revenue_type: details?.quott?.revenue_type,
      lease_start_date:
        details?.quott?.revenue_type !== "Sale"
          ? `${moment(details?.quott?.lease_start_date).format(
              "YYYY-MM-DD"
            )} 00:00:00` ?? undefined
          : undefined,
      lease_end_date:
        details?.quott?.revenue_type !== "Sale"
          ? `${moment(details?.quott?.lease_end_date).format(
              "YYYY-MM-DD"
            )} 23:59:59` ?? undefined
          : undefined,

      hand_over_date:
        details?.quott?.revenue_type === "Sale"
          ? `${moment(details?.quott?.occpation_date).format("YYYY-MM-DD")}` ??
            null
          : undefined,
      units: details?.units?.map((x) => x?.unit_id),
    };
    NetworkCall(
      `${config.api_url}/opportunity/units`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        if (res?.data?.count > 0) {
          setOpenDrawer(true);
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("The unit(s) are already occupied"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      });
  };
  const generate = async (value) => {
    if (template?.type === "send")
      return sendQuotation({
        quotation_id: id,
        type_master_id: value?.value,
      });
    setOpenpdf(true);
    await backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("...Loading"),
    });
    const payload = {
      id: id,
      type_master_id: value?.value,
    };
    await NetworkCall(
      `${config.api_url}/quotation/get_quotation_pdf`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setPdf({
          bool: true,
          data: res?.data,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("some thing went wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const checkPrimary = async (id, account_id) => {
    if (!account_id) {
      return setPrimary(true);
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const payload = {
      contact_id: id,
      account_id: account_id,
    };
    await NetworkCall(
      `${config.api_url}/contact/check_primary`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setPrimary(res?.data?.data);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("some thing went wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  const upDateCustom = () => {
    setCustom({
      ...custom,
      already: custom?.already ? custom?.already : !custom?.already,
      type: !custom?.type,
    });
  };
  const onSumbit = (e, dataNew) => {
    // setCustom({
    //     ...custom,
    //     type: !custom?.type
    // })
    const units = e?.map((x) => {
      return {
        ...x,
        totalDiscount: getTotalArray(
          x?.pricing
            ?.filter(
              (item) => !filterValues.includes(item?.componentType?.value)
            )
            ?.filter((x) => x?.is_active === true),
          "subtotal"
        ),
        room_rent: getTotalArray(
          x?.pricing
            ?.filter(
              (item) => !filterValues.includes(item?.componentType?.value)
            )
            ?.filter((x) => x?.is_active === true),
          "subtotal"
        ),
        pricing: x?.pricing?.map((v) => {
          return {
            ...v,
            PCid: v?.pricing_components?.PCid || v?.pricing_components?.value,
            PCname:
              v?.pricing_components?.PCname || v?.pricing_components?.label,
            rent_breakup_id:
              v?.pricing_components?.PCid || v?.pricing_components?.value,
            unit_id: x?.id ?? "",
            component_value:
              Number(v?.quantity ?? 0) * Number(v?.componentvalues ?? 0),
            amount: Number(!!v?.componentvalues ? v?.componentvalues : 0),
            beforeTax: !!v?.beforeTax ? Number(v?.beforeTax) : 0,
            companyId: !!v?.companyId
              ? v?.companyId
              : JSON.parse(
                  localStorage.getItem(LocalStorageKeys.selectedCompany)
                )?.value,
            currencyId: !!v?.currencyId
              ? v?.currencyId
              : JSON.parse(
                  localStorage.getItem(LocalStorageKeys.selectedCompany)
                )?.currency_id,
            currencyName: !!v?.currencyName
              ? v?.currencyName
              : JSON.parse(
                  localStorage.getItem(LocalStorageKeys.selectedCompany)
                )?.code,
            currencySymbol: !!v?.currencySymbol
              ? v?.currencySymbol
              : JSON.parse(
                  localStorage.getItem(LocalStorageKeys.selectedCompany)
                )?.currency_symbol,
            subtotal: !!v?.subtotal ? Number(v?.subtotal) : 0,
            tax: !!v?.tax ? Number(v?.tax) : 0,
            value: !!v?.componentvalues ? Number(v?.componentvalues) : 0,
            vatGroupId: !!v?.vatGroup?.value ? v?.vatGroup?.value : null,
            unitid: !!v?.unit_id ? v?.unit_id : x?.id,
            vat_id: !!v?.vat_id ? v?.vat_id : v?.vatGroup?.value ?? null,
            fixTax: Number(v?.fixTax),
          };
        }),
      };
    });

    let totalAmount = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .map((item) => Number(item?.amountWithoutDiscount))
      .reduce((a, b) => a + b, 0);

    let totalrefundableTax = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .filter((i) => i?.refundable === true)
      .map((item) => Number(item?.beforeTax))
      .reduce((a, b) => a + b, 0);

    let totalTax = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .map((item) => Number(item?.taxAmountValue))
      .reduce((a, b) => a + b, 0);

    let totalTaxwithDiscount = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .map((item) => Number(item?.tax))
      .reduce((a, b) => a + b, 0);

    let totalAmountwithDiscount = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .map((item) => Number(item?.beforeTax))
      .reduce((a, b) => a + b, 0);

    let totalDiscount =
      totalAmount + totalTax - (totalAmountwithDiscount + totalTaxwithDiscount);

    let milestone_item = [];

    if (
      details?.quott?.revenue_type === "Sale" &&
      !!details?.quott?.milestone_template_master_id
    ) {
      if (details?.quott?.template_type === "amount") {
        milestone_item = dataNew?.map((x, i) => {
          return {
            milestone_item_id: x?.id,
            value:
              i === 0
                ? x.percentage_value
                  ? Number(x?.percentage_value)
                  : 0
                : x.percentage
                ? Number(x?.percentage)
                : 0,
            value_type: i === 0 ? "amount" : "percentage",
            due_date: x?.payment_date,
          };
        });
      }
      if (details?.quott?.template_type === "variable") {
        milestone_item = dataNew.map((x) => {
          return {
            milestone_item_id: x?.id,
            value: x?.percentage_value ? Number(x?.percentage_value) : 0,
            value_type: x?.value_type,
            due_date: x?.payment_date,
          };
        });
      }
      if (details?.quott?.template_type === "percentage") {
        milestone_item = dataNew?.map((x, i) => {
          return {
            milestone_item_id: x?.id,
            value: x.percentage ? Number(x?.percentage) : 0,
            value_type: "percentage",
            due_date: x?.payment_date,
          };
        });
      }
    }

    totalAmount = HoleNumber(totalAmount);
    totalrefundableTax = HoleNumber(totalrefundableTax);
    totalTax = HoleNumber(totalTax);
    totalDiscount = HoleNumber(totalDiscount);
    totalTaxwithDiscount = HoleNumber(totalTaxwithDiscount);
    totalAmountwithDiscount = HoleNumber(totalAmountwithDiscount);

    onSumbitCustomize(units, {
      totalAmount,
      totalrefundableTax,
      totalTax,
      milestone_item,
      totalDiscount,
      totalTaxwithDiscount,
      totalAmountwithDiscount,
    });
  };

  const onSumbitCustomize = (unit_details, value) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const e = unit_details;

    let discoutValue = e
      ?.map((i) => i?.pricing)
      .flat()
      .map((val) => Number(val?.discountValue))
      .reduce((a, b) => a + b, 0);

    let units = e?.map((val) => {
      return {
        unit_id: val?.id ?? val?.unitid,
        unit_total_primary: val?.totalDiscount,
        unit_total_refundable: val?.unit_total_refundable,
        unit_total_others: null,
        total_rent_breakup: null,
        total_rent_discounts: null,
        total_rent_security_deposit: null,
        total_rent_tax: null,
        total_rent_extended: null,
        area_metric: val?.areaMetric,
        property_id: val?.propertyid,
        block_id: val?.blockid,
        floor_id: val?.floorid,
      };
    });

    let refundable = e
      ?.map((i) => i?.pricing)
      .flat()
      .map((val) => {
        let applied_component_value =
          Number(val?.beforeTax ?? 0) + Number(val?.tax ?? 0);

        return {
          unit_id: val?.unitid,
          rent_breakup_id:
            val?.componenttype === "Component"
              ? val?.componentType?.value === "is_wallet_item"
                ? null
                : val?.pricing_components?.id || val?.PCid
              : null,
          rent_amount: val?.beforeTax,
          primary: val?.primary,
          refundable: val?.refundable,
          taxable: val?.taxable,
          is_one_time: val?.isOnetime,
          display_percentage: HoleNumber(val?.discountPercentage),
          payment_period: val?.paymentPeriod,
          company_id: JSON.parse(
            localStorage.getItem(LocalStorageKeys.selectedCompany)
          )?.value,
          tax: val?.tax ?? 0,
          tax_percentage: val?.taxPercentage ?? 0,
          before_rent_amount: val?.amountWithoutDiscount,
          before_tax: val?.fixTax,
          component_value: val?.value,
          vat_group_id: val?.vat_id,
          is_quantity: val?.isQuantity,
          quantity: val?.quantity,
          value_basis_type: val?.valueBasisType,
          inspection_item:
            val?.componenttype === "Component"
              ? val?.componentType?.value === "is_wallet_item"
                ? val?.pricing_components?.id
                : null
              : val?.pricing_components?.id,
          discount_type: val?.discount_type,
          applied_value: applied_component_value,
          is_wallet_item:
            val?.componentType?.value === "is_wallet_item" ? true : false,
          // "uom_type":val?.uom_type,
          uom_type: val?.pricing_components?.uom_type
            ? val?.pricing_components?.uom_type
            : val?.paymentPeriod,
        };
      });

    let payload = {
      tenantId: `${config.tenantId}`,
      lead_id: details?.quott?.lead_id,
      total_amount: value?.totalAmountwithDiscount,
      total_discount: value?.totalDiscount,
      total_refundable: value?.totalrefundableTax,
      total_tax: value?.totalTaxwithDiscount,
      discount_percentage: value?.totalDiscount,
      tax_percentage: discoutValue,
      grace_period_type: "Days",
      units: units,
      rental_breakup: refundable,
      // "total_quote_amount": HoleNumber((checkNumber(value?.totalTax) + checkNumber(value?.totalAmount)) === 0 ? 0 : (checkNumber(value?.totalTax) + checkNumber(value?.totalAmount)) - checkNumber(value?.totalDiscount)),
      total_quote_amount: Number(
        (value?.totalTax + value?.totalAmount - value?.totalDiscount)?.toFixed(
          2
        )
      ),
      quotation_id: details?.quott?.id,
      milestone_item: value?.milestone_item ?? [],
    };

    NetworkCall(
      `${config.api_url}/quotation/create_customize`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        history.push(Routes?.quotation);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Quotation Updated Successfully"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  const render = () => {
    return (
      <>
        {custom?.type ? (
          <CustomizeQuote
            details={{
              quott_no: details?.quott?.quotation_no,
              lease_start_date: timeZoneConverter(
                details?.quott?.lease_start_date
              ),
              lease_end_date: timeZoneConverter(details?.quott?.lease_end_date),
              revenue_type: {
                value: details?.quott?.revenue_type,
                label: details?.quott?.revenue_type,
              },
            }}
            goBack={upDateCustom}
            t={t}
            data={details?.unitDetails}
            onSumbit={onSumbit}
            quote_details={details}
          />
        ) : (
          <>
            {
              //loading section
              loading ? (
                <LoadingSection
                  bottom={"45vh"}
                  message={t("Loading Quotation Details...")}
                />
              ) : (
                <Box>
                  {/*sub header*/}
                  <Subheader
                    title={`${details?.quott?.first_name ?? ""} ${
                      details?.quott?.last_name ?? ""
                    } ${
                      details?.quott?.lead_no
                        ? `(${details?.quott?.lead_no})`
                        : ""
                    }`}
                    goBack={() => {
                      history.goBack();
                    }}
                  />
                  <Box className={classes.container}>
                    <Grid container>
                      <Grid item xs={3} lg={3} xl={3.5} p={1}>
                        <Box p={1} className={classes.detailRoot}>
                          <Typography className={classes.title}>
                            {t("Quotation Details")}
                          </Typography>
                          {/*Quotation version card*/}
                          <QuotationVaersionCard
                            t={t}
                            data={{
                              quott_no: details?.quott?.quotation_no ?? "",
                              active: details?.quott?.status,
                              startDate: details?.quott?.created_at ?? "",
                            }}
                          />
                          <Box height="24px" />
                          {/*Quotation Detail*/}
                          <QuotationDetail
                            t={t}
                            data={{
                              startDate: details?.quott?.lease_start_date,
                              endDate: details?.quott?.lease_end_date,
                              billstartDate: details?.quott?.billing_start_date,
                              gradeperiod: details?.quott?.grace_period,
                              graceApplicable:
                                details?.quott?.apply_grace_period,
                              revenue_type: details?.quott?.revenue_type,
                              occpation_date: details?.quott?.occpation_date,
                              contract_start_date:
                                details?.quott?.contract_start_date,
                            }}
                          />

                          {details?.reservation?.reservation_status && (
                            <>
                              <Box marginTop="18px" />
                              <Divider />
                              <Box marginTop="16px">
                                {/*reservation details */}
                                <ReservationCard
                                  t={t}
                                  data={{
                                    reservation_no:
                                      details?.reservation?.reservation_no ??
                                      "-",
                                    is_active: details?.reservation?.is_active,
                                    date: `${timeZoneConverter(
                                      details?.reservation?.start_date
                                    )} - ${timeZoneConverter(
                                      details?.reservation?.end_date
                                    )}`,
                                    amount: `${details?.reservation?.currency?.symbol} ${details?.reservation?.reserved_amount}`,
                                    // paid: "20 Feb 22"
                                  }}
                                  onHistory={() => setHistory(true)}
                                  onSend={onReserve}
                                  onCancel={onCancelBooking}
                                  type={
                                    details?.reservation?.reservation_status
                                  }
                                />
                              </Box>
                            </>
                          )}

                          <Box height="18px" />
                          <Divider />
                          <Box height="18px" />
                          <Typography className={classes.title}>
                            {t("Contact Details")}
                          </Typography>
                          {/*lead card*/}
                          <ContactList
                            t={t}
                            data={{
                              name: `${details?.quott?.first_name ?? ""} ${
                                details?.quott?.last_name ?? ""
                              }`,
                              img:
                                details?.quott?.image_url?.length > 0
                                  ? details?.quott?.image_url
                                  : null,
                              arg: !details?.quott?.is_contact
                                ? "Prospect"
                                : false,
                              phone: `${
                                details?.quott?.mobile_no_country_code ?? ""
                              } ${details?.quott?.mobile_no ?? ""}`,
                              mail: details?.quott?.email_id,
                            }}
                            leadtype={true}
                            is_flex
                          />
                          {/* customer account */}
                          {details?.quott?.account_id && (
                            <Box>
                              <Typography className={classes.title} mt={1}>
                                {t("Customer account")}
                              </Typography>
                              <ContactList
                                t={t}
                                data={{
                                  name: `${details?.quott?.account_name ?? ""}`,
                                  img:
                                    details?.quott?.url?.length > 0
                                      ? details?.quott?.url
                                      : null,
                                  arg: false,
                                  phone: `${
                                    details?.quott
                                      ?.account_mobile_no_country_code ?? ""
                                  }`,
                                  mail: details?.quott?.account_email_id,
                                }}
                                leadtype={true}
                              />
                            </Box>
                          )}

                          {/* broker account */}
                          {details?.quott?.broker_account_id && (
                            <Box>
                              <Typography className={classes.title} mt={1}>
                                {t("Broker account")}
                              </Typography>
                              <ContactList
                                t={t}
                                data={{
                                  name: `${
                                    details?.quott?.broker_account_name ?? ""
                                  }`,
                                  img:
                                    details?.quott?.broker_url?.length > 0
                                      ? details?.quott?.broker_url
                                      : null,
                                  arg: false,
                                  phone: `${
                                    details?.quott
                                      ?.broker_account_mobile_no_country_code ??
                                    ""
                                  }`,
                                  mail: details?.quott?.broker_email_id,
                                }}
                                leadtype={true}
                                is_flex
                              />
                            </Box>
                          )}

                          {/* sale account */}
                          {details?.quott?.sales_account_id && (
                            <Box>
                              <Typography className={classes.title} mt={1}>
                                {t("Sales Broker Account")}
                              </Typography>
                              <ContactList
                                t={t}
                                data={{
                                  name: `${
                                    details?.quott?.sales_account_first_name ??
                                    ""
                                  } ${
                                    details?.quott?.sales_account_last_name ??
                                    ""
                                  }`,
                                  img: null,
                                  arg: false,
                                  phone: `${
                                    details?.quott
                                      ?.sales_account_mobile_no_country_code ??
                                    ""
                                  } ${
                                    details?.quott?.sales_account_mobile_no ??
                                    ""
                                  }`,
                                  mail: details?.quott?.sales_account_email_id,
                                }}
                                leadtype={true}
                                is_flex
                              />
                            </Box>
                          )}

                          {/* broker agent */}
                          {details?.quott?.b_agent_account_id && (
                            <Box>
                              <Typography className={classes.title} mt={1}>
                                {t("Broker Agent")}
                              </Typography>
                              <ContactList
                                t={t}
                                data={{
                                  name: `${
                                    details?.quott?.b_agent_account_name ?? ""
                                  }`,
                                  img: details?.quott?.b_agent_url ?? null,
                                  arg: false,
                                  phone: `${
                                    details?.quott
                                      ?.b_agent_account_mobile_no_country_code ??
                                    ""
                                  } ${
                                    details?.quott?.b_agent_account_mobile_no ??
                                    ""
                                  }`,
                                  mail: details?.quott?.b_agent_email_id,
                                }}
                                leadtype={true}
                                is_flex
                              />
                            </Box>
                          )}
                        </Box>
                        <Grid container spacing={1}>
                          {permission?.create && (
                            <Grid item xs={5.5}>
                              {/*send quotation btn*/}
                              <Button
                                disabled={isRead}
                                startIcon={
                                  <Send style={{ marginInlineEnd: "10px" }} />
                                }
                                onClick={() =>
                                  setTemplate({
                                    bool: true,
                                    type: "send",
                                  })
                                }
                                variant="outlined"
                                className={classes.reserve}
                                fullWidth
                              >
                                {details?.quott?.is_resend
                                  ? t("Resend")
                                  : t("Send")}{" "}
                                {t("Quotation")}
                              </Button>
                            </Grid>
                          )}

                          {permission?.read && (
                            <Grid item xs={6.5}>
                              {/*pdf download btn*/}
                              <Button
                                startIcon={
                                  <FileDownloadOutlinedIcon
                                    style={{ marginInlineEnd: "10px" }}
                                  />
                                }
                                onClick={() =>
                                  setTemplate({
                                    bool: true,
                                    type: "view",
                                  })
                                }
                                variant="outlined"
                                className={classes.reserve}
                                fullWidth
                              >
                                {t("View Quotation")}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={5} lg={5} xl={4.5}>
                        {/*unit list*/}
                        <div className={classes.unitList}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            <Typography className={classes.title}>
                              {t("Unit Details")}
                            </Typography>
                            {!isRead && (
                              <Typography
                                className={classes.Customize}
                                style={{ cursor: "pointer" }}
                                onClick={() => checkReservation()}
                              >
                                {t("Customize")}
                              </Typography>
                            )}
                          </Stack>

                          <UnitList
                            t={t}
                            onOpen={onClickUnit}
                            list={details?.units}
                            noBtn
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4} padding="16px 16px 0px 16px">
                        {/*Quotation Summary*/}
                        <Typography className={classes.title}>
                          {t("Quotation Summary")}
                        </Typography>
                        <div className={classes.summeryRoot}>
                          <QuotationSummery
                            symbol={details?.quott?.symbol}
                            datas={{
                              totalAmount: details?.quott?.total_amount ?? 0,
                              totalrefundableTax:
                                details?.quott?.total_refundable ?? 0,
                              totalTax: details?.quott?.total_tax ?? 0,
                              total: details?.quott?.total_amount ?? 0,
                              totalDiscount:
                                details?.quott?.total_discount ?? 0,
                            }}
                            total={
                              details?.quott?.total_quote_amount ??
                              details?.quott?.total_amount +
                                details?.quott?.total_tax
                            }
                            height="310px"
                            t={t}
                            unit={details?.unitDetails}
                          />
                        </div>
                        {/*button container shown if status equal to draft*/}
                        {(details?.quott?.status !== quotationStatus?.won ||
                          details?.quott?.status !==
                            quotationStatus?.notIntrseted) && (
                          <Grid container spacing={1}>
                            {permission?.create && (
                              <Grid item xs={6}>
                                {/*reservation  btn*/}
                                <Button
                                  disabled={
                                    !selectedAcc?.id
                                      ? true
                                      : isRead
                                      ? true
                                      : permission?.create
                                      ? details?.reservation?.reservation_status
                                          ?.length > 0
                                      : true
                                  }
                                  variant="outlined"
                                  className={classes.reserve}
                                  onClick={() =>
                                    handleClick({
                                      title: t("Convert to Reservation"),
                                      img: <ConvertToResrvation />,
                                      types: "Reserved",
                                      btn: t("Reserve & Send Payment Link"),
                                    })
                                  }
                                  fullWidth
                                >
                                  {t("Reservation / Booking")}
                                </Button>
                              </Grid>
                            )}
                            <Grid item xs={6}>
                              {is_broker_module ? (
                                <Button
                                  disabled={isRead || isReservation?.disable}
                                  onClick={() =>
                                    setIsReservation({
                                      bool: true,
                                      type: "mark_as_won",
                                    })
                                  }
                                  variant="contained"
                                  className={classes.btn}
                                  fullWidth
                                >
                                  {t("Mark as Won")}
                                </Button>
                              ) : (
                                permission?.create && (
                                  <Button
                                    disabled={
                                      isReadConvert ||
                                      details?.quott?.agreement_id
                                    }
                                    onClick={() => checkDetails(true)}
                                    variant="contained"
                                    className={classes.btn}
                                    fullWidth
                                  >
                                    {t("Confirm Order")}
                                  </Button>
                                )
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  {/*create Aggreement dialog*/}
                  <React.Fragment key={"right"}>
                    <Drawer
                      anchor={"right"}
                      open={openDrawer}
                      onClose={() => setOpenDrawer(false)}
                    >
                      <QuotationContactCreate
                        onClose={() => setOpenDrawer(false)}
                        quotation_id={id}
                        t={t}
                        data={details}
                        email_id={details?.quott?.email_id}
                        contactId={details?.quott?.contact_id}
                        company_id={details?.quott?.company_id}
                        setSelectedAcc={setSelectedAcc}
                        selectedAcc={selectedAcc}
                        reload={getQuotDetail}
                        type={details?.quott?.revenue_type}
                        total={
                          details?.quott?.total_amount +
                          details?.quott?.total_tax
                        }
                        is_prospect={true}
                        is_quote={true}
                        checkPrimary={checkPrimary}
                        primary={primary}
                        setPrimary={setPrimary}
                        is_conform={true}
                      />
                    </Drawer>
                  </React.Fragment>
                  {/*unit details dialog*/}
                  <Dialog
                    className={classes.dialog}
                    maxWidth="xs"
                    fullWidth
                    open={openUnitDetails}
                    onClose={() => setOpenUnitDetails(false)}
                  >
                    <UnitSingleDetails
                      shortlist
                      selectedUnits={selectedUnit}
                      t={t}
                      onClose={() => setOpenUnitDetails(false)}
                    />
                  </Dialog>
                  {/* snack bar for send quotation */}
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Box display="flex" className={classes.snackBarRoot} p={2}>
                      <Box>
                        <ApprovedWhite />
                      </Box>
                      <Box marginLeft="10px" flexGrow={1}>
                        <Typography className={classes.alertTitle}>
                          {t("Quotation Link Sent Successfully")}
                        </Typography>
                        <Typography className={classes.alertSub}>
                          {details?.quott?.is_resend ? t("Resend") : t("Send")}{" "}
                          to {details?.quott?.email_id}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton onClick={handleClose}>
                          <CloseIcon fontSize="small" sx={{ color: "white" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Snackbar>
                  {/*pop up for reservation flow */}
                  <AlertDialog
                    component={renderType()}
                    isnotTitle={true}
                    open={openReserve}
                    onClose={() => setOpenReserve(false)}
                    md={true}
                    noBg={true}
                  />
                  {/*reservation*/}
                  <AlertDialog
                    component={
                      <Booking
                        t={t}
                        currency={{
                          label: details?.quott?.symbol,
                          value: details?.quott?.currency_id,
                        }}
                        reservationPrice={details?.reservationPrice}
                        is_reservation={
                          details?.reservation?.reservation_status === "Booked"
                            ? true
                            : false
                        }
                        reload={getQuotDetail}
                        company_id={details?.quott?.company_id}
                        quotation_id={id}
                        setSendPayment={setSendPayment}
                        type={type?.type}
                        onClose={handleClick}
                      />
                    }
                    header={type?.type?.title}
                    open={type?.bool}
                    onClose={handleClick}
                    isNormal={true}
                    t={t}
                  />
                  {/*send payment link*/}
                  <AlertDialog
                    component={
                      <SendPaymentLink
                        t={t}
                        mobile={`${details?.quott?.mobile_no_country_code} ${details?.quott?.mobile_no}`}
                        email_id={details?.quott?.email_id}
                        onClose={() => setSendPayment(false)}
                      />
                    }
                    header={t("Send Payment Link")}
                    open={sendPayment}
                    onClose={() => setSendPayment(false)}
                    isNormal={true}
                    t={t}
                  />
                  {/*reservation history*/}
                  <AlertDialog
                    component={<ReservationHistory />}
                    header={t("Booking History")}
                    open={openHistory}
                    onClose={() => setHistory(false)}
                    isNormal={true}
                  />
                  {/*cancel booking*/}
                  <AlertDialog
                    component={
                      <CancelBooking
                        company_id={details?.quott?.company_id}
                        quotation_id={id}
                        t={t}
                        onClose={onCancelBooking}
                        reload={getQuotDetail}
                        type={
                          details?.reservation?.reservation_status === "Booked"
                            ? "Booking"
                            : "Reserved"
                        }
                        data={cancelBooking?.data}
                      />
                    }
                    header={t("Cancel Booking")}
                    open={cancelBooking?.bool}
                    onClose={onCancelBooking}
                    isNormal={true}
                    t={t}
                  />
                  {/* quotation pdf */}
                  <AlertDialog
                    header={details?.quott?.quotation_no}
                    onClose={() => {
                      setOpenpdf(false);
                      setPdf({
                        bool: false,
                        data: "",
                      });
                    }}
                    open={openpdf}
                    // render={renderButton()}
                    component={
                      <Box p={1} bgcolor={"#F5F7FA"}>
                        <Box style={{ height: `calc(100vh - 100px)` }}>
                          {pdf?.bool ? (
                            <iframe
                              src={`data:application/pdf;base64,${pdf?.data}`}
                              alt="pdf"
                              width="100%"
                              style={{ height: `calc(100vh - 152px)` }}
                              title="quotation"
                            />
                          ) : (
                            <NewLoader />
                          )}
                        </Box>
                      </Box>
                    }
                  />

                  <TemplateGenerator
                    t={t}
                    name={t("Quotation Template")}
                    btnText={
                      template?.type === "view" ? t("Generate") : t("Send")
                    }
                    open={template?.bool}
                    onClose={() =>
                      setTemplate({
                        bool: false,
                        type: "view",
                      })
                    }
                    type={1}
                    generate={generate}
                  />

                  {/* reservation is occur */}
                  <DialogBox
                    maxWidth={"sm"}
                    component={
                      <div
                        className={
                          language === "ar"
                            ? classes.proceedDialogArabic
                            : classes.proceedDialog
                        }
                      >
                        <Box>
                          <Typography className={classes.convert}>
                            {isReservation?.type === "mark_as_won"
                              ? t(
                                  "Are you sure you want to mark as won this quotation"
                                )
                              : t(
                                  "There is an active reservation present against your quotation please cancel and proceed"
                                )}
                          </Typography>
                          {/* <Typography className={classes.convertProcess}>
                                ddd
                              </Typography> */}
                          <Box
                            display="flex"
                            alignItems="center"
                            className={classes.marginTop}
                          >
                            {isReservation?.type === "mark_as_won" ? (
                              <Stack direction="row" spacing={1}>
                                <Button
                                  className={classes.yes}
                                  onClick={() => markAsWon()}
                                >
                                  {t("Yes")}
                                </Button>
                                <Button
                                  className={classes.No}
                                  onClick={() =>
                                    setIsReservation({
                                      bool: false,
                                      type: "",
                                    })
                                  }
                                >
                                  {t("No")}
                                </Button>
                              </Stack>
                            ) : (
                              <Button
                                className={classes.No}
                                onClick={() =>
                                  setIsReservation({
                                    bool: false,
                                    type: "",
                                  })
                                }
                              >
                                {t("Ok")}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </div>
                    }
                    open={isReservation?.bool}
                    onClose={() =>
                      setIsReservation({
                        bool: false,
                        type: "",
                      })
                    }
                    isnotTitle
                  />
                </Box>
              )
            }
          </>
        )}
      </>
    );
  };

  return <div>{accessCheckRender(render, permission)}</div>;
}
export default withNamespaces("quotationsList")(QuotationDetails)