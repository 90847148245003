import { Box, Typography, Divider, Button } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AlertDialog } from "../../../components";
import { Owner } from "./ownerSelect";
import { OwnerUnit, PropertyUnit } from "../../../assets";

export const UnitSelectionLead = ({
  onChange = () => false,
  value = [],
  company = null,
  datas = {},
  isEdit = false,
  onDeleteUnits = () => false,
  typeData = "",
  purpose = "",
  handleClose = () => false,
  setDataProps = () => false, dataProps = {}, addUnit = () => false,
  t = () => false,
  end_date
}) => {
  const classes = useStyles();
  const [chooseOwner, setChooseOwner] = React.useState(false);

  const types = [
    {
      value: 1,
      label: t("Select Landlord/Owner Units"),
      description: "",
      icon: <OwnerUnit />,
      search: "Search Owner",
      is_diver: true,
    },
    {
      value: 2,
      label: t("Select Property Units"),
      description: "",
      icon: <PropertyUnit />,
      search: "Search Property",
    },
  ];

  const [data, setData] = React.useState({
    type: types?.[1],
  });
  const [unitData, setUnitData] = React.useState({
    data: [],
    id: "",
  });

  const chooseOwnerforUnitSelection = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setChooseOwner(true);
  };

  React.useEffect(() => {
    if (isEdit) {
      // if (datas?.property_id) {
        setData({
          type: types[1],
        });
      // } else {
      //   setData({
      //     type: types[0],
      //   });
      // }
    }
    setChooseOwner(true)
    // eslint-disable-next-line
  }, []);

  const addUnits = () => {
    addUnit(unitData?.data , setChooseOwner , handleClose)
    // setChooseOwner(false);
    // handleClose()
  };

  const onChangeState = (unit, owner, type, is_delete, data) => {

    if (!data?.is_active === false) {
      setUnitData({
        data: unit?.filter((val) => val?.is_active === true),
        id: is_delete ?? "",
      });
      onChange(unit?.filter((val) => val?.is_active === true), owner, type);
    }
    else {
      setUnitData({
        data: [...datas?.units, {
          ...data,
          is_active: true
        }],
        id: is_delete ?? "",
      });
      onChange([...datas?.units, {
        ...data,
        is_active: true
      }], owner, type);
    }
  };

  return (
    <>
      {/* <Box>
        {types?.map((val) => {
          return (
            <>
              <Box
                className={classes.selectCard}
                onClick={() => chooseOwnerforUnitSelection("type", val)}
              >
                <Box display="flex" alignItems="center">
                  <Box mr={2}>{val?.icon}</Box>
                  <Box>
                    <Typography className={classes.ownerText}>
                      {val?.label}
                    </Typography>
                    <Typography className={classes.ownerSubText}>
                      {val?.description}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <ChevronRightIcon
                    className={classes.selectarrowRightIcon}
                  />
                </Box>
              </Box>
              {val?.is_diver && <Divider />}
            </>
          );
        })}
      </Box> */}

      <AlertDialog
        isNormal
        onClose={() => {
          setChooseOwner(false);
        }}
        header={
          data?.type?.value === 1
            ? t("Select Against Owner Units")
            : t("Select Against Property Units")
        }
        open={chooseOwner}
        component={
          <>
            <Box p={2}>
              <Owner
                datas={datas}
                isEdit={isEdit}
                company={company}
                onChange={onChangeState}
                type={data?.type}
                onDeleteUnits={onDeleteUnits}
                value={datas?.selectedUnit ?? []}
                onClose={() => {
                  chooseOwnerforUnitSelection("type", null);
                  onDeleteUnits([], null, true);
                }}
                is_show={true}
                typeData={typeData}
                purpose={purpose}
                end_date={end_date}
              />
            </Box>
            <Box p={"0px 16px 16px 16px"}>
              <Button
                variant="contained"
                className={classes.btnAdd}
                onClick={() => addUnits()}
              // disabled={
              //   datas?.units?.length >
              //   0
              //     ? false
              //     : true
              // }
              >
                {t("Add Units")}
              </Button >
            </Box >
          </>
        }
      ></AlertDialog >
    </>
  );
};
