import { useApolloClient } from "@apollo/client";
import { Box, Button, Dialog, Divider, Drawer, Grid, Typography } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingSection, Subheader } from "../../components";
import { AlertContext, AuthContext } from "../../contexts";
import { GET_SHORTLIST_UNIT } from "../../graphql/shortlistedqueries";
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, AssetMasterType, getRoutePermissionNew, returnQuotationRoutes } from "../../utils";
import { UnitSingleDetails } from "../propertyFinder3/component/singleUnitDetail";
import UnitCard from "../propertyFinder3/component/unitCard";
import { useStyles } from "./styles";
import { CreateLead } from "../../components/createLead";
import { withNamespaces } from 'react-i18next'


const ShortListUnits = props => {
    const { t } = (props)
    const client = useApolloClient();
    const history = useHistory();
    const classes = useStyles();
    const [list, setList] = React.useState([])
    const [selectedUnits, setSelectedunit] = React.useState("")
    const [open, setOpen] = React.useState(false)
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)
    const [loading, setLoading] = React.useState(true)
    const { state } = useLocation()
    const [permission, setPermission] = React.useState({});
    const [create, setCreate] = React.useState({
        bool: false,
        details: {},
        type: null
    })
const [state1 , setState1]=React.useState(true)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                //initial load
                if (state) {
                    getUnitList(state?.unitId)
                } else {
                    history.push(Routes.propertyFinder)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    //get unit list
    const getUnitList = (ids) => {
        client.query({
            query: GET_SHORTLIST_UNIT,
            fetchPolicy: 'network-only',
            variables: {
                unit_id: ids,
                assetType: AssetMasterType?.profile
            }
        }).then((res) => {

            setList(res.data?.unit)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    //on delete unit
    const onDelete = (id) => {
        if (list?.length > 1) {
            // const deleted = list.filter((ids) => ids.id !== id)[0]
            // getUnit([deleted?.id]);
            const deleted = list.filter((ids, index) => index !== id)
            setList(deleted);
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.info,
                msg: t("Cannot Delete"),
            });
        }
    }
    //on open unit details
    const openDetails = (value) => {
        setOpen(true)
        setSelectedunit(value)
    }

    //on click create quotation
    const handleQuotation = (type) => {
        let details;
        if (state?.duration?.lead) {

            history.push(
                {
                    pathname: create?.type === "shortlist" ? Routes.shortlistLead : Routes.createOppertunityQuotation,
                    state: {
                        type: "existing lead",
                        unit: list,
                        date: {
                            fromDate: state?.data?.duration?.fromDate,
                            toDate: state?.data?.duration?.toDate
                        },
                        data: state?.data,
                        duration: state?.duration,
                        companyID: state?.companyID
                    }
                })

        } else {
            details = {
                type: "new lead",
                unit: list,
                date: {
                    fromDate: state?.data?.duration?.fromDate,
                    toDate: state?.data?.duration?.toDate
                },
                data: state?.data,
                duration: state?.duration,
                companyID: state?.companyID
            }
            setCreate({
                bool: true,
                details,
                type
            })
        }


    }

    const onGoNext = (val, type) => {
        let quotation_route = returnQuotationRoutes(state?.duration?.lead, props?.isOppertunity, type)
        history.push(
            {
                pathname: create?.type === "shortlist" ? Routes.shortlistLead : quotation_route,
                state: {
                    ...create?.details,
                    ...val,
                    primary:state1
                }
            })
    }

    const handleCloseDrawr = () => {
        setCreate({
            ...create,
            bool: false
        })
    }

    const render = () => {
        return <>
            {/*subheader*/}
            <Subheader goBack={() => history.goBack()} title={t("Shortlisted Units")} />
            <div className={classes.root}>
                {
                    loading ? <LoadingSection bottom="40vh" />
                        :
                        <div className={classes.content1}>
                            <Grid container spacing={2}>
                                {/*unit list*/}
                                {list.map((val, index) => {
                                    return (
                                        <Grid item lg={2.5} md={4} sm={6} xs={12} >
                                            <UnitCard currency={val?.currencyByID?.symbol}
                                                onDelete={() => onDelete(index)}
                                                data={{ ...val, unit_type: val?.unit_type?.name, uom_name: val?.uom_master?.label }}
                                                type={val?.type}
                                                delete={true}
                                                t={t}
                                                onClick={openDetails} />
                                        </Grid>

                                    )
                                })}
                            </Grid>
                        </div>
                }

                <Divider />

                {/*button container*/}
                <div className={classes.content2}>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            <Typography className={classes.shortTitle}>
                                {list?.length}&nbsp;{t("Shortlisted")}
                            </Typography>
                        </Box>
                        {permission?.create && <>
                            <Box>
                                {!state?.disable &&
                                    <Button onClick={() => handleQuotation("shortlist")} variant="contained"
                                        className={classes.btn1}>
                                        {t("Shortlist To Opportunity")}
                                    </Button>}
                            </Box>
                            <Box marginInlineStart="8px">
                                <Button onClick={()=>handleQuotation("quotation")} variant="contained"
                                    className={classes.btn2}>
                                    {t("Create Quotation")}
                                </Button>
                            </Box>
                        </>}
                    </Box>
                </div>


            </div>
            {/*Dialog for unit Details*/}
            <Dialog maxWidth="xs" className={classes.dialog} fullWidth open={open} onClose={() => setOpen(false)}>
                <UnitSingleDetails
                    revenue_type={state?.data?.revenue_type}
                    grace={state.data?.stateFilter?.facilitesbool}
                    startDate={state?.data?.duration?.fromDate}
                    t={t}
                    endDate={state?.data?.duration?.toDate}
                    shortlist={true} selectedUnits={selectedUnits} onClose={() => setOpen(false)} />
            </Dialog>

            <React.Fragment key={'right'}>
                <Drawer
                    anchor={'right'}
                    open={create?.bool}
                    onClose={handleCloseDrawr}
                >
                    <CreateLead title="Create Contact" t={t} onNext={onGoNext} onClose={handleCloseDrawr} is_quotation={true} company_id={state?.companyID?.value} revenue_type={state?.data?.revenue_type} property_purpose={state?.data?.property_purpose} state1={state1} setState1={setState1}/>
                </Drawer>
            </React.Fragment>


        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div >
}
export default withNamespaces("shortListUnits")(ShortListUnits)