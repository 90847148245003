import { useApolloClient } from "@apollo/client"
import { Box, Dialog, Grid, Stack, useMediaQuery } from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { withNamespaces } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { BuildingIcon } from "../../assets/propertyBoard/buildingIcon"
import NoUnitsAsset from "../../assets/propertyBoard/nodataFound"
import { Parking } from "../../assets/propertyBoard/parking"
import { PropertyIcon } from "../../assets/propertyBoard/propertyIcon"
import { AlertDialog, LoadingSection, SearchFilter, Subheader, UseDebounce } from "../../components"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { BackendRoutes } from "../../router/routes"
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getCalendarCustomOptions, getCompanyOption, getRoutePermissionNew } from "../../utils"
import { PropertyDetailCard } from "./components/card"
import { DateRangePickerComponent } from "./components/dateRangePicker"
import { FilterDropdownComponent } from "./components/filterDropdown"
import PropertySelect from "./components/propertySelect"
import { StatusTab } from "./components/statusTab"
import { UnitCard } from "./components/unitCard"
import { ViewMoreDetails } from "./components/viewMoreDetails"
import ViewPropertyDetails from "./components/viewPropertyDetails"
import { useStyles } from "./style"
import { getPropertyHierarchyDetails } from "../../utils/propertyHierarchy"
export const initialFormState = {
    purpose: "",
    resource: "",
    start_period: new Date(),
    end_period: new Date(),
    duration: "",
    duration_type: "Days",
    blocking_purpose: "",
    error: {
        duration: "",
        resource: ""
    }
}
export const statusOptions = (t) => [
    {
        label: t("All"),
        value: null
    },
    {
        label: t("Vacant"),
        value: "vacant"
    },
    {
        label: t("Occupied"),
        value: "occupied"
    },
    {
        label: t("Blocked"),
        value: "blocked"
    },
    // {
    //     label: "All others",
    //     value: "All others"
    // },
]
const PropertyBoard = ({ t, loading, handleLoading }) => {
    // eslint-disable-next-line
    const client = useApolloClient()
    const [companyList, setCompanyList] = useState([])
    const [selectedCompany, setSelectedCompany] = useState("")
    const [searchText, setSearchText] = useState("")
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    // eslint-disable-next-line
    const [permission, setPermission] = useState("")
    // eslint-disable-next-line
    const [screenLoading, setScreenLoading] = useState(true)
    const classes = useStyles()
    const [isView, setIsView] = useState(false)
    const [isShare, setIsShare] = React.useState(false)
    const [viewDetails, setViewDetails] = useState([])
    const [selectType, setSelectType] = useState("")
    const [moreDetails, setMoreDetails] = useState(false)
    const [selectedProperty, setSelectedProperty] = useState("")
    const [selectedBlock, setSelectedBlock] = useState("")
    const [selectedFloor, setSelectedFloor] = useState("")
    const [selectedTeams, setSelectedTeams] = useState("");
    const [property_hierarchy_details, set_property_hierarchy_details] = useState({ custom_level_1: "", custom_level_2: "" });
    const [propertyDetails, setPropertyDetails] = useState({
        propertyOptions: []
    })
    const [blockOptions, setBlockOptions] = useState([])
    const [floorOptions, setFloorOptions] = useState([])
    const [offset, setOffset] = useState(0);
    const [calanderValue, setCalanderValue] = useState({})
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const [formData, setFormData] = useState(initialFormState)
    const [propertyBoardList, setPropertyBoardList] = React.useState([]);
    const [loadOffset, setLoadOffset] = React.useState(0)
    const debounce = UseDebounce()
    //new
    const [calendarOptions, setCalendarOptions] = React.useState([])
    const [selectedCalendarOption, setSelectedCalendarOption] = React.useState("")
    const [selectedType, setSelectedType] = React.useState("")
    const [vacancyStatus, setVacancyStatus] = React.useState("")
    const [count, setCount] = React.useState({
        total: 0,
        vacant: 0,
        blocked: 0
    })
    const matches = useMediaQuery('(max-width:600px)');
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list);
                    setSelectedCompany(company?.selected);
                    setCalendarOptions(getCalendarCustomOptions())
                    setSelectedCalendarOption(getCalendarCustomOptions()?.[0])
                    setSelectedType(statusOptions(t)?.[0])
                    setCalanderValue({
                        value: {
                            from_date: moment(new Date()).format("YYYY-MM-DD"),
                            to_date: moment(new Date()).format("YYYY-MM-DD"),
                            from_date_iso: moment(new Date()).format("YYYY-MM-DD") + "T00:00:00.000Z",
                            to_date_iso: moment(new Date()).format("YYYY-MM-DD") + "T00:00:00.000Z",
                        }
                    })
                }
            }
        }
        // eslint-disable-next-line
    }, [auth])

    const getPropertyBoardList = (selectedType, company_id, property_id, block_id, floor_id, offset = 0, limit = 10, search = "", calender_value, is_more = false) => {
        if (!is_more) {
            setScreenLoading(true)
        }

        let payload = {
            "properties": property_id ? [property_id] : [],
            "block_ids": block_id ? [block_id] : [],
            "floor_ids": floor_id ? [floor_id] : [],
            "start": offset,
            "length": 20,
            "type": selectedType,
            "search": search,
            "start_date": calender_value?.value?.from_date_iso,
            "end_date": calender_value?.value?.to_date_iso,
            "company_id": company_id
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.property_board}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            let temp_property_board_list = response?.data?.data?.map((i) => {
                return {
                    status: i?.status,
                    image: i?.logo ?? "",
                    property_type: i?.unit_purpose ?? "",
                    amount: `${i?.room_rent ?? ""}/${i?.payment_period ?? ""}`,
                    type: i?.is_vacant && "Vacant",
                    unit_name: i?.name ?? "",
                    area: `${i?.total_area ?? ""} ${i?.area_matric ?? ""}`,
                    unit_type: i?.unit_type ?? "",
                    bed: i?.total_bed_rooms ?? "",
                    bathrooms: i?.total_baths ?? "",
                    data: i,
                    name: i?.name,
                    ...i
                }
            })
            if (is_more) {
                setPropertyBoardList([...propertyBoardList, ...temp_property_board_list])
            } else {
                setPropertyBoardList(temp_property_board_list)
                setScreenLoading(false)
            }
            setCount({ total: response?.data?.total_count, vacant: response?.data?.vacant_count, blocked: response?.data?.blocked_count })
        }).catch(() => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
            });
        });
    }

    const getUnitDetails = (unit_id, params) => {
        let payload = {
            "unit_id": unit_id,
            "start_date": calanderValue?.value?.from_date,
            "end_date": calanderValue?.value?.to_date,
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.property_board_unit_details}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {

            let data = response?.data
            let details = {
                ...params,
                from: moment(calanderValue?.value?.from_date).format("DD MMM YYYY"),
                to: moment(calanderValue?.value?.to_date).format("DD MMM YYYY"),
                pricing: data?.unit_pricing?.units?.[0]?.pricing ?? [],
                link: data?.url,
                route: data?.route,
                execution_status: data?.data?.execution_status,
                furnishing: data?.data?.furnishing,
                assets: data?.data?.unit_assets,
                revenue_type: data?.data?.revenue_type,
                unit_no: data?.data?.unit_no,
                amenities: data?.data?.property?.facilities,
                built_year: moment(data?.data?.year_built).format("DD MMM YYYY"),
                amount: data?.unit_pricing?.units[0]?.roomRent,
                address: `${data?.data?.address?.street_1 ?? ""} ${data?.data?.address?.street_1?.length>0?",":""} ${data?.data?.address?.street_2 ?? ""} ${data?.data?.address?.street_2?.length > 0 ? "," : ""} ${data?.data?.address?.district} ${data?.data?.address?.district?.length > 0 ? "," : ""} ${data?.data?.address?.country}`,
                unit_block_request: data?.unit_block_request,
                over_lapping_agreement: data?.over_lapping_agreement,
                unit_vaccancy: data?.unit_vaccancy,
                property_id: data?.data?.property?.id,
                currency_symbol: selectedCompany?.currency_symbol
            }
            setViewDetails(details)
        }).catch(() => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
            });
        });
    }

    const getPropertyDetails = () => {
        let payload = {
            "unit_id": viewDetails?.data?.id,
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.property_board_property_details}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            let data = response?.data
            setViewDetails({
                ...viewDetails,
                property_no: data?.property_no,
                property_name: data?.name,
                property_assets: data?.assets,
                property_type: data?.type?.group_name,
                property_amenities: data?.facilities,
                property_address: `${data?.address?.street_1 ?? ""} ${data?.address?.street_2 ?? ""} ${data?.address?.city ?? ""} ${data?.address?.city?.length > 0 ? "," : ""} ${data?.address?.district} ${data?.address?.district?.length > 0 ? ",." : ""} ${data?.address?.country}`,
                total_unit: data?.unit_count,
                prop_long: data?.address?.longitude,
                prop_lat: data?.address?.latitude,
            })

        }).catch(() => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
            });
        });
    }

    const changeExecutionStatus = (execution_status) => {
        let payload = {
            "upsert_fields": {
                "id": viewDetails?.data?.id,
                "execution_status": execution_status
            }
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.unit_upsert}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            // let temp_property_board_list = response?.data?.data?.map((i) => {
            //     return {
            //         status: !i?.is_vacant && "Occupied",
            //         image: i?.logo ?? "",
            //         property_type: i?.unit_purpose ?? "",
            //         amount: `${i?.room_rent ?? ""}/${i?.payment_period ?? ""}`,
            //         type: i?.is_vacant && "Vacant",
            //         unit_name: i?.name ?? "",
            //         area: `${i?.carpet_area ?? ""} ${i?.area_matric ?? ""}`,
            //         unit_type: i?.unit_type ?? "",
            //         bed: i?.total_bed_rooms ?? "",
            //         bathrooms: i?.bathroom ?? "",
            //         data: i
            //     }
            // })
            // setViewDetails(temp_property_board_list)
        }).catch(() => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
            });
        });
    }


    //dummyData
    const HouseDetails = [
        {
            image: "https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg?w=740&t=st=1678346937~exp=1678347537~hmac=fea71d40eda36c3338ccb4cc70ffa8c9bdd89cc6fa7a4fee4d9a50c78f8189fe",
            amount: "$ 2,200 / Day",
            type: "Vacant",
            unit_name: "Unit Name",
            area: "200 sq.ft",
            floor: "2 BHK",
            bed: 2,
            bathrooms: 2,
            property_type: "Bedroom",
            unit_id: "Unit Id here",
            furnishing: "Furnishing",
            revenue_type: "Lease",
            built_year: 2021,
            building_type: "Apartment",
            orient: "Orient",
            community: "Community",
            address: "1st Floor, A Block, 23 Main Street, 3rd Cross street, 3rd Sector, Chennai, Tamilnadu, India - 60001",
            amenities: ["Free WiFi", "Car Parking", "24 Hrs CCTV", "Air Conditioner"],
            base_rent: "$ 1000",
            maintenance_percentage: "10 %",
            maintenance_amount: "$ 200",
            deposit: "$ 1000",
            property_name: "Property Name here",
            property_id: "Property Id here",
            total_units: "200",
            link: "https://dnetsoft.com/EVrbfvGPuqxBoIAKz710/jAMx...",
            nearby: ["Schools", "Hospitals", "Restaurants", "Parks", "Transportation"],
            blockDetails: {
                name: "Block Person name here",
                type: "Type",
                dateRange: "Block date range here"
            }
            ,
            agreementDetails: {
                id: "ID 123",
                from: "18 Jan 23",
                to: "21 Jan 23",
                revenue: "$ 235",
                contact_name: "ABC"
            },
            reservationDetails: {
                img: "",
                occupant_name: "Ram",
                reserved_by: "ABC"
            },
            occupant_history: [
                {
                    dateRange: "22 Jan 23 - 24 Jan 23",
                    agreement_no: "Agreement no",
                    contact_name: "Contact Person Name"
                },
                {
                    dateRange: "22 Jan 23 - 24 Jan 23",
                    agreement_no: "Agreement no",
                    contact_name: "Contact Person Name"
                },
                {
                    dateRange: "22 Jan 23 - 24 Jan 23",
                    agreement_no: "Agreement no",
                    contact_name: "Contact Person Name"
                },
            ],
            assets: [
                {
                    "id": "3743d50f-0c84-4bc7-81ad-f449a3f97b68",
                    "priority": 1,
                    "url": "https://protomate.objectstore.e2enetworks.net/27d7ee53-b860-42a5-aca1-9c48da5c3602/1678262558049-images.jfif.jpeg",
                    "file_meta": {
                        "name": "images",
                        "size": "8.52 KB",
                        "type": "jfif"
                    }
                },
                {
                    "id": "3743d50f-0c84-4bc7-81ad-f449a3f97b68",
                    "priority": 1,
                    "url": "https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg?w=740&t=st=1678346937~exp=1678347537~hmac=fea71d40eda36c3338ccb4cc70ffa8c9bdd89cc6fa7a4fee4d9a50c78f8189fe",
                    "file_meta": {
                        "name": "images",
                        "size": "8.52 KB",
                        "type": "jfif"
                    }
                },
            ]
        },
    ]

    React.useEffect(() => {
        if (selectedTeams?.value) {
            getPropertyByCompanyID(selectedCompany?.value, selectedTeams?.value, 0, 20, "");
        }
        //eslint-disable-next-line
    }, [selectedTeams]);

    const getPropertyByCompanyID = (companyID, teamsId, offset = 0, limit = 20, search = "", property, reload = false, moreData = false, noLoad = false, is_search = false) => {
        const payload = {
            company_id: companyID,
            status: [true],
            offset: offset,
            limit: 20,
            search: search,
            "start_date": calanderValue?.value?.from_date_iso,
            "end_date": calanderValue?.value?.to_date_iso,
        }
        if (teamsId !== "noteams") {
            payload["team"] = teamsId
            payload["team_all_property"] = true
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.property_get_all_properties}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then(async (response) => {
                // if (response?.data?.data?.property?.length > 0) {
                    const propertyData = response?.data?.data?.property?.map((val) => {
                        return {
                            id: val?.id,
                            name: val?.name,
                            logo: val?.logo,
                            no_of_units: val?.unit_count,
                            vacant_units: val?.vacant_units
                        }
                    })


                    if (!moreData && !is_search) {
                        const temp_property = reload ? property : propertyData?.[0];
                        setSelectedProperty(temp_property)
                        const property_hierarchy_details = await getPropertyHierarchyDetails({ property: temp_property });
                        const custom_level_1 = property_hierarchy_details?.hierarchy_id === 5 && property_hierarchy_details;
                        const custom_level_2 = property_hierarchy_details?.hierarchy_id === 6 && property_hierarchy_details;
                        set_property_hierarchy_details({ custom_level_1, custom_level_2 });
                    }

                    setPropertyDetails({ propertyOptions: moreData ? [...propertyDetails.propertyOptions,...propertyData] : propertyData })



                    if (!noLoad) {

                        getBlockByProperty(reload ? property?.id : propertyData?.[0]?.id, 0, 10, "")
                        getFloorByProperty(reload ? property?.id : propertyData?.[0]?.id, null, 0, 10, "")
                        getPropertyBoardList(selectedType?.value, companyID, reload ? property?.id : (is_search || (is_search && search === "")) ? null : propertyData?.[0]?.id, null, null, 0, 10, "", calanderValue)
                    }
                // }
                // else {
                //     // setSelectedProperty([])
                //     setPropertyDetails({ propertyOptions: [] })
                //     getBlockByProperty(null, 0, 10, "")
                //     getFloorByProperty(null, null, 0, 10, "")
                //     getPropertyBoardList(selectedType?.value, companyID, null, null, null, 0, 10, "", calanderValue)
                // }
                setScreenLoading(false)
            })
            .catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    };
    const reload = (type) => {
        if (type === "property") {
            getPropertyByCompanyID(selectedCompany?.value, selectedTeams?.value, 0, 20, "", selectedProperty, true, false, true);
        }
        else if (type === "block") {
            getBlockByProperty(selectedProperty?.id, 0, 20, "", selectedBlock, true)
        }
        else if (type === "floor") {
            getFloorByProperty(selectedProperty?.id, selectedBlock?.id, 0, 20, "", selectedFloor, true)
        }
    }

    const getBlockByProperty = (propertyID, offset = 0, limit = 10, search = "", selectedBlock, reload = false, moreData = false, is_search = false) => {
        const payload = {
            start: offset,
            length: 10,
            property_ids: propertyID ? [propertyID] : [],
            search: search,
            company_id: selectedCompany?.value
        };
        NetworkCall(
            `${config.api_url}${BackendRoutes?.block}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                // if (!reload) {
                if (response?.data?.data?.length > 0) {
                    const result = [{ name: t("All Blocks") }, ...response?.data?.data]
                    if (!is_search) {
                        setSelectedBlock(reload ? selectedBlock : result?.[0])
                        setBlockOptions(moreData ? blockOptions.concat(result) : result)
                    }
                    else {
                        setBlockOptions(result)
                    }

                }
                else if (!is_search) {
                    setSelectedBlock("")
                    setBlockOptions([])
                    setSelectedFloor("")
                    setFloorOptions([])
                }
            })
            .catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    }
    const getFloorByProperty = (propertyID, blockID, offset = 0, limit = 10, search = "", selectedFloor, reload = false, moreData = false) => {
        const payload = {
            start: offset,
            length: 10,
            property_ids: propertyID ? [propertyID] : [],
            block_ids: blockID ? [blockID] : [],
            search: search,
            company_id: selectedCompany?.value
        };
        NetworkCall(
            `${config.api_url}${BackendRoutes?.floor}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                if (response?.data?.data?.length > 0) {
                    const result = [{ name: "All Floors" }, ...response?.data?.data]
                    setSelectedFloor(reload ? selectedFloor : result?.[0])
                    setFloorOptions(moreData ? floorOptions.concat(result) : result)
                }
                else {
                    setSelectedFloor("")
                    setFloorOptions([])
                }
            })
            .catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    }
    //handleCompanyChange
    const onChangeCompany = (val) => {
        setSelectedCompany(val)
        getPropertyByCompanyID(val?.value, selectedTeams?.value, 0, 20, "")
    }
    //handleSearch
    const handleSearch = (val) => {
        setSearchText(val)
        debounce(() => getPropertyBoardList(selectedType?.value, selectedCompany?.value, selectedProperty?.id, selectedBlock?.id, selectedFloor?.id, 0, 10, val, calanderValue), 800)
    }
    //More Details
    const MoreDetails = (data) => {
        setViewDetails(data)

        setMoreDetails(true)
    }
    //openViewModal
    const openViewModal = (data) => {
        setViewDetails(data)
        setIsView(true)
        setVacancyStatus(data?.vacancy_status)
        getUnitDetails(data?.data?.id, data)
    }
    const unitReload = (unit_id, data) => {
        getPropertyBoardList(selectedType?.value, selectedCompany?.value, selectedProperty?.id, selectedBlock?.id, selectedFloor?.id, 0, 10, "", calanderValue)
        getUnitDetails(unit_id, data)
    }
    //handleSearch
    const handleDropDownSearch = async (type, val) => {
        if (type === "block") {
            setSelectedBlock(val)
            getBlockByProperty(selectedProperty?.id, 0, 20, val, true, false, false, true)
        }
        else if (type === "floor") {
            setSelectedFloor(val)
            getFloorByProperty(selectedProperty?.id, 0, 10, val, true, false, false, true)
        }
        else {
            setSelectedProperty(val)
            const property_hierarchy_details = await getPropertyHierarchyDetails({ property: val });
            const custom_level_1 = property_hierarchy_details?.hierarchy_id === 5 && property_hierarchy_details;
            const custom_level_2 = property_hierarchy_details?.hierarchy_id === 6 && property_hierarchy_details;
            set_property_hierarchy_details({ custom_level_1, custom_level_2 });
            getPropertyByCompanyID(selectedCompany?.value, selectedTeams?.value, 0, 20, val, true, false, false, false, true)
        }
    }
    // onchangeDate 
    // const onChangeCalendar = (date) => {
    //     setCalanderValue(date)
    //     getPropertyBoardList(selectedCompany?.value, selectedProperty?.id, selectedBlock?.id, selectedFloor?.id, null, 0, 10, "", date)

    // }
    //handleSelect propeerty,block,floor
    const handleSelect = async (type, data) => {
        setScreenLoading(true)
        if (type === "block") {
            setSelectedBlock(data)
            getFloorByProperty(selectedProperty?.id,data?.id)
            getPropertyBoardList(selectedType?.value, selectedCompany?.value, selectedProperty?.id, data?.id, null, 0, 10, "", calanderValue)
        }
        else if (type === "floor") {
            setSelectedFloor(data)
            getPropertyBoardList(selectedType?.value, selectedCompany?.value, selectedProperty?.id, selectedBlock?.id, data?.id, 0, 10, "", calanderValue)
        }
        else {
            setSelectedProperty(data)
            const property_hierarchy_details = await getPropertyHierarchyDetails({ property: data });
            const custom_level_1 = property_hierarchy_details?.hierarchy_id === 5 && property_hierarchy_details;
            const custom_level_2 = property_hierarchy_details?.hierarchy_id === 6 && property_hierarchy_details;
            set_property_hierarchy_details({ custom_level_1, custom_level_2 });
            // getPropertyByCompanyID(selectedCompany?.value, selectedTeams?.value, 0, 10, "", data, true)
            getPropertyBoardList(selectedType?.value, selectedCompany?.value, data?.id, null, null, 0, 10, "", calanderValue, false)
            getBlockByProperty(data?.id)
            getFloorByProperty(data?.id)
        }
    }
    //handleTeamChange
    const handleTeamsChange = (val) => {
        setSelectedTeams(val)
    }
    //formState update
    const updateState = (k, v, k1, v1) => {
        let error = formData.error;
        error[k] = "";
        error[k1] = ""
        setFormData({ ...formData, [k]: v, [k1]: v1, error })
    }
    const closeModal = () => {
        setIsView(false)
    }
    const openDialog = (val) => {
        // setIsView(false)
        setIsShare(true)
        setSelectType(val)
    }
    const closeDialog = () => {
        setIsShare(false)
        setFormData(initialFormState)
    }
    //fetch more data-infinite scroll
    const fetchMoreData = (type) => {

        setOffset(offset + 20);
        if (type === "property") {
            getPropertyByCompanyID(selectedCompany?.value, selectedTeams?.value, offset + 20, 20, "", selectedProperty, true, true, true);
        }
        else if (type === "block") {
            getBlockByProperty(selectedProperty?.id, 0, 10, "", selectedBlock, true, true)
        }
        else {
            getFloorByProperty(selectedProperty?.id, selectedBlock?.id, 0, 10, "", selectedFloor, true, true)
        }
    }

    //fetch more units
    const fetchMoreUnits = () => {
        getPropertyBoardList(selectedType?.value, selectedCompany?.value, selectedProperty?.id, selectedBlock?.id, selectedFloor?.id, loadOffset + 20, 20, "", calanderValue, true)
        setLoadOffset(loadOffset + 10)

    }

    // Function to change the Calendar
    const handleCalendarChange = (date) => {
        let result = {
            value: {
                from_date: moment(new Date(date?.value?.from_date)).format("YYYY-MM-DD"),
                to_date: moment(new Date(date?.value?.to_date)).format("YYYY-MM-DD"),
                from_date_iso: moment(new Date(date?.value?.from_date)).format("YYYY-MM-DD") + "T00:00:00.000Z",
                to_date_iso: moment(new Date(date?.value?.to_date)).format("YYYY-MM-DD") + "T00:00:00.000Z",
            }
        }
        if (date?.load) {
            setCalanderValue(result)
            setSelectedCalendarOption(date)
            getPropertyBoardList(selectedType?.value, selectedCompany?.value, selectedProperty?.id, selectedBlock?.id, selectedFloor?.id, 0, 10, "", result)

        }
    }
    //handle status
    const handleStatus = (val) => {
        setSelectedType(val)
        getPropertyBoardList(val?.value, selectedCompany?.value, selectedProperty?.id, selectedBlock?.id, selectedFloor?.id, 0, 20, "", calanderValue)

    }
    const render = () => {
        return (
            <Box>
                <Subheader hideBackButton title={t("propertyBoard")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        onChangeCompany(e)
                    }}
                    selectTeamsOptions
                    handleTeamsChange={handleTeamsChange}
                    selectedTeams={selectedTeams}
                    companyId={selectedCompany?.value}
                    moduleId={moduleId}

                />
                <Box p={2}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems="center" position={"relative"}>
                        <Stack direction={matches ? "column" : "row"} spacing={1} alignItems="center">
                            <PropertySelect placeholder={t("Select Property")} setOffset={setOffset} icon={<PropertyIcon />} options={propertyDetails?.propertyOptions} value={selectedProperty?.name ?? selectedProperty} selected={selectedProperty} setSelected={handleSelect} handleSearch={handleDropDownSearch} type={"property"} reload={reload} fetchMoreData={fetchMoreData} />
                            <PropertySelect
                                placeholder={
                                    property_hierarchy_details?.custom_level_1?.is_primary
                                        ? `Select ${property_hierarchy_details?.custom_level_1?.block_label}`
                                        : t("Select Block")
                                }
                                value={blockOptions?.length === 0
                                    ? (
                                        property_hierarchy_details?.custom_level_1?.is_primary
                                            ? `No ${property_hierarchy_details?.custom_level_1?.block_label}`
                                            : t("No Blocks")
                                    )
                                    : selectedBlock?.name ?? selectedBlock}
                                setOffset={setOffset} icon={<BuildingIcon />} options={blockOptions} selected={selectedBlock} setSelected={handleSelect} handleSearch={handleDropDownSearch} type={"block"} reload={reload} />
                            <PropertySelect
                                placeholder={
                                    property_hierarchy_details?.custom_level_1?.is_primary
                                        ? `Select ${property_hierarchy_details?.custom_level_1?.floor_label}`
                                        : property_hierarchy_details?.custom_level_2?.is_primary
                                            ? `Select ${property_hierarchy_details?.custom_level_2?.block_label}/${property_hierarchy_details?.custom_level_2?.floor_label}`
                                            : t("Select Floor")
                                }
                                value={floorOptions?.length === 0
                                    ? (
                                        property_hierarchy_details?.custom_level_1?.is_primary
                                            ? `No ${property_hierarchy_details?.custom_level_1?.floor_label}`
                                            : property_hierarchy_details?.custom_level_2?.is_primary
                                                ? `No ${property_hierarchy_details?.custom_level_2?.block_label}/${property_hierarchy_details?.custom_level_2?.floor_label}`
                                                : t("No Floors")
                                    )
                                    : selectedFloor?.name ?? selectedFloor}
                                setOffset={setOffset} icon={<Parking />} options={floorOptions} setSelected={handleSelect} handleSearch={handleDropDownSearch} type={"floor"} reload={reload} />
                        </Stack>
                        <Box>
                            <DateRangePickerComponent t={t} calanderValue={selectedCalendarOption} onChangeCalendar={handleCalendarChange} options={calendarOptions} />

                            {/* <SelectBox
                                color="#FFFFFF"
                                prefix={<CalenderIcon />}
                                options={calendarOptions}
                                onChange={(e) => handleCalendarChange(e)}
                                value={selectedCalendarOption}
                                selectHeight={"38px"}
                                // padding={"0px 0px 0px 6px"}
                            /> */}
                        </Box>
                    </Box>


                    <>
                        {!moreDetails ?
                            <Box className={classes.root} mt={2}>
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} pb={2}>

                                    <Grid container spacing={2} alignItems={"center"}>
                                        <Grid item xs={12} md={8} lg={8} sx={{padding:"16px 0px 0px 8px !important"}}>
                                            <StatusTab t={t} count={count} handleStatus={handleStatus} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2.5} lg={2.5} sx={{paddingTop:"0px !important"}}>
                                            <SearchFilter handleChange={handleSearch}
                                                value={searchText}
                                                // height={"40px"}
                                                placeholder={t("Search")} 
                                                customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={1.5} lg={1.5} sx={{paddingTop:"0px !important"}}>
                                            <FilterDropdownComponent
                                                value={selectedType}
                                                options={statusOptions(t)}
                                                onChange={(e) => handleStatus(e)}
                                                selectHeight="44px"
                                                color="#FFFFFF"
                                                placeholder='select value'

                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                {
                                    screenLoading ?
                                        <Box mt={2} height={`calc(100vh - 320px)`}>
                                            <LoadingSection screen={true} />
                                        </Box>

                                        :
                                        propertyBoardList?.length > 0 ?
                                            <InfiniteScroll
                                                dataLength={propertyBoardList?.length ?? ""}
                                                next={() => fetchMoreUnits()}
                                                hasMore={true}
                                                style={{ overflow: "none" }}
                                                height={`calc(100vh - 320px)`}
                                                className={classes.inifiniteScroll}
                                            >
                                                <Grid container spacing={2}>

                                                    {propertyBoardList?.map((x) => {
                                                        return (
                                                            <Grid item xs={12} sm={6} md={4} lg={2.4} id={x?.id}>
                                                                <PropertyDetailCard t={t} val={x} openViewModal={openViewModal} MoreDetails={MoreDetails} selectedCompany={selectedCompany} />
                                                            </Grid>
                                                        )
                                                    })}

                                                </Grid>
                                            </InfiniteScroll>
                                            :
                                            <center>
                                                <Box mt={4} className={classes.noDataStyle}>
                                                    <NoUnitsAsset />
                                                </Box>
                                            </center>
                                }
                            </Box>
                            : <Box className={classes.root} mt={2}>
                                <ViewMoreDetails t={t} data={viewDetails} openViewModal={openViewModal} setMoreDetails={setMoreDetails} propertyData={HouseDetails} />
                            </Box>
                        }
                    </>


                </Box>
                <Dialog
                    open={isView}
                    fullScreen
                >
                    <ViewPropertyDetails t={t} vacancyStatus={vacancyStatus} changeExecutionStatus={changeExecutionStatus} getPropertyDetails={getPropertyDetails} data={viewDetails} closeModal={closeModal} openDialog={openDialog} TabData={moreDetails ? [{ value: 1, label: t("Bedroom Details") }, { value: 3, label: "Other Details" }] : [{ value: 1, label: t("Unit Details") }, { value: 2, label: t("Property Details") }]} />
                </Dialog>
                <AlertDialog
                    medium
                    open={isShare}
                    onClose={closeDialog}
                    header={`${selectType === "share" ? t("Share") : selectType === "block" ? t("Block") : t("Reserve")} ${t("Unit")}`}
                    component={<UnitCard t={t} val={viewDetails} vacancyStatus={vacancyStatus} selectType={selectType} closeDialog={closeDialog} formData={formData} updateState={updateState} setFormData={setFormData} reload={unitReload} />}

                />
            </Box>
        )
    }

    return accessCheckRender(render, permission)
}
export default withNamespaces("propertyBoard")(PropertyBoard)