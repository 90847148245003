import { Box, Grid, IconButton, Badge, Stack } from '@mui/material'
import React from 'react'
import { AlertDialog, FilterGenerator, SearchFilter, TableWithPagination } from '../../../components'
import { RequestPopup } from './requestPopup'
import { TableStyles } from './style'
import { UpdatePopup } from './updatePopup'
import FilterIMG from '../../../assets/filter';
import { GET_ACCOUNT } from '../../../graphql/alreadyPaidQueries'
import { loadOptions } from '../../../utils/asyncPaginateLoadOptions'
import { config } from '../../../config'
import { NetworkCall } from '../../../networkcall'
import { NetWorkCallMethods, enumSelect, enum_types } from '../../../utils'

export const AlreadyPaidTable = (
    { row = {},
        heading = {},
        handleSearch = () => false,
        searchText = "",
        getFilterData = () => false,
        selectedCompany = {},
        handlePagination = () => false,
        handleChangeLimit = () => false,
        page = "",
        limit = "",
        rowcount = "",
        t = () => false,
        filterData = {},
        pathName = [],
        reload=()=>false
    }
) => {
    const classes = TableStyles()
    const [reqPopup, setreqPopup] = React.useState(false)
    const [decline, setDecline] = React.useState(false)
    const [reconcile, setReconcile] = React.useState(false)
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [reqpopupTemplateData, setreqpopupTemplateData] = React.useState("")
    const [enumData, setEnumData] = React.useState("")
    const [updateStatus, setUpdateStatus] = React.useState(false)
    const [selectedData, setSelectedData] = React.useState({})
    const openDecline = () => {
        setDecline(true)
    }
    const closeDecline = () => {
        setDecline(false)
    }
    const openReconcile = () => {
        setReconcile(true)
    }
    const closeReconcile = () => {
        setReconcile(false)
    }
    const closeReqpopup = () => {
        setreqPopup(false)
    }
    const getSelectedInvoice = (data) => {
        // const params = {
        //     query: GET_SELECTED_USER_INVOICE(data?.invoice_number, data?.reference_num).loc.source.body,
        //     variables: {},
        // };
        const payload = {
            invoice_no: data?.invoice_number ?? null,
            reference_id: data?.request_id ?? null,
            offset: 0,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}/compensated_invoices/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setreqpopupTemplateData(response?.data?.data?.compensated_invoices?.[0])
                setreqPopup(true)
            })
            .catch((error) => {

            });
    }
    const handleIcon = (type, data) => {
        if (type === "view") {
            setSelectedData(data)
            getSelectedInvoice(data)
        }
    }


    const statusUpdate = () => {
        setUpdateStatus(true)
    }
    //get compenstated type Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.compensated_invoices_type_enum])
        setEnumData(result?.compensated_invoices_type_enum)
    }

    React.useEffect(() => {
        getEnum()
    }, [updateStatus])



    return (
        <Box m={2}>
            <Grid container spacing={2} className={classes.content}>
                <Grid container alignItems={"center"} p={1} pl={2} pt={2}>
                    <Grid item xs={4}>
                        <SearchFilter placeholder={t("Search")} value={searchText} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                    </Grid >
                    <Grid item xs={8}>
                        <Box display={"flex"} justifyContent={"end"}>
                            <Stack direction="row" spacing={2} className={classes.filter}>
                                <IconButton onClick={() => setFilterDrawer(true)}
                                    className={classes.filterButton}>
                                    <Badge variant="dot" color="primary"
                                        invisible={false}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item className={classes.tablegrid} xs={12}>
                    <TableWithPagination
                        rows={row}
                        heading={heading}
                        handleIcon={handleIcon}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        page={page}
                        limit={limit}
                        showpagination={true}
                        dataType={[
                            { type: ["more_6_ins_item"], name: "icon" },
                            { type: ["text"], name: "request_id" },
                            { type: ["text"], name: "date" },
                            { type: ["text"], name: "requested_by" },
                            { type: ["text"], name: "agreement_no" },
                            { type: ["text"], name: "invoice_number" },
                            { type: ["text"], name: "amount_paid" },
                            { type: ["text"], name: "payment_date" },
                            { type: ["text"], name: "payment_method" },
                            { type: ["text"], name: "reference_num" },
                            { type: ["kyc_status"], name: "status" }
                        ]}
                        tableType="no-side"
                        height={'calc(100vh - 320px)'}
                        totalRowsCount={rowcount}
                        path={pathName}
                    />
                </Grid>
            </Grid >
            <AlertDialog open={reqPopup}
                onClose={() => setreqPopup(!reqPopup)}
                header={reqpopupTemplateData?.reference_id}
                component={<RequestPopup t={t} openDecline={openDecline} openReconcile={openReconcile}
                    templateData={reqpopupTemplateData} enumData={enumData} />}
                md maxWidth={"600px"}
            />
            <AlertDialog open={decline}
                onClose={closeDecline}
                header={t("Decline")}
                component={<UpdatePopup t={t} type={"Rejected"} close={closeDecline}
                    invoice_data={reqpopupTemplateData} title={t("Declining the request")}
                    message={t("This request will be closed and mark as declined")} enumData={enumData}
                    statusUpdate={statusUpdate}
                    closeReqpopup={closeReqpopup} />}
                md maxWidth={"400px"}
            />
            <AlertDialog open={reconcile}
                onClose={closeReconcile}
                header={t("Reconcile")}
                component={<UpdatePopup t={t} type={"Reconcile"} close={closeReconcile} reload={reload}
                    invoice_data={reqpopupTemplateData} title={t("Reconciling the request")}
                    message={t("This request will be reconciled")} enumData={enumData}
                    statusUpdate={statusUpdate}
                    closeReqpopup={closeReqpopup}
                    selectedData={selectedData}
                    selectedCompany={selectedCompany} />}
                md maxWidth={"400px"}
            />
            <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                onApply={(value) => getFilterData(value)}
                components={[
                    {
                        component: "date_range",
                        value: filterData?.dateRange ?? {},
                        state_name: "dateRange",
                        label: t("Requested Date")
                    },
                    {
                        component: "amount_minAndmax",
                        value: filterData?.amountMinMax ?? {},
                        label: t("Amount"),
                        amount_symbol: selectedCompany?.currency_symbol,
                        state_name: "amountMinMax"
                    },
                    {
                        component: "select",
                        label: t("Account Number"),
                        value: filterData?.accountNumber ?? "",
                        state_name: "accountNumber",
                        isPaginate: true,
                        loadOptions: (search, array, handleLoading) => loadOptions(
                            search,
                            array,
                            handleLoading,
                            GET_ACCOUNT,
                            'contact_account',
                            { company_id: selectedCompany?.value },
                            { label: "label", value: "value" }
                        ),
                    }
                ]} />
        </Box >
    )
}