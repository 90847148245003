import React, { createContext } from 'react'
import { withNamespaces } from 'react-i18next'
import { AccountType, ComponentTypes, ItemMasterTypes } from './utlis/freeTextInvoice'
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, checkNumber, enum_types } from '../../utils'
import { config } from '../../config'
import { NetworkCall } from '../../networkcall'
import InvoiceActive from './utlis/invoiceActive'
import InvoiceInactive from './utlis/invoiceInactive'
import DebitActive from './utlis/debitActive'
import DebitInactive from './utlis/debitInactive'
import CreditActive from './utlis/creditActive'
import CreateInActive from './utlis/creditInactive'
import { GET_PAYMENT_TERMS } from '../../graphql/queries'
import { useApolloClient } from '@apollo/client'
import { UNIT_DETAILS } from '../../graphql/freeTextInvoice'
import { UseDebounce } from '../../components'
import uuid from 'uuid'
import moment from 'moment'
import { AlertContext, AuthContext } from '../../contexts'
import { Routes } from '../../router/routes'

export const FreeTextInvoiceContext = createContext()

const initialTableData = (id, selectedCompany, type, componentType) => {
    return {
        rowid: id,
        rowType: type,
        unit: "",
        pricingComponent: "",
        qty: 1,
        amount: 0.00,
        discount: 0,
        taxableAmount: 0.00,
        discountType: { label: selectedCompany?.currency_symbol, value: selectedCompany?.currency_symbol },
        discountAmount: 0,
        tax: "Yes",
        taxGroup: "",
        taxes: 0.00,
        subtotal: 0.00,
        delete: true,
        path: ["amount", "qty", "discount"],
        taxable: false,
        rowValidation: true,
        componentType: componentType,
        itemTypeCategory: {
            type: "",
            category: ""
        },
        field: {
            qty: "number",
            amount: "number",
            discount: "number"
        },
        error: {
            unit: "",
            pricingComponent: "",
            taxGroup: ""
        }
    }
}

const FreeTextInvoiceContextProvider = (props) => {
    const { t = () => false } = props

    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
    const client = useApolloClient()
    const debounce = UseDebounce();
    const alert = React.useContext(AlertContext)
    const type = window.location.pathname === Routes.freeTextInvoice ? null : window.location.pathname === Routes.creditNote ? "Credit" : "Debit"
    const auth = React.useContext(AuthContext)
    const is_taxable = auth?.auth?.auth?.is_taxable

    const getTransactionType = {
        "Debit": {
            selectedIcon: <DebitActive />,
            notSelectedIcon: <DebitInactive />,
            priority: 2
        },
        "Invoice": {
            selectedIcon: <InvoiceActive />,
            notSelectedIcon: <InvoiceInactive />,
            priority: 1
        },
        "Credit": {
            selectedIcon: <CreditActive />,
            notSelectedIcon: <CreateInActive />,
            priority: 3
        }
    }
    const [initialType, setInitialType] = React.useState("create")
    const [freeTextData, setFreeTextData] = React.useState({
        tableRow: [],
        createPopup: false,
        view: false,
        searchText: "",
        delete: false,
        invoiceDataList: {},
        viewData: {},
        count: 0,

        // 
        published: false,
        propertyUnitSearchText: "",
        selectedTransactionType: {},
        selectedAccountType: AccountType(t)?.[0],
        description: "",
        selectedAccount: "",
        selectedAgreement: "",
        selectedPaymentTerms: {},
        selectedPropertyUnit: [],
        tableRowData: [],
        totalTaxableAmount: 0,
        totalTaxes: 0,
        totalAmount: 0,
        totalDiscount: 0,
        notes: "",
        invoice_type: "",
        dueDate: new Date(),
        periodFrom: new Date(),
        periodTo: new Date(),
        delectedTableRowData: [],
        error: {
            selectedTransactionType: "",
            selectedAccountType: "",
            description: "",
            selectedAccount: "",
            selectedAgreement: "",
            selectedPropertyUnit: "",
            invoice_type: "",
            dueDate: "",
            periodFrom: "",
            periodTo: "",
        }
    })


    const [stateData, setStateData] = React.useState({
        accountType: AccountType(t),
        step: 1,
        createEditList: false,
        editPropertyUnit: false,
        openNotes: false,
        notes: "",
        hoverNotes: false,
        type: "create",
        isDisableNotes: false,
        addTableRow: false
    })

    const [enumValue, setEnumValue] = React.useState({
        transactionType: [],
        paymentTerms: [],
        invoice_type: []
    })

    const [tableData, setTableData] = React.useState([initialTableData(uuid.v4(), selectedCompany)])
    const [offset, setOffset] = React.useState(0)
    const [isDisabled, setIsDisabled] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const updateState = (key, value) => {
        setFreeTextData({ ...freeTextData, [key]: value, error: { ...freeTextData?.error, [key]: "" } })
    }

    const updateStateData = (key, value) => {
        setStateData({ ...stateData, [key]: value })
    }

    const handleNext = () => {
        if (stateData?.step === 2) {
            if (stateData?.type === "view") {
                setStateData({ ...stateData, createEditList: true, step: stateData?.step + 1 })
                updateState("createPopup", false)
            } else {
                if (validate()) {
                    setStateData({ ...stateData, createEditList: true, step: stateData?.step + 1 })
                    updateState("createPopup", false)
                }
            }
        } else {
            if (stateData?.type === "view") {
                updateStateData("step", stateData?.step + 1)
            } else {
                if (validate()) {
                    // updateStateData("step", stateData?.step + 1)
                    if (freeTextData?.selectedAgreement === "" || freeTextData?.selectedAgreement === null) {
                        getPropertyList("", "", 0, 10, true, {}, stateData?.step + 1)
                    } else {
                        getPropertyList(freeTextData?.selectedAgreement?.value, "", 0, 10, true, {}, stateData?.step + 1)
                    }

                }
            }
        }
    }

    const handlePeriodFrom = (value) => {
        if (moment(value).format("YYYY-MM-DD") >= moment(freeTextData?.periodTo).format("YYYY-MM-DD")) {
            setFreeTextData({
                ...freeTextData,
                periodFrom: value,
                periodTo: value
            })
        }
        // updateState("periodFrom", value)
    }

    const handlePeriodTo = (value) => {
        if (moment(value).format("YYYY-MM-DD") <= moment(freeTextData?.periodFrom).format("YYYY-MM-DD")) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Period To should be greater than or equal to Period From",
            });
        } else {
            updateState("periodTo", value)
        }
    }

    const handleOnClose = () => {
        setFreeTextData({
            ...freeTextData,
            createPopup: false,
            propertyUnitSearchText: "",
            selectedTransactionType: {},
            selectedAccountType: AccountType(t)?.[0],
            description: "",
            selectedAccount: "",
            selectedAgreement: "",
            selectedPaymentTerms: {},
            selectedPropertyUnit: [],
            tableRowData: [],
            totalTaxableAmount: 0,
            totalTaxes: 0,
            totalAmount: 0,
            totalDiscount: 0,
            notes: "",
            invoice_type: "",
            dueDate: new Date(),
            periodFrom: new Date(),
            periodTo: new Date(),
            delectedTableRowData: [],
            published: false,
            error: {
                description: "",
                selectedAccount: "",
                selectedAgreement: "",
                selectedPropertyUnit: "",
                invoice_type: "",
                dueDate: "",
                periodFrom: "",
                periodTo: "",
            }
        })

        setStateData({
            ...stateData,
            accountType: AccountType(t),
            step: 1,
            createEditList: false,
            editPropertyUnit: false,
            openNotes: false,
            notes: "",
            hoverNotes: false,
            type: "create"
        })
        setIsDisabled(false)
    }

    const handleChangeTransactionType = (value) => {
        const typeValue = value?.value === "Debit" ?
            {
                label: "Debit",
                value: "Debit Invoice"
            } :
            value?.value === "Invoice" ? enumValue?.invoice_type?.[0] : ""

        setFreeTextData({
            ...freeTextData,
            selectedTransactionType: value,
            invoice_type: typeValue,

        })
    }

    const getFreeTextInvoiceEnum = (type = stateData?.type) => {
        const payload = { "enumName": [enum_types.free_text_invoice_type, enum_types.payment_terms, enum_types.invoice_charge_type, enum_types.free_text_invoice_payment_type] }
        NetworkCall(
            `${config.api_url}/enum`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                const resData = response?.data?.data
                const typeData = resData?.free_text_invoice_type?.map((e) => {
                    return {
                        ...e,
                        title: e?.label,
                        ...getTransactionType[e?.value]
                    }
                })
                typeData.sort((a, b) => getTransactionType[a?.label]?.priority - getTransactionType[b?.label]?.priority)
                // updateStateData("transactionType", typeData)
                setEnumValue({
                    transactionType: typeData,
                    paymentTerms: resData?.payment_terms?.filter((i) => i?.value !== "User Defined"),
                    invoice_type: resData?.invoice_charge_type,
                    role_type: resData?.free_text_invoice_payment_type
                })
                // updateState("selectedTransactionType", typeData?.[0])
                if (type === "create") {
                    getPaymentTerms(resData?.payment_terms?.filter((i) => i?.value !== "User Defined"), typeData?.[0], resData?.invoice_charge_type)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const getPaymentTerms = (list, typeData, invoice_type) => {
        const payload = {
            query: GET_PAYMENT_TERMS(selectedCompany?.value).loc.source.body,
            variables: {}
        }
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const data = list?.filter((i) => i?.value === res?.data?.data?.company_master?.[0]?.payment_terms)
                const invoiceType = typeData?.value === "Debit" ?
                    {
                        label: "Debit",
                        value: "Debit Invoice"
                    } :
                    typeData?.value === "Invoice" ? invoice_type?.[0] : ""
                setFreeTextData({ ...freeTextData, selectedTransactionType: typeData, selectedPaymentTerms: data?.length === 0 ? list?.[0] : data?.[0], invoice_type: invoiceType })
            })
            .catch((error) => {
                console.log(error)
            });
    }
    // React.useEffect(() => {
    //     getPropertyList()
    // }, [])
    const getPropertyList = (agreement_id = freeTextData?.selectedAgreement?.value, searchText, offset, limit, bool, extraStateField = {}, step = stateData?.step) => {
        const payload = {
            agreement_id: freeTextData?.selectedAccountType?.value === "Vendor" ? undefined : agreement_id?.length ? [agreement_id] : undefined,
            contract_id: freeTextData?.selectedAccountType?.value === "Vendor" ? agreement_id?.length ? agreement_id : undefined : undefined,
            limit: limit,
            offset: offset,
            search: searchText,
            company_id: selectedCompany?.value
        }
        NetworkCall(
            freeTextData?.selectedAccountType?.value === "Vendor" ? `${config.api_url}/free_text_invoice/contract_props_for_units` : `${config.api_url}/agreement-property-info/property_detail`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                if (bool) {
                    setStateData({
                        ...stateData,
                        ...extraStateField,
                        propertyUnitList: res?.data?.data,
                        step: step
                    })
                    // updateStateData("propertyUnitList", res?.data?.data)
                }
                else {
                    updateStateData("propertyUnitList", stateData?.propertyUnitList?.concat(...res?.data?.data))
                }
            })
            .catch((error) => {
                console.log(error)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                });
            });
    }

    const getPropertyUnitList = (
        offset = 0,
        limit = 10,
        bool = true,
        searchText = "",
        agreement_id = freeTextData?.selectedAgreement?.value) => {
        client
            .query({
                query: UNIT_DETAILS(offset, limit, searchText, agreement_id),
                fetchPolicy: "network-only",
            })
            .then((response) => {
                if (bool) {
                    updateStateData("propertyUnitList", response?.data?.agreement_units)
                }
                else {
                    updateStateData("propertyUnitList", stateData?.propertyUnitList?.concat(...response?.data?.agreement_units))
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAgreementSelect = (value) => {
        let removedTableRow = []
        getPropertyList(value?.value, "", 0, 10, true)
        if (stateData?.type === "edit") {
            removedTableRow.push(...constructTableRow(freeTextData?.tableRowData, true))
        }
        setFreeTextData({
            ...freeTextData,
            selectedAgreement: value,
            selectedPropertyUnit: [],
            tableRowData: [],
            totalTaxableAmount: 0.00,
            totalTaxes: 0.00,
            totalAmount: 0.00,
            totalDiscount: 0.00,
            delectedTableRowData: [...freeTextData?.delectedTableRowData, ...removedTableRow],
            error: {
                ...freeTextData?.error,
                selectedAgreement: ""
            }
        })
    }

    const handleLoadMorePropertyUnit = () => {
        setOffset(offset + 10)
        getPropertyList(freeTextData?.selectedAgreement?.value, "", offset + 10, 10, false)
        // getPropertyUnitList(offset + 10, 10, false, "", freeTextData?.selectedAgreement?.value);
    }


    const validate = () => {
        let isValid = true
        let error = freeTextData?.error

        // description: "",
        // selectedAccount: "",
        // selectedAgreement: "",

        if (stateData?.step === 1) {
            if (Object.keys(freeTextData?.selectedTransactionType)?.length === 0) {
                isValid = false
                error.selectedTransactionType = "Transaction Type is Required"
            }

            if (Object.keys(freeTextData?.selectedAccountType)?.length === 0) {
                isValid = false
                error.selectedAccountType = "Account Type is Required"
            }

            if (freeTextData?.description === "") {
                isValid = false
                error.description = "Description is Required"
            }

            if (freeTextData?.selectedTransactionType?.value !== "Credit") {
                if (freeTextData?.invoice_type === "") {
                    isValid = false
                    error.invoice_type = "Invoice Type is Required"
                }
            }

            if (freeTextData?.selectedAccount === "") {
                isValid = false
                error.selectedAccount = "Account is Required"
            }

            // if (Object.keys(freeTextData?.selectedAccount)?.length > 0 && freeTextData?.selectedAgreement === "") {
            //     isValid = false
            //     error.selectedAgreement = freeTextData?.selectedAccountType?.value === "Vendor" ? "Contract is Required" : "Agreement is Required"
            // }

            if (Object.keys(freeTextData?.selectedPaymentTerms)?.length === 0) {
                isValid = false
                error.selectedPaymentTerms = "Payment Terms is Required"
            }
        }
        else if (stateData?.step === 2) {
            if (freeTextData?.selectedPropertyUnit?.length === 0) {
                isValid = false
                error.selectedPropertyUnit = "Property / Unit is Required"
            }
        }

        setFreeTextData({ ...freeTextData, error });
        return isValid;

    }

    const handleSelectPropertyUnit = (value) => {
        if (stateData?.type !== "view") {
            if (freeTextData?.selectedPropertyUnit?.filter((i) => i?.property_id === value?.property_id)?.length > 0) {
                const data = freeTextData?.selectedPropertyUnit?.filter((i) => i?.property_id !== value?.property_id)
                if (stateData?.type === "edit") {
                    handleRemoveUnit([value?.property_id], true, data)
                } else {
                    updateState("selectedPropertyUnit", data)
                }
            } else {
                updateState("selectedPropertyUnit", [...freeTextData?.selectedPropertyUnit, value])
            }
        }
    }

    const handlePropertyUnitSearch = (searchText) => {
        updateState("propertyUnitSearchText", searchText)
        debounce(() => getPropertyList(freeTextData?.selectedAgreement?.value, searchText, 0, 10, true), 800);
    }

    const getCustomerList = (offset, limit, searchText, invoiceType, status, companyId, accountNum) => {
        const payload = {
            company_id: companyId ?? selectedCompany?.value,
            type: type?.length > 0 ? [type] : invoiceType ?? [],
            is_active: status ?? [true],
            search: searchText,
            account_no: accountNum?.length > 0 ? [accountNum] : [],
            offset: offset,
            limit: limit
        }
        NetworkCall(
            `${config.api_url}/free_text_invoice/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let result = response?.data?.data?.data?.map((e) => {
                    return {
                        id: e?.id,
                        invoice_no: e?.invoice_no ?? "-",
                        date: moment(e?.created_at).format("YYYY-MM-DD") ?? "-",
                        account_no: e?.account_no ?? "-",
                        account_name: e?.account_name ?? "-",
                        type: e?.type ?? "-",
                        total_amount_due: `${e?.symbol} ${e?.total_amount_due ?? 0}` ?? "-",
                        created_by: e?.created_by ?? "-",
                        description: e?.description?.length > 0 ? e?.description : '-' ?? "-",
                        status: e?.status ?? "-",
                        invoice_type: e?.invoice_type ?? "-"
                    }
                })
                setFreeTextData({
                    ...freeTextData,
                    view: false,
                    tableRow: result ?? [],
                    searchText: searchText ?? "",
                    count: response?.data?.data?.count,

                    createPopup: false,
                    propertyUnitSearchText: "",
                    selectedTransactionType: {},
                    selectedAccountType: AccountType(t)?.[0],
                    description: "",
                    selectedAccount: "",
                    selectedAgreement: "",
                    selectedPaymentTerms: {},
                    selectedPropertyUnit: [],
                    tableRowData: [],
                    totalTaxableAmount: 0,
                    totalTaxes: 0,
                    totalAmount: 0,
                    totalDiscount: 0,
                    notes: "",
                    invoice_type: "",
                    dueDate: new Date(),
                    periodFrom: new Date(),
                    periodTo: new Date(),
                    delectedTableRowData: [],
                    published: false,
                    error: {
                        description: "",
                        selectedAccount: "",
                        selectedAgreement: "",
                        selectedPropertyUnit: "",
                        invoice_type: "",
                        dueDate: "",
                        periodFrom: "",
                        periodTo: "",
                    }
                })

            })
            .catch((error) => {
                // console.log(error)
            });
    };

    /// table data functions

    const removeObjectFromArray = (item) => {
        // if (item?.id) {
        //     handleRemoveUnit([item?.unit?.property_id], false)
        // }
        let tempTotalTaxableAmount = 0
        let tempTotalTaxes = 0
        let tempTotalAmount = 0
        let tempTotalDiscount = 0

        tempTotalTaxableAmount = Number(freeTextData?.totalTaxableAmount) - Number(item?.taxableAmount)

        tempTotalTaxes = Number(freeTextData?.totalTaxes) - Number(item?.taxes)

        tempTotalAmount = Number(freeTextData?.totalAmount) - Number(item?.subtotal)

        tempTotalDiscount = (Number(freeTextData?.totalDiscount) - Number(item?.discount))

        setFreeTextData({
            ...freeTextData,
            tableRowData: freeTextData?.tableRowData?.filter(obj => {
                return obj.rowid !== item?.rowid;
            }),
            totalTaxableAmount: tempTotalTaxableAmount?.toFixed(2),
            totalTaxes: tempTotalTaxes?.toFixed(2),
            totalAmount: tempTotalAmount?.toFixed(2),
            totalDiscount: tempTotalDiscount?.toFixed(2),
            delectedTableRowData: [...freeTextData?.delectedTableRowData, item]
        }
        )
    };

    const setRowData = (numb, item, e) => {
        let value = e === "discount" ? numb : e === "taxGroup" ? numb : (numb.length === 0 ? "" : parseFloat(numb))

        let tempTableRowData = freeTextData?.tableRowData?.map(obj => {
            let i = e === "taxGroup" ?
                numb?.vat_group_item?.map((e) => e?.rate)?.reduce((startrate, endrate) => startrate + endrate, 0)
                : e === "pricingComponent" ?
                    numb?.vat?.vat_item?.map((e) => e?.rate)?.reduce((startrate, endrate) => startrate + endrate, 0)
                    :
                    obj?.taxGroup?.vat_group_item?.map((e) => e?.rate)?.reduce((startrate, endrate) => startrate + endrate, 0) ?? 0

            let totalBeforeDiscount = 0
            // let discount = 0
            let tempTaxableAmount = 0
            let tempTaxes = 0
            let tempSubtotal = 0
            if (obj.rowid === item.rowid) {
                switch (e) {
                    case "componentType":
                        return {
                            ...obj,
                            componentType: numb,
                        }
                    case "itemTypeCategory":
                        return {
                            ...obj,
                            itemTypeCategory: numb
                        }
                    case "unit":
                        return {
                            ...obj,
                            unit: numb,
                            error: {
                                ...obj?.error,
                                unit: ""
                            }
                        }
                    case "pricingComponent":
                        totalBeforeDiscount = checkNumber(item?.qty) * checkNumber(item?.amount)

                        if (totalBeforeDiscount >= item?.discountAmount) {

                            tempTaxableAmount = item?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(item?.discountAmount)) / 100) : totalBeforeDiscount - checkNumber(item?.discountAmount)

                            tempTaxes = tempTaxableAmount * (checkNumber(i ?? 0) / 100)

                            tempSubtotal = tempTaxableAmount + tempTaxes
                            return {
                                ...obj,
                                pricingComponent: numb,
                                taxable: numb?.taxtable,
                                taxes: !numb?.taxtable ? 0.00 : tempTaxes,
                                subtotal: !numb?.taxtable ? (obj?.subtotal - obj?.taxes) : tempSubtotal,
                                taxGroup: !numb?.taxtable ? "" : {
                                    ...numb?.vat,
                                    vat_group_item: numb?.vat?.vat_item
                                },
                                error: {
                                    ...obj?.error,
                                    pricingComponent: ""
                                }
                            }
                        } else {
                            return {
                                ...obj,
                                pricingComponent: numb,
                                taxableAmount: 0.00,
                                taxes: 0.00,
                                subtotal: 0.00,
                                error: {
                                    ...obj?.error,
                                    pricingComponent: ""
                                }
                            };
                        }
                    case "taxGroup":
                        totalBeforeDiscount = checkNumber(item?.qty) * checkNumber(item?.amount)

                        if (totalBeforeDiscount >= item?.discountAmount) {

                            tempTaxableAmount = item?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(item?.discountAmount)) / 100) : totalBeforeDiscount - checkNumber(item?.discountAmount)

                            tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                            tempSubtotal = tempTaxableAmount + tempTaxes

                            return {
                                ...obj,
                                taxGroup: numb,
                                taxableAmount: tempTaxableAmount?.toFixed(2),
                                taxes: tempTaxes?.toFixed(2),
                                subtotal: tempSubtotal?.toFixed(2),
                                error: {
                                    ...obj?.error,
                                    taxGroup: ""
                                }
                            }
                        } else {
                            return {
                                ...obj,
                                taxGroup: numb,
                                taxableAmount: 0.00,
                                taxes: 0.00,
                                subtotal: 0.00,
                                discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`,
                                error: {
                                    ...obj?.error,
                                    taxGroup: ""
                                }
                            };
                        }


                    case "discountAmount":
                        totalBeforeDiscount = checkNumber(item?.qty) * checkNumber(item?.amount)
                        if (totalBeforeDiscount >= numb) {

                            tempTaxableAmount = item?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(numb)) / 100) : totalBeforeDiscount - checkNumber(numb)

                            tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                            tempSubtotal = tempTaxableAmount + tempTaxes

                            return {
                                ...obj,
                                discount: item?.discountType?.value === "%" ? checkNumber((totalBeforeDiscount * checkNumber(numb)) / 100) : checkNumber(numb),
                                discountAmount: numb,
                                taxableAmount: tempTaxableAmount?.toFixed(2),
                                taxes: tempTaxes?.toFixed(2),
                                subtotal: tempSubtotal?.toFixed(2),
                            }
                        } else {
                            return {
                                ...obj,
                                discount: 0,
                                discountAmount: numb,
                                taxableAmount: 0.00,
                                taxes: 0.00,
                                subtotal: 0.00,
                                discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`
                            };
                        }

                    case "discountType":

                        totalBeforeDiscount = checkNumber(item?.qty) * checkNumber(item?.amount)
                        if (totalBeforeDiscount >= item?.discountAmount) {
                            tempTaxableAmount = numb?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(item?.discountAmount)) / 100) : totalBeforeDiscount - checkNumber(item?.discountAmount)

                            tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                            tempSubtotal = tempTaxableAmount + tempTaxes

                            return {
                                ...obj,
                                discount: numb?.value === "%" ? ((totalBeforeDiscount * checkNumber(item?.discountAmount)) / 100) : checkNumber(item?.discountAmount),
                                discountType: numb,
                                taxableAmount: tempTaxableAmount?.toFixed(2),
                                taxes: tempTaxes?.toFixed(2),
                                subtotal: tempSubtotal?.toFixed(2),
                            }
                        } else {
                            return {
                                ...obj,
                                discount: 0,
                                discountType: numb,
                                taxableAmount: 0.00,
                                taxes: 0.00,
                                subtotal: 0.00,
                                discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`
                            };
                        }
                    case "qty":
                        totalBeforeDiscount = checkNumber(value) * checkNumber(item?.amount)

                        if (totalBeforeDiscount >= item?.discountAmount) {
                            // discount = totalBeforeDiscount * (checkNumber(item?.discount) / 100)

                            tempTaxableAmount = item?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(item?.discountAmount)) / 100) : totalBeforeDiscount - checkNumber(item?.discountAmount)
                            // totalBeforeDiscount - checkNumber(item?.discount?.value)
                            tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                            tempSubtotal = tempTaxableAmount + tempTaxes
                            if (!obj?.taxable) {

                                return {
                                    ...obj,
                                    [e]: value,
                                    taxableAmount: tempTaxableAmount?.toFixed(2),
                                    taxes: 0.00,
                                    subtotal: tempTaxableAmount?.toFixed(2),
                                    discountError: ""
                                };
                            } else {
                                return {
                                    ...obj,
                                    [e]: value,
                                    taxableAmount: tempTaxableAmount?.toFixed(2),
                                    taxes: tempTaxes?.toFixed(2),
                                    subtotal: tempSubtotal?.toFixed(2),
                                    discountError: ""
                                };
                            }
                        } else {
                            return {
                                ...obj,
                                [e]: value,
                                taxableAmount: 0.00,
                                taxes: 0.00,
                                subtotal: 0.00,
                                discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`
                            };
                        }

                    case "amount":
                        totalBeforeDiscount = checkNumber(value) * checkNumber(item?.qty)

                        if (totalBeforeDiscount >= item?.discountAmount) {
                            // discount = totalBeforeDiscount * (checkNumber(item?.discount) / 100)

                            tempTaxableAmount = item?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(item?.discountAmount)) / 100) : totalBeforeDiscount - checkNumber(item?.discountAmount)
                            // totalBeforeDiscount - checkNumber(item?.discount?.value)

                            tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                            tempSubtotal = tempTaxableAmount + tempTaxes
                            if (!obj?.taxable) {

                                return {
                                    ...obj,
                                    [e]: value,
                                    taxableAmount: tempTaxableAmount?.toFixed(2),
                                    taxes: 0.00,
                                    subtotal: tempTaxableAmount?.toFixed(2),
                                    discountError: ""
                                };
                            } else {
                                return {
                                    ...obj,
                                    [e]: value,
                                    taxableAmount: tempTaxableAmount?.toFixed(2),
                                    taxes: tempTaxes?.toFixed(2),
                                    subtotal: tempSubtotal?.toFixed(2),
                                    discountError: ""
                                };
                            }
                        }
                        else {
                            return {
                                ...obj,
                                [e]: value,
                                taxableAmount: tempTaxableAmount?.toFixed(2),
                                taxes: tempTaxes?.toFixed(2),
                                subtotal: tempSubtotal?.toFixed(2),
                                discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`
                            };
                        }
                    case "discount":
                        totalBeforeDiscount = checkNumber(item?.qty) * checkNumber(item?.amount)
                        if (value?.value <= totalBeforeDiscount) {
                            // discount = totalBeforeDiscount * (checkNumber(value) / 100)           
                            tempTaxableAmount = value?.select === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(value?.value)) / 100) : totalBeforeDiscount - checkNumber(value?.value)

                            tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                            tempSubtotal = tempTaxableAmount + tempTaxes
                            if (!obj?.taxable) {

                                return {
                                    ...obj,
                                    [e]: value,
                                    taxableAmount: tempTaxableAmount.toFixed(2),
                                    taxes: 0.00,
                                    subtotal: tempTaxableAmount.toFixed(2),
                                    discountError: ""
                                };
                            } else {
                                return {
                                    ...obj,
                                    [e]: value,
                                    taxableAmount: tempTaxableAmount.toFixed(2),
                                    taxes: tempTaxes.toFixed(2),
                                    subtotal: tempSubtotal.toFixed(2),
                                    discountError: ""
                                };
                            }
                        }
                        else {

                            return {
                                ...obj,
                                [e]: value,
                                taxableAmount: 0.00,
                                taxes: 0.00,
                                subtotal: 0.00,
                                discountError: `Discount Must be lower or equal than ${totalBeforeDiscount}`
                            };
                        }



                    default:
                        return { ...obj, [e]: value };
                }
                // }
            }
            return obj
            // eslint-disable-next-line
        })

        let tempTotalTaxableAmount = 0
        let tempTotalTaxes = 0
        let tempTotalAmount = 0
        let tempTotalDiscount = 0

        tempTableRowData?.map((row) => {
            tempTotalTaxableAmount = Number(tempTotalTaxableAmount) + Number(row?.taxableAmount)

            tempTotalTaxes = Number(tempTotalTaxes) + Number(row?.taxes)

            tempTotalAmount = (Number(tempTotalAmount) + Number(row?.subtotal))

            tempTotalDiscount = (Number(tempTotalDiscount) + Number(row?.discount))
            return false
        })

        setFreeTextData({
            ...freeTextData,
            tableRowData: tempTableRowData,
            totalTaxableAmount: tempTotalTaxableAmount?.toFixed(2),
            totalTaxes: tempTotalTaxes?.toFixed(2),
            totalAmount: tempTotalAmount?.toFixed(2),
            totalDiscount: tempTotalDiscount?.toFixed(2)
        });
    }

    const addTableRow = (event) => {
        setStateData({
            ...stateData,
            addTableRow: true
        })
        setAnchorEl(event.currentTarget)
    }

    const handleCloseNewLine = (type) => {
        if (type?.value === "Component") {
            setFreeTextData({
                ...freeTextData,
                tableRowData: [...freeTextData?.tableRowData, initialTableData(uuid.v4(), selectedCompany, type, ComponentTypes?.[0])]
            })
        } else {
            setFreeTextData({
                ...freeTextData,
                tableRowData: [...freeTextData?.tableRowData, initialTableData(uuid.v4(), selectedCompany, type, ItemMasterTypes?.[0])]
            })
        }

        setStateData({
            ...stateData,
            addTableRow: false
        })
        setAnchorEl(null);
    }

    const handleAddMenuClose = () => {
        setStateData({
            ...stateData,
            addTableRow: false
        })
        setAnchorEl(null)
    }

    const constructTableRow = (listArray, isDeleted) => {
        const list = listArray?.map((data) => {
            return {
                id: data?.unit?.id ?? undefined,
                created_by: localStorage.getItem(LocalStorageKeys.profileID),
                unit_id: data?.unit?.unit_id ?? data?.unit?.value,
                agreement_unit_id: data?.unit?.agreement_unit_id,
                updated_by: freeTextData?.id && localStorage.getItem(LocalStorageKeys.profileID),
                is_active: isDeleted ? false : undefined,
                payments: {
                    id: data?.id ?? undefined,
                    pricing_component_id: data?.rowType?.value === "Item Master" ? undefined : data?.pricingComponent?.value,
                    quantity: data?.qty,
                    amount: data?.amount,
                    discount: data?.discount,
                    taxable_amount: data?.taxableAmount,
                    taxes: data?.taxes,
                    subtotal_amount: data?.subtotal,
                    updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                    created_by: localStorage.getItem(LocalStorageKeys.profileID),
                    currency_id: selectedCompany?.currency_id,
                    vat_group_master_id: data?.taxGroup?.value,
                    percentage_value: data?.discountAmount,
                    discount_type: data?.discountType?.value === "%" ? "Percentage" : "Amount",
                    item_type: data?.rowType?.value,
                    inspection_items_id: data?.rowType?.value === "Item Master" ? data?.pricingComponent?.value : undefined,
                    uom_type: data?.rowType?.value === "Component" ? "Total" : data?.pricingComponent?.period_type?.length ? data?.pricingComponent?.period_type : ""
                }
            }
        })

        return list
    }

    const handleCreateinvoice = () => {

        if (tableRowValidation()) {
            const errorArray = freeTextData?.tableRowData?.map((d) => {
                return d?.error?.pricingComponent?.length === 0 && d?.error?.taxGroup?.length === 0 && d?.error?.unit?.length === 0
            })
            if (errorArray.every(item => item === true)) {
                setIsDisabled(true)
                const normalTableRow = constructTableRow(freeTextData?.tableRowData)
                const delectedTableRow = constructTableRow(freeTextData?.delectedTableRowData?.filter((i) => i?.id), true)
                const payload = {
                    insert_fields: {
                        company_id: selectedCompany?.value,
                        id: freeTextData?.id ?? undefined,
                        description: freeTextData?.description,
                        account_no: freeTextData?.selectedAccount?.account_no,
                        agreement_id: freeTextData?.selectedAccountType?.value === "Customer" ? freeTextData?.selectedAgreement?.value?.length ? freeTextData?.selectedAgreement?.value : undefined : undefined,
                        type: freeTextData?.selectedTransactionType?.value,
                        invoice_amount: freeTextData?.totalAmount,
                        due_date: freeTextData?.dueDate,
                        vat_group_master_id: freeTextData?.taxGroup?.value,
                        invoice_type: freeTextData?.invoice_type?.value ?? undefined,
                        total_taxable_amount: freeTextData?.totalTaxableAmount,
                        total_taxable_taxes: freeTextData?.totalTaxes,
                        total_amount_due: freeTextData?.totalAmount,
                        created_by: localStorage.getItem(LocalStorageKeys.profileID),
                        updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                        period_from: freeTextData?.periodFrom,
                        period_to: freeTextData?.periodTo,
                        units: [...normalTableRow, ...delectedTableRow],
                        contract_id: freeTextData?.selectedAccountType?.value === "Vendor" ? freeTextData?.selectedAgreement?.value : undefined,
                        notes: freeTextData?.notes,
                        payment_terms: freeTextData?.selectedPaymentTerms?.value,
                        total_discount: freeTextData?.totalDiscount
                    }
                }
                NetworkCall(
                    `${config.api_url}/free_text_invoice/upsert`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false
                )
                    .then(async(response) => {
                        setIsDisabled(false)
                        await handleOnClose()
                        await getCustomerList(0, 10, "", [], [], selectedCompany?.value)
                    })
                    .catch((error) => {
                        // setIsDisable(false)
                        console.log(error)
                    });
            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please fill all mandatory fields"),
                });
            }
        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory fields"),
            });
        }
    }

    const getFreeTextInvoice = (rowdata, type) => {
        const payload = {
            id: rowdata?.id
        }
        NetworkCall(
            `${config.api_url}/free_text_invoice/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const resData = res?.data?.data

                getPropertyList(resData?.agreement?.value, "", 0, 10, true, { step: 1, type: type, notes: resData?.notes })

                const constructTableRow = resData?.units?.map((i) => {
                    return {
                        rowid: uuid.v4(),
                        id: i?.payments?.[0]?.id,
                        unit: {
                            ...i?.unit,
                            unit_name: i?.unit?.label,
                            type_name: i?.unit?.type?.label,
                            property_name: i?.unit?.property?.name,
                            agreement_unit_id: i?.agreement_unit_id,
                            id: i?.id,
                            ...i?.unit?.address,
                            ...i?.unit?.property
                        },
                        pricingComponent: i?.payments?.[0]?.item_type === "Item Master" ? i?.payments?.[0]?.item_master : i?.payments?.[0]?.pricing_components,
                        qty: i?.payments?.[0]?.quantity,
                        amount: i?.payments?.[0]?.amount?.toFixed(2),
                        discount: i?.payments?.[0]?.discount,
                        taxableAmount: i?.payments?.[0]?.taxable_amount?.toFixed(2),
                        discountType: i?.payments?.[0]?.discount_type === "Percentage" ? { label: "%", value: "%" } : { label: selectedCompany?.currency_symbol, value: selectedCompany?.currency_symbol },
                        discountAmount: i?.payments?.[0]?.discount_type === "Percentage" ? i?.payments?.[0]?.percentage_value : i?.payments?.[0]?.discount,
                        tax: "Yes",
                        taxGroup: {
                            ...i?.payments?.[0]?.vat_group,
                            vat_group_item: i?.payments?.[0]?.vat_group?.vat_item
                        } ?? "",
                        taxes: (i?.payments?.[0]?.taxes)?.toFixed(2),
                        subtotal: (i?.payments?.[0]?.subtotal_amount)?.toFixed(2),
                        delete: true,
                        path: ["amount", "qty", "discount"],
                        taxable: i?.payments?.[0]?.pricing_components?.taxtable,
                        rowValidation: true,
                        rowType: { label: i?.payments?.[0]?.item_type, value: i?.payments?.[0]?.item_type },
                        componentType: i?.payments?.[0]?.item_type === "Component" ? i?.payments?.[0]?.pricing_components?.string_val : i?.payments?.[0]?.item_master?.string_val,
                        itemTypeCategory: {
                            type: i?.payments?.[0]?.item_type === "Component" ? "" : {
                                label: i?.payments?.[0]?.item_master?.item,
                                value: i?.payments?.[0]?.item_master?.item
                            },
                            category: i?.payments?.[0]?.item_type === "Component" ? "" : i?.payments?.[0]?.item_master?.category
                        },
                        field: {
                            qty: "number",
                            amount: "number",
                            discount: "number"
                        },
                        error: {
                            unit: "",
                            pricingComponent: "",
                            taxGroup: ""
                        }
                    }
                })
                const data = {
                    tableRow: freeTextData?.tableRow,
                    view: false,
                    searchText: "",
                    delete: false,
                    invoiceDataList: freeTextData?.invoiceDataList,
                    viewData: freeTextData?.viewData,
                    count: freeTextData?.count,

                    id: resData?.id,
                    published: rowdata?.status === "Published" ? true : false,
                    createPopup: true,
                    propertyUnitSearchText: "",
                    selectedTransactionType: { value: resData?.type_enum?.value, title: resData?.type_enum?.label, ...getTransactionType?.[resData?.type_enum?.value] },
                    selectedAccountType: AccountType(t)?.filter((i) => i?.value === resData?.account?.relationship)?.[0],
                    description: resData?.description,
                    selectedAccount: { ...resData?.account, name: resData?.account?.label, account_no: resData?.account?.value },
                    selectedAgreement: resData?.agreement,
                    selectedPaymentTerms: { label: resData?.payment_terms, value: resData?.payment_terms },
                    selectedPropertyUnit: resData?.properties,
                    tableRowData: constructTableRow,
                    totalTaxableAmount: resData?.total_taxable_amount?.toFixed(2),
                    totalTaxes: resData?.total_taxable_taxes?.toFixed(2),
                    totalAmount: resData?.total_amount_due?.toFixed(2),
                    totalDiscount: resData?.total_discount?.toFixed(2),
                    notes: resData?.notes,
                    invoice_type: resData?.invoice_type_enum,
                    dueDate: new Date(resData?.due_date),
                    periodFrom: new Date(resData?.period_from),
                    periodTo: new Date(resData?.period_to),
                    delectedTableRowData: [],
                    error: {
                        selectedTransactionType: "",
                        selectedAccountType: "",
                        description: "",
                        selectedAccount: "",
                        selectedAgreement: "",
                        selectedPropertyUnit: "",
                        invoice_type: "",
                        dueDate: "",
                        periodFrom: "",
                        periodTo: "",
                    }
                }
                setFreeTextData(data)
                setStateData({
                    ...stateData,
                    step: 1,
                    type: type
                })
                getFreeTextInvoiceEnum(type)
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const handleEditAgreement = () => {
        setStateData({
            ...stateData,
            step: 1,
            createEditList: false,
        })

        updateState("createPopup", true)
    }

    const handleEdit = () => {
        updateState("createPopup", true)
        setStateData({
            ...stateData,
            step: 1,
            createEditList: false,
            type: "edit"
        })
    }

    const handleRemoveUnit = (property_array, removeValue = true, selectedProperty = []) => {
        const data = freeTextData?.tableRowData?.filter((i) => property_array?.includes(i?.unit?.property_id))
        let tempTotalTaxableAmount = 0
        let tempTotalTaxes = 0
        let tempTotalAmount = 0
        let tempTotalDiscount = 0
        let removeArray = []

        if (removeValue) {
            data?.map((item) => {
                tempTotalTaxableAmount = Number(freeTextData?.totalTaxableAmount) - Number(item?.taxableAmount)

                tempTotalTaxes = Number(freeTextData?.totalTaxes) - Number(item?.taxes)

                tempTotalAmount = Number(freeTextData?.totalAmount) - Number(item?.subtotal)

                tempTotalDiscount = (Number(freeTextData?.totalDiscount) - Number(item?.discount))

                removeArray = freeTextData?.tableRowData?.filter(obj => {
                    return obj.rowid !== item?.rowid;
                })
                return true
            })
        }

        // else {
        //     updateState("delectedTableRowData", [freeTextData?.delectedTableRowData, ...data])
        // }
        setFreeTextData({
            ...freeTextData,
            tableRowData: removeArray,
            totalTaxableAmount: tempTotalTaxableAmount?.toFixed(2),
            totalTaxes: tempTotalTaxes?.toFixed(2),
            totalAmount: tempTotalAmount?.toFixed(2),
            totalDiscount: tempTotalDiscount?.toFixed(2),
            delectedTableRowData: [...freeTextData?.delectedTableRowData, ...data],
            selectedPropertyUnit: selectedProperty
        })
    }


    const tableRowValidation = () => {
        let isValid = true

        freeTextData?.tableRowData?.forEach((item) => {
            if (Object.keys(item.unit).length === 0) {
                item.error.unit = "Unit is required";
                isValid = false;
            } else {
                item.error.unit = "";
                isValid = true;
            }

            if (Object.keys(item.pricingComponent).length === 0) {
                item.error.pricingComponent = "Component is required";
                isValid = false;
            } else {
                item.error.pricingComponent = "";
                isValid = true;
            }

            if (is_taxable) {
                if (item?.taxable) {
                    if (item.taxGroup === "") {
                        item.error.taxGroup = "Tax group is required";
                        isValid = false;
                    } else {
                        item.error.taxGroup = "";
                        isValid = true;
                    }
                } else {
                    item.error.taxGroup = "";
                    isValid = true;
                }
            }

            if (item?.discountError?.length > 0) {
                isValid = false
            } else {
                isValid = true
            }
        })
        updateState("tableRowData", freeTextData?.tableRowData)
        return isValid
    }

    const handleShowMore = () => {
        setStateData({
            ...stateData,
            openNotes: true,
            isDisableNotes: true
        })
    }
    return (
        <FreeTextInvoiceContext.Provider
            value={{
                t,
                freeTextData,
                setFreeTextData,
                updateState,
                updateStateData,
                stateData,
                setStateData,
                handleNext,
                selectedCompany,
                getFreeTextInvoiceEnum,
                getPropertyUnitList,
                handleAgreementSelect,
                handleLoadMorePropertyUnit,
                handleSelectPropertyUnit,
                handlePropertyUnitSearch,
                tableData,
                setTableData,
                addTableRow,
                setRowData,
                removeObjectFromArray,
                handleCreateinvoice,
                getFreeTextInvoice,
                handleEditAgreement,
                handleRemoveUnit,
                enumValue,
                handleOnClose,
                handleChangeTransactionType,
                handlePeriodFrom,
                handlePeriodTo,
                getCustomerList,
                initialType,
                setInitialType,
                handleEdit,
                isDisabled,
                handleShowMore,
                handleCloseNewLine,
                anchorEl,
                handleAddMenuClose,
                is_taxable
            }}>
            {props.children}
        </FreeTextInvoiceContext.Provider>
    )
}

export default withNamespaces("freeTextInvoice")(FreeTextInvoiceContextProvider)