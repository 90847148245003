import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Drawer,
  Typography,
  Badge,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DialogBox,
  DrawerComponent,
  FilterGenerator,
  FormGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  enumSelect,
  enum_types,
  getCompanyOption,
  getRoutePermissionNew,
  LeadNewheading,
  LeadNewpath,
  LocalStorageKeys,
  NetWorkCallMethods,
  AlertProps,
  concat_string,
  getCalendarOptions,
  wrapLabelsInT,
} from "../../utils";
import { AddLead } from "./createlead";
import { LeadStylesParent } from "./style";
import { NewLoader } from "../../components/newLoader";
import { withNamespaces } from "react-i18next";
import { loadOptionsApis } from "../maintenancerequest/utils";
import moment from "moment";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { QuickViewDrawer } from "./components/quickViewDrawer";
import { INITIAL_QUICK_VIEW_STATE } from "./utils/quickViewUtils";
import { CloseIconSvg } from "./assets/closeIconSvg";
import { LeadComments } from "../../components/viewComments/leadComments";
import { subDays } from "date-fns";
import { GET_INTERNAL_LEAD_SOURCE } from "../../graphql/queries";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";

const LeadNew = (props) => {
  const { t } = props;
  const size = useWindowDimensions();
  const client_id = localStorage?.getItem(LocalStorageKeys?.clinetID);
  const classes = LeadStylesParent({ size });
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [list, setList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [selectedTeams, setSelectedTeams] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [is_table_loading, set_is_table_loading] = React.useState(false);
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const [open, setOpen] = React.useState({
    open: false,
    data: null,
  });
  const [data1, setData1] = React.useState("");

  const debounce = UseDebounce();

  const [isEdit, setEdit] = React.useState({
    id: null,
    bool: false,
  });
  const [filterData, setFilterData] = React.useState({
    status: "In Progress",
    lead_source: [],
    external_lead_source: [],
    lead_owner: [],
    revenue_type: [],
    purpose: [],
    urgent_type: [],
    date: "",
    active: true,
    custom_date_value: "",
  });
  const [drawer, setDrawer] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [enumValue, setEnumValue] = React.useState({
    source: [],
    revenue_type: [],
    purpose_global_type: [],
    urgent_type: [],
  });
  const [permission, setPermission] = React.useState({});
  const [commentsDrawer, setCommentsDrawer] = React.useState(false);
  const [is_add_form_open, set_is_add_form_open] = useState(false);
  const [quick_view_state, set_quick_view_state] = useState(
    INITIAL_QUICK_VIEW_STATE
  );
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          if (company?.list?.length > 0) {
            // getDetails(company?.selected);
            getEnum();
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  const OpenCommentsDrawer = () => {
    setCommentsDrawer(!commentsDrawer);
  };
  const Status = {
    "In Progress": "Open",
    Converted: "Qualify",
    "Lost Lead": "Disqualify",
    Archieved: "Archieved",
    Unqualified: "Unqualified",
  };
  const GetList = (
    company,
    team,
    searchText,
    offSet,
    limit,
    source,
    status,
    lead_owner,
    purpose,
    type,
    startDate,
    endDate,
    active,
    urgent_type,
    external_lead_source
  ) => {
    set_is_table_loading(true);
    let clientID = localStorage.getItem(LocalStorageKeys.clinetID);
    const payload = {
      offset: offSet,
      company_id: company?.value,
      limit: limit,
      client: clientID,
      is_active: true,
      search: searchText ?? undefined,
      internal_lead_source_id: source?.map((x) => x) ?? undefined,
      status: status?.length > 0 ? status : undefined,
      lead_owner,
      purpose,
      type,
      startDate: !!startDate
        ? `${moment(startDate).format("YYYY-MM-DD")} 00:00:00`
        : null,
      endDate: !!endDate
        ? `${moment(endDate).format("YYYY-MM-DD")} 23:59:59`
        : null,
      urgent: urgent_type,
      comment_from: moment(subDays(new Date(), 14)).format("YYYY-MM-DD"),
      comment_to: moment(new Date()).format("YYYY-MM-DD"),
      domain_referrer: external_lead_source
    };
    if (team?.value !== "noteams") {
      payload["team"] = team?.value;
    }
    NetworkCall(
      `${config.api_url}/lead_proxy/get_proxy_lead_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let list = res?.data?.data?.proxy_lead?.map((val) => {
          return {
            id: val?.id ?? "",
            LeadNumber: val?.reference_no ?? "",
            LeadName: val?.lead_name ?? "",
            MobileNumber: `${val?.mobile_country_code ?? ""}  ${val?.mobile_no ?? ""
              }`,
            Email: val?.email ?? "-",
            Source: val?.lead_source ?? "-",
            LeadOwner:
              val?.first_name || val?.last_name
                ? `${val?.first_name ?? ""} ${val?.last_name ?? ""}`
                : "-",
            Status: Status[val?.status] ?? "",
            description: val?.description ?? "-",
            date: val?.created_at ?? "-",
            purpose: val?.purpose ?? "-",
            statusDate: val?.updated_at ?? "-",
            type: val?.revenue_type ?? "-",
            country_name: val?.country_name ?? "-",
            company: val?.company ?? "-",
            icon: "more_2",
            urgent: val?.urgent,
            data: val,
            internal_lead_source_name: val?.internal_lead_source_name,
            is_active: val?.is_active,
          };
        });
        setList({
          count: res?.data?.data?.count ?? 0,
          list: list ?? [],
          comment_count: res?.data?.data?.comment?.[0]?.count
        });
        setLoader(false);
        set_is_table_loading(false);
      })
      .catch((err) => {
        setLoader(false);
        set_is_table_loading(false);
      });
  };
  // pagination function
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    GetList(
      selectedCompany,
      selectedTeams,
      searchText,
      offset,
      limit,
      filterData?.lead_source?.map((x) => x?.value),
      filterData?.status,
      filterData?.lead_owner?.map((x) => x?.value) ?? [],
      filterData?.purpose?.map((x) => x?.value) ?? [],
      filterData?.type?.map((x) => x?.value) ?? [],
      filterData?.date?.value?.from_date,
      filterData?.date?.value?.to_date,
      filterData?.active,
      filterData?.urgent_type,
      filterData?.external_lead_source?.map((x)=> x?.value)
    );
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    GetList(
      selectedCompany,
      selectedTeams,
      searchText,
      0,
      value,
      filterData?.lead_source?.map((x) => x?.value),
      filterData?.status,
      filterData?.lead_owner?.map((x) => x?.value) ?? [],
      filterData?.purpose?.map((x) => x?.value) ?? [],
      filterData?.type?.map((x) => x?.value) ?? [],
      filterData?.date?.value?.from_date,
      filterData?.date?.value?.to_date,
      filterData?.active,
      filterData?.urgent_type,
      filterData?.date,
      filterData?.external_lead_source?.map((x)=> x?.value)
    );
  };
  // dialog open
  const OpenDrawer = () => {
    setDrawer(!drawer);
  };
  // icon click function
  const handleIcon = (type, data, status) => {
    const full_data = data?.data;
    switch (type) {
      case "edit":
        setEdit({
          bool: true,
          id: data?.id,
        });
        setDialog(true);
        break;

      case "view":
        history.push({
          pathname: Routes.leadview,
          state: {
            main: {
              company: selectedCompany,
              id: data?.id,
              selectedCompany: selectedCompany,
              team: selectedTeams?.value === "noteams" ? null : selectedTeams
            },
          },
        });
        break;

      case "Achieved":
        save({
          status: "In Progress",
          id: data?.id,
        });
        setOpen({
          data: data,
          open: false,
        });
        break;

      case "delete":
        save({
          id: data?.id,
          is_active: !data?.is_active,
        });
        break;

      case "quick_view":
        set_quick_view_state({
          id: full_data?.id,
          lead_name: full_data?.lead_name ?? undefined,
          reference_no: full_data?.reference_no ?? undefined,
          purpose: full_data?.purpose ?? undefined,
          revenue_type: full_data?.revenue_type ?? undefined,
          mobile_no: full_data?.mobile_no
            ? concat_string(
              {
                mobile_code: full_data?.mobile_country_code,
                mobile_no: full_data?.mobile_no,
              },
              ["mobile_code", "mobile_no"],
              " "
            )
            : undefined,
          email_id: full_data?.email ?? undefined,
          description: full_data?.description ?? undefined,
          is_add_comment_enabled:
            full_data?.status === "In Progress" ? true : false,
          priority: full_data?.urgent ?? undefined,
        });
        set_is_add_form_open(!is_add_form_open);
        break;

      default:
        history.push({
          pathname: Routes.leadview,
          state: {
            main: {
              company: selectedCompany,
              id: data?.id,
              selectedCompany: selectedCompany,
            },
          },
        });
        break;
    }
  };
  // close dialog and clear all states
  const closeDrawer = () => {
    setDialog(!dialog);
    setEdit({
      id: null,
      bool: false,
    });
  };
  // add laed function
  const addlead = () => {
    setDialog(!dialog);
  };
  // filter apply function
  const onApplyFilter = (value) => {
    setFilterData(value);
    GetList(
      selectedCompany,
      selectedTeams,
      searchText,
      0,
      limit,
      value?.lead_source?.map((x) => x?.value) ?? [],
      value?.status ?? [],
      value?.lead_owner?.map((x) => x?.value) ?? [],
      value?.purpose?.map((x) => x?.value) ?? [],
      value?.type?.map((x) => x?.value) ?? [],
      value?.date?.value?.from_date,
      value?.date?.value?.to_date,
      value?.active,
      value?.urgent_type,
      value?.external_lead_source?.map((x)=>x?.value)
    );
  };
  // get details of tabel data
  const getDetails = (team) => {
    GetList(
      selectedCompany,
      team,
      searchText,
      0,
      limit,
      filterData?.lead_source?.map((x) => x?.value),
      filterData?.status,
      filterData?.lead_owner?.map((x) => x?.value) ?? [],
      filterData?.purpose?.map((x) => x?.value) ?? [],
      filterData?.type?.map((x) => x?.value) ?? [],
      filterData?.date?.value?.from_date,
      filterData?.date?.value?.to_date,
      filterData?.active,
      filterData?.urgent_type,
      filterData?.external_lead_source?.map((x)=>x?.value)
    );
  };
  // get enum
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.lead_source,
      enum_types.revenue_type,
      enum_types.purpose_global_type,
      enum_types.urgent_type,
    ]);
    setEnumValue({
      source: result?.lead_source,
      revenue_type: result?.revenue_type,
      purpose_global_type: result?.purpose_global_type,
      urgent_type: result?.urgent_type,
    });
  };
  // Function to change property
  const handleTeamsChange = (team) => {
    setSelectedTeams(team);
    getDetails(team);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    debounce(
      () =>
        GetList(
          selectedCompany,
          selectedTeams,
          value,
          0,
          10,
          filterData?.lead_source?.map((x) => x?.value),
          filterData?.status,
          filterData?.lead_owner?.map((x) => x?.value) ?? [],
          filterData?.purpose?.map((x) => x?.value) ?? [],
          filterData?.type?.map((x) => x?.value) ?? [],
          filterData?.date?.value?.from_date,
          filterData?.date?.value?.to_date,
          filterData?.active,
          filterData?.urgent_type,
          filterData?.external_lead_source?.map((x)=>x?.value)
        ),
      800
    );
  };

  const save = (data) => {
    let datas = {
      id: data?.id,
      is_active: data?.is_active,
      status: data?.status,
      archieved_description: data?.archieved_description,
    };

    NetworkCall(
      `${config.api_url}/lead_proxy/upsert`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        getDetails(selectedCompany?.value);
        setOpen({
          data: data,
          open: false,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  const render = () => {
    return (
      <>
        {/* sub nav bar */}
        <Subheader
          hideBackButton={true}
          title={`${t("Lead")}(${list?.count ?? 0})`}
          select
          options={companyList}
          value={selectedCompany}
          placeholder={t("Search By Lead Name")}
          goBack={() => {
            history.push(Routes.home);
          }}
          onchange={(e) => {
            setSelectedCompany(e);
          }}
          selectTeamsOptions
          handleTeamsChange={handleTeamsChange}
          companyId={selectedCompany?.value}
          moduleId={moduleId}
          selectedTeams={selectedTeams}
        />

        {loader ? (
          <NewLoader />
        ) : (
          <>
            {/* body contant */}
            <div className={classes.root}>
              <Grid container className={classes.content} spacing={2}>
                <Grid item xs={4}>
                  <Box>
                    {/* search box */}
                    <SearchFilter
                      value={searchText}
                      placeholder={t("Search")}
                      handleChange={(value) => handleSearch(value)}
                      customfieldSx={{
                        "& .MuiOutlinedInput-root": { height: "40px" },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                  <Box display={"flex"} sx={{ float: "right" }}>
                    <Stack
                      direction="row"
                      divider={
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ marginInline: "16px" }}
                        />
                      }
                      spacing={2}
                    >
                      <Box mr={1}>
                        <Tooltip title={t("View Latest Comment")} placement="top" arrow>
                          {list.comment_count > 0 ?
                            <IconButton size="small" className={classes.img} onClick={OpenCommentsDrawer} >
                              <Badge variant="dot" color="error" classes={{ badge: classes.customBadge }}>
                                <img src="/images/chat.svg" alt="chat" />
                              </Badge>
                            </IconButton>
                            :
                            <IconButton size="small" className={classes.img} onClick={OpenCommentsDrawer} >
                              <img src="/images/chat.svg" alt="chat" />
                            </IconButton>
                          }
                        </Tooltip>
                      </Box>
                      <Box>
                        <IconButton
                          size="small"
                          className={classes.img}
                          onClick={OpenDrawer}
                        >
                          <img src="/images/filter.svg" alt="filter" />
                        </IconButton>
                      </Box>
                      {permission?.create && (
                        <Box>
                          {/* add agent button */}
                          <Button
                            variant="contained"
                            className={classes.button}
                            onClick={addlead}
                          >
                            {t("Create Lead")}
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Grid>

                {/* Main tableData */}
                <Grid item xs={12} className={classes.tableMain}>
                  <TableWithPagination
                    is_loading={is_table_loading}
                    heading={LeadNewheading(t)}
                    rows={list?.list ?? ""}
                    path={LeadNewpath}
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    archived={true}
                    handleIcon={handleIcon}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={list?.count}
                    page={page}
                    limit={limit}
                    tableType="no-side"
                    noDataSvg
                    dataType={[
                      { type: ["lead_more"], icon: "icon" },
                      { type: ["text"], name: "LeadNumber" },
                      { type: ["date"], name: "date" },
                      { type: ["long_text"], name: "description" },
                      { type: ["text"], name: "LeadName" },
                      { type: ["text"], name: "Email" },
                      { type: ["text"], name: "company" },
                      { type: ["text"], name: "country_name" },
                      { type: ["text"], name: "MobileNumber" },
                      { type: ["text"], name: "purpose" },
                      { type: ["text"], name: "type" },
                      { type: ["text"], name: "internal_lead_source_name" },
                      { type: ["priority"], name: "urgent" },
                      { type: ["text"], name: "LeadOwner" },
                      { type: ["status"], name: "Status" },
                    ]}
                    height={"calc(100vh - 315px)"}
                    view={permission?.read}
                    edit={permission?.update}
                    delete={permission?.delete}
                  />
                </Grid>
                {/* end Main tableData */}
              </Grid>

              {/* filter drawer */}
              {drawer && (
                <FilterGenerator
                  open={drawer}
                  onClose={() => setDrawer(false)}
                  components={[
                    {
                      component: "select",
                      value: filterData?.lead_source,
                      options: [],
                      isMulti: true,
                      label: t("Internal Lead Source"),
                      placeholder: t("Select Internal Lead Source"),
                      state_name: "lead_source",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptions(
                          search,
                          array,
                          handleLoading,
                          GET_INTERNAL_LEAD_SOURCE,
                          "internal_lead_source",
                          { client_id }
                        ),
                      debounceTimeout: 800,
                      isPaginate: true,
                    },
                    {
                      component: "select",
                      value: filterData?.external_lead_source,
                      options: [],
                      isMulti: true,
                      label: t("External Lead Source"),
                      placeholder: t("Select External Lead Source"),
                      state_name: "external_lead_source",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "lead_proxy/get-domain",
                          {
                            company_id: selectedCompany?.value,
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                        ),
                      debounceTimeout: 800,
                      isPaginate: true,
                    },
                    {
                      component: "select",
                      value: filterData?.lead_owner,
                      options: [],
                      isMulti: true,
                      label: t("Lead Owner"),
                      placeholder: t("Select Lead Owner"),
                      state_name: "lead_owner",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "lead/lead_owner",
                          {
                            company_id: selectedCompany?.value,
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                          // manualResponse
                        ),
                      debounceTimeout: 800,
                      isPaginate: true,
                    },
                    {
                      component: "select",
                      value: filterData?.type,
                      options: enumValue?.revenue_type ?? [],
                      isMulti: true,
                      label: t("Type"),
                      placeholder: t("Select Type"),
                      state_name: "type",
                    },
                    {
                      component: "select",
                      value: filterData?.purpose,
                      options: enumValue?.purpose_global_type ?? [],
                      isMulti: true,
                      label: t("Purpose"),
                      placeholder: t("Select Purpose"),
                      state_name: "purpose",
                    },
                    {
                      component: "select",
                      value: filterData?.date,
                      state_name: "date",
                      label: t("Created On"),
                      placeholder: t("Select Date"),
                      options: getCalendarOptions(),
                    },
                    {
                      component: "toggleButton",
                      value: filterData?.status,
                      options: [
                        { label: t("Open"), value: "In Progress" },
                        { label: t("Qualify"), value: "Converted" },
                        { label: t("Disqualify"), value: "Lost Lead" },
                        { label: t("Archived"), value: "Archieved" },
                        { label: t("Unqualified"), value: "Unqualified" },
                      ],
                      isMulti: false,
                      state_name: "status",
                      label: t("Status"),
                    },
                    {
                      component: "toggleButton",
                      value: filterData?.urgent_type,
                      options: wrapLabelsInT(enumValue?.urgent_type, t),
                      isMulti: true,
                      state_name: "urgent_type",
                      label: t("Priority"),
                    },
                  ]}
                  onApply={(value) => onApplyFilter(value)}
                />
              )}
            </div>
          </>
        )}

        {/* create lead */}
        <React.Fragment key={'right'}>
          <Drawer
            anchor={'right'}
            open={commentsDrawer}
            onClose={() => setCommentsDrawer(false)}
          >
            <Box>
              <LeadComments t={t} closeDrawer={() => setCommentsDrawer(false)} company_id={selectedCompany?.value} clientID={localStorage.getItem(LocalStorageKeys.clinetID)} filterData={filterData} selectedCompany={selectedCompany} selectedTeams={selectedTeams} />
            </Box>
          </Drawer >
        </React.Fragment >
        <React.Fragment key={"right"}>
          <Drawer anchor={"right"} open={dialog} onClose={closeDrawer}>
            <AddLead
              t={t}
              permission={permission}
              isEdit={isEdit}
              parentID={"id_123"}
              closeDrawer={closeDrawer}
              selectedCompany={selectedCompany?.value}
              getDetails={getDetails}
              company={selectedCompany}
            />
          </Drawer>
        </React.Fragment>

        <DialogBox
          open={open?.open}
          background
          maxWidth={"xs"}
          isnotTitle
          handleClose={() =>
            setOpen({
              open: false,
              ...open,
            })
          }
          component={
            <Box p={2}>
              <FormGenerator
                t={t}
                spacing={1.5}
                components={[
                  {
                    isActive: true,
                    component: "text",
                    label: "Description/Requirement",
                    value: data1,
                    placeholder: "Description/Requirement",
                    onChange: (e) => setData1(e?.target?.value),
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 12,
                    },
                    isPaginate: true,
                    multiline: true,
                    height: "60px",
                  },
                ]}
              />
              <Button
                className={classes.commandbtn}
                disabled={setData1?.length > 0 ? false : true}
                style={{ width: "100%", marginTop: "12px" }}
                onClick={() =>
                  save({
                    status: "In Progress",
                    id: open?.data?.id,
                    archieved_description: data1,
                  })
                }
              >
                Submit
              </Button>
            </Box>
          }
        />
        <DrawerComponent
          anchor={size?.width >= 600 ? "right" : "bottom"}
          DrawerClass={classes.quick_view_drawer}
          open={is_add_form_open}
          onClose={() => set_is_add_form_open(!is_add_form_open)}
          component={
            <>
              <Stack
                className={classes.quick_view_drawer_header}
                direction={"row"}
                columnGap={"12px"}
                alignItems={"center"}
              >
                <IconButton
                  onClick={() => set_is_add_form_open(!is_add_form_open)}
                  className={classes.quick_view_drawer_close_button}
                >
                  <CloseIconSvg />
                </IconButton>
                <Typography className={classes.quick_view_drawer_header_title}>
                  {t("Quick View")}
                </Typography>
              </Stack>
              <Box className={classes.quick_view_drawer_body_view}>
                <QuickViewDrawer
                  t={t}
                  selected_company={selectedCompany}
                  data={quick_view_state}
                />
              </Box>
            </>
          }
        />
      </>
    );
  };

  return <>{accessCheckRender(render, permission)}</>;
};
export default withNamespaces("leads")(LeadNew);
