export const intitalState = (data, is_edit = false) => {
  return {
    id: "",
    name: "",
    job_title: "",
    company: "",
    mobile: "",
    des: "",
    email: "",
    leadsource: "",
    purpose: {
      value: "Commercial",
      label: "Commercial",
    },
    selectedUnit: [],
    lead_owner: null,
    fixUnit: [],
    edit_owner: null,
    property_id: null,
    changeUnit: [],
    city: "",
    country: !is_edit
      ? {
          value: data?.data?.country?.id,
          label: data?.data?.country?.country_name,
        }
      : "",
    internal_lead_source: "",
    count: null,
    type: {
      value: "Lease",
      label: "Lease",
    },
    duration: {
      select: "Monthly",
      value: "12",
    },
    lease_start_date: new Date(),
    active: true,
    unit_type: "",
    preferred_mode_contact_type: "",
    property: "",
    error: {
      name: "",
      mobile: "",
      email: "",
      leadsource: "",
      purpose: "",
      selectedUnit: "",
      type: "",
      duration: "",
      lease_start_date: "",
      preferred_mode_contact_type: "",
    },
  };
};

export const returnEditData = (data) => {
  const result = {
    id: data?.id,
    name: data?.lead_name,
    des: data?.description,
    mobile: {
      mobile: data?.mobile_no,
      mobile_code: data?.mobile_country_code,
    },
    email: data?.email,
    leadsource: {
      value: data?.lead_source,
      label: data?.lead_source,
    },
    purpose: {
      value: data?.purpose,
      label: data?.purpose,
    },
    selectedUnit: data?.proxy_units,
    fixUnit: data?.proxy_units,
    changeUnit: [],
    edit_owner: data?.owner_id
      ? {
          label: data?.owner_name,
          value: data?.owner_id,
          label1: data?.account_no,
        }
      : null,
    type: {
      value: data?.revenue_type,
      label: data?.revenue_type,
    },
    duration: {
      value: data?.value,
      select: data?.lease_period,
    },
    lease_start_date: new Date(data?.lease_start_date),
    // property_id: data?.property?.value ? { ...data?.property, lable1: data?.property } : null,
    job_title: data?.job_title,
    company: data?.company,
    city: data?.city,
    country: {
      value: data?.country_id,
      label: data?.country_name,
    },
    internal_lead_source: {
      value: data?.internal_lead_source_id,
      label: data?.internal_lead_source_name,
    },
    count: data?.unit_count ?? null,
    active: data?.is_active,
    property: {
      value: data?.property_id,
      label: data?.property_name,
    },
    unit_type: data?.unit_type
      ? {
          value: data?.unit_type,
          label: data?.unit_type_name,
        }
      : "",
    preferred_mode_contact_type: {
      value: data?.preferred_mode_contact,
      label: data?.preferred_mode_contact,
    },
    error: {
      name: "",
      mobile: "",
      email: "",
      leadsource: "",
      purpose: "",
      selectedUnit: "",
    },
  };
  return result;
};
